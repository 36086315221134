import * as React from 'react';
import { useIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
// ----------------------------------------------------------------------------

export default function ObjectiveProgramCard({ entity }) {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Card key={entity.programGuid.toString()}>
      <CardHeader
        title={
          <div>
            <p>{entity.titluProgram}</p>
          </div>
        }
        action={
          <Button variant="outlined" onClick={() => navigate(`${entity.programGuid}/obiective`)}>
            {intl.formatMessage({ id: 'objectiveProgramCard.button.label' })}
          </Button>
        }
      />
      <CardContent>
        <p>{intl.formatMessage({ id: 'programCard.competition' })}</p>
        <p>{entity.numeCompetitieParinte}</p>
      </CardContent>
    </Card>
  );
}
