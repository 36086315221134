import programFormModel from './programInformationFormModel';

const {
  programInformationFormField: { programAdmin }
} = programFormModel;

export const parseProgramInformation = (formProgramInformationValues) => {
  const parseProgramInformationForm = { ...formProgramInformationValues };

  parseProgramInformationForm[programAdmin.name] = {
    Nume: parseProgramInformationForm[programAdmin.name].label,
    UserGuid: parseProgramInformationForm[programAdmin.name].id
  };

  return parseProgramInformationForm;
};
