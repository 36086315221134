import { useIntl } from 'react-intl';
import { Backdrop, CircularProgress, Grid, Stack } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import PropTypes from 'prop-types';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import InstitutionProgramEvaluationProjectSheet from './InstitutionProgramEvaluationProjectSheet';
import { evaluationActions } from '../../../actions';
import { ACTIONS, ENTITIES } from '../../../utils/enums';
// ----------------------------------------------------------------------

InstitutionProgramEvaluationObjectives.propTypes = {
  objectives: PropTypes.array,
  programId: PropTypes.string
};

export default function InstitutionProgramEvaluationObjectives({ objectives, programId }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [selectedObjective, setSelectedObjective] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const evaluation = useSelector((state) => state.evaluation);

  if (evaluation?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Stack spacing={2}>
      {selectedObjective && (
        <Button
          sx={{ width: 1 / 10 }}
          variant="outlined"
          onClick={() => {
            if (selectedProject) {
              setSelectedProject(null);
            } else if (selectedObjective) {
              setSelectedObjective(null);
            }
          }}
        >
          {intl.formatMessage({ id: 'form.previous_step' })}
        </Button>
      )}
      {!selectedObjective &&
        objectives &&
        objectives.map((objective) => (
          <Stack spacing={2} key={objective.obiectivGuid.toString()}>
            <Card>
              <CardHeader
                title={
                  <div>
                    <p>{objective.titluObiectiv}</p>
                  </div>
                }
                action={
                  <Button
                    variant="outlined"
                    onClick={() => setSelectedObjective(objective.obiectivGuid)}
                  >
                    {intl.formatMessage({ id: 'programEvaluation.view.projects' })}
                  </Button>
                }
              />
              <CardContent />
            </Card>
          </Stack>
        ))}
      {selectedObjective &&
        !selectedProject &&
        !programId &&
        objectives &&
        objectives
          .find((objective) => objective.obiectivGuid === selectedObjective)
          .proiecte.map((project) => (
            <Stack spacing={2} key={project.proiectGuid.toString()}>
              <Card>
                <CardHeader
                  title={
                    <div>
                      <p>{project.titluProiect}</p>
                    </div>
                  }
                  action={
                    <Button
                      variant="outlined"
                      onClick={() => setSelectedProject(project.proiectGuid)}
                    >
                      {intl.formatMessage({ id: 'programEvaluation.view.projects.sheet' })}
                    </Button>
                  }
                />
                <CardContent />
              </Card>
            </Stack>
          ))}
      {selectedObjective &&
        !selectedProject &&
        programId &&
        objectives &&
        objectives
          .find((objective) => objective.obiectivGuid === selectedObjective)
          .proiecte.map((project) => (
            <Card key={project.proiectGuid}>
              <Grid container alignItems="center">
                <Grid item xs={9} sm={9}>
                  <CardHeader
                    title={
                      <div>
                        <p>{project.titluProiect}</p>
                      </div>
                    }
                  />
                  <CardContent>
                    <p>
                      {intl.formatMessage({ id: 'global.result' })}:{' '}
                      {project.fisaFinala.criterii.reduce((prev, curr) => prev + curr.scor, 0)} /{' '}
                      {project.fisaFinala.criterii.reduce((prev, curr) => prev + curr.scorMaxim, 0)}
                    </p>
                  </CardContent>
                </Grid>
                <Grid item xs={3} sm={3}>
                  <Stack spacing={2} sx={{ mr: 2 }}>
                    <Button
                      variant="outlined"
                      onClick={() => setSelectedProject(project.proiectGuid)}
                    >
                      {intl.formatMessage({ id: 'program.card.view.sheet' })}
                    </Button>
                    {user.abilities.cannot(ACTIONS.VIEW, ENTITIES.ADMINISTRATOR) && (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          dispatch(
                            evaluationActions.sendProjectToMonitoring(
                              project.proiectGuid,
                              programId
                            )
                          );
                        }}
                      >
                        {intl.formatMessage({ id: 'global.send.monitoring' })}
                      </Button>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          ))}
      {selectedObjective && selectedProject && objectives && (
        <InstitutionProgramEvaluationProjectSheet
          sheet={objectives
            .find((objective) => objective.obiectivGuid === selectedObjective)
            .proiecte.find((project) => project.proiectGuid === selectedProject)}
        />
      )}
    </Stack>
  );
}
