import informatiiStiintificeForm from './informatiiStiintificeFormModel';

const {
  parentName,
  informatiiStiintificeFormFields: {
    prezentare,
    situatieTara,
    situatieStrainatate,
    contributie,
    dotariExistente,
    dotariNecesare
  }
} = informatiiStiintificeForm;

export const parseInformatiiStiintifice = (proiect) => ({
  [parentName]: {
    [prezentare.name]: proiect[prezentare.name],
    [situatieTara.name]: proiect[situatieTara.name],
    [situatieStrainatate.name]: proiect[situatieStrainatate.name],
    [contributie.name]: proiect[contributie.name],
    [dotariExistente.name]: proiect[dotariExistente.name],
    [dotariNecesare.name]: proiect[dotariNecesare.name]
  }
});
