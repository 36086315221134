import myAccountFormModel from './myAccountFormModel';

const {
  myAccountFormField: { lastName, firstName, email, phone }
} = myAccountFormModel;

export const getInitialMyAccountFormValues = (user) => ({
  id: user.userId,
  address: user.address,
  [lastName.name]: user.lastName ? user.lastName : '',
  [firstName.name]: user.firstName ? user.firstName : '',
  [email.name]: user.email ? user.email : '',
  [phone.name]: user.phone ? user.phone : ''
});
