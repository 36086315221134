export const monitoringConstants = {
  GET_PROGRAM_CYCLES_SUCCESS: 'GET_PROGRAM_CYCLES_SUCCESS',
  GET_PROGRAM_CYCLES_FAILURE: 'GET_PROGRAM_CYCLES_FAILURE',
  GET_PROGRAM_CYCLES_REQUEST: 'GET_PROGRAM_CYCLES_REQUEST',

  GET_PROGRAM_ASSIGNED_MONITORS_SUCCESS: 'GET_PROGRAM_ASSIGNED_MONITORS_SUCCESS',
  GET_PROGRAM_ASSIGNED_MONITORS_FAILURE: 'GET_PROGRAM_ASSIGNED_MONITORS_FAILURE',
  GET_PROGRAM_ASSIGNED_MONITORS_REQUEST: 'GET_PROGRAM_ASSIGNED_MONITORS_REQUEST',

  GET_MONITOR_PROGRAMS_SUCCESS: 'GET_MONITOR_PROGRAMS_SUCCESS',
  GET_MONITOR_PROGRAMS_FAILURE: 'GET_MONITOR_PROGRAMS_FAILURE',
  GET_MONITOR_PROGRAMS_REQUEST: 'GET_MONITOR_PROGRAMS_REQUEST',

  GET_MONITOR_PROJECTS_SUCCESS: 'GET_MONITOR_PROJECTS_SUCCESS',
  GET_MONITOR_PROJECTS_FAILURE: 'GET_MONITOR_PROJECTS_FAILURE',
  GET_MONITOR_PROJECTS_REQUEST: 'GET_MONITOR_PROJECTS_REQUEST',

  GET_MONITOR_PROJECT_CYCLE_PHASES_SUCCESS: 'GET_MONITOR_PROJECT_CYCLE_PHASES_SUCCESS',
  GET_MONITOR_PROJECT_CYCLE_PHASES_FAILURE: 'GET_MONITOR_PROJECT_CYCLE_PHASES_FAILURE',
  GET_MONITOR_PROJECT_CYCLE_PHASES_REQUEST: 'GET_MONITOR_PROJECT_CYCLE_PHASES_REQUEST',

  GET_MONITOR_PHASE_SUCCESS: 'GET_MONITOR_PHASE_SUCCESS',
  GET_MONITOR_PHASE_FAILURE: 'GET_MONITOR_PHASE_FAILURE',
  GET_MONITOR_PHASE_REQUEST: 'GET_MONITOR_PHASE_REQUEST',

  MONITOR_SUBMIT_BUDGET_CHECK_SUCCESS: 'MONITOR_SUBMIT_BUDGET_CHECK_SUCCESS',
  MONITOR_SUBMIT_BUDGET_CHECK_FAILURE: 'MONITOR_SUBMIT_BUDGET_CHECK_FAILURE',
  MONITOR_SUBMIT_BUDGET_CHECK_REQUEST: 'MONITOR_SUBMIT_BUDGET_CHECK_REQUEST',

  MONITORING_SUBMIT_PHASES_SUCCESS: 'MONITORING_SUBMIT_PHASES_SUCCESS',
  MONITORING_SUBMIT_PHASES_FAILURE: 'MONITORING_SUBMIT_PHASES_FAILURE',
  MONITORING_SUBMIT_PHASES_REQUEST: 'MONITORING_SUBMIT_PHASES_REQUEST',

  SAVE_CYCLES_SUCCESS: 'SAVE_CYCLES_SUCCESS',
  SAVE_CYCLES_FAILURE: 'SAVE_CYCLES_FAILURE',
  SAVE_CYCLES_REQUEST: 'SAVE_CYCLES_REQUEST',

  SAVE_ASSIGNED_MONITORS_SUCCESS: 'SAVE_ASSIGNED_MONITORS_SUCCESS',
  SAVE_ASSIGNED_MONITORS_FAILURE: 'SAVE_ASSIGNED_MONITORS_FAILURE',
  SAVE_ASSIGNED_MONITORS_REQUEST: 'SAVE_ASSIGNED_MONITORS_REQUEST'
};
