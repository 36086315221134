import { useIntl } from 'react-intl';
import { Backdrop, CircularProgress, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import Page from '../components/Page';
import CardList from '../components/lists/CardList';
import { evaluationActions } from '../actions';
import ProgramEvaluationProjectCard from '../components/cards/ProgramEvaluationProjectCard';
// ----------------------------------------------------------------------

export default function ProgramsEvaluationProjectsSheets() {
  const intl = useIntl();
  const { idProgram, idObiectiv } = useParams();

  const [sort, setSort] = useState('ascending');
  const [searchText, setSearchText] = useState('');
  const [projectItems, setProjectItems] = useState([]);
  const evaluation = useSelector((state) => state.evaluation);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(evaluationActions.getMyProgramEvaluationPrograms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (evaluation && evaluation.programs && evaluation.programs.length > 0) {
      const selectedProgram = evaluation.programs.find(
        (program) => program.programGuid === +idProgram
      );
      const selectedObjective = selectedProgram.obiective.find(
        (obiectiv) => obiectiv.obiectivGuid === +idObiectiv
      );

      const projects = [...selectedObjective.proiecte].sort((p1, p2) =>
        p1.titluProiect.localeCompare(p2.titluProiect)
      );

      setProjectItems(
        projects.map((project) => ({
          ...project,
          numeObiectiv: selectedObjective.titluObiectiv
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluation]);

  useEffect(() => {
    let filteredProjects = [];
    if (evaluation && evaluation.programs && evaluation.programs.length > 0) {
      const selectedProgram = [...evaluation.programs].find(
        (program) => program.programGuid === +idProgram
      );
      const selectedObjective = selectedProgram.obiective.find(
        (obiectiv) => obiectiv.obiectivGuid === +idObiectiv
      );

      if (searchText && searchText.length > 0) {
        filteredProjects = selectedObjective.proiecte.filter((proiect) =>
          proiect.titluProiect.toLowerCase().includes(searchText.toLowerCase())
        );
      } else {
        filteredProjects = selectedObjective.proiecte;
      }

      filteredProjects = filteredProjects.map((project) => ({
        ...project,
        numeObiectiv: selectedObjective.titluObiectiv
      }));
    }

    filteredProjects = filteredProjects.sort((p1, p2) =>
      sort === 'ascending'
        ? p1.titluProiect.toLowerCase().localeCompare(p2.titluProiect.toLowerCase())
        : p2.titluProiect.toLowerCase().localeCompare(p1.titluProiect.toLowerCase())
    );

    setProjectItems([...filteredProjects]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, searchText]);

  if (evaluation?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Page title={intl.formatMessage({ id: 'applicant.programmes' })} my={2}>
      <Container>
        {projectItems && (
          <CardList
            entityId="proiectGuid"
            sort={sort}
            setSort={setSort}
            searchText={searchText}
            setSearchText={setSearchText}
            sortFieldTitle={intl.formatMessage({ id: 'global.name' })}
            items={projectItems}
            Card={ProgramEvaluationProjectCard}
          />
        )}
      </Container>
    </Page>
  );
}
