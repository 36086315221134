import { monitoringConstants } from '../constants';

export function monitoring(
  state = {
    loading: false,
    saving: false,
    programCycles: [],
    programs: [],
    projects: [],
    phases: [],
    phase: null,
    assignedMonitors: [],
    notaJustificativa: null
  },
  action
) {
  switch (action.type) {
    case monitoringConstants.GET_PROGRAM_CYCLES_REQUEST:
      return {
        ...state,
        loading: true,
        programCycles: []
      };
    case monitoringConstants.GET_PROGRAM_ASSIGNED_MONITORS_REQUEST:
      return {
        ...state,
        loading: true,
        assignedMonitors: []
      };
    case monitoringConstants.GET_MONITOR_PROGRAMS_REQUEST:
      return {
        ...state,
        loading: true,
        programs: []
      };
    case monitoringConstants.GET_MONITOR_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
        projects: []
      };
    case monitoringConstants.GET_MONITOR_PHASE_REQUEST:
      return {
        ...state,
        loading: true,
        phase: null
      };
    case monitoringConstants.GET_MONITOR_PROJECT_CYCLE_PHASES_REQUEST:
      return {
        ...state,
        loading: true,
        phases: [],
        notaJustificativa: null
      };
    case monitoringConstants.MONITORING_SUBMIT_PHASES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case monitoringConstants.GET_PROGRAM_CYCLES_SUCCESS:
      return {
        ...state,
        loading: false,
        programCycles: action.cycles
      };
    case monitoringConstants.GET_PROGRAM_ASSIGNED_MONITORS_SUCCESS:
      return {
        ...state,
        loading: false,
        assignedMonitors: action.monitors
      };
    case monitoringConstants.GET_MONITOR_PROGRAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        programs: action.programs.programe
      };
    case monitoringConstants.GET_MONITOR_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: action.projects.proiecte
      };
    case monitoringConstants.GET_MONITOR_PROJECT_CYCLE_PHASES_SUCCESS:
      return {
        ...state,
        loading: false,
        phases: action.phases.faze,
        notaJustificativa: action.phases.notaJustificativa
      };
    case monitoringConstants.GET_MONITOR_PHASE_SUCCESS:
      return {
        ...state,
        loading: false,
        phase: action.phase
      };
    case monitoringConstants.MONITORING_SUBMIT_PHASES_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case monitoringConstants.GET_PROGRAM_CYCLES_FAILURE:
    case monitoringConstants.GET_PROGRAM_ASSIGNED_MONITORS_FAILURE:
    case monitoringConstants.GET_MONITOR_PROGRAMS_FAILURE:
    case monitoringConstants.GET_MONITOR_PROJECTS_FAILURE:
    case monitoringConstants.GET_MONITOR_PROJECT_CYCLE_PHASES_FAILURE:
    case monitoringConstants.GET_MONITOR_PHASE_FAILURE:
    case monitoringConstants.MONITORING_SUBMIT_PHASES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
