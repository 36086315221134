import userFormModel from './userFormModel';
import { UserRoles } from '../../domain/UserRoles';

const {
  userFormField: { lastName, firstName, email, phone, roles, institution }
} = userFormModel;

export const getInitialUserFormValues = (user) => ({
  id: user.id,
  address: user.address,
  [lastName.name]: user.lastName ? user.lastName : '',
  [firstName.name]: user.firstName ? user.firstName : '',
  [email.name]: user.email ? user.email : '',
  [phone.name]: user.phone ? user.phone : '',
  [roles.name]: user.userType ? user.userType : [UserRoles.AplicantProiect],
  [institution.name]: user.institutionGuid ? user.institutionGuid : ''
});
