import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userActions } from '../../../actions';

// ----------------------------------------------------------------------

export default function ResetPasswordForm({ token }) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required(intl.formatMessage({ id: 'form.my_account.password.required' }))
      .test(
        'consecutiveChars',
        intl.formatMessage({ id: 'form.my_account.password.consecutiveChars' }),
        (value) =>
          // eslint-disable-next-line react/no-this-in-sfc
          !value.match(/(.)\1/)
      )
      .min(10, intl.formatMessage({ id: 'form.my_account.password.lengthMin' }))
      .max(128, intl.formatMessage({ id: 'form.my_account.password.lengthMax' }))
      .matches(/\w*[a-z]\w*/, intl.formatMessage({ id: 'form.my_account.password.smallLetter' }))
      .matches(/\w*[A-Z]\w*/, intl.formatMessage({ id: 'form.my_account.password.capitalLetter' }))
      .matches(/\d/, intl.formatMessage({ id: 'form.my_account.password.number' }))
      .matches(
        /[!+@#$%^&*()\-_"={};:,<.>]/,
        intl.formatMessage({ id: 'form.my_account.password.specialChar' })
      )
      .matches(/^\S*$/, intl.formatMessage({ id: 'form.my_account.password.whiteSpace' })),
    confirmPassword: Yup.string()
      .required(intl.formatMessage({ id: 'form.my_account.password.required' }))
      .test(
        'passwords-match',
        intl.formatMessage({ id: 'form.my_account.password.match' }),
        function (value) {
          // eslint-disable-next-line react/no-this-in-sfc
          return this.parent.password === value;
        }
      )
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: (values) => {
      dispatch(userActions.resetPassword(values.password, token, navigate));
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="password"
            type={showPassword ? 'text' : 'password'}
            label={intl.formatMessage({ id: 'form.my_account.password' })}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            required
          />

          <TextField
            fullWidth
            autoComplete="confirm-password"
            type={showConfirmPassword ? 'text' : 'password'}
            label={intl.formatMessage({ id: 'form.my_account.confirm_password' })}
            {...getFieldProps('confirmPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowConfirmPassword} edge="end">
                    <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
            required
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            onClick={formik.submitForm}
          >
            {intl.formatMessage({ id: 'form.my_account.reset_password' })}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
