import institutionInfoFormModel from './institutionInfoFormModel';

const {
  institutionInfoFormField: {
    name,
    shortName,
    fiscalCode,
    region,
    county,
    city,
    address,
    directorLastName,
    directorFirstName,
    directorEmail,
    directorPhone,
    directorTitle,
    economicDirectorLastName,
    economicDirectorFirstName,
    economicDirectorEmail,
    economicDirectorPhone,
    economicDirectorTitle
  }
} = institutionInfoFormModel;

export const getInitialInstitutionInfoFormValues = (institution) => ({
  id: institution.institutieGuid,
  [name.name]: institution.denumireCompleta ? institution.denumireCompleta : '',
  [shortName.name]: institution.denumireScurta ? institution.denumireScurta : '',
  [fiscalCode.name]: institution.codFiscal ? institution.codFiscal : '',
  [region.name]: institution.regiune ? institution.regiune : '',
  [county.name]: institution.judet ? institution.judet : '',
  [city.name]: institution.oras ? institution.oras : '',
  [address.name]: institution.adresa ? institution.adresa : '',
  [directorLastName.name]: institution.numeDirectorGeneral ? institution.numeDirectorGeneral : '',
  [directorFirstName.name]: institution.prenumeDirectorGeneral
    ? institution.prenumeDirectorGeneral
    : '',
  [directorEmail.name]: institution.emailDirectorGeneral ? institution.emailDirectorGeneral : '',
  [directorPhone.name]: institution.telefonDirectorGeneral
    ? institution.telefonDirectorGeneral
    : '',
  [directorTitle.name]: institution.titluDirectorGeneral ? institution.titluDirectorGeneral : '',
  [economicDirectorLastName.name]: institution.numeDirectorEconomic
    ? institution.numeDirectorEconomic
    : '',
  [economicDirectorFirstName.name]: institution.prenumeDirectorEconomic
    ? institution.prenumeDirectorEconomic
    : '',
  [economicDirectorEmail.name]: institution.emailDirectorEconomic
    ? institution.emailDirectorEconomic
    : '',
  [economicDirectorPhone.name]: institution.telefonDirectorEconomic
    ? institution.telefonDirectorEconomic
    : '',
  [economicDirectorTitle.name]: institution.titluDirectorEconomic
    ? institution.titluDirectorEconomic
    : ''
});
