import * as Yup from 'yup';
import competitionProgramEvaluationFormModel from './competitionProgramEvaluationFormModel';

const {
  competitionProgramEvaluationField: { criteria }
} = competitionProgramEvaluationFormModel;

export const competitionProgramEvaluationFormValidationSchema = () =>
  Yup.object().shape({
    [criteria.name]: Yup.array()
      .min(1, `${criteria.requiredErrorMsg}`)
      .of(
        Yup.object().shape({
          [criteria.title.name]: Yup.string()
            .nullable()
            .required(`${criteria.title.requiredErrorMsg}`),
          [criteria.score.name]: Yup.number()
            .nullable()
            .positive(`${criteria.score.numberErrorMsg}`)
            .required(`${criteria.score.numberErrorMsg}`)
        })
      )
  });
