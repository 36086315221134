import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
// ----------------------------------------------------------------------------

InstitutionCompetitionCard.propTypes = {
  entity: PropTypes.object
};

// TODO check view / edit / disabled conditions
export default function InstitutionCompetitionCard({ entity }) {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Card key={entity.competitionGuid.toString()}>
      <CardHeader
        title={
          <div>
            <p>{entity.nume}</p>
          </div>
        }
        action={
          <>
            {moment(entity.perioadaDepunereIntervalInferior).diff(moment()) <= 0 &&
              moment(entity.perioadaDepunereIntervalSuperior).diff(moment()) >= 0 &&
              !entity.programAplicantGuid && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(`${entity.competitionGuid.toString()}/aplica`);
                  }}
                >
                  {intl.formatMessage({ id: 'global.aplica' })}
                </Button>
              )}
            {moment(entity.perioadaDepunereIntervalInferior).diff(moment()) <= 0 &&
              moment(entity.perioadaDepunereIntervalSuperior).diff(moment()) >= 0 &&
              entity.programAplicantGuid && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(`../programe/${entity.programAplicantGuid}`);
                  }}
                >
                  {intl.formatMessage({ id: 'programCard.button.label' })}
                </Button>
              )}
            {/* {(moment(entity.perioadaVerifciareEligibilitateIntervalInferior).diff(moment()) > 0 ||
              moment(entity.perioadaVerifciareEligibilitateIntervalSuperior).diff(moment()) <
                0) && (
              <Button
                variant="outlined"
                onClick={() => {
                  navigate(`${entity.competitionGuid.toString()}/${entity.programAplicantGuid}`);
                }}
              >
                {intl.formatMessage({ id: 'programCard.button.label' })}
              </Button>
            )} */}
          </>
        }
      />
      <CardContent>
        <p>{intl.formatMessage({ id: 'global.competition_period' })}</p>
        <p>
          {moment(entity.durataDesfasurareIntervalInferior).format('D MMMM YYYY')} -{' '}
          {moment(entity.durataDesfasurareIntervalSuperior).format('D MMMM YYYY')}
        </p>
      </CardContent>
    </Card>
  );
}
