import * as React from 'react';
import { Button, TextField } from '@mui/material/';
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';

const ContainerStyle = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
});

const Input = styled('input')({
  display: 'none'
});

const ButtonStyle = styled(Button)({
  // marginLeft: '2rem'
});

export default function FileUploadField({
  formDataName,
  label,
  handleUpload,
  handleDownload,
  buttonLabel,
  buttonLabelDownload,
  disabled = false,
  currentFile,
  currentFileName,
  required,
  hideFilenameInput,
  error,
  touched
}) {
  const onFileUpload = (event) => {
    if (event.target.files[0]) {
      const formData = new FormData();
      formData.append(formDataName, event.target.files[0], event.target.files[0].name);
      handleUpload(formData);
    }
  };

  return (
    <ContainerStyle>
      <TextField
        disabled
        label={label}
        value={currentFileName}
        fullWidth
        InputLabelProps={{ shrink: !!currentFileName }}
        required={required}
        error={touched && !!error}
        helperText={touched && !!error && <FormattedMessage id={error} />}
        sx={hideFilenameInput ? { display: 'none' } : {}}
      />
      {disabled ? (
        <ButtonStyle
          variant="contained"
          component="span"
          size="large"
          onClick={() => handleDownload(currentFile)}
        >
          {buttonLabelDownload}
        </ButtonStyle>
      ) : (
        <label htmlFor={`contained-button-file-${formDataName}`}>
          <Input
            accept="application/pdf"
            id={`contained-button-file-${formDataName}`}
            multiple
            type="file"
            onChange={onFileUpload}
          />
          <ButtonStyle variant="contained" component="span" size="large">
            {buttonLabel}
          </ButtonStyle>
        </label>
      )}
    </ContainerStyle>
  );
}
