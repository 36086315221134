import { useIntl } from 'react-intl';
import { Backdrop, CircularProgress, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import Page from '../components/Page';
import CardList from '../components/lists/CardList';
import { evaluationActions } from '../actions';
import ObjectiveProgramCard from '../components/cards/ObjectiveProgramCard';
// ----------------------------------------------------------------------

export default function ObjectivePrograms() {
  const intl = useIntl();

  const [sort, setSort] = useState('ascending');
  const [searchText, setSearchText] = useState('');
  const [programItems, setProgramItems] = useState([]);
  const evaluation = useSelector((state) => state.evaluation);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(evaluationActions.getMyTechnicalEvaluationPrograms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (evaluation && evaluation.programs) {
      setProgramItems(
        [...evaluation.programs].sort((p1, p2) => p1.titluProgram.localeCompare(p2.titluProgram))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluation]);

  useEffect(() => {
    let filteredPrograms = [];
    if (evaluation && evaluation.programs) {
      if (searchText && searchText.length > 0) {
        filteredPrograms = [...evaluation.programs].filter((program) =>
          program.titluProgram.toLowerCase().includes(searchText.toLowerCase())
        );
      } else {
        filteredPrograms = [...evaluation.programs];
      }
    }

    filteredPrograms = filteredPrograms.sort((p1, p2) =>
      sort === 'ascending'
        ? p1.titluProgram.toLowerCase().localeCompare(p2.titluProgram.toLowerCase())
        : p2.titluProgram.toLowerCase().localeCompare(p1.titluProgram.toLowerCase())
    );

    setProgramItems([...filteredPrograms]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, searchText]);

  if (evaluation?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Page title={intl.formatMessage({ id: 'applicant.programmes' })} my={2}>
      <Container>
        {programItems && (
          <CardList
            entityId="programGuid"
            sort={sort}
            setSort={setSort}
            searchText={searchText}
            setSearchText={setSearchText}
            sortFieldTitle={intl.formatMessage({ id: 'global.name' })}
            items={programItems}
            Card={ObjectiveProgramCard}
          />
        )}
      </Container>
    </Page>
  );
}
