// material
import { Container, Typography } from '@mui/material';
// components
import { useIntl } from 'react-intl';
import Page from '../components/Page';
//

// ----------------------------------------------------------------------

export default function Terms() {
  const intl = useIntl();

  return (
    <Page title={intl.formatMessage({ id: 'page.terms.title' })} my={2}>
      <Container>
        <Typography variant="h3">{intl.formatMessage({ id: 'page.terms.title' })}</Typography>
        <Typography>{intl.formatMessage({ id: 'page.terms.body1' })} ...</Typography>
      </Container>
    </Page>
  );
}
