import { userConstants } from '../constants';
import defineAbilitiesFor from '../utils/ability';
import { UserRoles } from '../domain/UserRoles';

const currentUser = JSON.parse(localStorage.getItem('user'));

const initialState = currentUser
  ? {
      loggedIn: true,
      info: currentUser,
      selectedUser: {},
      users: {
        loading: false,
        data: []
      },
      abilities: defineAbilitiesFor([...currentUser.userType])
    }
  : { loggedIn: false, info: currentUser, selectedUser: {}, abilities: defineAbilitiesFor([]) };

export function user(state = initialState, action) {
  switch (action.type) {
    case userConstants.USER_LOGIN_REQUEST:
    case userConstants.USER_LOGOUT_REQUEST:
    case userConstants.GET_CURRENT_USER_INFO_REQUEST:
    case userConstants.GET_USER_INFO_REQUEST:
    case userConstants.GET_USER_BUSINESS_INFO_REQUEST:
    case userConstants.USER_ADD_REQUEST:
    case userConstants.USER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_USER_REQUEST:
    case userConstants.GET_USERS_REQUEST:
      return {
        ...state,
        users: { ...state.users, loading: true }
      };
    case userConstants.USER_UPDATE_SUCCESS:
      if (action.isCurrent) {
        return {
          ...state,
          info: { ...state.info, ...action.user },
          loading: false
        };
      }

      return {
        ...state,
        users: {
          loading: false,
          data: state.users.data.map((user) =>
            user.id === action.user.id ? { ...user, ...action.user } : user
          )
        }
      };
    case userConstants.USER_LOGIN_SUCCESS:
    case userConstants.GET_CURRENT_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        info: { ...state.info, ...action.user },
        abilities: defineAbilitiesFor(action.user.userType)
      };
    case userConstants.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedUser: action.user
      };
    case userConstants.GET_USER_BUSINESS_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        info: {
          ...state.info,
          institutieParinte: action.user.institutieParinte ? action.user.institutieParinte : 0,
          proiecteAccesibile: action.user.proiecteAccesibile ? action.user.proiecteAccesibile : []
        }
      };
    case userConstants.GET_USERS_SUCCESS:
      return {
        ...state,
        users: {
          loading: false,
          data: action.users
        }
      };
    case userConstants.USER_ADD_SUCCESS:
      return {
        ...state,
        users: {
          loading: false,
          data: [...state.users.data, action.user]
        }
      };
    case userConstants.DELETE_USER_SUCCESS:
      return {
        ...state,
        users: {
          loading: false,
          data: state.users.data.filter(
            (user) =>
              user.userType.includes(UserRoles.PlatformAdministrator) || user.id !== action.id
          )
        }
      };
    case userConstants.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case userConstants.USER_UPDATE_FAILURE:
    case userConstants.USER_ADD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.USER_LOGIN_FAILURE:
    case userConstants.USER_LOGOUT_FAILURE:
      return {
        loading: false,
        error: action.error
      };
    case userConstants.GET_CURRENT_USER_INFO_FAILURE:
    case userConstants.GET_USER_INFO_FAILURE:
    case userConstants.GET_USER_BUSINESS_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_USERS_FAILURE:
    case userConstants.DELETE_USER_FAILURE:
      return {
        ...state,
        users: {
          loading: false,
          data: [...state.users.data]
        },
        error: action.error
      };
    case userConstants.GET_USER_INFO_DISPOSE:
      return {
        ...state,
        loading: false,
        selectedUser: {}
      };
    default:
      return state;
  }
}
