import { useIntl } from 'react-intl';
import {
  Backdrop,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Tab,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ReactPDF from '@react-pdf/renderer';
import Page from '../components/Page';
import { evaluationActions } from '../actions';
import { PROGRAM_EVALUATION_STATUS, PROGRAM_SHEET_EVALUATION_STATUS } from '../utils/enums';
import InstitutionProgramEvaluationObjectives from '../components/_dashboard/institutions/InstitutionProgramEvaluationObjectives';
import InstitutionProgramEvaluationFinancing from '../components/_dashboard/institutions/InstitutionProgramEvaluationFinancing';
import InstitutionProgramEvaluationResults from '../components/_dashboard/institutions/InstitutionProgramEvaluationResults';
import InstitutionProgramEvaluationAnexa25 from './InstitutionProgramEvaluationAnexa25';
// ----------------------------------------------------------------------

export default function InstitutionProgramEvaluation() {
  const { idProgram } = useParams();
  const intl = useIntl();
  const [value, setValue] = useState('1');

  const evaluation = useSelector((state) => state.evaluation);
  const [totalScore, setTotalScore] = useState(0);
  const [projectsScore, setProjectsScore] = useState([]);
  const [passedProjectsScore, setPassedProjectsScore] = useState([]);
  const [evaluatorsInfo, setEvaluatorsInfo] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(evaluationActions.getInstitutionProgramEvaluation(idProgram, 'evaluare'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (evaluation.programEvaluation) {
      let total = 0;
      evaluation.programEvaluation.fisaFinala.criterii.forEach(
        (criterium) => (total += +criterium.scor)
      );
      setTotalScore(total);

      if (evaluation.programEvaluation.fiseEvaluatori) {
        setEvaluatorsInfo(
          evaluation.programEvaluation.fiseEvaluatori.map((fisa) => ({
            userId: fisa.userId,
            username: fisa.username
          }))
        );
      }

      const projectsScore = [];
      evaluation.programEvaluation.obiective.forEach((objective) => {
        objective.proiecte.forEach((project) => {
          const fiseProiect = [...project.fiseEvaluatori].sort((p1, p2) => p1.userId - p2.userId);
          const scorProiectEvaluatori = fiseProiect.map((fisa) =>
            fisa.criterii.reduce((accumulator, criteriu) => accumulator + criteriu.scor, 0)
          );
          projectsScore.push({
            projectId: project.proiectGuid,
            projectTitle: project.titluProiect,
            evaluatorsScore: fiseProiect.map((fisa, index) => ({
              userId: fisa.userId,
              score: scorProiectEvaluatori[index]
            }))
          });
        });
      });

      setProjectsScore(projectsScore);
      setPassedProjectsScore(
        projectsScore.filter(
          (projectScore) =>
            projectScore.evaluatorsScore.reduce(
              (accumulator, evaluatorScore) => accumulator + evaluatorScore.score,
              0
            ) /
              3 >=
            65
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluation.programEvaluation]);

  if (evaluation?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Page title={intl.formatMessage({ id: 'applicant.programmes' })} my={2}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            {intl.formatMessage({ id: 'page.evaluation.sheet.title' })} ({' '}
            {intl.formatMessage({ id: 'global.status' })}:{' '}
            {evaluation &&
            evaluation.programEvaluation &&
            evaluation.programEvaluation.statusEvaluareTehnica
              ? evaluation.programEvaluation.statusEvaluareTehnica
              : 'N/A'}{' '}
            )
          </Typography>
        </Stack>
        {evaluation && evaluation.programEvaluation && evaluation.programEvaluation.fiseEvaluatori && (
          <Grid item xs={4} sm={4}>
            <ReactPDF.PDFDownloadLink
              document={
                <InstitutionProgramEvaluationAnexa25
                  fise={[...evaluation.programEvaluation.fiseEvaluatori].sort(
                    (fisa1, fisa2) => fisa1.userId - fisa2.userId
                  )}
                  programeTitle={evaluation.programEvaluation.titluProgram}
                  totalProgram={totalScore}
                  projectsScore={projectsScore}
                  passedProjectsScore={passedProjectsScore}
                  evaluatorsInfo={evaluatorsInfo}
                  intl={intl}
                />
              }
              fileName="anexa2_5.pdf"
            >
              {({ blob, url, loading, error }) => intl.formatMessage({ id: 'global.anexa.2.5' })}
            </ReactPDF.PDFDownloadLink>
          </Grid>
        )}
        <TabContext value={value}>
          <TabList onChange={handleChange} aria-label="tabs">
            <Tab
              label={intl.formatMessage({ id: 'institution.program.evaluation.tab1' })}
              value="1"
            />
            <Tab
              label={intl.formatMessage({ id: 'institution.program.evaluation.tab2' })}
              value="2"
            />
            {evaluation &&
              evaluation.programEvaluation &&
              evaluation.programEvaluation.statusEvaluareTehnica &&
              evaluation.programEvaluation.statusEvaluareTehnica ===
                PROGRAM_EVALUATION_STATUS.ACCEPTED && (
                <Tab
                  label={intl.formatMessage({ id: 'institution.program.evaluation.tab3' })}
                  value="3"
                />
              )}
            {evaluation &&
              evaluation.programEvaluation &&
              evaluation.programEvaluation.statusEvaluareTehnica &&
              evaluation.programEvaluation.statusEvaluareTehnica ===
                PROGRAM_EVALUATION_STATUS.ACCEPTED &&
              evaluation.programEvaluation.contractFinantareFile && (
                <Tab
                  label={intl.formatMessage({ id: 'institution.program.evaluation.tab4' })}
                  value="4"
                />
              )}
          </TabList>
          <TabPanel value="1" index={0} key={0}>
            <Grid container spacing={5}>
              {evaluation.programEvaluation &&
                evaluation.programEvaluation.fisaFinala &&
                evaluation.programEvaluation.fisaFinala.statusFisaEvaluare ===
                  PROGRAM_SHEET_EVALUATION_STATUS.SUBMITTED &&
                evaluation.programEvaluation.fisaFinala.criterii.map((criterion, index) => (
                  <Grid item xs={12} sm={12} key={index}>
                    <Grid container spacing={5} alignItems="center">
                      <Grid item xs={10} sm={10}>
                        <Typography variant="h5" gutterBottom component="div">
                          {criterion.denumire}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <TextField
                          id={`score${index}`}
                          name={`score${index}`}
                          label={`${intl.formatMessage({ id: 'global.score' })} (max: ${
                            criterion.scorMaxim
                          })`}
                          value={criterion.scor}
                          disabled
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          id={`comment${index}`}
                          name={`comment${index}`}
                          label={intl.formatMessage({ id: 'global.comments' })}
                          value={criterion.comentariu}
                          variant="outlined"
                          disabled
                          fullWidth
                          multiline
                          rows={4}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </TabPanel>
          <TabPanel value="2" index={1} key={1}>
            <InstitutionProgramEvaluationObjectives
              objectives={
                evaluation.programEvaluation &&
                evaluation.programEvaluation.obiective.sort((o1, o2) =>
                  o1.titluObiectiv.localeCompare(o2.titluObiectiv)
                )
              }
            />
          </TabPanel>
          <TabPanel value="3" index={2} key={2}>
            <InstitutionProgramEvaluationFinancing program={evaluation.programEvaluation} />
          </TabPanel>
          <TabPanel value="4" index={3} key={3}>
            <InstitutionProgramEvaluationResults
              objectives={
                evaluation.programEvaluation &&
                evaluation.programEvaluation.obiective.sort((o1, o2) =>
                  o1.titluObiectiv.localeCompare(o2.titluObiectiv)
                )
              }
              programId={evaluation.programEvaluation && evaluation.programEvaluation.programGuid}
            />
          </TabPanel>
        </TabContext>
      </Container>
    </Page>
  );
}
