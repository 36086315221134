import { monitoringConstants } from '../constants';
import { triggerError, triggerSuccess } from '../utils/toasterTriggers';
import { monitoringService } from '../services';

export const monitoringActions = {
  getProgramCycles,
  getInstitutionProgramCycles,
  getProgramAssignedMonitors,
  saveCycles,
  saveAssignedMonitors,
  getMyMonitoringPrograms,
  getMyMonitoringProjects,
  getProgramCycleProjects,
  getProgramCycleProjectPhases,
  getCyclePhases,
  getPhase,
  submitBudgetCheck,
  submitMonitoringPhases
};

function getProgramCycles(idProgram) {
  return (dispatch) => {
    dispatch(request());
    monitoringService.getProgramCycles(idProgram).then(
      (cycles) => {
        dispatch(success(cycles));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: monitoringConstants.GET_PROGRAM_CYCLES_REQUEST };
  }
  function success(cycles) {
    return { type: monitoringConstants.GET_PROGRAM_CYCLES_SUCCESS, cycles };
  }
  function failure(error) {
    return { type: monitoringConstants.GET_PROGRAM_CYCLES_FAILURE, error };
  }
}

function getInstitutionProgramCycles(idProgram) {
  return (dispatch) => {
    dispatch(request());
    monitoringService.getInstitutionProgramCycles(idProgram).then(
      (cycles) => {
        dispatch(success(cycles));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: monitoringConstants.GET_PROGRAM_CYCLES_REQUEST };
  }
  function success(cycles) {
    return { type: monitoringConstants.GET_PROGRAM_CYCLES_SUCCESS, cycles };
  }
  function failure(error) {
    return { type: monitoringConstants.GET_PROGRAM_CYCLES_FAILURE, error };
  }
}

function getProgramAssignedMonitors(idProgram) {
  return (dispatch) => {
    dispatch(request());
    monitoringService.getProgramAssignedMonitors(idProgram).then(
      (monitors) => {
        dispatch(success(monitors));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: monitoringConstants.GET_PROGRAM_ASSIGNED_MONITORS_REQUEST };
  }
  function success(monitors) {
    return { type: monitoringConstants.GET_PROGRAM_ASSIGNED_MONITORS_SUCCESS, monitors };
  }
  function failure(error) {
    return { type: monitoringConstants.GET_PROGRAM_ASSIGNED_MONITORS_FAILURE, error };
  }
}

function getMyMonitoringPrograms() {
  return (dispatch) => {
    dispatch(request());
    monitoringService.getMyMonitoringPrograms().then(
      (programs) => {
        dispatch(success(programs));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: monitoringConstants.GET_MONITOR_PROGRAMS_REQUEST };
  }
  function success(programs) {
    return { type: monitoringConstants.GET_MONITOR_PROGRAMS_SUCCESS, programs };
  }
  function failure(error) {
    return { type: monitoringConstants.GET_MONITOR_PROGRAMS_FAILURE, error };
  }
}

function getMyMonitoringProjects(programId) {
  return (dispatch) => {
    dispatch(request());
    monitoringService.getMyMonitoringProjects(programId).then(
      (projects) => {
        dispatch(success(projects));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: monitoringConstants.GET_MONITOR_PROJECTS_REQUEST };
  }
  function success(projects) {
    return { type: monitoringConstants.GET_MONITOR_PROJECTS_SUCCESS, projects };
  }
  function failure(error) {
    return { type: monitoringConstants.GET_MONITOR_PROJECTS_FAILURE, error };
  }
}

function getProgramCycleProjects(idProgram, idCiclu) {
  return (dispatch) => {
    dispatch(request());
    monitoringService.getProgramCycleProjects(idProgram, idCiclu).then(
      (projects) => {
        dispatch(success(projects));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: monitoringConstants.GET_MONITOR_PROJECTS_REQUEST };
  }
  function success(projects) {
    return { type: monitoringConstants.GET_MONITOR_PROJECTS_SUCCESS, projects };
  }
  function failure(error) {
    return { type: monitoringConstants.GET_MONITOR_PROJECTS_FAILURE, error };
  }
}

function getProgramCycleProjectPhases(idCiclu, idProiect) {
  return (dispatch) => {
    dispatch(request());
    monitoringService.getProgramCycleProjectPhases(idCiclu, idProiect).then(
      (phases) => {
        dispatch(success(phases));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: monitoringConstants.GET_MONITOR_PROJECT_CYCLE_PHASES_REQUEST };
  }
  function success(phases) {
    return { type: monitoringConstants.GET_MONITOR_PROJECT_CYCLE_PHASES_SUCCESS, phases };
  }
  function failure(error) {
    return { type: monitoringConstants.GET_MONITOR_PROJECT_CYCLE_PHASES_FAILURE, error };
  }
}

function getCyclePhases(idCiclu, idProiect) {
  return (dispatch) => {
    dispatch(request());
    monitoringService.getCyclePhases(idCiclu, idProiect).then(
      (phases) => {
        dispatch(success(phases));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: monitoringConstants.GET_MONITOR_PROJECT_CYCLE_PHASES_REQUEST };
  }
  function success(phases) {
    return { type: monitoringConstants.GET_MONITOR_PROJECT_CYCLE_PHASES_SUCCESS, phases };
  }
  function failure(error) {
    return { type: monitoringConstants.GET_MONITOR_PROJECT_CYCLE_PHASES_FAILURE, error };
  }
}

function getPhase(idProiect, idCiclu, idFaza) {
  return (dispatch) => {
    dispatch(request());
    monitoringService.getPhase(idProiect, idCiclu, idFaza).then(
      (phase) => {
        dispatch(success(phase));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: monitoringConstants.GET_MONITOR_PHASE_REQUEST };
  }
  function success(phase) {
    return { type: monitoringConstants.GET_MONITOR_PHASE_SUCCESS, phase };
  }
  function failure(error) {
    return { type: monitoringConstants.GET_MONITOR_PHASE_FAILURE, error };
  }
}

function saveCycles(data) {
  return (dispatch) => {
    dispatch(request());
    monitoringService.saveCycles(data).then(
      () => {
        dispatch(success());
        window.location.reload();
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: monitoringConstants.SAVE_CYCLES_REQUEST };
  }
  function success(data) {
    return { type: monitoringConstants.SAVE_CYCLES_SUCCESS, data };
  }
  function failure(error) {
    return { type: monitoringConstants.SAVE_CYCLES_FAILURE, error };
  }
}

function saveAssignedMonitors(data) {
  return (dispatch) => {
    dispatch(request());
    monitoringService.saveAssignedMonitors(data).then(
      () => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: monitoringConstants.SAVE_ASSIGNED_MONITORS_REQUEST };
  }
  function success() {
    return { type: monitoringConstants.SAVE_ASSIGNED_MONITORS_SUCCESS };
  }
  function failure(error) {
    return { type: monitoringConstants.SAVE_ASSIGNED_MONITORS_FAILURE, error };
  }
}

function submitBudgetCheck(monitorizareProiectId, status, monitorComments) {
  return (dispatch) => {
    dispatch(request());
    monitoringService.submitBudgetCheck(monitorizareProiectId, status, monitorComments).then(
      () => {
        dispatch(success());
        window.location.reload();
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: monitoringConstants.MONITOR_SUBMIT_BUDGET_CHECK_REQUEST };
  }
  function success() {
    return { type: monitoringConstants.MONITOR_SUBMIT_BUDGET_CHECK_SUCCESS };
  }
  function failure(error) {
    return { type: monitoringConstants.MONITOR_SUBMIT_BUDGET_CHECK_FAILURE, error };
  }
}

function submitMonitoringPhases(idProgram, data) {
  return (dispatch) => {
    dispatch(request());
    monitoringService.submitMonitoringPhases(idProgram, data).then(
      () => {
        dispatch(success());
        triggerSuccess('', 'toast.success.monitoring.submit.phases');
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: monitoringConstants.MONITORING_SUBMIT_PHASES_REQUEST };
  }
  function success() {
    return { type: monitoringConstants.MONITORING_SUBMIT_PHASES_SUCCESS };
  }
  function failure(error) {
    return { type: monitoringConstants.MONITORING_SUBMIT_PHASES_FAILURE, error };
  }
}
