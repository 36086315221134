import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { useState } from 'react';
// material
import {
  Card,
  Table,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import MuiTableListToolbar from './MuiTableListToolbar';
import Scrollbar from '../Scrollbar';
import MuiTableListHead from './MuiTableListHead';
import SearchNotFound from '../SearchNotFound';
//

// ----------------------------------------------------------------------
const ACTION_COLUMN_ID = 'actions';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, filterFunction) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, filterFunction(query));
  }
  return stabilizedThis.map((el) => el[0]);
}

MuiTable.propTypes = {
  tableHead: PropTypes.object,
  itemIdentifier: PropTypes.string,
  data: PropTypes.object,
  filterFunction: PropTypes.func,
  checkboxActive: PropTypes.bool,
  handleDeleteEntity: PropTypes.func,
  defaultOrder: PropTypes.oneOf(['asc', 'desc']),
  defaultOrderBy: PropTypes.string,
  deleteConfirmationTitle: PropTypes.string,
  deleteConfirmationMessage: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  MoreMenu: PropTypes.element
};

export default function MuiTable({
  tableHead,
  itemIdentifier,
  data,
  filterFunction,
  checkboxActive,
  handleDeleteEntity,
  defaultOrder,
  defaultOrderBy,
  deleteConfirmationTitle,
  deleteConfirmationMessage,
  searchPlaceholder,
  MoreMenu
}) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState(defaultOrder);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [filterText, setFilterText] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const user = useSelector((state) => state.user);

  const intl = useIntl();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = user.users.data.map((n) => n[itemIdentifier]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, itemIdentifier) => {
    const selectedIndex = selected.indexOf(itemIdentifier);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, itemIdentifier);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByText = (event) => {
    setFilterText(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const filteredUsers = data
    ? applySortFilter(data, getComparator(order, orderBy), filterText, filterFunction)
    : [];

  const isEntityNotFound = filteredUsers.length === 0;

  const handleDelete = () => {
    handleDeleteEntity(selected);
    setSelected([]);
  };

  return (
    <Card>
      {checkboxActive && (
        <MuiTableListToolbar
          numSelected={selected.length}
          filterText={filterText}
          onFilter={handleFilterByText}
          handleDelete={handleDelete}
          deleteConfirmationTitle={deleteConfirmationTitle}
          deleteConfirmationMessage={deleteConfirmationMessage}
          checkboxActive={checkboxActive}
          searchPlaceholder={searchPlaceholder}
        />
      )}
      {data && (
        <>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <MuiTableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={tableHead}
                  rowCount={data.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  checkboxActive={checkboxActive}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const isItemSelected = selected.indexOf(row[itemIdentifier]) !== -1;
                      return (
                        <TableRow
                          hover
                          key={row[itemIdentifier]}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {checkboxActive && (
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, row[itemIdentifier])}
                              />
                            </TableCell>
                          )}
                          {tableHead.map((columnConfig, indexColumn) => {
                            if (columnConfig.id !== ACTION_COLUMN_ID) {
                              return (
                                <TableCell
                                  key={`${row[itemIdentifier]}${indexColumn}`}
                                  align="left"
                                >
                                  {row[columnConfig.id]}
                                </TableCell>
                              );
                            }

                            return (
                              <TableCell key={`${row[itemIdentifier]}${indexColumn}`} align="right">
                                <MoreMenu data={{ ...row }} />
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell
                        colSpan={checkboxActive ? tableHead.length + 1 : tableHead.length}
                      />
                    </TableRow>
                  )}
                </TableBody>
                {isEntityNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={checkboxActive ? tableHead.length + 1 : tableHead.length}
                        sx={{ py: 3 }}
                      >
                        <SearchNotFound />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={intl.formatMessage({ id: 'global.rows_per_page' })}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}–${to} ${intl.formatMessage({ id: 'global.of' })} ${
                count !== -1 ? count : `${intl.formatMessage({ id: 'global.more_than' })} ${to}`
              }`
            }
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Card>
  );
}
