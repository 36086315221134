// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Container, Typography, Box } from '@mui/material';
// layouts
import { useIntl } from 'react-intl';
// components
import Page from '../components/Page';
import { LoginForm } from '../components/authentication/login';
import DashboardFooter from '../layouts/dashboard/DashboardFooter';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  backgroundColor: '#E7F0FB',
  background: `#E7F0FB url('/static/background_logo.png') 0 0 repeat`
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: 'calc(100vh - 139px - 8.5rem)',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login({ isAdmin }) {
  const intl = useIntl();

  // display LoginForm
  return (
    <RootStyle title="Login">
      <SectionStyle>
        <Container maxWidth="sm">
          <Box
            component="img"
            src="/static/login_logo.png"
            style={{ maxWidth: 'fit-content', margin: 'auto', marginTop: '3rem' }}
          />
          <ContentStyle>
            <Stack sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                {intl.formatMessage({ id: 'form.my_account.login' })}
              </Typography>
            </Stack>
            <LoginForm isAdmin={isAdmin} />
          </ContentStyle>
        </Container>
      </SectionStyle>
      <DashboardFooter />
    </RootStyle>
  );
}
