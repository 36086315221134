import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import ProiectCard from '../../cards/ProiectCard';

export default function ProjectProposition({ role }) {
  const objectives = useSelector(
    (state) => state.programs.selectedProgram?.obiectiveSiProiecte?.obiectiveSiProiecte
  );
  const projects = objectives
    ? JSON.parse(JSON.stringify(objectives))
        .sort((o1, o2) => o1.obiectivGuid - o2.obiectivGuid)
        .map((objective) =>
          objective.proiecte
            .sort((p1, p2) => p1.proiectGuid - p2.proiectGuid)
            .map((project) => {
              const temp = JSON.parse(JSON.stringify(project));
              temp.titluObiectiv = objective.titluObiectiv;
              return temp;
            })
        )
        .flat()
    : [];

  return (
    <Stack spacing={3}>
      {projects &&
        projects.map((project) => <ProiectCard key={project.id} proiect={project} role={role} />)}
    </Stack>
  );
}
