import { combineReducers } from 'redux';
import { user } from './user.reducer';
import { documents } from './documents.reducer';
import { institutions } from './institutions.reducer';
import { competitions } from './competitions.reducer';
import { eligibility } from './eligibillity.reducer';
import { evaluation } from './evaluation.reducer';
import { monitoring } from './monitoring.reducer';
import { report } from './report.reducer';
import { programs } from './programs.reducer';
import { toasterReducer } from './toaster.reducer';
import { userConstants } from '../constants';

// combine reducers for the redux store
const rootReducer = combineReducers({
  user,
  documents,
  institutions,
  competitions,
  eligibility,
  programs,
  evaluation,
  monitoring,
  report,
  toaster: toasterReducer
});

export default (state, action) =>
  rootReducer(action.type === userConstants.USER_LOGOUT ? undefined : state, action);
