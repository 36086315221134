import competitionInformationFormModel from './competitionInformationFormModel';

const {
  competitionInformationFormField: {
    name,
    startDate,
    endDate,
    submitStartDate,
    submitEndDate,
    checkEligibilityStartDate,
    checkEligibilityEndDate,
    eligibilityContestationStartDate,
    eligibilityContestationEndDate,
    technicalEvaluationProjectStartDate,
    technicalEvaluationProjectEndDate,
    technicalEvaluationProgrammeStartDate,
    technicalEvaluationProgrammeEndDate,
    technicalEvaluationContestationStartDate,
    technicalEvaluationContestationEndDate,
    technicalEvaluationContestationSolutionStartDate,
    technicalEvaluationContestationSolutionEndDate,
    monitoringStartDate,
    monitoringEndDate,
    reportingStartDate,
    reportingEndDate,
    competitionInstitutions
  }
} = competitionInformationFormModel;

export const getInitialCompetitionInformationFormValues = (competitionInformation) => ({
  id: competitionInformation.competitionGuid,
  [name.name]: competitionInformation.nume ?? '',
  [startDate.name]: competitionInformation.durataDesfasurareIntervalInferior ?? null,
  [endDate.name]: competitionInformation.durataDesfasurareIntervalSuperior ?? null,
  [submitStartDate.name]: competitionInformation.perioadaDepunereIntervalInferior ?? null,
  [submitEndDate.name]: competitionInformation.perioadaDepunereIntervalSuperior ?? null,
  [checkEligibilityStartDate.name]:
    competitionInformation.perioadaVerificareEligibilitateIntervalInferior ?? null,
  [checkEligibilityEndDate.name]:
    competitionInformation.perioadaVerificareEligibilitateIntervalSuperior ?? null,
  [eligibilityContestationStartDate.name]:
    competitionInformation.perioadaContestatieEligibilitateIntervalInferior ?? null,
  [eligibilityContestationEndDate.name]:
    competitionInformation.perioadaContestatieEligibilitateIntervalSuperior ?? null,
  [technicalEvaluationProjectStartDate.name]:
    competitionInformation.perioadaEvaluareTehnicaProiecteIntervalInferior ?? null,
  [technicalEvaluationProjectEndDate.name]:
    competitionInformation.perioadaEvaluareTehnicaProiecteIntervalSuperior ?? null,
  [technicalEvaluationProgrammeStartDate.name]:
    competitionInformation.perioadaEvaluareTehnicaProgramIntervalInferior ?? null,
  [technicalEvaluationProgrammeEndDate.name]:
    competitionInformation.perioadaEvaluareTehnicaProgramIntervalSuperior ?? null,
  [technicalEvaluationContestationStartDate.name]:
    competitionInformation.perioadaContestatieEvaluareTehnicaIntervalInferior ?? null,
  [technicalEvaluationContestationEndDate.name]:
    competitionInformation.perioadaContestatieEvaluareTehnicaIntervalSuperior ?? null,
  [technicalEvaluationContestationSolutionStartDate.name]:
    competitionInformation.perioadaSolutionareContestatiiTehniceIntervalInferior ?? null,
  [technicalEvaluationContestationSolutionEndDate.name]:
    competitionInformation.perioadaSolutionareContestatiiTehniceIntervalSuperior ?? null,
  [monitoringStartDate.name]: competitionInformation.perioadaMonitorizareIntervalInferior ?? null,
  [monitoringEndDate.name]: competitionInformation.perioadaMonitorizareIntervalSuperior ?? null,
  [reportingStartDate.name]: competitionInformation.perioadaRaportareIntervalInferior ?? null,
  [reportingEndDate.name]: competitionInformation.perioadaRaportareIntervalSuperior ?? null,
  [competitionInstitutions.name]: competitionInformation.institutiiAsociate
    ? competitionInformation.institutiiAsociate.map((institutie) => ({ id: institutie }))
    : []
});
