import { useIntl } from 'react-intl';
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography
} from '@mui/material';
// The Effect Hook performs side effects in function components:
import { useEffect } from 'react';
// dispatch is used as a callback which gets invoked once some async action is complete.
// useSelector allows you to extract data from the Redux store state, using a selector function.
import { useDispatch, useSelector } from 'react-redux';
// imports everything in the react module
import * as React from 'react';
// useParams hook returns an object of key/value pairs of the dynamic params from the current URL that were matched by the <Route path>
import { useParams } from 'react-router-dom';
// Formik uses useFormik to create the <Formik> component (which renders a React Context Provider).
import { useFormik } from 'formik';
// import page component
import Page from '../components/Page';
// import from actions
import { competitionActions, userActions, programActions } from '../actions';
// import for Autocomplete Form
import EditableFormAutocomplete from '../forms/shared/EditableFormAutocomplete';
import { getInitialAdminEligibilityAssignFormValues } from '../forms/programs/adminEligibilityAssign/formInitialValues';
import { adminEligibilityAssignFormValidationSchema } from '../forms/programs/adminEligibilityAssign/validationSchema';
import adminEligibilityAssignFormModel from '../forms/programs/adminEligibilityAssign/adminEligibilityAssignFormModel';
import { UserRoles } from '../domain/UserRoles';
import { parseAdminEligibility } from '../forms/programs/adminEligibilityAssign/formOutputParser';
// ----------------------------------------------------------------------

const {
  adminEligibilityAssignField: { reviwer, writer }
} = adminEligibilityAssignFormModel;

export default function AdminCompetitionProgramsEligibility() {
  // useIntl hook: Once you've declared your IntlProvider, you can get access to the intl object via calling this hook in a functional React component
  const intl = useIntl();
  // useSelector extracts  data from the competitions state
  const competitions = useSelector((state) => state.competitions);
  // useSelector extracts  data from the programs state
  const programs = useSelector((state) => state.programs);
  // useSelector extracts  data from the user state
  const user = useSelector((state) => state.user);
  // dispatch is used as a callback which gets invoked once some async action is complete.
  const dispatch = useDispatch();
  // useParams hook returns an object of key/value pairs of the dynamic params from the current URL that were matched by the <Route path>
  const { idCompetition, idProgram } = useParams();

  // The Effect Hook performs side effects in function components:
  // dispatch is used as a callback which gets invoked once some async action is complete.
  useEffect(() => {
    dispatch(competitionActions.getCompetition(idCompetition));
    dispatch(programActions.getProgram(idProgram));
    dispatch(programActions.getProgramEligibilityResponsibles(idProgram));
    dispatch(userActions.getAll(user.info.userType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useFormik() is a custom React hook that will return all Formik state and helpers directly.
  const formik = useFormik({
    initialValues: getInitialAdminEligibilityAssignFormValues(
      programs.eligibilityResponsibles || {}
    ),
    validationSchema: adminEligibilityAssignFormValidationSchema(),
    enableReinitialize: true,
    onSubmit: (values) => {
      const valuesCopy = JSON.parse(JSON.stringify(values));
      dispatch(
        programActions.updateProgramEligibilityResponsibles(
          parseAdminEligibility(valuesCopy),
          idProgram,
          'eligibilitate'
        )
      );
    }
  });

  // display CircularProgress on programs  or competitions loading
  if (competitions?.loading || programs?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  // display Grid with 2 items

  return (
    <Page title={intl.formatMessage({ id: 'institution.competitions' })} my={2}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            {intl.formatMessage({ id: 'page.admin.competitions.program.eligibility' })}
          </Typography>
        </Stack>
        {competitions.selectedCompetition &&
          programs.selectedProgram &&
          user.users &&
          user.users.data && (
            <>
              <Grid container spacing={5}>
                {/* Grid item 1 size 6/12 (1/2) of small viewport, 12/12 (all) of extrasmall viewport */}
                <Grid item xs={12} sm={6}>
                  <EditableFormAutocomplete
                    parentName={writer.name}
                    formik={formik}
                    required
                    label={intl.formatMessage({ id: writer.label })}
                    variant="outlined"
                    fullWidth
                    options={user.users.data
                      .filter(
                        (userOption) =>
                          !userOption.userType.includes(UserRoles.PlatformAdministrator) &&
                          userOption.userType.includes(UserRoles.EvaluatorEligibilitate)
                      )
                      .map((userOption) => ({
                        id: userOption.id,
                        label: `${userOption.firstName} ${userOption.lastName}`
                      }))}
                  />
                </Grid>
                {/* Grid item 2 size 6/12 (1/2) of small viewport, 12/12 (all) of extrasmall viewport */}
                <Grid item xs={12} sm={6}>
                  <EditableFormAutocomplete
                    parentName={reviwer.name}
                    formik={formik}
                    required
                    label={intl.formatMessage({ id: reviwer.label })}
                    variant="outlined"
                    fullWidth
                    options={user.users.data
                      .filter(
                        (userOption) =>
                          !userOption.userType.includes(UserRoles.PlatformAdministrator) &&
                          userOption.userType.includes(UserRoles.EvaluatorEligibilitate)
                      )
                      .map((userOption) => ({
                        id: userOption.id,
                        label: `${userOption.firstName} ${userOption.lastName}`
                      }))}
                  />
                </Grid>
              </Grid>
              <Button variant="contained" onClick={() => formik.submitForm()} sx={{ marginTop: 2 }}>
                {intl.formatMessage({ id: 'global.save' })}
              </Button>
            </>
          )}
      </Container>
    </Page>
  );
}
