import * as React from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { Formik, FieldArray } from 'formik';
import { Stack, Typography } from '@mui/material';
import RezultatFazaCard from './RezultatFazaCard';
import schemaRealizareForm from '../../../forms/proiect/schemaRealizare/schemaRealizareFormModel';

export default function Rezultate({ formik, project, disabled }) {
  const intl = useIntl();
  return (
    <div>
      <Typography variant="h4">
        {intl.formatMessage({ id: 'page.project.results.title' })}
      </Typography>
      <FieldArray
        name={schemaRealizareForm.name}
        render={() => (
          <Stack>
            {formik.values[schemaRealizareForm.name].map((elem, index) => (
              <RezultatFazaCard
                key={index}
                formik={formik}
                disabled={disabled}
                id={index}
                elem={elem}
              />
            ))}
          </Stack>
        )}
      />
    </div>
  );
}
