export const ACTIONS = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  DELETE: 'DELETE'
};

export const ENTITIES = {
  MY_ACCOUNT: 'MY_ACCOUNT',
  APPLICANT: 'APPLICANT',
  ELIGIBILITY: 'ELIGIBILITY',
  INSTITUTION: 'INSTITUTION',
  ADMINISTRATOR: 'ADMINISTRATOR',
  ELIGIBILITY_DOCUMENTS: 'ELIGIBILITY_DOCUMENTS',
  OBJECTIVE: 'OBJECTIVE',
  PROGRAM: 'PROGRAM',
  MONITOR: 'MONITOR',
  REPORT: 'REPORT'
};

export const FORM_IDS = {
  ADMIN_COMPETITION_INFO: 'adminCompetitionInfo',
  ADMIN_COMPETITION_ELIGIBILITY: 'adminCompetitionEligibility',
  ADMIN_COMPETITION_PROGRAM_EVALUATION: 'adminCompetitionProgramEvaluation',
  ADMIN_COMPETITION_PROJECT_EVALUATION: 'adminCompetitionProjectEvaluation',
  PROGRAM_INFORMATION: 'programInformation',
  PROGRAM_SUPPORTING_DOCUMENTS: 'programSupportingDocuments',
  PROGRAM_TOTAL_VALUE: 'programTotalValue',
  PROGRAM_EXPECTED_RESULTS: 'programExpectedResults',
  PROGRAM_INCOME_STATUS: 'programIncomeStatus',
  PROGRAM_PROJECTS_OBJECTIVES: 'programProjectsObjectives',
  PROGRAM_PROJECT_PROPOSITION: 'programProjectProposition'
};

export const EVALUATOR_ROLES = {
  USER_REVIEWER: 'UserReviewer',
  USER_WRITER: 'UserWriter',
  USER_CONTESTATIE_REVIEWER: 'UserContestatieReviewer',
  USER_CONTESTATIE_WRITER: 'UserContestatieWriter'
};

export const ELIGIBILITY_STATUS = {
  PENDING: 'Nesubmis',
  EVALUATING: 'In curs de evaluare',
  REJECTED: 'Respins',
  ACCEPTED: 'Admis',
  SUBMITTED: 'Submisa'
};

export const PROJECT_EVALUATION_STATUS = {
  EVALUATING: 'In curs de completare',
  SUBMITTED: 'Submisa'
};

export const PROGRAM_SHEET_EVALUATION_STATUS = {
  EVALUATING: 'In curs de completare',
  SUBMITTED: 'Submisa'
};

export const PROGRAM_EVALUATION_STATUS = {
  EVALUATING: 'In curs de completare',
  ACCEPTED: 'Admis',
  REJECTED: 'Respins'
};

export const MONITORING_STATUS = {
  EVALUATING: 'In curs de monitorizare',
  ACCEPTED: 'Admis',
  REJECTED: 'Respins'
};

export const REPORTING_STATUS = {
  EVALUATING: 'In curs de completare',
  SUBMITTED: 'Submisa'
};

export const PUBLICATION_TYPES = {
  ARTICOL_JURNAL: 'Articol jurnal',
  BAZA_DE_DATE: 'Baza de date',
  CAPITOL_CARTE: 'Capitol carte',
  CARTE: 'Carte',
  CONTINUT_POSTATE: 'Continut postat',
  CURS: 'Curs',
  DIZERTATIE: 'Dizertatie',
  LABORATOR: 'Laborator',
  LUCRARE_CONFERINTA: 'Lucrare conferinta',
  PRODUS: 'Produs',
  RAPORT_DE_CERCETARE: 'Raport de cercetare',
  RECENZIE: 'Recenzie',
  SERVICIU: 'Serviciu',
  SET_DE_DATE: 'Set de date',
  STANDARD: 'Standard',
  STUDIU: 'Studiu'
};

export const PUBLICATION_CATEGORIES = {
  CERCETARE: 'Cercetare',
  DIDACTIC: 'Didactic'
};

export const PATENT_STATUSES = {
  CERERE: 'Cerere',
  BREVET: 'Brevet'
};

export const PATENT_TYPES = {
  NATIONAL: 'National',
  INTERNATIONAL: 'International'
};

export const REPORT_DOCUMENTS_GENERAL = {
  ANEXA_9: 'Anexa9',
  ANEXA_10: 'Anexa10',
  ANEXA_11: 'Anexa11'
};

export const REPORT_DOCUMENTS_FIRST_PHASE = {
  ANEXA_12: 'Anexa12'
};

export const REPORT_DOCUMENTS_LAST_PHASE = {
  ANEXA_8: 'Anexa8',
  ANEXA_18_2: 'Anexa18.2',
  ANEXA_18_3: 'Anexa18.3',
  ANEXA_18_4: 'Anexa18.4'
};

export const REPORT_DOCUMENTS_YEAR_END = {
  ANEXA_14: 'Anexa14',
  ANEXA_18_1: 'Anexa18.1',
  ANEXA_18_5: 'Anexa18.5'
};

export const REPORT_DOCUMENTS_PROJECT_END = {
  ANEXA_13: 'Anexa13',
  ANEXA_15: 'Anexa15'
};

export const ProjectDocumentType = {
  DescriereaStiintificaFile: 'DescriereaStiintificaFile',
  StructuraBugetuluiFile: 'StructuraBugetuluiFile',
  ManagementulRisculuiFile: 'ManagementulRisculuiFile',
  ImpactFile: 'ImpactFile',
  PlanFile: 'PlanFile',
  AlteInformatiiFile: 'AlteInformatiiFile'
};
