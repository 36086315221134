import monitoringCycleFormModel from './monitoringCycleFormModel';

const {
  monitoringCycleField: { cycle }
} = monitoringCycleFormModel;

export const getInitialMonitoringCycleFormValues = (programCycles) => ({
  [cycle.name]: programCycles.cicluri
    ? programCycles.cicluri.map((programCycle) => ({
        [cycle.id.name]: programCycle.cicluId ? programCycle.cicluId : null,
        [cycle.title.name]: programCycle.denumire ? programCycle.denumire : '',
        [cycle.startDate.name]: programCycle.dataIncepere ? programCycle.dataIncepere : '',
        [cycle.endDate.name]: programCycle.dataFinalizare ? programCycle.dataFinalizare : '',
        [cycle.final.name]: programCycle.cicluFinal ? programCycle.cicluFinal : false,
        [cycle.projects.name]: programCycle.proiecte
          ? programCycle.proiecte.map((project) => ({
              [cycle.projects.project.name]: { id: project.proiectAsignatGuid },
              [cycle.projects.budget.name]: project.sumaAlocata,
              [cycle.projects.monitor.name]: { id: project.monitorGuid }
            }))
          : []
      }))
    : []
});
