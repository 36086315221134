import PropTypes from 'prop-types';
// material
import { Paper, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {};

export default function SearchNotFound({ ...other }) {
  const intl = useIntl();
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        {intl.formatMessage({ id: 'global.not_found' })}
      </Typography>
    </Paper>
  );
}
