import * as React from 'react';
import { useIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import moment from 'moment';
// ----------------------------------------------------------------------------

export default function MonitorProgramProjectCyclePhaseCard({ entity }) {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Card key={entity.fazaGuid.toString()}>
      <Grid container alignItems="center">
        <Grid item xs={9} sm={9}>
          <CardHeader
            title={
              <div>
                <p>{entity.titlu}</p>
              </div>
            }
          />
          <CardContent>
            {entity.subtitlu && (
              <p>
                {intl.formatMessage({ id: 'global.phase' })}: {entity.subtitlu}
              </p>
            )}
            <p>
              {intl.formatMessage({ id: 'global.interval' })}:{' '}
              {moment(entity.dataInceput).format('D MMMM YYYY')} -{' '}
              {moment(entity.dataFinal).format('D MMMM YYYY')}
            </p>
            <p />
            <p>
              {intl.formatMessage({ id: 'global.budget' })}: {entity.buget}
            </p>
          </CardContent>
        </Grid>
        <Grid item xs={3} sm={3}>
          <Stack spacing={2} sx={{ mr: 2 }}>
            <Button variant="outlined" onClick={() => navigate(`${entity.fazaGuid}`)}>
              {intl.formatMessage({ id: 'global.view.details' })}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}
