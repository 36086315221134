import * as React from 'react';
import { useIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { Collapse, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AdminProgramCardDetailsRow from './AdminProgramCardDetailsRow';
import { ELIGIBILITY_STATUS } from '../../utils/enums';
import { programActions } from '../../actions';
// ----------------------------------------------------------------------------

const ExpandMore = styled((props) => {
  // eslint-disable-next-line no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

InstitutionProgramCard.propTypes = {
  entity: PropTypes.object
};

export default function InstitutionProgramCard({ entity }) {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const competitions = useSelector((state) => state.competitions);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      {competitions && entity.competition && (
        <Card key={entity.programGuid}>
          <CardHeader
            title={
              <div>
                <p>{entity.detaliiProgram.denumireProgramNucleu}</p>
              </div>
            }
            action={
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            }
          />
          <CardContent>
            <p>{intl.formatMessage({ id: 'programCard.competition' })}</p>
            <p>{entity.competition.nume}</p>
          </CardContent>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <AdminProgramCardDetailsRow
                startDate={entity.competition.perioadaDepunereIntervalInferior}
                endDate={entity.competition.perioadaDepunereIntervalSuperior}
                title={intl.formatMessage({ id: 'global.submission' })}
                onClick={() => navigate(`../programe/${entity.programGuid}`)}
              />
              {entity.eligibility.status === ELIGIBILITY_STATUS.PENDING && (
                <AdminProgramCardDetailsRow
                  startDate={entity.competition.perioadaVerificareEligibilitateIntervalInferior}
                  endDate={entity.competition.perioadaVerificareEligibilitateIntervalSuperior}
                  title={intl.formatMessage({ id: 'global.check_eligibility' })}
                  buttonText={intl.formatMessage({ id: 'global.submit_eligibility' })}
                  onClick={() => dispatch(programActions.submitForEligibility(entity.programGuid))}
                />
              )}
              {entity.eligibility.status !== ELIGIBILITY_STATUS.PENDING && (
                <>
                  <AdminProgramCardDetailsRow
                    startDate={entity.competition.perioadaVerificareEligibilitateIntervalInferior}
                    endDate={entity.competition.perioadaVerificareEligibilitateIntervalSuperior}
                    title={intl.formatMessage({ id: 'global.check_eligibility' })}
                    onClick={() => navigate(`${entity.programGuid}/eligibilitate`)}
                  />
                  <AdminProgramCardDetailsRow
                    startDate={entity.competition.perioadaContestatieEligibilitateIntervalInferior}
                    endDate={entity.competition.perioadaContestatieEligibilitateIntervalSuperior}
                    title={intl.formatMessage({ id: 'global.check_eligibility_contestation' })}
                  />
                  <AdminProgramCardDetailsRow
                    startDate={entity.competition.perioadaEvaluareTehnicaProiecteIntervalInferior}
                    endDate={entity.competition.perioadaEvaluareTehnicaProiecteIntervalSuperior}
                    title={intl.formatMessage({ id: 'global.check_projects_technical' })}
                  />
                  <AdminProgramCardDetailsRow
                    startDate={entity.competition.perioadaEvaluareTehnicaProgramIntervalInferior}
                    endDate={entity.competition.perioadaEvaluareTehnicaProgramIntervalSuperior}
                    title={intl.formatMessage({
                      id: 'global.check_program_technical'
                    })}
                    onClick={() => navigate(`${entity.programGuid}/evaluare-program`)}
                  />
                  <AdminProgramCardDetailsRow
                    startDate={entity.competition.perioadaMonitorizareIntervalInferior}
                    endDate={entity.competition.perioadaMonitorizareIntervalSuperior}
                    title={intl.formatMessage({ id: 'global.check_monitoring' })}
                    onClick={() => navigate(`${entity.programGuid}/monitorizare/cicluri`)}
                  />
                  <AdminProgramCardDetailsRow
                    startDate={entity.competition.perioadaRaportareIntervalInferior}
                    endDate={entity.competition.perioadaRaportareIntervalSuperior}
                    title={intl.formatMessage({ id: 'global.check_reporting' })}
                    onClick={() => navigate(`${entity.programGuid}/raportare/proiecte`)}
                  />
                </>
              )}
            </CardContent>
          </Collapse>
        </Card>
      )}
    </>
  );
}
