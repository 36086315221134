import { useIntl } from 'react-intl';
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography
} from '@mui/material';
// The Effect Hook performs side effects in function components:
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import everything from the react module
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
// import Page component
import Page from '../components/Page';
import { eligibilityActions } from '../actions';
import eligibilitySheetFormModel from '../forms/eligibility/eligibilitySheetFormModel';
import { getInitialEligibilitySheetFormValues } from '../forms/eligibility/formInitialValues';
import EditableFormTextField from '../forms/shared/EditableFormTextField';
import EditableFormCheckbox from '../forms/shared/EditableFormCheckbox';
import { ACTIONS, ELIGIBILITY_STATUS, ENTITIES, EVALUATOR_ROLES } from '../utils/enums';
import Document from '../components/Document';
import { parseEligibilitySheet } from '../forms/eligibility/formOutputParser';
// ----------------------------------------------------------------------

const {
  eligibilitySheetField: { documents, status, eligibilitySheet }
} = eligibilitySheetFormModel;

export default function EligibilitySheet() {
  const { id } = useParams();
  const intl = useIntl();
  const [isSaveDraft, setSaveDraft] = useState(null); // set initial State to null
  const [readOnly, setReadOnly] = useState(true); // set initial State to true

  // useSelector extracts  data from the eligibility state
  const eligibility = useSelector((state) => state.eligibility);
  // useSelector extracts  data from the user state
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const theme = useTheme();

  // getAssignedPrograms
  useEffect(() => {
    dispatch(eligibilityActions.getAssignedPrograms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // getProgram by id
  useEffect(() => {
    if (eligibility.programs && eligibility.programs.length > 0) {
      dispatch(eligibilityActions.getProgram(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eligibility.programs]);

  useEffect(() => {
    if (eligibility && eligibility.selectedProgram) {
      setReadOnly(
        eligibility.selectedProgram.role === EVALUATOR_ROLES.USER_REVIEWER ||
          eligibility.selectedProgram.role === EVALUATOR_ROLES.USER_CONTESTATIE_REVIEWER ||
          eligibility.selectedProgram.statusFisaEligibilitate === ELIGIBILITY_STATUS.SUBMITTED
      );
      if (!eligibility.eligibilitySheet) {
        dispatch(eligibilityActions.getEligibilitySheet(id, eligibility.selectedProgram.role));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eligibility.selectedProgram]);

  const formik = useFormik({
    initialValues: getInitialEligibilitySheetFormValues(eligibility.eligibilitySheet || {}),
    enableReinitialize: true,
    onSubmit: (values) => {
      const valuesCopy = JSON.parse(JSON.stringify(values));
      if (isSaveDraft) {
        dispatch(
          eligibilityActions.saveDraft(
            id,
            eligibility.selectedProgram.role,
            parseEligibilitySheet(valuesCopy)
          )
        );
      } else {
        dispatch(
          eligibilityActions.submit(
            id,
            eligibility.selectedProgram.role,
            parseEligibilitySheet(valuesCopy)
          )
        );
      }
    }
  });

  useEffect(() => {
    if (isSaveDraft !== null) {
      formik.submitForm().finally(() => setSaveDraft(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveDraft]);

  // display CircularProgress on eligibility page loading
  if (eligibility?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  // display eligibility Sheet
  return (
    <Page title={intl.formatMessage({ id: 'applicant.programmes' })} my={2}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            {intl.formatMessage({ id: 'page.eligibility.sheet.title' })}
          </Typography>
        </Stack>
        {user.abilities.can(ACTIONS.VIEW, ENTITIES.ELIGIBILITY_DOCUMENTS) &&
          eligibility.eligibilitySheet &&
          formik.values.contestationFile && (
            <>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h6" gutterBottom>
                  {intl.formatMessage({ id: 'page.eligibility.documents.title' })}
                </Typography>
              </Stack>
              <Stack
                spacing={2}
                sx={{
                  marginBottom: 5,
                  borderRadius: '10px',
                  border: `1px solid ${theme.palette.grey[400]}`,
                  padding: '10px'
                }}
              >
                {formik.values.contestationFile && (
                  <Document document={formik.values.contestationFile} disabled isAdmin={false} />
                )}

                {formik.values[documents.name].map((document) => (
                  <Document
                    key={document[documents.filename.name]}
                    document={document}
                    disabled
                    isAdmin={false}
                  />
                ))}
              </Stack>
            </>
          )}
        <Grid container spacing={5}>
          {formik.values[eligibilitySheet.name][eligibilitySheet.criteria.name].map(
            (criterion, index) => (
              <Grid item xs={12} sm={12} key={index}>
                <EditableFormCheckbox
                  parentName={eligibilitySheet.name}
                  childrenNames={[
                    eligibilitySheet.criteria.name,
                    eligibilitySheet.criteria.value.name
                  ]}
                  index={index}
                  indexParent={eligibilitySheet.criteria.name}
                  formik={formik}
                  inputLabel={
                    formik.values[eligibilitySheet.name][eligibilitySheet.criteria.name][index][
                      eligibilitySheet.criteria.criteria.name
                    ]
                  }
                  variant="outlined"
                  disabled={readOnly}
                  fullWidth
                />
              </Grid>
            )
          )}
          <Grid item xs={12} sm={12}>
            <EditableFormTextField
              parentName={eligibilitySheet.name}
              childrenNames={[eligibilitySheet.comments.name]}
              formik={formik}
              label={intl.formatMessage({ id: eligibilitySheet.comments.label })}
              variant="outlined"
              disabled={readOnly}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <EditableFormCheckbox
              parentName={status.name}
              formik={formik}
              inputLabel={intl.formatMessage({ id: status.label })}
              variant="outlined"
              disabled={readOnly}
              fullWidth
            />
          </Grid>
          {!readOnly && (
            <>
              <Grid item xs={2} sm={2}>
                <Button variant="outlined" onClick={() => setSaveDraft(true)}>
                  {intl.formatMessage({ id: 'eligibility.save_review' })}
                </Button>
              </Grid>
              <Grid item xs={2} sm={2}>
                <Button variant="contained" onClick={() => setSaveDraft(false)}>
                  {intl.formatMessage({ id: 'eligibility.submit' })}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </Page>
  );
}
