import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';

EditableFormDatePicker.propTypes = {
  parentName: PropTypes.string,
  childrenNames: PropTypes.array,
  inputAdornmentChildrenNames: PropTypes.array,
  formik: PropTypes.any,
  tooltip: PropTypes.string,
  canBeNegative: PropTypes.bool,
  type: PropTypes.string,
  format: PropTypes.string
};

export default function EditableFormDatePicker({
  parentName,
  childrenNames,
  formik,
  format,
  ...nativeProps
}) {
  let value = parentName in formik.values ? formik.values[parentName] : '';
  let error = formik.errors[parentName] ? formik.errors[parentName] : false;
  let touched = formik.touched[parentName] ? formik.touched[parentName] : false;
  let errorMessageId = formik.errors[parentName] ? formik.errors[parentName] : false;
  let tag = `${parentName}`;

  if (childrenNames) {
    childrenNames.forEach((childName) => {
      touched = touched ? touched[childName] : touched;
      error = error ? error[childName] : error;
      errorMessageId = errorMessageId ? errorMessageId[childName] : errorMessageId;
      tag = `${tag}.${childName}`;
      value = value[childName];
    });
  }

  return (
    <DesktopDatePicker
      id={tag}
      name={tag}
      {...nativeProps}
      inputFormat={format}
      value={value}
      onChange={(value) => formik.setFieldValue(tag, value.startOf('day').format())}
      renderInput={(params) => (
        <TextField
          {...params}
          {...nativeProps}
          helperText={touched && !!error && <FormattedMessage id={errorMessageId} />}
          error={touched && !!error}
        />
      )}
    />
  );
}
