const patentsForm = {
  formId: 'patentsForm',
  patentsField: {
    patent: {
      name: 'Brevet',
      id: {
        name: 'Id'
      },
      status: {
        name: 'Status',
        label: 'patent.status',
        requiredErrorMsg: 'form.error.required'
      },
      number: {
        name: 'Numar',
        label: 'patent.number',
        requiredErrorMsg: 'form.error.required'
      },
      roName: {
        name: 'NumeRomana',
        label: 'patent.name_ro',
        requiredErrorMsg: 'form.error.required'
      },
      enName: {
        name: 'NumeEngleza',
        label: 'patent.name_en',
        requiredErrorMsg: 'form.error.required'
      },
      roDescription: {
        name: 'RezumatRomana',
        label: 'patent.description_ro'
      },
      enDescription: {
        name: 'RezumatEngleza',
        label: 'patent.description_en'
      },
      proposalYear: {
        name: 'AnPropunere',
        label: 'patent.proposal_year'
      },
      acceptanceYear: {
        name: 'AnAcceptare',
        label: 'patent.acceptance_year'
      },
      type: {
        name: 'Tip',
        label: 'patent.type'
      },
      authors: {
        name: 'Autori',
        label: 'publication.authors',
        button: 'form.expectedResults.keywords.button.label'
      }
    },
    cycle: {
      name: 'CicluId'
    },
    phase: {
      name: 'FazaId'
    }
  }
};

export default patentsForm;
