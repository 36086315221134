import * as _ from 'lodash';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import * as React from 'react';
import { useSelector } from 'react-redux';
import ProjectActionButton from './ProjectActionButton';
import EditableTableCell from '../../../EditableTableCell';

import projectsObjectivesForm from '../../../../forms/program/projectsObjectives/projectsObjectivesFormModel';
import { UserRoles } from '../../../../domain/UserRoles';
import EditableFormAutocomplete from '../../../../forms/shared/EditableFormAutocomplete';

const {
  projectsObjectivesFormFields: {
    projectFields: { additionalInfo, name: projectName, type, length, total, results, responsible }
  }
} = projectsObjectivesForm;

export default function ProjectTableRow({
  project,
  index,
  objectiveIndex,
  name,
  formik,
  disabled,
  arrayHelpers,
  program,
  autofocus
}) {
  const user = useSelector((state) => state.user);

  return (
    <TableRow key={index}>
      <TableCell>{`${objectiveIndex + 1}.${index + 1}`}</TableCell>
      <EditableTableCell
        getValue={() => project[projectName.name]}
        disabled={disabled}
        formik={formik}
        name={`${name}.${projectName.name}`}
        autoFocus={autofocus}
      />
      <EditableTableCell
        getValue={() => project[additionalInfo.name][type.name]}
        disabled={disabled}
        formik={formik}
        name={`${name}.${additionalInfo.name}.${type.name}`}
      />
      <EditableTableCell
        getValue={() => project[additionalInfo.name][length.name]}
        disabled={disabled}
        formik={formik}
        name={`${name}.${additionalInfo.name}.${length.name}`}
        type="number"
      />
      <EditableTableCell
        getValue={() => {
          let total = 0;
          _.range(program.start, program.end + 1).forEach((year) => {
            if (project[additionalInfo.name][year]) {
              total += +project[additionalInfo.name][year];
            }
          });
          return total;
        }}
        disabled
        formik={formik}
        name={`${name}.${additionalInfo.name}.${total.name}`}
        type="number"
      />
      {_.range(program.start, program.end + 1).map((year) => (
        <EditableTableCell
          key={year}
          getValue={() => project[additionalInfo.name][year]}
          disabled={disabled}
          formik={formik}
          name={`${name}.${additionalInfo.name}.${year}`}
          type="number"
        />
      ))}
      <EditableTableCell
        getValue={() => project[additionalInfo.name][results.name]}
        disabled={disabled}
        formik={formik}
        name={`${name}.${additionalInfo.name}.${results.name}`}
      />
      {disabled && (
        <EditableTableCell
          getValue={() => project[additionalInfo.name][responsible.name].nume}
          disabled={disabled}
          formik={formik}
          name={`${name}.${additionalInfo.name}.${responsible.name}.nume`}
        />
      )}
      {!disabled && (
        <TableCell>
          <EditableFormAutocomplete
            parentName={name.split('.')[0]}
            childrenNames={[...name.split('.').slice(1), additionalInfo.name, responsible.name]}
            formik={formik}
            required
            variant="outlined"
            fullWidth
            options={
              user && user.users && user.users.data
                ? user.users.data
                    .filter(
                      (userOption) =>
                        !userOption.userType.includes(UserRoles.AdministratorInstitutie)
                    )
                    .map((userOption) => ({
                      id: userOption.id,
                      label: `${userOption.firstName} ${userOption.lastName}`
                    }))
                : []
            }
          />
        </TableCell>
      )}
      {!disabled && (
        <TableCell>
          <ProjectActionButton actionType="remove" onClick={() => arrayHelpers.remove(index)} />
        </TableCell>
      )}
    </TableRow>
  );
}
