import * as React from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditableTableCell from '../../EditableTableCell';

import costuriTotaleForm from '../../../forms/proiect/costuriTotale/costuriTotaleFormModel';

const {
  costuriTotaleTableHead: { total },
  costuriTotaleFormFields
} = costuriTotaleForm;

export default function CosturiTotale({ formik, project, disabled }) {
  const intl = useIntl();
  const years = _.range(project.start, project.end + 1);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{intl.formatMessage({ id: 'form.project.cost.header.costuri' })}</TableCell>
          {years.map((year) => (
            <TableCell key={year}>{year}</TableCell>
          ))}
          <TableCell>{intl.formatMessage({ id: total.label })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>1</TableCell>
          {years.map((year, index) => (
            <TableCell key={index}>{index + 2}</TableCell>
          ))}
          <TableCell>
            {`${years.length + 2} = ${years.map((_, index) => index + 2).join(' + ')}`}
          </TableCell>
        </TableRow>
        {costuriTotaleFormFields.map((field, index) => (
          <TableRow key={index}>
            <TableCell>{intl.formatMessage({ id: field.label })}</TableCell>
            {years.map((year) => (
              <EditableTableCell
                key={year}
                name={`${costuriTotaleForm.name}.${field.name}.${year}`}
                formik={formik}
                getValue={() => {
                  if (field.name === 'total') {
                    let total = 0;
                    costuriTotaleFormFields.forEach((currentField) => {
                      if (currentField.name !== 'total') {
                        if (formik.values[costuriTotaleForm.name][currentField.name][year]) {
                          total += +formik.values[costuriTotaleForm.name][currentField.name][year];
                        }
                      }
                    });
                    return total;
                  }
                  return formik?.values?.[costuriTotaleForm.name]?.[field.name]?.[year] ?? '';
                }}
                disabled={field.name === 'total' || disabled}
                type="number"
              />
            ))}
            <EditableTableCell
              name={`${costuriTotaleForm.name}.${field.name}.${total.name}`}
              formik={formik}
              getValue={() => {
                if (field.name !== 'total') {
                  let total = 0;
                  if (
                    formik &&
                    formik.values &&
                    formik.values[costuriTotaleForm.name] &&
                    formik.values[costuriTotaleForm.name][field.name]
                  ) {
                    years.forEach((year) => {
                      if (formik.values[costuriTotaleForm.name][field.name][year]) {
                        total += +formik.values[costuriTotaleForm.name][field.name][year];
                      }
                    });
                  }
                  return total;
                }

                let total = 0;
                costuriTotaleFormFields.forEach((currentField) => {
                  if (currentField.name !== 'total') {
                    years.forEach((year) => {
                      if (formik.values[costuriTotaleForm.name][currentField.name][year]) {
                        total += +formik.values[costuriTotaleForm.name][currentField.name][year];
                      }
                    });
                  }
                });
                return total;
              }}
              disabled
              type="number"
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
