import { useIntl } from 'react-intl';
import { Grid, Tab, TextField, Typography } from '@mui/material';
import * as React from 'react';
import PropTypes from 'prop-types';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ACTIONS, ENTITIES } from '../../../utils/enums';
// ----------------------------------------------------------------------

InstitutionProgramEvaluationProjectSheet.propTypes = {
  sheet: PropTypes.object
};

export default function InstitutionProgramEvaluationProjectSheet({ sheet }) {
  const intl = useIntl();
  const [value, setValue] = useState('1');
  const user = useSelector((state) => state.user);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container spacing={5}>
      {user.abilities.can(ACTIONS.VIEW, ENTITIES.INSTITUTION) && (
        <Grid container spacing={2}>
          {sheet.fisaFinala.criterii.map((criterion, index) => (
            <Grid item xs={12} sm={12} key={index}>
              <Grid container spacing={5} alignItems="center">
                <Grid item xs={10} sm={10}>
                  <Typography variant="h5" gutterBottom component="div">
                    {criterion.denumire}
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <TextField
                    id={`score${index}`}
                    name={`score${index}`}
                    label={`${intl.formatMessage({ id: 'global.score' })} (max: ${
                      criterion.scorMaxim
                    })`}
                    value={criterion.scor}
                    disabled
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id={`comment${index}`}
                    name={`comment${index}`}
                    label={intl.formatMessage({ id: 'global.comments' })}
                    value={criterion.comentariu}
                    variant="outlined"
                    disabled
                    fullWidth
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
      {user.abilities.cannot(ACTIONS.VIEW, ENTITIES.INSTITUTION) && (
        <TabContext value={value}>
          <TabList onChange={handleChange} aria-label="tabs">
            <Tab label={intl.formatMessage({ id: 'admin.evaluation.subtab1' })} value="1" />
            <Tab label={intl.formatMessage({ id: 'admin.evaluation.subtab2' })} value="2" />
            <Tab label={intl.formatMessage({ id: 'admin.evaluation.subtab3' })} value="3" />
            <Tab label={intl.formatMessage({ id: 'admin.evaluation.subtab4' })} value="4" />
          </TabList>
          {sheet.fiseEvaluatori.map((fisaEvaluator, fisaIndex) => (
            <TabPanel value={`${fisaIndex + 1}`} index={fisaIndex} key={fisaIndex}>
              <Grid container spacing={2}>
                {fisaEvaluator.criterii.map((criterion, index) => (
                  <Grid item xs={12} sm={12} key={index}>
                    <Grid container spacing={5} alignItems="center">
                      <Grid item xs={10} sm={10}>
                        <Typography variant="h5" gutterBottom component="div">
                          {criterion.denumire}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <TextField
                          id={`score${index}`}
                          name={`score${index}`}
                          label={`${intl.formatMessage({ id: 'global.score' })} (max: ${
                            criterion.scorMaxim
                          })`}
                          value={criterion.scor}
                          disabled
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          id={`comment${index}`}
                          name={`comment${index}`}
                          label={intl.formatMessage({ id: 'global.comments' })}
                          value={criterion.comentariu}
                          variant="outlined"
                          disabled
                          fullWidth
                          multiline
                          rows={4}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
          ))}
          <TabPanel value="4" index={3}>
            <Grid container spacing={2}>
              {sheet.fisaFinala.criterii.map((criterion, index) => (
                <Grid item xs={12} sm={12} key={index}>
                  <Grid container spacing={5} alignItems="center">
                    <Grid item xs={10} sm={10}>
                      <Typography variant="h5" gutterBottom component="div">
                        {criterion.denumire}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <TextField
                        id={`score${index}`}
                        name={`score${index}`}
                        label={`${intl.formatMessage({ id: 'global.score' })} (max: ${
                          criterion.scorMaxim
                        })`}
                        value={criterion.scor}
                        disabled
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        id={`comment${index}`}
                        name={`comment${index}`}
                        label={intl.formatMessage({ id: 'global.comments' })}
                        value={criterion.comentariu}
                        variant="outlined"
                        disabled
                        fullWidth
                        multiline
                        rows={4}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </TabPanel>
        </TabContext>
      )}
    </Grid>
  );
}
