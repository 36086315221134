import * as Yup from 'yup';
import institutionFormModel from './institutionFormModel';

const {
  institutionFormField: { name, shortName, fiscalCode, region, county, city, address }
} = institutionFormModel;

export const institutionFormValidationSchema = (institutions) =>
  Yup.object().shape({
    [name.name]: Yup.string().required(`${name.requiredErrorMsg}`),
    [shortName.name]: Yup.string().required(`${shortName.requiredErrorMsg}`),
    [fiscalCode.name]: Yup.string()
      .required(`${fiscalCode.requiredErrorMsg}`)
      .test({
        name: 'check existing fiscal code',
        message: fiscalCode.duplicateFiscalCodeErrorMsg,
        /* eslint-disable */
        test: function (value) {
            if (value) {
                if (this.parent.id) {
                    return !institutions.find((institution) => institution.institutieGuid !== this.parent.id && institution.codFiscal.toLowerCase() === value.toLowerCase());
                }
                return !institutions.find((institution) => institution.codFiscal.toLowerCase() === value.toLowerCase());
            }
            return true;
        }
    }),
    [region.name]: Yup.string().required(`${region.requiredErrorMsg}`),
    [county.name]: Yup.string().required(`${county.requiredErrorMsg}`),
    [city.name]: Yup.string().required(`${city.requiredErrorMsg}`),
    [address.name]: Yup.string().required(`${address.requiredErrorMsg}`)
  });
