import * as React from 'react';
import { useIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import { MONITORING_STATUS } from '../../utils/enums';
// ----------------------------------------------------------------------------

export default function InstitutionProgramMonitorCycleProjectCard({ entity }) {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Card key={entity.proiectGuid.toString()}>
      <Grid container alignItems="center">
        <Grid item xs={9} sm={9}>
          <CardHeader
            title={
              <div>
                <p>{entity.titluProiect}</p>
              </div>
            }
          />
          <CardContent>
            <p>
              {intl.formatMessage({ id: 'global.status' })}: {entity.statusMonitorizare}{' '}
            </p>
            <p>
              {intl.formatMessage({ id: 'global.budget' })}: {entity.buget}{' '}
            </p>
          </CardContent>
        </Grid>
        <Grid item xs={3} sm={3}>
          <Stack spacing={2} sx={{ mr: 2 }}>
            <Button
              disabled={
                entity.statusMonitorizare === MONITORING_STATUS.EVALUATING ||
                entity.statusMonitorizare === MONITORING_STATUS.ACCEPTED
              }
              variant="outlined"
              onClick={() => navigate(`${entity.proiectGuid}/faze`)}
            >
              {intl.formatMessage({ id: 'institution.monitoring.project.phases' })}
            </Button>
            <Button variant="outlined" onClick={() => navigate(`${entity.proiectGuid}/rezultate`)}>
              {intl.formatMessage({ id: 'institution.monitoring.project.results' })}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}
