export function removeValue(obj, value) {
  const isArray = Array.isArray(obj);
  Object.keys(obj).forEach((key) => {
    if (obj[key] === value) {
      if (isArray) {
        obj.splice(key, 1);
      } else {
        delete obj[key];
      }
    } else if (typeof obj[key] === 'object') {
      removeValue(obj[key], value);
    }
    if (isArray && obj.length === key) {
      removeValue(obj, value);
    }
  });
  return obj;
}

export function extractNumber(input) {
  if (input === '') return null;
  const numbers = input.match(/[+-]?([0-9]+([.][0-9]*)?)/g);
  return numbers ? numbers[0] : '0';
}

export function extractPositiveNumber(input) {
  input = extractNumber(input);
  if (!input) return null;
  if (input && Number(input) <= 0) {
    input = '0';
  }
  return input;
}

export function extractCurrency(input) {
  if (input === '') return null;
  const numbers = input.match(/[+-]?([0-9]+([.][0-9]{0,2})?)/g);
  return numbers ? numbers[0] : '0';
}
