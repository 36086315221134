import { camelizeKeys, pascalizeKeys } from 'humps';
import FileDownload from 'js-file-download';
import { authHeader } from '../utils/auth-header';
import { env } from '../utils/config';
import { userService } from './user.service';

export const programService = {
  getProgram,
  getMyPrograms,
  getProgramsApplicant,
  createProgram,
  getProgramEligibilityResponsibles,
  getProgramProjectsEvaluationResponsibles,
  getProgramEvaluationResponsibles,
  updateProgramInformation,
  updateProgramTotalValue,
  updateProgramExpectedResults,
  updateProgramIncomeStatus,
  updateProgramProjectsObjectives,
  uploadProgramAdminCv,
  downloadProgramAdminCv,
  getSupportingDocuments,
  uploadSupportingDocuments,
  downloadSupportingDocument,
  deleteSupportingDocument,
  updateProgramEligibilityResponsibles,
  updateProgramProjectsEvaluationResponsibles,
  updateProgramEvaluationResponsibles,
  getProject,
  updateProject,
  addProgram,
  checkProgramEligibility,
  submitForEligibility,
  updateProgram,
  contestEligibility,
  getAdminProgramEvaluation,
  setProgramEvaluationStatus,
  getProjectsAvailableForMonitoring
};

function getProgram(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_COMMON_BASE_PATH}/Program/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getAdminProgramEvaluation(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/VizualizeazaEvaluareProgram/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getProjectsAvailableForMonitoring(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/VizualizareProiecteProgramMonitorizat/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getMyPrograms(institutionId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/VizualizeazaProgrameAsociateInstitutiei/${institutionId}`,
    requestOptions
  ).then(handleResponse);
}

function checkProgramEligibility(programId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${programId}/VerificaEligibilitate`,
    requestOptions
  ).then(handleResponse);
}

function getProgramsApplicant() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_APLICANT_PROIECT_BASE_PATH}/VizualizeazaProgrameleMele`,
    requestOptions
  ).then(handleResponse);
}

function createProgram(competitionGuid) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      CompetitieGuid: competitionGuid
    })
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/CreeazaProgram`,
    requestOptions
  ).then(handleResponse);
}

function getProgramEligibilityResponsibles(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/VizualizeazaUseriEligibilitateProgram/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getProgramProjectsEvaluationResponsibles(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/VizualizeazaEvaluatoriObiective/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getProgramEvaluationResponsibles(id, type) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/VizualizeazaEvaluatoriProgram/${id}/${type}`,
    requestOptions
  ).then(handleResponse);
}

function updateProgramInformation(id, data) {
  delete data.programAdminCV;
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(pascalizeKeys(data))
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${id}/AdaugaInformatiiProgram`,
    requestOptions
  ).then(handleResponse);
}

function updateProgramTotalValue(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(pascalizeKeys(data))
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${id}/AdaugaValoareTotala`,
    requestOptions
  ).then(handleResponse);
}

function updateProgramExpectedResults(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(pascalizeKeys(data))
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${id}/AdaugaRezultatePrevizionate`,
    requestOptions
  ).then(handleResponse);
}

function updateProgramIncomeStatus(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(pascalizeKeys(data))
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${id}/AdaugaSituatiaVeniturilor`,
    requestOptions
  ).then(handleResponse);
}

function updateProgramProjectsObjectives(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(pascalizeKeys(data))
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${id}/AdaugaObiectiveSiProiecte`,
    requestOptions
  ).then(handleResponse);
}

function uploadProgramAdminCv(id, formData) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: formData
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${id}/IncarcaCvDirector`,
    requestOptions
  ).then(handleResponse);
}

function downloadProgramAdminCv(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    responseType: 'blob'
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${id}/DescarcaCvDirectorProgram`,
    requestOptions
  ).then((response) => {
    response.blob().then((data) => {
      FileDownload(
        data,
        decodeURIComponent(
          `${response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0]}.pdf`
        )
      );
    });
  });
}

function getSupportingDocuments(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_COMMON_BASE_PATH}/Program/${id}/VizualizeazaDocumenteSuport`,
    requestOptions
  ).then(handleResponse);
}

function uploadSupportingDocuments(id, formData) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: formData
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${id}/IncarcaDocumenteSuport`,
    requestOptions
  ).then(handleResponse);
}

function downloadSupportingDocument(id, fileUrl) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    responseType: 'blob'
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_COMMON_BASE_PATH}/Program/${id}/DescarcaDocumentSuport/${fileUrl}`,
    requestOptions
  ).then((response) => {
    response.blob().then((data) => {
      FileDownload(
        data,
        decodeURIComponent(
          `${response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0]}.pdf`
        )
      );
    });
  });
}

function deleteSupportingDocument(id, fileUrl) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${id}/StergeDocumentSuport/${fileUrl}`,
    requestOptions
  ).then(handleResponse);
}

function getProject(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_APLICANT_PROIECT_BASE_PATH}/VizualizeazaProiect/${id}`,
    requestOptions
  ).then(handleResponse);
}

function updateProject(id, data) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(pascalizeKeys(data))
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_APLICANT_PROIECT_BASE_PATH}/CompleteazaProiect/${id}`,
    requestOptions
  ).then(handleResponse);
}

function setProgramEvaluationStatus(id, status) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ Status: status ? 'Admis' : 'Respins' })
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/SeteazaStatusProgram/${id}`,
    requestOptions
  ).then(handleResponse);
}

function updateProgramEligibilityResponsibles(data, programId, type) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/AsociazaUseriEligibilitate/${programId}/${type}`,
    requestOptions
  ).then(handleResponse);
}

function updateProgramProjectsEvaluationResponsibles(data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/AsigneazaEvaluatoriObiective`,
    requestOptions
  ).then(handleResponse);
}

function updateProgramEvaluationResponsibles(data, type) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/AsigneazaEvaluatoriProgram/${type}`,
    requestOptions
  ).then(handleResponse);
}

function submitForEligibility(programId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  };
  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${programId}/SubmiteCatreEligibilitate`,
    requestOptions
  ).then(handleResponse);
}

function addProgram(program) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(program)
  };
  // TODO: enable when backend active
  // return fetch(
  //   `${env.REACT_APP_SERVER_IDENTITY_BASE_PATH}${env.REACT_APP_SERVER_APP_REGISTRY_BASE_PATH}${env.REACT_APP_SERVER_APP_REGISTRY_INSTANCES}`,
  //   requestOptions
  // ).then(handleResponse);
  return new Promise((resolve, reject) => {
    resolve(program);
  });
}

function updateProgram(program) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(program)
  };
  // TODO: enable when backend active
  // return fetch(
  //   `${env.REACT_APP_SERVER_IDENTITY_BASE_PATH}${env.REACT_APP_SERVER_APP_REGISTRY_BASE_PATH}${env.REACT_APP_SERVER_APP_REGISTRY_INSTANCES}`,
  //   requestOptions
  // ).then(handleResponse);
  return new Promise((resolve, reject) => {
    resolve(program);
  });
}

function contestEligibility(formData, idProgram) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: formData
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${idProgram}/ContestaEligibilitatea`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        window.location.reload(true);
      }
      return Promise.reject(JSON.parse(text));
    }

    const data = text && JSON.parse(text);

    if (data) {
      return camelizeKeys(data);
    }

    return text;
  });
}
