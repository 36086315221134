import { useIntl } from 'react-intl';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import { useNavigate } from 'react-router-dom';

export default function ProiectCard({ proiect }) {
  const intl = useIntl();
  const disabled = true;
  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader
        title={proiect.titluProiect}
        action={
          <Button variant="outlined" onClick={() => navigate(`proiect/${proiect.proiectGuid}`)}>
            {intl.formatMessage({
              id: disabled ? 'proiectCard.button.view.label' : 'proiectCard.button.edit.label'
            })}
          </Button>
        }
      />
      <CardContent>
        <p>{intl.formatMessage({ id: 'form.projectsObjectives.objective.label' })}</p>
        <p>{proiect.titluObiectiv}</p>
      </CardContent>
    </Card>
  );
}
