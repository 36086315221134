import * as React from 'react';
import { useIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { PROJECT_EVALUATION_STATUS } from '../../utils/enums';
// ----------------------------------------------------------------------------

export default function ProgramsEvaluationCard({ entity }) {
  const intl = useIntl();
  const navigate = useNavigate();
  const [buttonText, setButtonText] = useState('projectEvaluationSheet.button.label');

  useEffect(() => {
    if ('fisaTehnicaAsignata' in entity) {
      if ('fisaFinalaVizibilaModerator' in entity) {
        if (
          entity.fisaFinalaVizibilaModerator.statusFisaEvaluare ===
          PROJECT_EVALUATION_STATUS.SUBMITTED
        ) {
          setButtonText('projectEvaluationSheet.view.button.label');
        } else if (
          entity.fisaTehnicaAsignata.statusFisaEvaluare !== PROJECT_EVALUATION_STATUS.SUBMITTED
        ) {
          setButtonText('projectEvaluationSheet.button.label');
        } else {
          setButtonText('projectEvaluationSheet.final.button.label');
        }
      } else if (
        entity.fisaTehnicaAsignata.statusFisaEvaluare === PROJECT_EVALUATION_STATUS.SUBMITTED
      ) {
        setButtonText('projectEvaluationSheet.view.button.label');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  return (
    <Card key={entity.programGuid.toString()}>
      <Grid container alignItems="center">
        <Grid item xs={9} sm={9}>
          <CardHeader
            title={
              <div>
                <a href={`programe/${entity.programGuid.toString()}`}>{entity.titluProgram}</a>
              </div>
            }
          />
          <CardContent>
            <p>{intl.formatMessage({ id: 'programCard.competition' })}</p>
            <p>{entity.numeCompetitieParinte}</p>
          </CardContent>
        </Grid>
        <Grid item xs={3} sm={3}>
          <Stack spacing={2} sx={{ mr: 2 }}>
            <Button variant="outlined" onClick={() => navigate(`${entity.programGuid}/fisa`)}>
              {intl.formatMessage({ id: buttonText })}
            </Button>
            <Button variant="outlined" onClick={() => navigate(`${entity.programGuid}/obiective`)}>
              {intl.formatMessage({ id: 'programEvaluation.view.projectSheets' })}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}
