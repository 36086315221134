import { disposeToaster, setToaster, Success, Error } from '../reducers/toaster.reducer';
import store from './store';

export function triggerSuccess(message, title, values = null) {
  store.dispatch(
    setToaster({
      type: Success,
      message,
      title,
      values
    })
  );
}

export function triggerError(message, title) {
  store.dispatch(
    setToaster({
      type: Error,
      message,
      title
    })
  );
}

export function triggerDispose() {
  store.dispatch(disposeToaster());
}
