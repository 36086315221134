import { useIntl } from 'react-intl';
import TableActionButton from '../../../TableActionButton';

export default function ProjectActionButton({ onClick, actionType }) {
  const intl = useIntl();

  return (
    <TableActionButton
      variant="outlined"
      label={intl.formatMessage({ id: 'form.projectsObjectives.project.label' })}
      onClick={onClick}
      actionType={actionType}
    />
  );
}
