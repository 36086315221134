import projectsObjectivesForm from './projectsObjectivesFormModel';

const {
  projectsObjectivesFormFields: { objectives, objectiveFields, projectFields }
} = projectsObjectivesForm;

export const parseProjectsObjectives = (projectsObjectivesValues) => {
  const parseProjectsObjectivesForm = { ...projectsObjectivesValues };

  parseProjectsObjectivesForm[objectives.name] = projectsObjectivesValues[objectives.name].map(
    (objective) => ({
      ...objective,
      [objectiveFields.projects.name]: objective[objectiveFields.projects.name].map((project) => ({
        ...project,
        [projectFields.additionalInfo.name]: {
          ...project[projectFields.additionalInfo.name],
          [projectFields.responsible.name]: {
            Id: project[projectFields.additionalInfo.name][projectFields.responsible.name].id,
            Nume: project[projectFields.additionalInfo.name][projectFields.responsible.name].nume
              ? project[projectFields.additionalInfo.name][projectFields.responsible.name].nume
              : project[projectFields.additionalInfo.name][projectFields.responsible.name].label
          }
        }
      }))
    })
  );

  return parseProjectsObjectivesForm;
};
