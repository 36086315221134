import Button from '@mui/material/Button';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';

export default function ActionButton({ actionType, label, onClick, variant, ...props }) {
  const getButtonIcon = () => {
    if (actionType === 'insert') return <AddCircleOutlineOutlinedIcon />;
    if (actionType === 'remove') return <RemoveCircleOutlineOutlinedIcon />;
    return null;
  };
  return (
    <Button
      onClick={onClick}
      variant={variant}
      color={actionType === 'remove' ? 'error' : 'primary'}
      startIcon={getButtonIcon()}
      {...props}
    >
      {label}
    </Button>
  );
}
