import * as React from 'react';
import moment from 'moment';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import EditableFormTextField from '../forms/shared/EditableFormTextField';

export default function EditableTableCell({
  name,
  formik,
  getValue,
  disabled,
  type = 'text',
  placeholder,
  format,
  ...props
}) {
  const [edit, setEditMode] = React.useState(true);
  const [value, setValue] = React.useState(getValue());

  if (disabled)
    return (
      <TableCell {...props}>
        {type === 'date' ? moment(getValue()).format(format) : getValue()}
      </TableCell>
    );

  if (!edit)
    return (
      <TableCell onClick={() => setEditMode(true)} {...props}>
        {type === 'date' ? moment(getValue()).format(format) : getValue()}
      </TableCell>
    );

  return (
    <TableCell {...props}>
      {(type === 'text' || type === 'number') && (
        <EditableFormTextField
          parentName={name.split('.')[0]}
          childrenNames={name.split('.').slice(1)}
          formik={formik}
          variant="outlined"
          fullWidth
          type={type}
        />
      )}
      {type === 'date' && (
        <DesktopDatePicker
          id={name}
          name={name}
          {...props}
          inputFormat={format}
          defaultValue={value}
          value={value}
          onChange={(v) => {
            formik.setFieldValue(name, v.format());
            setValue(v);
          }}
          renderInput={(params) => <TextField {...params} {...props} />}
        />
      )}
    </TableCell>
  );
}
