import * as Yup from 'yup';
import userFormModel from './userFormModel';
import { UserRoles } from '../../domain/UserRoles';

const {
  userFormField: { lastName, firstName, email, phone, roles, institution }
} = userFormModel;

export const userFormValidationSchema = (isAdmin, users) =>
  Yup.object().shape({
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [email.name]: Yup.string()
      .email(email.emailErrorMsg)
      .required(`${email.requiredErrorMsg}`)
      .test({
        name: 'check existing email',
        message: email.duplicateEmailErrorMsg,
        /* eslint-disable */
        test: function (value) {
          if (value) {
            if (this.parent.id) {
              return !users.find((user) => user.id !== this.parent.id && user.email.toLowerCase() === value.toLowerCase());
            }
            return !users.find((user) => user.email.toLowerCase() === value.toLowerCase());
          }
          return true;
        }
    }),
    [phone.name]: Yup.string()
      .required(`${phone.requiredErrorMsg}`)
      .phone('RO', `${phone.phoneErrorMsg}`),
    [roles.name]: Yup.array().min(1, `${roles.requiredErrorMsg}`),
    [institution.name]: Yup.string()
      .required(`${institution.requiredErrorMsg}`)
      .test({
        name: 'required',
        message: `${institution.requiredErrorMsg}`,
        // eslint-disable-next-line object-shorthand,func-names
        test: function (value) {
          const selectedRoles = this.parent[roles.name];
          if (
            isAdmin &&
            (selectedRoles.includes(UserRoles.AdministratorInstitutie) ||
              selectedRoles.includes(UserRoles.AplicantProiect))
          ) {
            if (value && value.length > 0) {
              return true;
            }
            return false;
          }
          return true;
        }
      })
  });
