const informatiiStiintificeForm = {
  formId: 'informatiiStiintifice',
  parentName: 'informatiiStiintifice',
  informatiiStiintificeFormFields: {
    prezentare: {
      name: 'prezentare',
      label: 'page.project.technical_info.prezentare'
    },
    situatieTara: {
      name: 'situatieTara',
      label: 'page.project.technical_info.situatieTara'
    },
    situatieStrainatate: {
      name: 'situatieStrainatate',
      label: 'page.project.technical_info.situatieStrainatate'
    },
    contributie: {
      name: 'contributie',
      label: 'page.project.technical_info.contributie'
    },
    dotariExistente: {
      name: 'dotariExistente',
      label: 'page.project.technical_info.dotariExistente'
    },
    dotariNecesare: {
      name: 'dotariNecesare',
      label: 'page.project.technical_info.dotariNecesare'
    }
  }
};

export default informatiiStiintificeForm;
