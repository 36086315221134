import * as Yup from 'yup';
import informatiiGeneraleForm from './informatiiGenerale/informatiiGeneraleFormModel';

const {
  informatiiGeneraleFormFields: { cnpResponsabil, telResponsabil, faxResponsabil, emailResponsabil }
} = informatiiGeneraleForm;

const CNP_REGEX =
  '^[1-9]\\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\\d|3[01])(0[1-9]|[1-4]\\d|5[0-2]|99)(00[1-9]|0[1-9]\\d|[1-9]\\d\\d)\\d$';

export const proiectValidationSchema = () =>
  Yup.object().shape({
    [emailResponsabil.name]: Yup.string().email(`${emailResponsabil.invalidFormatErrorMsg}`),
    [telResponsabil.name]: Yup.string().phone('RO', `${telResponsabil.invalidFormatErrorMsg}`),
    [faxResponsabil.name]: Yup.string().phone('RO', `${faxResponsabil.invalidFormatErrorMsg}`),
    [cnpResponsabil.name]: Yup.string().matches(
      CNP_REGEX,
      `${cnpResponsabil.invalidFormatErrorMsg}`
    )
  });
