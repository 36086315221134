import * as React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FieldArray, Formik } from 'formik';
import { useIntl } from 'react-intl';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';

import { FORM_IDS } from '../../../utils/enums';
import EditableFormTextField from '../../../forms/shared/EditableFormTextField';
import TotalValueRow from './TotalValueRow';

import { programActions } from '../../../actions';

import totalValueFormPermanentFields from '../../../forms/program/totalValue/totalValueFormModel';
import { getInitialTotalValueFormValues } from '../../../forms/program/totalValue/formInitialValues';
import { totalValueFormValidationSchema } from '../../../forms/program/totalValue/validationSchema';

const {
  totalValueFormFields: { yearTable, totalValue }
} = totalValueFormPermanentFields;

export default function TotalValue({ program, disabled, isNew }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const programState = useSelector((state) => state.programs.selectedProgram);

  const years = _.range(program.start, program.end + 1);

  const formik = {
    initialValues: getInitialTotalValueFormValues(program.start, program.end, programState),
    validationSchema: totalValueFormValidationSchema(program.start, program.end),
    onSubmit: (values) => {
      const data = JSON.parse(JSON.stringify(values));

      if (isNew) {
        dispatch(
          programActions.createProgram(id, (program) => {
            dispatch(programActions.updateProgramTotalValue(program.programGuid, data));
            navigate(`../../../programe/${program.programGuid.toString()}`, { replace: true });
          })
        );
      } else {
        dispatch(programActions.updateProgramTotalValue(id, data));
      }
    }
  };

  return (
    <Formik
      initialValues={formik.initialValues}
      validationSchema={formik.validationSchema}
      onSubmit={formik.onSubmit}
    >
      {({ handleChange, handleBlur, values, errors, touched, setFieldValue, handleSubmit }) => (
        <form id={FORM_IDS.PROGRAM_TOTAL_VALUE} onSubmit={handleSubmit} noValidate>
          <Stack spacing={3}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {intl.formatMessage({ id: 'program.totalValue.table.head.year' })}
                    </TableCell>
                    <TableCell>
                      {intl.formatMessage({ id: 'program.totalValue.table.head.valueEstimation' })}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <FieldArray
                    name={yearTable.name}
                    render={() =>
                      years.map((year, index) => (
                        <TotalValueRow
                          key={year}
                          year={year}
                          index={index}
                          disabled={disabled}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          values={values}
                          setFiledValue={setFieldValue}
                        />
                      ))
                    }
                  />
                </TableBody>
              </Table>
            </TableContainer>
            <EditableFormTextField
              parentName={totalValue.name}
              formik={{
                errors,
                touched,
                handleChange,
                handleBlur,
                values
              }}
              label={intl.formatMessage({ id: totalValue.label })}
              variant="outlined"
              fullWidth
              disabled
              InputProps={{
                endAdornment: <InputAdornment position="end">RON</InputAdornment>
              }}
              InputLabelProps={{ shrink: true }}
              type="currency"
            />
          </Stack>
        </form>
      )}
    </Formik>
  );
}
