import PropTypes from 'prop-types';
import _ from 'lodash';
import { FieldArray } from 'formik';
import { useIntl } from 'react-intl';

import { Typography } from '@mui/material';
import moment from 'moment';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Fragment } from 'react';
import EditableTableCell from '../../EditableTableCell';
import schemaRealizareForm from '../../../forms/proiect/schemaRealizare/schemaRealizareFormModel';
import { getNewFaza } from '../../../forms/proiect/schemaRealizare/formInitialValues';
import { defaultConfig } from '../../../utils/config';
import ActionButton from '../../TableActionButton';

const {
  schemaRealizareFields: { faza },
  schemaRealizareTableHead: { dataInceput, dataFinal, termenPredare, buget, titlu },
  cycles
} = schemaRealizareForm;

const getTotalBudgetForFaze = (faze) => {
  if (faze)
    return faze
      .map((curr) => Number(curr[faza.name][buget.name]))
      .reduce((partialSum, curr) => partialSum + (Number.isNaN(curr) ? 0 : curr), 0);
  return 0;
};

function isValidDate(date) {
  return date && Object.prototype.toString.call(date) === '[object Date]' && !Number.isNaN(date);
}

const checkYear = (year, phase) => {
  const wrapper = new Date(phase[faza.name][dataInceput.name]);
  if (!isValidDate(wrapper)) return false;
  return wrapper.getFullYear() === year;
};

SchemaRealizare.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
  project: PropTypes.object
};

export default function SchemaRealizare({ formik, disabled, project }) {
  const intl = useIntl();
  const years = _.range(project.start, project.end + 1);

  const totalBuget = {
    total: getTotalBudgetForFaze(formik.values[schemaRealizareForm.name])
  };
  years.forEach((year) => {
    totalBuget[year] = getTotalBudgetForFaze(
      formik.values[schemaRealizareForm.name].filter((curr) => checkYear(year, curr))
    );
  });

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Typography variant="h4">
        {intl.formatMessage({ id: 'page.project.schema.title' })}
      </Typography>
      <Typography variant="h6">
        {intl.formatMessage({ id: 'page.project.schema.cycles' })}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{intl.formatMessage({ id: 'table.head.name' })}</TableCell>
            <TableCell>{intl.formatMessage({ id: dataInceput.label })}</TableCell>
            <TableCell>{intl.formatMessage({ id: dataFinal.label })}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formik.values[cycles.name].map((cycle) => (
            <TableRow key={cycle[cycles.id.name]}>
              <TableCell>{cycle[cycles.cycleName.name]}</TableCell>
              <TableCell>{moment(cycle[cycles.startDate.name]).format('D MMMM YYYY')}</TableCell>
              <TableCell>{moment(cycle[cycles.endDate.name]).format('D MMMM YYYY')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{intl.formatMessage({ id: 'table.head.number' })}</TableCell>
            <TableCell>{intl.formatMessage({ id: dataInceput.label })}</TableCell>
            <TableCell>{intl.formatMessage({ id: dataFinal.label })}</TableCell>
            <TableCell>{intl.formatMessage({ id: titlu.label })}</TableCell>
            <TableCell>{intl.formatMessage({ id: buget.label })}</TableCell>
            <TableCell>{intl.formatMessage({ id: termenPredare.label })}</TableCell>
            <TableCell>{intl.formatMessage({ id: 'table.head.actions' })}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <FieldArray
            name={schemaRealizareForm.name}
            render={(arrayHelpers) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                {years.map((year) => (
                  <Fragment key={year}>
                    <TableRow key={year}>
                      <TableCell colSpan={6}>
                        <h4>{`${intl.formatMessage({ id: 'global.year' })} ${year}`}</h4>
                      </TableCell>
                      <TableCell>
                        {!disabled && (
                          <ActionButton
                            actionType="insert"
                            variant="contained"
                            label="Faza"
                            onClick={() => arrayHelpers.push(getNewFaza(year))}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    {formik.values[schemaRealizareForm.name].map((currentPhase, index) => {
                      if (checkYear(year, currentPhase))
                        return (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <EditableTableCell
                              formik={formik}
                              disabled={disabled}
                              name={`${schemaRealizareForm.name}.${index}.${dataInceput.parent}.${dataInceput.name}`}
                              getValue={() => currentPhase[dataInceput.parent][dataInceput.name]}
                              type="date"
                              format={defaultConfig.dateTimeFormats.short}
                            />
                            <EditableTableCell
                              formik={formik}
                              disabled={disabled}
                              name={`${schemaRealizareForm.name}.${index}.${dataFinal.parent}.${dataFinal.name}`}
                              getValue={() => currentPhase[dataFinal.parent][dataFinal.name]}
                              type="date"
                              format={defaultConfig.dateTimeFormats.short}
                            />
                            <EditableTableCell
                              formik={formik}
                              disabled={disabled}
                              name={`${schemaRealizareForm.name}.${index}.${titlu.parent}.${titlu.name}`}
                              getValue={() => currentPhase[titlu.parent][titlu.name]}
                              type="text"
                            />
                            <EditableTableCell
                              formik={formik}
                              disabled={disabled}
                              name={`${schemaRealizareForm.name}.${index}.${buget.parent}.${buget.name}`}
                              getValue={() => currentPhase[buget.parent][buget.name]}
                              type="number"
                            />
                            <EditableTableCell
                              formik={formik}
                              disabled={disabled}
                              type="date"
                              name={`${schemaRealizareForm.name}.${index}.${termenPredare.parent}.${termenPredare.name}`}
                              getValue={() =>
                                currentPhase[termenPredare.parent][termenPredare.name]
                              }
                              format={defaultConfig.dateTimeFormats.short}
                            />
                            <TableCell>
                              {!disabled && (
                                <ActionButton
                                  actionType="remove"
                                  variant="contained"
                                  label="Faza"
                                  onClick={() => {
                                    arrayHelpers.remove(index);
                                  }}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      return <div key={index} />;
                    })}
                    <TableRow>
                      <TableCell colSpan={4}>
                        {`${intl.formatMessage({ id: 'table.head.total' })} ${year}`}
                      </TableCell>
                      <TableCell>{totalBuget[year]}</TableCell>
                      <TableCell colSpan={2} />
                    </TableRow>
                  </Fragment>
                ))}
              </LocalizationProvider>
            )}
          />
          <TableRow>
            <TableCell colSpan={4}>{intl.formatMessage({ id: 'table.head.total' })}</TableCell>
            <TableCell>{totalBuget.total}</TableCell>
            <TableCell colSpan={2} />
          </TableRow>
        </TableBody>
      </Table>
    </LocalizationProvider>
  );
}
