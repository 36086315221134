const expectedResultsForm = {
  formId: 'expectedResults',
  expectedResultsFormFields: {
    expectedResults: {
      name: 'rezultatePrevizionate',
      label: 'form.expectedResults.expectedResults',
      placeholder: 'form.expectedResults.expectedResults.placeholder',
      requiredErrorMsg: 'form.expectedResults.expectedResults.error.required'
    },
    keywords: {
      name: 'cuvinteCheie',
      label: 'form.expectedResults.keywords',
      button: 'form.expectedResults.keywords.button.label'
    }
  }
};

export default expectedResultsForm;
