import schemaRealizareForm from './schemaRealizareFormModel';

const {
  schemaRealizareFields: { activitatiFaza, rezultateFaza, faza },
  schemaRealizareTableHead: { dataInceput, dataFinal, termenPredare, buget, titlu },
  activitatiFaza: { informatiiAditionale, descriere, nume },
  rezultateFazaFields,
  cycles
} = schemaRealizareForm;

export const getNewRezultat = () => {
  const newRezultat = {};
  rezultateFazaFields.map((field) => (newRezultat[field.name] = ''));
  return newRezultat;
};

export const getNewActivitate = () => ({
  [informatiiAditionale.name]: '',
  [descriere.name]: '',
  [nume.name]: ''
});

export const getNewFaza = (year) => ({
  [activitatiFaza.name]: [],
  [faza.name]: {
    [dataInceput.name]: new Date(year, 0, 1).toISOString(),
    [dataFinal.name]: new Date(year, 0, 1).toISOString(),
    [termenPredare.name]: new Date(year, 0, 1).toISOString(),
    [buget.name]: '',
    [titlu.name]: ''
  },
  [rezultateFaza.name]: [getNewRezultat()]
});

export const getInitialSchemaRealizareFormValues = (proiect) => ({
  [cycles.name]: proiect?.[cycles.name] ?? [],
  [schemaRealizareForm.name]:
    proiect && proiect[schemaRealizareForm.name]
      ? [...proiect[schemaRealizareForm.name]].sort((f1, f2) =>
          f1.faza.dataInceput.localeCompare(f2.faza.dataInceput)
        )
      : []
});
