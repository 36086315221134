const totalValueFormPermanentFields = {
  formId: 'programForm',
  totalValueFormFields: {
    yearTable: {
      name: 'tabel',
      year: 'an',
      estimation: 'estimare',
      rows: []
    },
    totalValue: {
      name: 'valoareTotala',
      label: 'form.program.totalValue',
      placeholder: 'form.program.totalValue.placeholder',
      requiredErrorMsg: 'form.program.totalValue.error.required'
    }
  }
};

export default totalValueFormPermanentFields;
