import * as Yup from 'yup';
import totalValueFormPermanentFields from './totalValueFormModel';

const {
  totalValueFormFields: { yearTable, totalValue }
} = totalValueFormPermanentFields;

export const totalValueFormValidationSchema = () => {
  Yup.object().shape({
    [totalValue.name]: Yup.string().required(`${totalValue.requiredErrorMsg}`)
  });
};
