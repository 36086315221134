const alteInformatiiForm = {
  formId: 'alteInformatii',
  alteInformatiiFormFields: {
    alteInformatii: {
      name: 'alteInformatii',
      label: 'page.project.otherInfo'
    }
  }
};

export default alteInformatiiForm;
