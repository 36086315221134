export const userConstants = {
  USER_ADD_SUCCESS: 'USER_ADD_SUCCESS',
  USER_ADD_FAILURE: 'USER_ADD_FAILURE',
  USER_ADD_REQUEST: 'USER_ADD_REQUEST',

  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  USER_UPDATE_FAILURE: 'USER_UPDATE_FAILURE',
  USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST',

  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
  USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',

  USER_LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
  USER_LOGOUT_FAILURE: 'USER_LOGOUT_FAILURE',
  USER_LOGOUT_REQUEST: 'USER_LOGOUT_REQUEST',

  USER_REQUEST_RESET_PASSWORD_SUCCESS: 'USER_REQUEST_RESET_PASSWORD_SUCCESS',
  USER_REQUEST_RESET_PASSWORD_FAILURE: 'USER_REQUEST_RESET_PASSWORD_FAILURE',
  USER_REQUEST_RESET_PASSWORD_REQUEST: 'USER_REQUEST_RESET_PASSWORD_REQUEST',

  USER_RESET_PASSWORD_SUCCESS: 'USER_RESET_PASSWORD_SUCCESS',
  USER_RESET_PASSWORD_FAILURE: 'USER_RESET_PASSWORD_FAILURE',
  USER_RESET_PASSWORD_REQUEST: 'USER_RESET_PASSWORD_REQUEST',

  GET_CURRENT_USER_INFO_REQUEST: 'GET_CURRENT_USER_INFO_REQUEST',
  GET_CURRENT_USER_INFO_SUCCESS: 'GET_CURRENT_USER_INFO_SUCCESS',
  GET_CURRENT_USER_INFO_FAILURE: 'GET_CURRENT_USER_INFO_FAILURE',

  GET_USER_INFO_REQUEST: 'GET_USER_INFO_REQUEST',
  GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
  GET_USER_INFO_FAILURE: 'GET_USER_INFO_FAILURE',
  GET_USER_INFO_DISPOSE: 'GET_USER_INFO_DISPOSE',

  GET_USER_BUSINESS_INFO_REQUEST: 'GET_USER_BUSINESS_INFO_REQUEST',
  GET_USER_BUSINESS_INFO_SUCCESS: 'GET_USER_BUSINESS_INFO_SUCCESS',
  GET_USER_BUSINESS_INFO_FAILURE: 'GET_USER_BUSINESS_INFO_FAILURE',

  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE: 'GET_USERS_FAILURE',

  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

  USER_LOGOUT: 'USER_LOGOUT'
};
