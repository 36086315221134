import projectTechnicalSheetFormModel from './projectTechnicalSheetFormModel';

const {
  projectTechnicalSheetField: { projectTechnicalSheet }
} = projectTechnicalSheetFormModel;

export const parseProjectTechnicalSheet = (projectTechinicalSheetValues) => {
  const parseEligibilitySheetForm = { ...projectTechinicalSheetValues[projectTechnicalSheet.name] };
  parseEligibilitySheetForm[projectTechnicalSheet.criteria.name].forEach((criteria) => {
    delete criteria[projectTechnicalSheet.criteria.maxValue.name];
  });

  return parseEligibilitySheetForm;
};
