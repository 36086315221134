import { useIntl } from 'react-intl';
import { Backdrop, CircularProgress, Container, Grid, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import schemaRealizareForm from '../forms/proiect/schemaRealizare/schemaRealizareFormModel';
import { monitoringActions } from '../actions';
import Page from '../components/Page';
import MonitorPhaseResultCard from '../components/cards/MonitorPhaseResultCard';
// ----------------------------------------------------------------------

const {
  activitatiFaza: { informatiiAditionale, descriere, nume }
} = schemaRealizareForm;

export default function MonitorProgramProjectCyclePhase() {
  const { idProiect, idCiclu, idFaza } = useParams();
  const intl = useIntl();

  const monitoring = useSelector((state) => state.monitoring);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(monitoringActions.getPhase(idProiect, idCiclu, idFaza));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (monitoring?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Page title={intl.formatMessage({ id: 'monitoring.phase.view.title' })} my={2}>
      <Container>
        {monitoring.phase && (
          <Grid container alignItems="center">
            <Grid item xs={12} sm={12}>
              <Typography variant="h4">{monitoring.phase.titlu}</Typography>
              <Typography variant="subtitle1" sx={{ mb: 3 }}>
                {moment(monitoring.phase.dataInceput).format('D MMMM YYYY')} -{' '}
                {moment(monitoring.phase.dataFinal).format('D MMMM YYYY')}
              </Typography>
              {monitoring.phase.subtitlu && (
                <Typography variant="h6">
                  {intl.formatMessage({ id: 'monitoring.phase.name' })}: {monitoring.phase.subtitlu}
                </Typography>
              )}
              <Typography variant="h6" sx={{ mb: 3 }}>
                {intl.formatMessage({ id: 'global.budget' })}: {monitoring.phase.buget}
              </Typography>
              <Typography variant="h5" sx={{ mb: 2 }}>
                {intl.formatMessage({ id: 'form.project.schema.activitati' })}
              </Typography>
              {monitoring.phase.activitatiFaza.map((activity, indexActivity) => (
                <Grid container spacing={3} key={indexActivity} sx={{ mb: 2 }}>
                  <Grid item sm={12} md={2}>
                    <TextField
                      label={intl.formatMessage({ id: nume.label })}
                      variant="outlined"
                      fullWidth
                      disabled
                      defaultValue={activity[nume.name]}
                    />
                  </Grid>
                  <Grid item sm={12} md={2}>
                    <TextField
                      label={intl.formatMessage({ id: descriere.label })}
                      variant="outlined"
                      fullWidth
                      disabled
                      defaultValue={activity[descriere.name]}
                    />
                  </Grid>
                  <Grid item sm={12} md={8}>
                    <TextField
                      label={intl.formatMessage({ id: informatiiAditionale.label })}
                      variant="outlined"
                      fullWidth
                      disabled
                      defaultValue={activity[informatiiAditionale.name]}
                    />
                  </Grid>
                </Grid>
              ))}
              <Typography variant="h6" sx={{ mb: 1 }}>
                {intl.formatMessage({ id: 'form.project.schema.rezultate' })}
              </Typography>
              {monitoring.phase.rezultateFaza.map((result, indexResult) => (
                <MonitorPhaseResultCard result={result} key={indexResult} />
              ))}
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
}
