// eslint-disable-next-line camelcase
import { camelizeKeys } from 'humps';
import { authHeader } from '../utils/auth-header';
import { env } from '../utils/config';
import { userService } from './index';

export const institutionService = {
  getAll,
  getInfo,
  addInstitution,
  updateInstitution
};

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/VizualizareInstitutii?pageSize=0`,
    requestOptions
  ).then(handleResponse);
}

function getInfo(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Institutie/${id}`,
    requestOptions
  ).then(handleResponse);
}

function addInstitution(institution) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(institution)
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/InregistrareInstitutie`,
    requestOptions
  ).then(handleResponse);
}

function updateInstitution(institution) {
  const institutionData = { ...institution };
  delete institutionData.id;
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(institutionData)
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/ActualizareInstitutie/${institution.id}`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        window.location.reload(true);
      }
      return Promise.reject(JSON.parse(text));
    }

    const data = text && JSON.parse(text);

    if (data) {
      return camelizeKeys(data);
    }

    return text;
  });
}
