const monitoringAssignedMonitorsForm = {
  formId: 'monitoringAssignedMonitorsForm',
  monitoringAssignedMonitorsField: {
    assignedMonitors: {
      name: 'monitoriAsignati',
      project: {
        name: 'ProiectGuid',
        label: 'global.project',
        requiredErrorMsg: 'form.error.required'
      },
      monitor: {
        name: 'MonitorGuid',
        label: 'monitoring.AssignedMonitors.responsible',
        requiredErrorMsg: 'form.error.required'
      }
    }
  }
};

export default monitoringAssignedMonitorsForm;
