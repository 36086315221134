const adminEligibilityAssignForm = {
  formId: 'adminEligibilityAssignForm',
  adminEligibilityAssignField: {
    writer: {
      name: 'UserWriterGuid',
      label: 'eligibility.reponsible.writer',
      requiredErrorMsg: 'form.error.required'
    },
    reviwer: {
      name: 'UserReviewerGuid',
      label: 'eligibility.reponsible.reviwer',
      requiredErrorMsg: 'form.error.required'
    }
  }
};

export default adminEligibilityAssignForm;
