import * as React from 'react';
import { useIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
// ----------------------------------------------------------------------------

export default function AdminProgramEvaluationCard({ program, competition }) {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <>
      {program && program.fisaEvaluareFinala && (
        <Card key={program.programGuid} sx={{ mb: 1 }}>
          <CardHeader
            title={
              <div>
                <p>{program.numeProgram}</p>
              </div>
            }
            action={
              <Button
                variant="outlined"
                onClick={() => navigate(`${program.programGuid}/fise-evaluare`)}
              >
                {intl.formatMessage({ id: 'admin.program.evaluation.sheets' })}
              </Button>
            }
          />
          <CardContent>
            <p>{intl.formatMessage({ id: 'institution' })}</p>
            <p>{program.numeInstitutie}</p>
            <p>
              {intl.formatMessage({ id: 'global.result' })}:{' '}
              {program.fisaEvaluareFinala.criterii.reduce((prev, curr) => prev + curr.scor, 0)} /{' '}
              {program.fisaEvaluareFinala.criterii.reduce((prev, curr) => prev + curr.scorMaxim, 0)}
            </p>
          </CardContent>
        </Card>
      )}
    </>
  );
}
