import { institutionConstants } from '../constants';

export function institutions(
  state = { loading: false, saving: false, institutions: {}, institution: {} },
  action
) {
  switch (action.type) {
    case institutionConstants.GET_INSTITUTIONS_REQUEST:
    case institutionConstants.GET_INSTITUTION_REQUEST:
    case institutionConstants.INSTITUTION_ADD_REQUEST:
    case institutionConstants.INSTITUTION_UPDATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case institutionConstants.GET_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        institutions: action.institutions
      };
    case institutionConstants.INSTITUTION_UPDATE_SUCCESS:
    case institutionConstants.INSTITUTION_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        institutions: [
          ...(state.institutions.data ? state.institutions.data : []),
          action.institution
        ],
        institution: action.institution
      };
    case institutionConstants.GET_INSTITUTION_SUCCESS:
      return {
        ...state,
        loading: false,
        institution: action.institution
      };
    case institutionConstants.GET_INSTITUTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        institutions: [],
        error: action.error
      };
    case institutionConstants.INSTITUTION_ADD_FAILURE:
    case institutionConstants.GET_INSTITUTION_FAILURE:
    case institutionConstants.INSTITUTION_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
