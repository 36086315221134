import { alpha } from '@mui/material/styles';
// ----------------------------------------------------------------------

export default function Table(theme) {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(even)': {
            backgroundColor: alpha('#9D9D9D', 0.1)
          }
        },
        head: {
          '&:nth-of-type(even)': {
            backgroundColor: '#FFFFFF'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: alpha('#9D9D9D', 0.2)
        }
      }
    }
  };
}
