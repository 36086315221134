import monitoringAssignedMonitorsForm from './monitoringAssignedMonitorsFormModel';

const {
  monitoringAssignedMonitorsField: { assignedMonitors }
} = monitoringAssignedMonitorsForm;

export const parseMonitoringAssignedMonitors = (monitoringAssignedMonitorsValues) => {
  let parseMonitoringAssignedMonitorsForm = [];

  if (monitoringAssignedMonitorsValues[assignedMonitors.name]) {
    parseMonitoringAssignedMonitorsForm = monitoringAssignedMonitorsValues[
      assignedMonitors.name
    ].map((assignedMonitorsItem) => ({
      [assignedMonitors.project.name]: assignedMonitorsItem[assignedMonitors.project.name].id,
      [assignedMonitors.monitor.name]: assignedMonitorsItem[assignedMonitors.monitor.name].id
    }));
  }

  return parseMonitoringAssignedMonitorsForm;
};
