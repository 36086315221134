// eslint-disable-next-line camelcase
import { camelizeKeys } from 'humps';
import { authHeader } from '../utils/auth-header';
import { env } from '../utils/config';
import { userService } from './index';

export const competitionService = {
  getAllAdmin,
  getInstitutionCompetitions,
  getCompetition,
  addCompetition,
  updateCompetition,
  addCompetitionEligibility,
  addCompetitionProgramEvaluation,
  addCompetitionProjectEvaluation,
  getCompetitionPrograms
};

function getAllAdmin() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/VizualizareCompetitii?pageSize=0`,
    requestOptions
  ).then(handleResponse);
}

function getInstitutionCompetitions(institutionId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/VizualizeazaCompetitiiAccesibileDeInstitutie/${institutionId}`,
    requestOptions
  ).then(handleResponse);
}

function getCompetition(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/VizualizareCompetitie/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getCompetitionPrograms(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/VizualizeazaProgrameDepuseLaCompetitie/${id}`,
    requestOptions
  ).then(handleResponse);
}

function addCompetition(competition) {
  competition.InstitutiiAsociate = competition.InstitutiiAsociate.map(
    (institution) => institution.id
  );
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(competition)
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/InregistrareCompetitie`,
    requestOptions
  ).then(handleResponse);
}

function updateCompetition(competition) {
  competition.InstitutiiAsociate = competition.InstitutiiAsociate.map(
    (institution) => institution.id
  );
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(competition)
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/ActualizareCompetitie`,
    requestOptions
  ).then(handleResponse);
}

function addCompetitionEligibility(competitionEligibility, competitionId) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(competitionEligibility)
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/AdaugaFisaEligibilitate/${competitionId}`,
    requestOptions
  ).then(handleResponse);
}

function addCompetitionProgramEvaluation(competitionProgramEvaluation, competitionId) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(competitionProgramEvaluation)
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/AdaugaFisaEvaluare/${competitionId}/program`,
    requestOptions
  ).then(handleResponse);
}

function addCompetitionProjectEvaluation(competitionProjectEvaluation, competitionId) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(competitionProjectEvaluation)
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/AdaugaFisaEvaluare/${competitionId}/proiect`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        window.location.reload(true);
      }
      return Promise.reject(JSON.parse(text));
    }

    const data = text && JSON.parse(text);

    if (data) {
      return camelizeKeys(data);
    }

    return text;
  });
}
