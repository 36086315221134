import * as React from 'react';
import { Button, TextField, InputLabel, FormControl } from '@mui/material/';
import { styled } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { useDispatch, useSelector } from 'react-redux';
import { documentActions } from '../actions';

const ContainerStyle = styled('div')({
  display: 'flex'
});

const TextFieldStyle = styled(TextField)({
  flexBasis: '20%'
});

const Input = styled('input')({
  display: 'none'
});

const ButtonStyle = styled(Button)({
  marginLeft: '2rem'
});

const LoadingButtonStyle = styled(LoadingButton)({
  marginLeft: '2rem'
});

export default function FileUploadField({ label, placeholder, isAdmin }) {
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [fileName, setFileName] = React.useState('');
  const documents = useSelector((state) => state.documents);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const intl = useIntl();

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onFileUpload = () => {
    if (selectedFile && fileName) {
      const formData = new FormData();
      formData.append('files', selectedFile, fileName);
      if (isAdmin) {
        dispatch(documentActions.uploadDocumentAdmin(formData));
      } else {
        dispatch(documentActions.uploadDocument(formData, user.info.institutieParinte));
      }
    }
  };

  return (
    <>
      {label ? <InputLabel>{label}</InputLabel> : ''}
      <ContainerStyle>
        <FormControl>
          <TextFieldStyle
            label={intl.formatMessage({ id: 'page.applicant.documents.name' })}
            disabled={documents.saving}
            required
            error={fileName.length === 0}
            helperText={
              fileName.length === 0
                ? intl.formatMessage({ id: 'page.applicant.documents.name.required' })
                : ''
            }
            onChange={(event) => setFileName(event.target.value)}
          />
        </FormControl>
        <FormControl>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="contained-button-file">
            <Input
              accept="application/pdf"
              id="contained-button-file"
              multiple
              type="file"
              onChange={onFileChange}
            />
            <ButtonStyle
              variant="contained"
              component="span"
              disabled={documents.saving}
              size="large"
              sx={{ backgroundColor: selectedFile ? 'green' : 'primary' }}
            >
              {placeholder}
            </ButtonStyle>
          </label>
        </FormControl>
        <FormControl>
          <LoadingButtonStyle
            loading={documents.saving}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            disabled={!selectedFile || fileName.length === 0}
            onClick={onFileUpload}
            size="large"
          >
            {intl.formatMessage({ id: 'global.save' })}
          </LoadingButtonStyle>
        </FormControl>
      </ContainerStyle>
    </>
  );
}
