// eslint-disable-next-line camelcase
import { camelizeKeys } from 'humps';
import { authHeader } from '../utils/auth-header';
import { env } from '../utils/config';
import { userService } from './index';
import { EVALUATOR_ROLES } from '../utils/enums';

export const eligibilityService = {
  getAssignedPrograms,
  getEligibilitySheet,
  submit,
  saveDraft
};

function getAssignedPrograms() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ELIGIBILITATE_BASE_PATH}/VizualizeazaProgrameAsignate`,
    requestOptions
  ).then(handleResponse);
}

function getEligibilitySheet(id, role) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const type =
    role === EVALUATOR_ROLES.USER_REVIEWER || role === EVALUATOR_ROLES.USER_WRITER
      ? 'eligibilitate'
      : 'contestatie';
  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ELIGIBILITATE_BASE_PATH}/VizualizeazaFisaEligibilitateProgram/${id}/${type}`,
    requestOptions
  ).then(handleResponse);
}

function submit(programId, role, data) {
  if ('contestationFile' in data) {
    delete data.contestationFile;
  }

  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  const type =
    role === EVALUATOR_ROLES.USER_REVIEWER || role === EVALUATOR_ROLES.USER_WRITER
      ? 'eligibilitate'
      : 'contestatie';
  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ELIGIBILITATE_BASE_PATH}/SubmiteFisaEligibilitateProgram/${programId}/${type}`,
    requestOptions
  ).then(handleResponse);
}

function saveDraft(programId, role, data) {
  if ('contestationFile' in data) {
    delete data.contestationFile;
  }

  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  const type =
    role === EVALUATOR_ROLES.USER_REVIEWER || role === EVALUATOR_ROLES.USER_WRITER
      ? 'eligibilitate'
      : 'contestatie';
  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ELIGIBILITATE_BASE_PATH}/CompleteazaFisaEligibilitateProgram/${programId}/${type}`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        window.location.reload(true);
      }
      return Promise.reject(JSON.parse(text));
    }

    const data = text && JSON.parse(text);

    if (data) {
      return camelizeKeys(data);
    }

    return text;
  });
}
