import adminEligibilityAssignFormModel from './adminEligibilityAssignFormModel';

const {
  adminEligibilityAssignField: { writer, reviwer }
} = adminEligibilityAssignFormModel;

export const parseAdminEligibility = (adminEligibility) => {
  const parsedAdminEligibility = { ...adminEligibility };
  if (adminEligibility[writer.name]) {
    parsedAdminEligibility[writer.name] = adminEligibility[writer.name].id;
  }

  if (adminEligibility[reviwer.name]) {
    parsedAdminEligibility[reviwer.name] = adminEligibility[reviwer.name].id;
  }

  return parsedAdminEligibility;
};
