// components
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Button, Container, FormHelperText, Grid } from '@mui/material';
// imports everything in the react module
import * as React from 'react';
// dispatch is used as a callback which gets invoked once some async action is complete.
// useSelector allows you to extract data from the Redux store state, using a selector function.
import { useDispatch, useSelector } from 'react-redux';
// The Effect Hook performs side effects in function components:
import { useEffect } from 'react';
// Formik uses useFormik to create the <Formik> component (which renders a React Context Provider).
import { useFormik } from 'formik';
import { reportActions, userActions } from '../actions';
import { UserRoles } from '../domain/UserRoles';
import EditableFormAutocomplete from '../forms/shared/EditableFormAutocomplete';
// import Page component
import Page from '../components/Page';
import reportAssignedEvaluatorsFormModel from '../forms/reporting/adminAssignedReportEvaluators/reportAssignedEvaluatorsFormModel';
import { getInitialReportAssignedEvaluatorsFormValues } from '../forms/reporting/adminAssignedReportEvaluators/formInitialValues';
import { reportAssignedEvaluatorsFormValidationSchema } from '../forms/reporting/adminAssignedReportEvaluators/validationSchema';
import { parseReportAssignedEvaluators } from '../forms/reporting/adminAssignedReportEvaluators/formOutputParser';

// ----------------------------------------------------------------------

const {
  reportAssignedEvaluatorsField: { assignedEvaluators }
} = reportAssignedEvaluatorsFormModel;

export default function AdminCompetitionProgramReporting() {
  const { idProgram } = useParams();
  const intl = useIntl();

  // useSelector extracts  data from the report state
  const report = useSelector((state) => state.report);
  // useSelector extracts  data from the user state
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // The Effect Hook performs side effects in function components:
  // dispatch is used as a callback which gets invoked once some async action is complete.
  useEffect(() => {
    dispatch(userActions.getAll(user.info.userType));
    dispatch(reportActions.getAdminProgramAvailableProjectsForReporting(idProgram));
    dispatch(reportActions.getProgramAssignedReportEvaluators(idProgram));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProgram]);

  const formikAssignedEvaluators = useFormik({
    initialValues: getInitialReportAssignedEvaluatorsFormValues(report.evaluators.asocieri || []),
    enableReinitialize: true,
    validationSchema: reportAssignedEvaluatorsFormValidationSchema(),
    onSubmit: (values) => {
      const valuesCopy = parseReportAssignedEvaluators(JSON.parse(JSON.stringify(values)));
      dispatch(reportActions.saveAssignedEvaluators(valuesCopy));
    }
  });

  // display Grid with 4 items
  return (
    <Page title={intl.formatMessage({ id: 'monitoring.page.title' })} my={2}>
      <>
        {user.users &&
          user.users.data &&
          report &&
          report.availableProjects &&
          report.availableProjects.proiecteInCicluri && (
            <Container>
              <Button
                variant="contained"
                sx={{ mb: 2 }}
                onClick={() =>
                  formikAssignedEvaluators.setFieldValue(assignedEvaluators.name, [
                    ...formikAssignedEvaluators.values[assignedEvaluators.name],
                    {
                      [assignedEvaluators.project.name]: { id: 0 },
                      [assignedEvaluators.mainEvaluator.name]: { id: 0 },
                      [assignedEvaluators.secondEvaluator.name]: { id: 0 }
                    }
                  ])
                }
              >
                {intl.formatMessage({ id: 'report.evaluators.add' })}
              </Button>
              {formikAssignedEvaluators.values[assignedEvaluators.name].map(
                (assignedEvaluator, indexAssignedEvaluator) => (
                  <Grid container spacing={5} key={indexAssignedEvaluator} sx={{ mb: 2 }}>
                    {/* Grid item 1 size 3/12 of viewport */}
                    <Grid item xs={3} sm={3}>
                      <EditableFormAutocomplete
                        parentName={assignedEvaluators.name}
                        childrenNames={[indexAssignedEvaluator, assignedEvaluators.project.name]}
                        formik={formikAssignedEvaluators}
                        required
                        label={intl.formatMessage({ id: assignedEvaluators.project.label })}
                        variant="outlined"
                        fullWidth
                        options={report.availableProjects.proiecteInCicluri
                          .map((project) => ({
                            id: project.proiectGuid,
                            label: project.numeProiect
                          }))
                          .filter((proiect) => {
                            if (
                              indexAssignedEvaluator === 0 &&
                              formikAssignedEvaluators.values[assignedEvaluators.name].length > 1
                            ) {
                              for (
                                let i = 1;
                                i < formikAssignedEvaluators.values[assignedEvaluators.name].length;
                                i += 1
                              ) {
                                if (
                                  formikAssignedEvaluators.values[assignedEvaluators.name][i][
                                    assignedEvaluators.project.name
                                  ].id === proiect.id
                                ) {
                                  return false;
                                }
                              }
                            }
                            for (let i = 0; i < indexAssignedEvaluator; i += 1) {
                              if (
                                formikAssignedEvaluators.values[assignedEvaluators.name][i][
                                  assignedEvaluators.project.name
                                ].id === proiect.id
                              ) {
                                return false;
                              }
                            }
                            return true;
                          })}
                      />
                    </Grid>
                    {/* Grid item 2 size 3/12 of viewport */}
                    <Grid item xs={3} sm={3}>
                      <EditableFormAutocomplete
                        parentName={assignedEvaluators.name}
                        childrenNames={[
                          indexAssignedEvaluator,
                          assignedEvaluators.mainEvaluator.name
                        ]}
                        formik={formikAssignedEvaluators}
                        required
                        label={intl.formatMessage({ id: assignedEvaluators.mainEvaluator.label })}
                        variant="outlined"
                        fullWidth
                        options={user.users.data
                          .filter((userOption) =>
                            userOption.userType.includes(UserRoles.EvaluatorRapoarte)
                          )
                          .map((userOption) => ({
                            id: userOption.id,
                            label: `${userOption.firstName} ${userOption.lastName}`
                          }))}
                      />
                    </Grid>
                    {/* Grid item 3 size 3/12 of viewport */}
                    <Grid item xs={3} sm={3}>
                      <EditableFormAutocomplete
                        parentName={assignedEvaluators.name}
                        childrenNames={[
                          indexAssignedEvaluator,
                          assignedEvaluators.secondEvaluator.name
                        ]}
                        formik={formikAssignedEvaluators}
                        required
                        label={intl.formatMessage({ id: assignedEvaluators.secondEvaluator.label })}
                        variant="outlined"
                        fullWidth
                        options={user.users.data
                          .filter((userOption) =>
                            userOption.userType.includes(UserRoles.EvaluatorRapoarte)
                          )
                          .map((userOption) => ({
                            id: userOption.id,
                            label: `${userOption.firstName} ${userOption.lastName}`
                          }))}
                      />
                    </Grid>
                    {/* Grid item 4 size 3/12 of viewport */}
                    <Grid item xs={3} sm={3}>
                      {formikAssignedEvaluators.errors[assignedEvaluators.name] &&
                        !Array.isArray(
                          formikAssignedEvaluators.errors[assignedEvaluators.name]
                        ) && (
                          <FormHelperText id="component-helper-text" error>
                            {intl.formatMessage({
                              id: formikAssignedEvaluators.errors[assignedEvaluators.name]
                            })}
                          </FormHelperText>
                        )}
                    </Grid>
                  </Grid>
                )
              )}
              {formikAssignedEvaluators.values[assignedEvaluators.name] &&
                formikAssignedEvaluators.values[assignedEvaluators.name].length > 0 && (
                  <Button variant="contained" onClick={() => formikAssignedEvaluators.submitForm()}>
                    {intl.formatMessage({
                      id: 'global.save'
                    })}
                  </Button>
                )}
            </Container>
          )}
      </>
    </Page>
  );
}
