import * as React from 'react';
import { useIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import moment from 'moment';
// ----------------------------------------------------------------------------

export default function InstitutionProgramMonitorCycleProjectPhaseCard({ entity, cardProps }) {
  const intl = useIntl();

  return (
    <Card key={entity.fazaGuid.toString()}>
      <Grid container alignItems="center">
        <Grid item xs={9} sm={9}>
          <CardHeader
            title={
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      cardProps &&
                      cardProps.selectedPhases &&
                      cardProps.selectedPhases.find((phase) => phase.id === entity.fazaGuid)
                        ? cardProps.selectedPhases.find((phase) => phase.id === entity.fazaGuid)
                            .selected
                        : false
                    }
                    onChange={() => cardProps.handleCheckboxChange(entity.fazaGuid)}
                    sx={{ display: cardProps.phaseSelection ? '' : 'none' }}
                  />
                }
                label={
                  <Typography variant="heading5" color="primary">
                    {entity.titlu}
                  </Typography>
                }
              />
            }
          />
          <CardContent>
            {entity.subtitlu && (
              <p>
                {intl.formatMessage({ id: 'global.phase' })}: {entity.subtitlu}
              </p>
            )}
            <p>
              {intl.formatMessage({ id: 'global.interval' })}:{' '}
              {moment(entity.dataInceput).format('D MMMM YYYY')} -{' '}
              {moment(entity.dataFinal).format('D MMMM YYYY')}
            </p>
            <p />
            <p>
              {intl.formatMessage({ id: 'global.budget' })}: {entity.buget}
            </p>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}
