import * as Yup from 'yup';
import reportAssignedEvaluatorsFormModel from './reportAssignedEvaluatorsFormModel';

const {
  reportAssignedEvaluatorsField: { assignedEvaluators }
} = reportAssignedEvaluatorsFormModel;

export const reportAssignedEvaluatorsFormValidationSchema = () =>
  Yup.object().shape({
    [assignedEvaluators.name]: Yup.array()
      .of(
        Yup.object().shape({
          [assignedEvaluators.project.name]: Yup.object().test({
            name: 'check id > 0',
            message: assignedEvaluators.project.requiredErrorMsg,
            /* eslint-disable */
            test: function (value) {
              return value.id && value.id > 0;
            }
          }),
          [assignedEvaluators.mainEvaluator.name]: Yup.object().test({
            name: 'check id > 0',
            message: assignedEvaluators.mainEvaluator.requiredErrorMsg,
            /* eslint-disable */
            test: function (value) {
              return value.id && value.id > 0;
            }
          }),
          [assignedEvaluators.secondEvaluator.name]: Yup.object().test({
            name: 'check id > 0',
            message: assignedEvaluators.secondEvaluator.requiredErrorMsg,
            /* eslint-disable */
            test: function (value) {
              return value.id && value.id > 0;
            }
          }),
        })
      )
      .min(1, `${assignedEvaluators.requiredErrorMsg}`)
  });
