const competitionInformationForm = {
  formId: 'competitionInformationForm',
  competitionInformationFormField: {
    name: {
      name: 'Nume',
      label: 'competition.name',
      requiredErrorMsg: 'form.error.required'
    },
    startDate: {
      name: 'Durata_Desfasurare_Interval_Inferior',
      label: 'competition.startDate',
      requiredErrorMsg: 'form.error.required',
      dateErrorMsg: 'form.date.error'
    },
    endDate: {
      name: 'Durata_Desfasurare_Interval_Superior',
      label: 'competition.endDate',
      requiredErrorMsg: 'form.error.required',
      dateErrorMsg: 'form.date.error',
      dateOrderErrorMsg: 'form.date.order.error'
    },
    submitStartDate: {
      name: 'Perioada_Depunere_Interval_Inferior',
      label: 'competition.submitStartDate',
      requiredErrorMsg: 'form.error.required',
      dateErrorMsg: 'form.date.error'
    },
    submitEndDate: {
      name: 'Perioada_Depunere_Interval_Superior',
      label: 'competition.submitEndDate',
      requiredErrorMsg: 'form.error.required',
      dateErrorMsg: 'form.date.error',
      dateOrderErrorMsg: 'form.date.order.error'
    },
    checkEligibilityStartDate: {
      name: 'Perioada_Verificare_Eligibilitate_Interval_Inferior',
      label: 'competition.checkEligibilityStartDate',
      requiredErrorMsg: 'form.error.required',
      dateErrorMsg: 'form.date.error'
    },
    checkEligibilityEndDate: {
      name: 'Perioada_Verificare_Eligibilitate_Interval_Superior',
      label: 'competition.checkEligibilityEndDate',
      requiredErrorMsg: 'form.error.required',
      dateErrorMsg: 'form.date.error',
      dateOrderErrorMsg: 'form.date.order.error'
    },
    eligibilityContestationStartDate: {
      name: 'Perioada_Contestatie_Eligibilitate_Interval_Inferior',
      label: 'competition.eligibilityContestationStartDate',
      requiredErrorMsg: 'form.error.required',
      dateErrorMsg: 'form.date.error'
    },
    eligibilityContestationEndDate: {
      name: 'Perioada_Contestatie_Eligibilitate_Interval_Superior',
      label: 'competition.eligibilityContestationEndDate',
      requiredErrorMsg: 'form.error.required',
      dateErrorMsg: 'form.date.error',
      dateOrderErrorMsg: 'form.date.order.error'
    },
    technicalEvaluationProjectStartDate: {
      name: 'Perioada_Evaluare_Tehnica_Proiecte_Interval_Inferior',
      label: 'competition.technicalEvaluationProjectStartDate',
      requiredErrorMsg: 'form.error.required',
      dateErrorMsg: 'form.date.error'
    },
    technicalEvaluationProjectEndDate: {
      name: 'Perioada_Evaluare_Tehnica_Proiecte_Interval_Superior',
      label: 'competition.technicalEvaluationProjectEndDate',
      requiredErrorMsg: 'form.error.required',
      dateErrorMsg: 'form.date.error',
      dateOrderErrorMsg: 'form.date.order.error'
    },
    technicalEvaluationProgrammeStartDate: {
      name: 'Perioada_Evaluare_Tehnica_Program_Interval_Inferior',
      label: 'competition.technicalEvaluationProgrammeStartDate',
      requiredErrorMsg: 'form.error.required',
      dateErrorMsg: 'form.date.error'
    },
    technicalEvaluationProgrammeEndDate: {
      name: 'Perioada_Evaluare_Tehnica_Program_Interval_Superior',
      label: 'competition.technicalEvaluationProgrammeEndDate',
      requiredErrorMsg: 'form.error.required',
      dateErrorMsg: 'form.date.error',
      dateOrderErrorMsg: 'form.date.order.error'
    },
    technicalEvaluationContestationStartDate: {
      name: 'Perioada_Contestatie_Evaluare_Tehnica_Interval_Inferior',
      label: 'competition.technicalEvaluationContestationStartDate',
      requiredErrorMsg: 'form.error.required',
      dateErrorMsg: 'form.date.error'
    },
    technicalEvaluationContestationEndDate: {
      name: 'Perioada_Contestatie_Evaluare_Tehnica_Interval_Superior',
      label: 'competition.technicalEvaluationContestationEndDate',
      requiredErrorMsg: 'form.error.required',
      dateErrorMsg: 'form.date.error',
      dateOrderErrorMsg: 'form.date.order.error'
    },
    technicalEvaluationContestationSolutionStartDate: {
      name: 'Perioada_Solutionare_Contestatii_Tehnice_Interval_Inferior',
      label: 'competition.technicalEvaluationContestationSolutionStartDate',
      requiredErrorMsg: 'form.error.required',
      dateErrorMsg: 'form.date.error'
    },
    technicalEvaluationContestationSolutionEndDate: {
      name: 'Perioada_Solutionare_Contestatii_Tehnice_Interval_Superior',
      label: 'competition.technicalEvaluationContestationSolutionEndDate',
      requiredErrorMsg: 'form.error.required',
      dateErrorMsg: 'form.date.error',
      dateOrderErrorMsg: 'form.date.order.error'
    },
    monitoringStartDate: {
      name: 'Perioada_Monitorizare_Interval_Inferior',
      label: 'competition.monitoringStartDate',
      requiredErrorMsg: 'form.error.required',
      dateErrorMsg: 'form.date.error'
    },
    monitoringEndDate: {
      name: 'Perioada_Monitorizare_Interval_Superior',
      label: 'competition.monitoringEndDate',
      requiredErrorMsg: 'form.error.required',
      dateErrorMsg: 'form.date.error',
      dateOrderErrorMsg: 'form.date.order.error'
    },
    reportingStartDate: {
      name: 'Perioada_Raportare_Interval_Inferior',
      label: 'competition.reportingStartDate',
      requiredErrorMsg: 'form.error.required',
      dateErrorMsg: 'form.date.error'
    },
    reportingEndDate: {
      name: 'Perioada_Raportare_Interval_Superior',
      label: 'competition.reportingEndDate',
      requiredErrorMsg: 'form.error.required',
      dateErrorMsg: 'form.date.error',
      dateOrderErrorMsg: 'form.date.order.error'
    },
    competitionInstitutions: {
      name: 'InstitutiiAsociate',
      label: 'administrator.institutions',
      requiredErrorMsg: 'form.error.required'
    }
  }
};

export default competitionInformationForm;
