import { getInitialInformatiiGeneraleFormValues } from './informatiiGenerale/formInitialValues';
import { getInitialDeclaratiiFormValues } from './declaratii/formInitialValues';
import { getInitialAlteInformatiiFormValues } from './alteInformatii/formInitialValues';
import { getInitialResurseUmaneFormValues } from './resurseUmane/formInitialValues';
import { getInitialCosturiTotaleFormValues } from './costuriTotale/formInitialValues';
import { getInitialSchemaRealizareFormValues } from './schemaRealizare/formInitialValues';
import { getInitialInformatiiStiintificeFormValues } from './informatiiStiintifice/formInitialValues';

export const getInitialProiectFormValues = (proiect, years) => ({
  ...getInitialInformatiiGeneraleFormValues(proiect),
  ...getInitialAlteInformatiiFormValues(proiect),
  ...getInitialDeclaratiiFormValues(proiect),
  ...getInitialResurseUmaneFormValues(proiect),
  ...getInitialCosturiTotaleFormValues(proiect, years),
  ...getInitialSchemaRealizareFormValues(proiect),
  ...getInitialInformatiiStiintificeFormValues(proiect)
});
