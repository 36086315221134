import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { FieldArray, Formik } from 'formik';

import Stack from '@mui/material/Stack';
import KeywordField from '../../KeywordField';
import EditableFormTextField from '../../../forms/shared/EditableFormTextField';

import { expectedResultsFormValidationSchema } from '../../../forms/program/expectedResults/validationSchema';
import expectedResultsFormModel from '../../../forms/program/expectedResults/expectedResultsFormModel';
import { getInitialExpectedResultsFormValues } from '../../../forms/program/expectedResults/formInitialValues';

import { programActions } from '../../../actions';
import { FORM_IDS } from '../../../utils/enums';

const {
  expectedResultsFormFields: { expectedResults, keywords }
} = expectedResultsFormModel;

export default function ExpectedResults({ disabled, isNew }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const program = useSelector((state) => state.programs.selectedProgram);

  const formik = {
    initialValues: getInitialExpectedResultsFormValues(program ?? {}),
    validationSchema: expectedResultsFormValidationSchema(),
    onSubmit: (values) => {
      const data = JSON.parse(JSON.stringify(values));

      if (isNew) {
        dispatch(
          programActions.createProgram(id, (program) => {
            dispatch(programActions.updateProgramExpectedResults(program.programGuid, data));
            navigate(`../../../programe/${program.programGuid.toString()}`, { replace: true });
          })
        );
      } else {
        dispatch(programActions.updateProgramExpectedResults(id, data));
      }
    }
  };

  return (
    <Formik
      initialValues={formik.initialValues}
      validationSchema={formik.validationSchema}
      onSubmit={formik.onSubmit}
    >
      {({ handleChange, handleBlur, values, errors, touched, handleSubmit }) => (
        <form id={FORM_IDS.PROGRAM_EXPECTED_RESULTS} onSubmit={handleSubmit} noValidate>
          <Stack spacing={3}>
            <EditableFormTextField
              parentName={expectedResults.name}
              formik={{
                errors,
                touched,
                handleChange,
                handleBlur,
                values
              }}
              label={intl.formatMessage({ id: expectedResults.label })}
              variant="outlined"
              fullWidth
              disabled={disabled}
              multiline
              rows={2}
            />
            <FieldArray
              name={keywords.name}
              render={(arrayHelpers) => (
                <KeywordField
                  parentName={keywords.name}
                  disabled={disabled}
                  values={values}
                  keywordField={keywords}
                  arrayHelpers={arrayHelpers}
                />
              )}
            />
          </Stack>
        </form>
      )}
    </Formik>
  );
}
