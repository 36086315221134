export const programConstants = {
  PROGRAM_UPDATE_SUCCESS: 'PROGRAM_UPDATE_SUCCESS',
  PROGRAM_UPDATE_FAILURE: 'PROGRAM_UPDATE_FAILURE',
  PROGRAM_UPDATE_REQUEST: 'PROGRAM_UPDATE_REQUEST',

  PROGRAM_SUBMIT_FOR_ELIGIBILITY_SUCCESS: 'PROGRAM_SUBMIT_FOR_ELIGIBILITY_SUCCESS',
  PROGRAM_SUBMIT_FOR_ELIGIBILITY_FAILURE: 'PROGRAM_SUBMIT_FOR_ELIGIBILITY_FAILURE',
  PROGRAM_SUBMIT_FOR_ELIGIBILITY_REQUEST: 'PROGRAM_SUBMIT_FOR_ELIGIBILITY_REQUEST',

  GET_PROGRAM_SUCCESS: 'GET_PROGRAM_SUCCESS',
  GET_PROGRAM_FAILURE: 'GET_PROGRAM_FAILURE',
  GET_PROGRAM_REQUEST: 'GET_PROGRAM_REQUEST',

  GET_ADMIN_PROGRAM_EVALUATION_SUCCESS: 'GET_ADMIN_PROGRAM_EVALUATION_SUCCESS',
  GET_ADMIN_PROGRAM_EVALUATION_FAILURE: 'GET_ADMIN_PROGRAM_EVALUATION_FAILURE',
  GET_ADMIN_PROGRAM_EVALUATION_REQUEST: 'GET_ADMIN_PROGRAM_EVALUATION_REQUEST',

  GET_MONITORING_PROJECTS_SUCCESS: 'GET_MONITORING_PROJECTS_SUCCESS',
  GET_MONITORING_PROJECTS_FAILURE: 'GET_MONITORING_PROJECTS_FAILURE',
  GET_MONITORING_PROJECTS_REQUEST: 'GET_MONITORING_PROJECTS_REQUEST',

  GET_PROGRAMS_SUCCESS: 'GET_PROGRAMS_SUCCESS',
  GET_PROGRAMS_FAILURE: 'GET_PROGRAMS_FAILURE',
  GET_PROGRAMS_REQUEST: 'GET_PROGRAMS_REQUEST',

  GET_PROGRAMS_APPLICANT_SUCCESS: 'PROGRAMS_GET_PROGRAMS_APPLICANT_SUCCESS',
  GET_PROGRAMS_APPLICANT_FAILURE: 'PROGRAMS_GET_PROGRAMS_APPLICANT_FAILURE',
  GET_PROGRAMS_APPLICANT_REQUEST: 'PROGRAMS_GET_PROGRAMS_APPLICANT_REQUEST',

  CREATE_PROGRAM_SUCCESS: 'CREATE_PROGRAM_SUCCESS',
  CREATE_PROGRAM_FAILURE: 'CREATE_PROGRAM_FAILURE',
  CREATE_PROGRAM_REQUEST: 'CREATE_PROGRAM_REQUEST',

  GET_PROGRAM_ELIGIBILITY_RESPONSIBLES_SUCCESS: 'GET_PROGRAM_ELIGIBILITY_RESPONSIBLES_SUCCESS',
  GET_PROGRAM_ELIGIBILITY_RESPONSIBLES_FAILURE: 'GET_PROGRAM_ELIGIBILITY_RESPONSIBLES_FAILURE',
  GET_PROGRAM_ELIGIBILITY_RESPONSIBLES_REQUEST: 'GET_PROGRAM_ELIGIBILITY_RESPONSIBLES_REQUEST',

  GET_PROGRAM_PROJECTS_EVALUATION_RESPONSIBLES_SUCCESS:
    'GET_PROGRAM_PROJECTS_EVALUATION_RESPONSIBLES_SUCCESS',
  GET_PROGRAM_PROJECTS_EVALUATION_RESPONSIBLES_FAILURE:
    'GET_PROGRAM_PROJECTS_EVALUATION_RESPONSIBLES_FAILURE',
  GET_PROGRAM_PROJECTS_EVALUATION_RESPONSIBLES_REQUEST:
    'GET_PROGRAM_PROJECTS_EVALUATION_RESPONSIBLES_REQUEST',

  GET_PROGRAM_EVALUATION_RESPONSIBLES_SUCCESS: 'GET_PROGRAM_EVALUATION_RESPONSIBLES_SUCCESS',
  GET_PROGRAM_EVALUATION_RESPONSIBLES_FAILURE: 'GET_PROGRAM_EVALUATION_RESPONSIBLES_FAILURE',
  GET_PROGRAM_EVALUATION_RESPONSIBLES_REQUEST: 'GET_PROGRAM_EVALUATION_RESPONSIBLES_REQUEST',

  UPDATE_PROGRAM_INFORMATION_SUCCESS: 'UPDATE_PROGRAM_INFORMATION_SUCCESS',
  UPDATE_PROGRAM_INFORMATION_FAILURE: 'UPDATE_PROGRAM_INFORMATION_FAILURE',
  UPDATE_PROGRAM_INFORMATION_REQUEST: 'UPDATE_PROGRAM_INFORMATION_REQUEST',

  UPDATE_PROGRAM_TOTAL_VALUE_SUCCESS: 'UPDATE_PROGRAM_TOTAL_VALUE_SUCCESS',
  UPDATE_PROGRAM_TOTAL_VALUE_FAILURE: 'UPDATE_PROGRAM_TOTAL_VALUE_FAILURE',
  UPDATE_PROGRAM_TOTAL_VALUE_REQUEST: 'UPDATE_PROGRAM_TOTAL_VALUE_REQUEST',

  UPDATE_PROGRAM_INCOME_STATUS_SUCCESS: 'UPDATE_PROGRAM_INCOME_STATUS_SUCCESS',
  UPDATE_PROGRAM_INCOME_STATUS_FAILURE: 'UPDATE_PROGRAM_INCOME_STATUS_FAILURE',
  UPDATE_PROGRAM_INCOME_STATUS_REQUEST: 'UPDATE_PROGRAM_INCOME_STATUS_REQUEST',

  UPDATE_PROGRAM_EXPECTED_RESULTS_SUCCESS: 'UPDATE_PROGRAM_EXPECTED_RESULTS_SUCCESS',
  UPDATE_PROGRAM_EXPECTED_RESULTS_FAILURE: 'UPDATE_PROGRAM_EXPECTED_RESULTS_FAILURE',
  UPDATE_PROGRAM_EXPECTED_RESULTS_REQUEST: 'UPDATE_PROGRAM_EXPECTED_RESULTS_REQUEST',

  UPDATE_PROGRAM_PROJECTS_OBJECTIVES_SUCCESS: 'UPDATE_PROGRAM_PROJECTS_OBJECTIVES_SUCCESS',
  UPDATE_PROGRAM_PROJECTS_OBJECTIVES_FAILURE: 'UPDATE_PROGRAM_PROJECTS_OBJECTIVES_FAILURE',
  UPDATE_PROGRAM_PROJECTS_OBJECTIVES_REQUEST: 'UPDATE_PROGRAM_PROJECTS_OBJECTIVES_REQUEST',

  UPLOAD_PROGRAM_ADMIN_CV_SUCCESS: 'UPLOAD_PROGRAM_ADMIN_CV_SUCCESS',
  UPLOAD_PROGRAM_ADMIN_CV_FAILURE: 'UPLOAD_PROGRAM_ADMIN_CV_FAILURE',
  UPLOAD_PROGRAM_ADMIN_CV_REQUEST: 'UPLOAD_PROGRAM_ADMIN_CV_REQUEST',

  DOWNLOAD_PROGRAM_ADMIN_CV_SUCCESS: 'DOWNLOAD_PROGRAM_ADMIN_CV_SUCCESS',
  DOWNLOAD_PROGRAM_ADMIN_CV_FAILURE: 'DOWNLOAD_PROGRAM_ADMIN_CV_FAILURE',
  DOWNLOAD_PROGRAM_ADMIN_CV_REQUEST: 'DOWNLOAD_PROGRAM_ADMIN_CV_REQUEST',

  GET_PROGRAM_DOCUMENTS_SUCCESS: 'GET_PROGRAM_DOCUMENTS_SUCCESS',
  GET_PROGRAM_DOCUMENTS_FAILURE: 'GET_PROGRAM_DOCUMENTS_FAILURE',
  GET_PROGRAM_DOCUMENTS_REQUEST: 'GET_PROGRAM_DOCUMENTS_REQUEST',

  UPLOAD_PROGRAM_DOCUMENTS_SUCCESS: 'UPLOAD_PROGRAM_DOCUMENTS_SUCCESS',
  UPLOAD_PROGRAM_DOCUMENTS_FAILURE: 'UPLOAD_PROGRAM_DOCUMENTS_FAILURE',
  UPLOAD_PROGRAM_DOCUMENTS_REQUEST: 'UPLOAD_PROGRAM_DOCUMENTS_REQUEST',

  DELETE_PROGRAM_DOCUMENT_SUCCESS: 'DELETE_PROGRAM_DOCUMENT_SUCCESS',
  DELETE_PROGRAM_DOCUMENT_FAILURE: 'DELETE_PROGRAM_DOCUMENT_FAILURE',
  DELETE_PROGRAM_DOCUMENT_REQUEST: 'DELETE_PROGRAM_DOCUMENT_REQUEST',

  DOWNLOAD_PROGRAM_DOCUMENT_SUCCESS: 'DOWNLOAD_PROGRAM_DOCUMENT_SUCCESS',
  DOWNLOAD_PROGRAM_DOCUMENT_FAILURE: 'DOWNLOAD_PROGRAM_DOCUMENT_FAILURE',
  DOWNLOAD_PROGRAM_DOCUMENT_REQUEST: 'DOWNLOAD_PROGRAM_DOCUMENT_REQUEST',

  GET_PROJECT_SUCCESS: 'GET_PROJECT_SUCCESS',
  GET_PROJECT_FAILURE: 'GET_PROJECT_FAILURE',
  GET_PROJECT_REQUEST: 'GET_PROJECT_REQUEST',

  UPDATE_PROJECT_SUCCESS: 'UPDATE_PROJECT_SUCCESS',
  UPDATE_PROJECT_FAILURE: 'UPDATE_PROJECT_FAILURE',
  UPDATE_PROJECT_REQUEST: 'UPDATE_PROJECT_REQUEST',

  SET_PROGRAM_STATUS_SUCCESS: 'SET_PROGRAM_STATUS_SUCCESS',
  SET_PROGRAM_STATUS_FAILURE: 'SET_PROGRAM_STATUS_FAILURE',
  SET_PROGRAM_STATUS_REQUEST: 'SET_PROGRAM_STATUS_REQUEST',

  UPDATE_PROGRAM_ELIGIBILITY_RESPONSIBLES_SUCCESS:
    'UPDATE_PROGRAM_ELIGIBILITY_RESPONSIBLES_SUCCESS',
  UPDATE_PROGRAM_ELIGIBILITY_RESPONSIBLES_FAILURE:
    'UPDATE_PROGRAM_ELIGIBILITY_RESPONSIBLES_FAILURE',
  UPDATE_PROGRAM_ELIGIBILITY_RESPONSIBLES_REQUEST:
    'UPDATE_PROGRAM_ELIGIBILITY_RESPONSIBLES_REQUEST',

  UPDATE_PROGRAM_PROJECT_EVALUATION_RESPONSIBLES_SUCCESS:
    'UPDATE_PROGRAM_PROJECT_EVALUATION_RESPONSIBLES_SUCCESS',
  UPDATE_PROGRAM_PROJECT_EVALUATION_RESPONSIBLES_FAILURE:
    'UPDATE_PROGRAM_PROJECT_EVALUATION_RESPONSIBLES_FAILURE',
  UPDATE_PROGRAM_PROJECT_EVALUATION_RESPONSIBLES_REQUEST:
    'UPDATE_PROGRAM_PROJECT_EVALUATION_RESPONSIBLES_REQUEST',

  UPDATE_PROGRAM_EVALUATION_RESPONSIBLES_SUCCESS: 'UPDATE_PROGRAM_EVALUATION_RESPONSIBLES_SUCCESS',
  UPDATE_PROGRAM_EVALUATION_RESPONSIBLES_FAILURE: 'UPDATE_PROGRAM_EVALUATION_RESPONSIBLES_FAILURE',
  UPDATE_PROGRAM_EVALUATION_RESPONSIBLES_REQUEST: 'UPDATE_PROGRAM_EVALUATION_RESPONSIBLES_REQUEST',

  CONTEST_ELIGIBILITY_SUCCESS: 'CONTEST_ELIGIBILITY_SUCCESS',
  CONTEST_ELIGIBILITY_FAILURE: 'CONTEST_ELIGIBILITY_FAILURE',
  CONTEST_ELIGIBILITY_REQUEST: 'CONTEST_ELIGIBILITY_REQUEST'
};
