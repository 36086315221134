import institutionFormModel from './institutionFormModel';

const {
  institutionFormField: { name, shortName, fiscalCode, region, county, city, address }
} = institutionFormModel;

export const getInitialInstitutionFormValues = (institution) => ({
  id: institution.institutieGuid,
  [name.name]: institution.denumireCompleta ? institution.denumireCompleta : '',
  [shortName.name]: institution.denumireScurta ? institution.denumireScurta : '',
  [fiscalCode.name]: institution.codFiscal ? institution.codFiscal : '',
  [region.name]: institution.regiune ? institution.regiune : '',
  [county.name]: institution.judet ? institution.judet : '',
  [city.name]: institution.oras ? institution.oras : '',
  [address.name]: institution.adresa ? institution.adresa : ''
});
