import { useIntl } from 'react-intl';
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography
} from '@mui/material';
// The Effect Hook performs side effects in function components:
import { useEffect } from 'react';
// dispatch is used as a callback which gets invoked once some async action is complete
// useSelector allows you to extract data from the Redux store state, using a selector function.
import { useDispatch, useSelector } from 'react-redux';
// imports everything from the react module
import * as React from 'react';
import { useParams } from 'react-router-dom';
// Formik uses useFormik to create the <Formik> component (which renders a React Context Provider).
import { useFormik } from 'formik';
// import Page component
import Page from '../components/Page';
import { competitionActions, userActions, programActions } from '../actions';
import EditableFormAutocomplete from '../forms/shared/EditableFormAutocomplete';
import { getInitialAdminEligibilityContestationAssignFormValues } from '../forms/programs/adminEligibilityContestationAssign/formInitialValues';
import { adminEligibilityContestationAssignFormValidationSchema } from '../forms/programs/adminEligibilityContestationAssign/validationSchema';
import adminEligibilityContestationAssignFormModel from '../forms/programs/adminEligibilityContestationAssign/adminEligibilityContestationAssignFormModel';
import { UserRoles } from '../domain/UserRoles';
import { parseAdminEligibilityContestation } from '../forms/programs/adminEligibilityContestationAssign/formOutputParser';
// ----------------------------------------------------------------------

const {
  adminEligibilityContestationAssignField: { reviwer, writer }
} = adminEligibilityContestationAssignFormModel;

export default function AdminCompetitionProgramsEligibilityContestation() {
  const intl = useIntl();

  // useSelector extracts  data from the competitions state
  const competitions = useSelector((state) => state.competitions);
  // useSelector extracts  data from the programs state
  const programs = useSelector((state) => state.programs);
  // useSelector extracts  data from the user state
  const user = useSelector((state) => state.user);
  // dispatch is used as a callback which gets invoked once some async action is complete.
  const dispatch = useDispatch();
  const { idCompetition, idProgram } = useParams();

  // The Effect Hook performs side effects in function components:
  // dispatch is used as a callback which gets invoked once some async action is complete.
  useEffect(() => {
    dispatch(competitionActions.getCompetition(idCompetition)); // get Competitions by id
    dispatch(programActions.getProgram(idProgram)); // get program by id
    dispatch(programActions.getProgramEligibilityResponsibles(idProgram)); // get ProgramEligibility Responsibles by Program id
    dispatch(userActions.getAll(user.info.userType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: getInitialAdminEligibilityContestationAssignFormValues(
      programs.eligibilityResponsibles || {}
    ),
    enableReinitialize: true,
    validationSchema: adminEligibilityContestationAssignFormValidationSchema(),
    onSubmit: (values) => {
      const valuesCopy = JSON.parse(JSON.stringify(values));
      dispatch(
        programActions.updateProgramEligibilityResponsibles(
          parseAdminEligibilityContestation(valuesCopy),
          idProgram,
          'contestatie'
        )
      );
    }
  });

  // display CircularProgress on competitions or programs loading
  if (competitions?.loading || programs?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  // display Grid with 2 items
  return (
    <Page title={intl.formatMessage({ id: 'institution.competitions' })} my={2}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            {intl.formatMessage({ id: 'page.admin.competitions.program.eligibility' })}
          </Typography>
        </Stack>
        {competitions.selectedCompetition &&
          programs.selectedProgram &&
          user.users &&
          user.users.data && (
            <>
              <Grid container spacing={5}>
                {/* Grid item 1 size 6/12 of small viewport size 12/12 (full) of extrasmall viewport */}
                <Grid item xs={12} sm={6}>
                  <EditableFormAutocomplete
                    parentName={writer.name}
                    formik={formik}
                    required
                    label={intl.formatMessage({ id: writer.label })}
                    variant="outlined"
                    fullWidth
                    options={user.users.data
                      .filter(
                        (userOption) =>
                          !userOption.userType.includes(UserRoles.PlatformAdministrator) &&
                          userOption.userType.includes(UserRoles.EvaluatorContestatieEligibilitate)
                      )
                      .map((userOption) => ({
                        id: userOption.id,
                        label: `${userOption.firstName} ${userOption.lastName}`
                      }))}
                  />
                </Grid>
                {/* Grid item 2 size 6/12 of small viewport size 12/12 (full) of extrasmall viewport */}
                <Grid item xs={12} sm={6}>
                  <EditableFormAutocomplete
                    parentName={reviwer.name}
                    formik={formik}
                    required
                    label={intl.formatMessage({ id: reviwer.label })}
                    variant="outlined"
                    fullWidth
                    options={user.users.data
                      .filter(
                        (userOption) =>
                          !userOption.userType.includes(UserRoles.PlatformAdministrator) &&
                          userOption.userType.includes(UserRoles.EvaluatorContestatieEligibilitate)
                      )
                      .map((userOption) => ({
                        id: userOption.id,
                        label: `${userOption.firstName} ${userOption.lastName}`
                      }))}
                  />
                </Grid>
              </Grid>
              <Button variant="contained" onClick={() => formik.submitForm()} sx={{ marginTop: 2 }}>
                {intl.formatMessage({ id: 'global.save' })}
              </Button>
            </>
          )}
      </Container>
    </Page>
  );
}
