import React from 'react';
import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';

const ColorButton = ({ borderColor, labelColor, ...nativeProps }) => {
  const CustomButton = withStyles(() => ({
    root: {
      borderColor,
      color: labelColor
    }
  }))(Button);

  return (
    <CustomButton variant={nativeProps.variant} component={nativeProps.component} {...nativeProps}>
      {nativeProps.children}
    </CustomButton>
  );
};

export default ColorButton;
