import _ from 'lodash';

import projectsObjectivesForm from './projectsObjectivesFormModel';

const {
  projectsObjectivesFormFields: { objectives, total }
} = projectsObjectivesForm;

export const getInitialProjectsObjectivesFormValues = (form, start, end) => {
  const initialValues = {
    [objectives.name]:
      form && form[objectives.name]
        ? [...form[objectives.name]]
            .sort((o1, o2) => o1.obiectivGuid - o2.obiectivGuid)
            .map((objective) => ({
              ...objective,
              proiecte: [...objective.proiecte].sort((p1, p2) => p1.proiectGuid - p2.proiectGuid)
            }))
        : [],
    [total.name]:
      form?.[total.name] && form?.[total.name] !== {}
        ? form?.[total.name]
        : {
            [total.name]: 0
          }
  };
  if (!form?.[total.name] || form?.[total.name] === {})
    _.range(start, end + 1).map((year) => (initialValues[total.name][year] = undefined));
  return initialValues;
};
