import { useIntl } from 'react-intl';
import TableActionButton from '../../../TableActionButton';

export default function ObjectiveActionButton({ onClick, actionType }) {
  const intl = useIntl();

  return (
    <TableActionButton
      variant="contained"
      label={intl.formatMessage({ id: 'form.projectsObjectives.objective.label' })}
      onClick={onClick}
      actionType={actionType}
    />
  );
}
