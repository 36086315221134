const institutionInfoForm = {
  formId: 'institutionInfoForm',
  institutionInfoFormField: {
    name: {
      name: 'Denumire_Completa',
      label: 'institution.full_name',
      requiredErrorMsg: 'form.error.required'
    },
    shortName: {
      name: 'Denumire_Scurta',
      label: 'institution.short_name',
      requiredErrorMsg: 'form.error.required'
    },
    fiscalCode: {
      name: 'Cod_Fiscal',
      label: 'instutition.fiscal_code',
      requiredErrorMsg: 'form.error.required'
    },
    region: {
      name: 'Regiune',
      label: 'instutition.region',
      requiredErrorMsg: 'form.error.required'
    },
    county: {
      name: 'Judet',
      label: 'institution.county',
      requiredErrorMsg: 'form.error.required'
    },
    city: {
      name: 'Oras',
      label: 'institution.city',
      requiredErrorMsg: 'form.error.required'
    },
    address: {
      name: 'Adresa',
      label: 'institution.address',
      requiredErrorMsg: 'form.error.required'
    },
    directorLastName: {
      name: 'Nume_Director_General',
      label: 'institution.director.lastname'
    },
    directorFirstName: {
      name: 'Prenume_Director_General',
      label: 'institution.director.firstname'
    },
    directorEmail: {
      name: 'Email_Director_General',
      label: 'institution.director.email',
      emailErrorMsg: 'form.email.error'
    },
    directorPhone: {
      name: 'Telefon_Director_General',
      label: 'institution.director.phone',
      phoneErrorMsg: 'form.phone.error'
    },
    directorTitle: {
      name: 'Titlu_Director_General',
      label: 'institution.director.title'
    },
    economicDirectorLastName: {
      name: 'Nume_Director_Economic',
      label: 'institution.director.lastname'
    },
    economicDirectorFirstName: {
      name: 'Prenume_Director_Economic',
      label: 'institution.director.firstname'
    },
    economicDirectorEmail: {
      name: 'Email_Director_Economic',
      label: 'institution.director.email',
      emailErrorMsg: 'form.email.error'
    },
    economicDirectorPhone: {
      name: 'Telefon_Director_Economic',
      label: 'institution.director.phone',
      phoneErrorMsg: 'form.phone.error'
    },
    economicDirectorTitle: {
      name: 'Titlu_Director_Economic',
      label: 'institution.director.title'
    }
  }
};

export default institutionInfoForm;
