import { useIntl } from 'react-intl';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import ActionButton from '../../../TableActionButton';
import EditableTableCell from '../../../EditableTableCell';
import incomeStatusTableFields from '../../../../forms/program/incomeStatus/incomeStatusTableFields';

const {
  rowFields: { year }
} = incomeStatusTableFields;

const [, program, contract] = incomeStatusTableFields.rowFields.firstCols;
const [venitDinINCD, contVenit] = incomeStatusTableFields.rowFields.lastCols;

export default function IncomeStatusRow({
  index,
  row,
  years,
  rowAction,
  formik,
  name,
  disabled,
  value
}) {
  const intl = useIntl();

  return (
    <TableRow>
      <TableCell>{index}</TableCell>
      {row.label ? (
        <TableCell colSpan={row.ommitContract ? 2 : 1}>
          {intl.formatMessage({ id: row.label })}
        </TableCell>
      ) : (
        <EditableTableCell
          getValue={() => value[program.name]}
          disabled={disabled}
          formik={formik}
          name={`${name}.${program.name}`}
        />
      )}
      {!row.ommitContract && (
        <EditableTableCell
          getValue={() => value[contract.name]}
          disabled={disabled}
          formik={formik}
          name={`${name}.${contract.name}`}
        />
      )}
      {years.map((yr) => (
        <EditableTableCell
          key={yr}
          getValue={() => value[`${year.namePrefix}${yr}`]}
          disabled={disabled}
          formik={formik}
          name={`${name}.${year.namePrefix}${yr}`}
          type="number"
        />
      ))}
      <EditableTableCell
        getValue={() => value[venitDinINCD.name]}
        disabled={disabled}
        formik={formik}
        name={`${name}.${venitDinINCD.name}`}
        type="number"
      />
      <EditableTableCell
        getValue={() => value[contVenit.name]}
        disabled={disabled}
        formik={formik}
        name={`${name}.${contVenit.name}`}
        type="number"
      />
      <TableCell>
        {row.itemToAdd && !disabled && (
          <ActionButton
            actionType={rowAction.type}
            label={row.itemToAdd}
            variant="contained"
            onClick={rowAction.onClick}
          />
        )}
      </TableCell>
    </TableRow>
  );
}
