import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { TextField, Grid } from '@mui/material';

import { useEffect, useState } from 'react';
import { ACTIONS, ENTITIES, FORM_IDS } from '../../../utils/enums';

import programFormModel from '../../../forms/program/programInformation/programInformationFormModel';
import { getInitialProgramInformationFormValues } from '../../../forms/program/programInformation/formInitialValues';
import { programInformationFormValidationSchema } from '../../../forms/program/programInformation/validationSchema';

import EditableFormTextField from '../../../forms/shared/EditableFormTextField';
import EditableFormAutocomplete from '../../../forms/shared/EditableFormAutocomplete';
import FileUploadField from '../FileUploadField';

import { programActions, userActions } from '../../../actions';

import { fDateNoDay } from '../../../utils/formatTime';
import { UserRoles } from '../../../domain/UserRoles';
import { parseProgramInformation } from '../../../forms/program/programInformation/formOutputParser';

// ----------------------------------------------------------------------------

const {
  programInformationFormField: {
    contractor,
    fiscalCode,
    coreProgram,
    acronym,
    programAdmin,
    programAdminCV,
    description,
    purpose,
    length,
    goals,
    domains
  }
} = programFormModel;

export default function ProgramInformation({ disabled, isNew }) {
  const intl = useIntl();
  const { id } = useParams();
  const program = useSelector((state) => state.programs.selectedProgram);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [currentFileName, setCurrentFileName] = useState('');
  const [userOptions, setUserOptions] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      user.abilities.can(ACTIONS.VIEW, ENTITIES.INSTITUTION) ||
      user.abilities.can(ACTIONS.VIEW, ENTITIES.ADMINISTRATOR)
    ) {
      dispatch(userActions.getAll(user.info.userType, user.info.institutieParinte));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUserOptions(
      user && user.users && user.users.data
        ? user.users.data
            .filter(
              (userOption) => !userOption.userType.includes(UserRoles.AdministratorInstitutie)
            )
            .map((userOption) => ({
              id: userOption.id,
              label: `${userOption.firstName} ${userOption.lastName}`
            }))
        : []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.users]);

  const formik = useFormik({
    initialValues: getInitialProgramInformationFormValues(program),
    validationSchema: programInformationFormValidationSchema(),
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!values[programAdmin.name]) {
        delete values[programAdmin.name];
      }
      const data = JSON.parse(JSON.stringify(parseProgramInformation(values)));

      if (isNew) {
        dispatch(
          programActions.createProgram(id, (program) => {
            dispatch(
              programActions.updateProgramInformation(
                program.programGuid,
                data,
                values[programAdminCV.name]
              )
            );
            navigate(`../../../programe/${program.programGuid.toString()}`, { replace: true });
          })
        );
      } else {
        dispatch(programActions.updateProgramInformation(id, data, values[programAdminCV.name]));
      }
    }
  });

  useEffect(() => {
    if (formik.values[programAdminCV.name]) {
      if (formik.values[programAdminCV.name] instanceof FormData) {
        setCurrentFileName(formik.values[programAdminCV.name].get('file').name);
      }
    } else {
      setCurrentFileName(program?.detaliiProgram?.cvDirector?.numeFisierOriginal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, program]);

  return (
    <form id={FORM_IDS.PROGRAM_INFORMATION} onSubmit={formik.handleSubmit} noValidate>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            value={program && program.institutie ? program.institutie.denumireCompleta : ''}
            label={intl.formatMessage({ id: contractor.label })}
            variant="outlined"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={program && program.institutie ? program.institutie.codFiscal : ''}
            label={intl.formatMessage({ id: fiscalCode.label })}
            variant="outlined"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <EditableFormTextField
            parentName={coreProgram.name}
            formik={formik}
            label={intl.formatMessage({ id: coreProgram.label })}
            variant="outlined"
            fullWidth
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <EditableFormTextField
            parentName={acronym.name}
            formik={formik}
            label={intl.formatMessage({ id: acronym.label })}
            variant="outlined"
            fullWidth
            disabled={disabled}
            required
          />
        </Grid>
        {userOptions && (
          <Grid item xs={12} sm={6}>
            {!disabled && (
              <EditableFormAutocomplete
                parentName={programAdmin.name}
                formik={formik}
                required
                label={intl.formatMessage({ id: programAdmin.label })}
                variant="outlined"
                fullWidth
                options={userOptions}
              />
            )}
            {disabled && (
              <EditableFormTextField
                parentName={programAdmin.name}
                childrenNames={['label']}
                InputLabelProps={{ shrink: true }}
                formik={formik}
                label={intl.formatMessage({ id: programAdmin.label })}
                variant="outlined"
                fullWidth
                disabled={disabled}
              />
            )}
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <FileUploadField
            formDataName="file"
            disabled={disabled}
            buttonLabel="Upload"
            buttonLabelDownload="Download"
            label={intl.formatMessage({ id: programAdminCV.label })}
            handleUpload={(data) => formik.setFieldValue(programAdminCV.name, data)}
            handleDownload={() => dispatch(programActions.downloadProgramAdminCv(id))}
            currentFile={program?.detaliiProgram?.cvDirector?.fisierUrl}
            currentFileName={currentFileName}
            error={formik.errors[programAdminCV.name] ? formik.errors[programAdminCV.name] : false}
            touched={
              formik.touched[programAdminCV.name] ? formik.touched[programAdminCV.name] : false
            }
            required
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <EditableFormTextField
            parentName={description.name}
            formik={formik}
            label={intl.formatMessage({ id: description.label })}
            variant="outlined"
            fullWidth
            disabled={disabled}
            required
            multiline
            rows={3}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <EditableFormTextField
            parentName={purpose.name}
            formik={formik}
            label={intl.formatMessage({ id: purpose.label })}
            variant="outlined"
            fullWidth
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            value={
              program?.detaliiProgram?.dataStartProgram && program.detaliiProgram.dataEndProgram
                ? `${fDateNoDay(program.detaliiProgram.dataStartProgram)} - ${fDateNoDay(
                    program.detaliiProgram.dataEndProgram
                  )}`
                : ''
            }
            label={intl.formatMessage({ id: length.label })}
            variant="outlined"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <EditableFormTextField
            parentName={goals.name}
            formik={formik}
            label={intl.formatMessage({ id: goals.label })}
            variant="outlined"
            fullWidth
            disabled={disabled}
            required
            multiline
            rows={3}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <EditableFormTextField
            parentName={domains.name}
            formik={formik}
            label={intl.formatMessage({ id: domains.label })}
            variant="outlined"
            fullWidth
            disabled={disabled}
            required
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </form>
  );
}
