import * as React from 'react';
import { useIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { Box, Collapse, Grid, IconButton, Typography } from '@mui/material';
import { lowerFirst } from 'lodash';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import { PATENT_STATUSES, PATENT_TYPES } from '../../utils/enums';
import patentsFormModel from '../../forms/reporting/patents/patentsFormModel';
// ----------------------------------------------------------------------------

const {
  patentsField: { patent }
} = patentsFormModel;

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

const MiniCardHeader = styled(CardHeader)(() => ({
  padding: '24px 24px 24px'
}));

export default function ReporterProgramReportProjectCyclePhasePatentCard({ entity }) {
  const intl = useIntl();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card key={entity.id.toString()}>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12}>
          <MiniCardHeader
            title={`${entity[lowerFirst(patent.roName.name)]} / ${
              entity[lowerFirst(patent.enName.name)]
            }`}
            subheader={`${intl.formatMessage({ id: 'patent.status' })}: ${
              PATENT_STATUSES[entity[lowerFirst(patent.status.name)]]
            }`}
            action={
              <div>
                <ExpandMore
                  expand={expanded}
                  aria-expanded={expanded}
                  aria-label="show more"
                  onClick={handleExpandClick}
                >
                  <ExpandMoreIcon color="primary" />
                </ExpandMore>
              </div>
            }
          />
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Box sx={{ mb: 1 }}>
                <p>
                  <Typography variant="heading5" sx={{ color: 'gray' }}>
                    {intl.formatMessage({ id: patent.roDescription.label })}
                  </Typography>
                </p>
                <p>
                  <Typography variant="heading5" sx={{ color: 'black' }}>
                    {entity[lowerFirst(patent.roDescription.name)]}
                  </Typography>
                </p>
              </Box>
              <Box sx={{ mb: 1 }}>
                <p>
                  <Typography variant="heading5" sx={{ color: 'gray' }}>
                    {intl.formatMessage({ id: patent.enDescription.label })}
                  </Typography>
                </p>
                <p>
                  <Typography variant="heading5" sx={{ color: 'black' }}>
                    {entity[lowerFirst(patent.enDescription.name)]}
                  </Typography>
                </p>
              </Box>
              <Box sx={{ mb: 1 }}>
                <p>
                  <Typography variant="heading5" sx={{ color: 'gray' }}>
                    {intl.formatMessage({ id: patent.proposalYear.label })}
                  </Typography>
                </p>
                <p>
                  <Typography variant="heading5" sx={{ color: 'black' }}>
                    {entity[lowerFirst(patent.proposalYear.name)]}
                  </Typography>
                </p>
              </Box>
              <Box sx={{ mb: 1 }}>
                <p>
                  <Typography variant="heading5" sx={{ color: 'gray' }}>
                    {intl.formatMessage({ id: patent.acceptanceYear.label })}
                  </Typography>
                </p>
                <p>
                  <Typography variant="heading5" sx={{ color: 'black' }}>
                    {entity[lowerFirst(patent.acceptanceYear.name)]}
                  </Typography>
                </p>
              </Box>
              <Box sx={{ mb: 1 }}>
                <p>
                  <Typography variant="heading5" sx={{ color: 'gray' }}>
                    {intl.formatMessage({ id: patent.number.label })}
                  </Typography>
                </p>
                <p>
                  <Typography variant="heading5" sx={{ color: 'black' }}>
                    {entity[lowerFirst(patent.number.name)]}
                  </Typography>
                </p>
              </Box>
              <Box sx={{ mb: 1 }}>
                <p>
                  <Typography variant="heading5" sx={{ color: 'gray' }}>
                    {intl.formatMessage({ id: patent.type.label })}
                  </Typography>
                </p>
                <p>
                  <Typography variant="heading5" sx={{ color: 'black' }}>
                    {PATENT_TYPES[entity[lowerFirst(patent.type.name)]]}
                  </Typography>
                </p>
              </Box>
              <Box sx={{ mb: 1 }}>
                <p>
                  <Typography variant="heading5" sx={{ color: 'gray' }}>
                    {intl.formatMessage({ id: patent.authors.label })}
                  </Typography>
                </p>
                <p>
                  <Typography variant="heading5" sx={{ color: 'black' }}>
                    {entity[lowerFirst(patent.authors.name)] &&
                    entity[lowerFirst(patent.authors.name)].length > 0
                      ? entity[lowerFirst(patent.authors.name)].join(', ')
                      : ''}
                  </Typography>
                </p>
              </Box>
            </CardContent>
          </Collapse>
        </Grid>
      </Grid>
    </Card>
  );
}
