import { documentConstants } from '../constants';
import { triggerError, triggerSuccess } from '../utils/toasterTriggers';
import { documentService } from '../services';

export const documentActions = {
  getAll,
  getAllAdmin,
  deleteDocument,
  deleteDocumentAdmin,
  uploadDocument,
  uploadDocumentAdmin,
  downloadDocument,
  downloadAnexa2,
  uploadAnexa2,
  uploadActAditionalContractFinantare,
  uploadAcordRealocare,
  downloadDocumentAdmin,
  downloadContestationDocument,
  downloadProgramContestationDocument,
  downloadContractFinantareSemnat,
  downloadActAditionalContractFinantareSemnat,
  downloadAcordRealocare,
  downloadNotaJustificativa,
  downloadAnexaRaport,
  downloadAnexaRaportRaportor,
  downloadProjectResponsibleCV,
  downloadProjectDocument
};

function getAll(idInstitutie) {
  return (dispatch) => {
    dispatch(request());
    documentService.getAll(idInstitutie).then(
      (documents) => {
        dispatch(success(documents));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: documentConstants.DOCUMENT_GET_ALL_REQUEST };
  }
  function success(documents) {
    return { type: documentConstants.DOCUMENT_GET_ALL_SUCCESS, documents };
  }
  function failure(error) {
    return { type: documentConstants.DOCUMENT_GET_ALL_FAILURE, error };
  }
}

function getAllAdmin() {
  return (dispatch) => {
    dispatch(request());
    documentService.getAllAdmin().then(
      (documents) => {
        dispatch(success(documents));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: documentConstants.DOCUMENT_GET_ALL_ADMIN_REQUEST };
  }
  function success(documents) {
    return { type: documentConstants.DOCUMENT_GET_ALL_ADMIN_SUCCESS, documents };
  }
  function failure(error) {
    return { type: documentConstants.DOCUMENT_GET_ALL_ADMIN_FAILURE, error };
  }
}

function deleteDocument(fisierUrl, idInstitutie) {
  return (dispatch) => {
    dispatch(request());

    documentService.deleteDocument(fisierUrl, idInstitutie).then(
      () => {
        dispatch(success(fisierUrl));
        triggerSuccess('', 'toast.success.document.delete');
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.delete');
      }
    );
  };

  function request() {
    return { type: documentConstants.DOCUMENT_DELETE_REQUEST };
  }
  function success(fisierUrl) {
    return { type: documentConstants.DOCUMENT_DELETE_SUCCESS, fisierUrl };
  }
  function failure(error) {
    return { type: documentConstants.DOCUMENT_DELETE_FAILURE, error };
  }
}

function deleteDocumentAdmin(fisierUrl) {
  return (dispatch) => {
    dispatch(request());

    documentService.deleteDocumentAdmin(fisierUrl).then(
      () => {
        dispatch(success(fisierUrl));
        triggerSuccess('', 'toast.success.document.delete');
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.delete');
      }
    );
  };

  function request() {
    return { type: documentConstants.DOCUMENT_DELETE_ADMIN_REQUEST };
  }
  function success(fisierUrl) {
    return { type: documentConstants.DOCUMENT_DELETE_ADMIN_SUCCESS, fisierUrl };
  }
  function failure(error) {
    return { type: documentConstants.DOCUMENT_DELETE_ADMIN_FAILURE, error };
  }
}

function uploadDocument(formData, idInstitutie) {
  return (dispatch) => {
    dispatch(request());

    documentService.uploadDocument(formData, idInstitutie).then(
      (documents) => {
        dispatch(success(documents));
        triggerSuccess('', 'toast.success.save');
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.save');
      }
    );
  };

  function request() {
    return { type: documentConstants.DOCUMENT_UPLOAD_REQUEST };
  }
  function success(documents) {
    return { type: documentConstants.DOCUMENT_UPLOAD_SUCCESS, documents };
  }
  function failure(error) {
    return { type: documentConstants.DOCUMENT_UPLOAD_FAILURE, error };
  }
}

function uploadAnexa2(formData, idProgram) {
  return (dispatch) => {
    dispatch(request());

    documentService.uploadAnexa2(formData, idProgram).then(
      () => {
        dispatch(success());
        triggerSuccess('', 'toast.success.save');
        window.location.reload();
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.save');
      }
    );
  };

  function request() {
    return { type: documentConstants.DOCUMENT_UPLOAD_ANEXA2_REQUEST };
  }
  function success() {
    return { type: documentConstants.DOCUMENT_UPLOAD_ANEXA2_SUCCESS };
  }
  function failure(error) {
    return { type: documentConstants.DOCUMENT_UPLOAD_ANEXA2_FAILURE, error };
  }
}

function uploadActAditionalContractFinantare(formData, idProiect) {
  return (dispatch) => {
    dispatch(request());

    documentService.uploadActAditionalContractFinantare(formData, idProiect).then(
      () => {
        dispatch(success());
        triggerSuccess('', 'toast.success.save');
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.save');
      }
    );
  };

  function request() {
    return { type: documentConstants.DOCUMENT_UPLOAD_ACT_ADITIONAL_CONTRACT_FINANTARE_REQUEST };
  }
  function success() {
    return { type: documentConstants.DOCUMENT_UPLOAD_ACT_ADITIONAL_CONTRACT_FINANTARE_SUCCESS };
  }
  function failure(error) {
    return {
      type: documentConstants.DOCUMENT_UPLOAD_ACT_ADITIONAL_CONTRACT_FINANTARE_FAILURE,
      error
    };
  }
}

function uploadAcordRealocare(formData, idProiect) {
  return (dispatch) => {
    dispatch(request());

    documentService.uploadAcordRealocare(formData, idProiect).then(
      () => {
        dispatch(success());
        triggerSuccess('', 'toast.success.save');
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.save');
      }
    );
  };

  function request() {
    return { type: documentConstants.DOCUMENT_UPLOAD_ACORD_REALOCARE_REQUEST };
  }
  function success() {
    return { type: documentConstants.DOCUMENT_UPLOAD_ACORD_REALOCARE_SUCCESS };
  }
  function failure(error) {
    return {
      type: documentConstants.DOCUMENT_UPLOAD_ACORD_REALOCARE_FAILURE,
      error
    };
  }
}

function uploadDocumentAdmin(formData) {
  return (dispatch) => {
    dispatch(request());

    documentService.uploadDocumentAdmin(formData).then(
      (documents) => {
        dispatch(success(documents));
        triggerSuccess('', 'toast.success.save');
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.save');
      }
    );
  };

  function request() {
    return { type: documentConstants.DOCUMENT_UPLOAD_ADMIN_REQUEST };
  }
  function success(documents) {
    return { type: documentConstants.DOCUMENT_UPLOAD_ADMIN_SUCCESS, documents };
  }
  function failure(error) {
    return { type: documentConstants.DOCUMENT_UPLOAD_ADMIN_FAILURE, error };
  }
}

function downloadDocument(fisierUrl, idInstitutie) {
  return (dispatch) => {
    dispatch(request());
    documentService.downloadDocument(fisierUrl, idInstitutie).then(
      () => {
        dispatch(success(fisierUrl));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: documentConstants.DOCUMENT_DOWNLOAD_REQUEST };
  }
  function success(fisierUrl) {
    return { type: documentConstants.DOCUMENT_DOWNLOAD_SUCCESS, fisierUrl };
  }
  function failure(error) {
    return { type: documentConstants.DOCUMENT_DOWNLOAD_FAILURE, error };
  }
}

function downloadAnexa2(idProgram) {
  return (dispatch) => {
    dispatch(request());
    documentService.downloadAnexa2(idProgram).then(
      () => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: documentConstants.DOCUMENT_DOWNLOAD_REQUEST };
  }
  function success() {
    return { type: documentConstants.DOCUMENT_DOWNLOAD_SUCCESS };
  }
  function failure(error) {
    return { type: documentConstants.DOCUMENT_DOWNLOAD_FAILURE, error };
  }
}

function downloadContestationDocument(fisierUrl, idProiect) {
  return (dispatch) => {
    dispatch(request());
    documentService.downloadContestationDocument(fisierUrl, idProiect).then(
      () => {
        dispatch(success(fisierUrl));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: documentConstants.SUPPORT_DOCUMENT_DOWNLOAD_REQUEST };
  }
  function success(fisierUrl) {
    return { type: documentConstants.SUPPORT_DOCUMENT_DOWNLOAD_SUCCESS, fisierUrl };
  }
  function failure(error) {
    return { type: documentConstants.SUPPORT_DOCUMENT_DOWNLOAD_FAILURE, error };
  }
}

function downloadProgramContestationDocument(fisierUrl, idProgram) {
  return (dispatch) => {
    dispatch(request());
    documentService.downloadProgramContestationDocument(fisierUrl, idProgram).then(
      () => {
        dispatch(success(fisierUrl));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: documentConstants.SUPPORT_DOCUMENT_DOWNLOAD_REQUEST };
  }
  function success(fisierUrl) {
    return { type: documentConstants.SUPPORT_DOCUMENT_DOWNLOAD_SUCCESS, fisierUrl };
  }
  function failure(error) {
    return { type: documentConstants.SUPPORT_DOCUMENT_DOWNLOAD_FAILURE, error };
  }
}

function downloadContractFinantareSemnat(fisierUrl, idProgram) {
  return (dispatch) => {
    dispatch(request());
    documentService.downloadContractFinantareSemnat(fisierUrl, idProgram).then(
      () => {
        dispatch(success(fisierUrl));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: documentConstants.SUPPORT_DOCUMENT_DOWNLOAD_REQUEST };
  }
  function success(fisierUrl) {
    return { type: documentConstants.SUPPORT_DOCUMENT_DOWNLOAD_SUCCESS, fisierUrl };
  }
  function failure(error) {
    return { type: documentConstants.SUPPORT_DOCUMENT_DOWNLOAD_FAILURE, error };
  }
}

function downloadActAditionalContractFinantareSemnat(fisierUrl, idProiect) {
  return (dispatch) => {
    dispatch(request());
    documentService.downloadActAditionalContractFinantareSemnat(fisierUrl, idProiect).then(
      () => {
        dispatch(success(fisierUrl));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: documentConstants.SUPPORT_DOCUMENT_DOWNLOAD_REQUEST };
  }
  function success(fisierUrl) {
    return { type: documentConstants.SUPPORT_DOCUMENT_DOWNLOAD_SUCCESS, fisierUrl };
  }
  function failure(error) {
    return { type: documentConstants.SUPPORT_DOCUMENT_DOWNLOAD_FAILURE, error };
  }
}

function downloadAcordRealocare(fisierUrl, idProiect) {
  return (dispatch) => {
    dispatch(request());
    documentService.downloadAcordRealocare(fisierUrl, idProiect).then(
      () => {
        dispatch(success(fisierUrl));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: documentConstants.SUPPORT_DOCUMENT_DOWNLOAD_REQUEST };
  }
  function success(fisierUrl) {
    return { type: documentConstants.SUPPORT_DOCUMENT_DOWNLOAD_SUCCESS, fisierUrl };
  }
  function failure(error) {
    return { type: documentConstants.SUPPORT_DOCUMENT_DOWNLOAD_FAILURE, error };
  }
}

function downloadNotaJustificativa(fisierUrl) {
  return (dispatch) => {
    dispatch(request());
    documentService.downloadNotaJustificativa(fisierUrl).then(
      () => {
        dispatch(success(fisierUrl));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: documentConstants.SUPPORT_DOCUMENT_DOWNLOAD_REQUEST };
  }
  function success(fisierUrl) {
    return { type: documentConstants.SUPPORT_DOCUMENT_DOWNLOAD_SUCCESS, fisierUrl };
  }
  function failure(error) {
    return { type: documentConstants.SUPPORT_DOCUMENT_DOWNLOAD_FAILURE, error };
  }
}

function downloadDocumentAdmin(fisierUrl) {
  return (dispatch) => {
    dispatch(request());
    documentService.downloadDocumentAdmin(fisierUrl).then(
      () => {
        dispatch(success(fisierUrl));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: documentConstants.DOCUMENT_DOWNLOAD_ADMIN_REQUEST };
  }
  function success(fisierUrl) {
    return { type: documentConstants.DOCUMENT_DOWNLOAD_ADMIN_SUCCESS, fisierUrl };
  }
  function failure(error) {
    return { type: documentConstants.DOCUMENT_DOWNLOAD_ADMIN_FAILURE, error };
  }
}

function downloadAnexaRaport(programId, phaseId, cycleId, documentType) {
  return (dispatch) => {
    dispatch(request());
    documentService.downloadAnexaRaport(programId, phaseId, cycleId, documentType).then(
      () => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: documentConstants.DOCUMENT_DOWNLOAD_REPORT_DOCUMENT_REQUEST };
  }
  function success() {
    return { type: documentConstants.DOCUMENT_DOWNLOAD_REPORT_DOCUMENT_SUCCESS };
  }
  function failure(error) {
    return { type: documentConstants.DOCUMENT_DOWNLOAD_REPORT_DOCUMENT_FAILURE, error };
  }
}

function downloadAnexaRaportRaportor(fileUrl) {
  return (dispatch) => {
    dispatch(request());
    documentService.downloadAnexaRaportRaportor(fileUrl).then(
      () => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: documentConstants.DOCUMENT_DOWNLOAD_REPORT_DOCUMENT_REQUEST };
  }
  function success() {
    return { type: documentConstants.DOCUMENT_DOWNLOAD_REPORT_DOCUMENT_SUCCESS };
  }
  function failure(error) {
    return { type: documentConstants.DOCUMENT_DOWNLOAD_REPORT_DOCUMENT_FAILURE, error };
  }
}

function downloadProjectResponsibleCV(projectId) {
  return (dispatch) => {
    dispatch(request());
    documentService.downloadProjectResponsibleCV(projectId).then(
      () => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: documentConstants.DOCUMENT_DOWNLOAD_PROJECT_RESPONSIBLE_CV_DOCUMENT_REQUEST };
  }
  function success() {
    return { type: documentConstants.DOCUMENT_DOWNLOAD_PROJECT_RESPONSIBLE_CV_DOCUMENT_SUCCESS };
  }
  function failure(error) {
    return {
      type: documentConstants.DOCUMENT_DOWNLOAD_PROJECT_RESPONSIBLE_CV_DOCUMENT_FAILURE,
      error
    };
  }
}

function downloadProjectDocument(projectId, type) {
  return (dispatch) => {
    dispatch(request());
    documentService.downloadProjectDocument(projectId, type).then(
      () => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: documentConstants.DOCUMENT_DOWNLOAD_PROJECT_DOCUMENT_REQUEST };
  }
  function success() {
    return { type: documentConstants.DOCUMENT_DOWNLOAD_PROJECT_DOCUMENT_SUCCESS };
  }
  function failure(error) {
    return {
      type: documentConstants.DOCUMENT_DOWNLOAD_PROJECT_DOCUMENT_FAILURE,
      error
    };
  }
}
