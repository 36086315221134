// scroll bar
import 'simplebar/src/simplebar.css';

// The react-dom package provides DOM-specific methods that can be used at the top level of your app and as an escape hatch to get outside the React model if you need to.
import ReactDOM from 'react-dom';
// A <BrowserRouter> stores the current location in the browser's address bar using clean URLs and navigates using the browser's built-in history stack.
import { BrowserRouter } from 'react-router-dom';
// for anything asynchronous on the server, you need Helmet to encapsulate data on a per-request basis
import { HelmetProvider } from 'react-helmet-async';
// The <Provider> component makes the Redux store available to any nested components that need to access the Redux store.
import { Provider } from 'react-redux';
// A store.js plugin is a function that returns an object that gets added to the store.
import store from './utils/store';

// main file
import App from './App';
// Service workers essentially act as proxy servers that sit between web applications, the browser, and the network (when available)
import * as serviceWorker from './serviceWorker';
// With the reportWebVitals function, you can send any of results to an analytics endpoint to measure and track real user performance on your site.
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------
// The ReactDOM.render() function takes two arguments, HTML code and an HTML element.
// The purpose of the function is to display the specified HTML code inside the specified HTML element.
ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
