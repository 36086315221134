import { useFormik } from 'formik';
// material
import { Backdrop, Button, CircularProgress, Container, Grid, Typography } from '@mui/material';
// components
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../components/Page';
//
import EditableFormTextField from '../forms/shared/EditableFormTextField';
import myAccountFormModel from '../forms/myaccount/myAccountFormModel';
import { getInitialMyAccountFormValues } from '../forms/myaccount/formInitialValues';
import { myAccountFormValidationSchema } from '../forms/myaccount/validationSchema';
import { userActions } from '../actions';

// ----------------------------------------------------------------------

const {
  myAccountFormField: { lastName, firstName, email, phone }
} = myAccountFormModel;

export default function MyAccount() {
  const intl = useIntl();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: getInitialMyAccountFormValues(user.info),
    validationSchema: myAccountFormValidationSchema(),
    onSubmit: (values) => {
      const valuesCopy = JSON.parse(JSON.stringify(values));
      dispatch(userActions.updateUser(valuesCopy, true));
    }
  });

  if (user?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Page title={intl.formatMessage({ id: 'page.my_account.title' })} my={2}>
      <Container>
        <Typography variant="h3">{intl.formatMessage({ id: 'my_account.title' })}</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={lastName.name}
              formik={formik}
              label={intl.formatMessage({ id: lastName.label })}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={firstName.name}
              formik={formik}
              label={intl.formatMessage({ id: firstName.label })}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={email.name}
              formik={formik}
              label={intl.formatMessage({ id: email.label })}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={phone.name}
              formik={formik}
              label={intl.formatMessage({ id: phone.label })}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <Button variant="contained" onClick={() => formik.submitForm()} sx={{ marginTop: 2 }}>
          {intl.formatMessage({ id: 'global.save' })}
        </Button>
      </Container>
    </Page>
  );
}
