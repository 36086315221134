import * as Yup from 'yup';
import competitionInformationFormModel from './competitionInformationFormModel';

const {
  competitionInformationFormField: {
    name,
    startDate,
    endDate,
    submitStartDate,
    submitEndDate,
    checkEligibilityStartDate,
    checkEligibilityEndDate,
    eligibilityContestationStartDate,
    eligibilityContestationEndDate,
    technicalEvaluationProjectStartDate,
    technicalEvaluationProjectEndDate,
    technicalEvaluationProgrammeStartDate,
    technicalEvaluationProgrammeEndDate,
    technicalEvaluationContestationStartDate,
    technicalEvaluationContestationEndDate,
    technicalEvaluationContestationSolutionStartDate,
    technicalEvaluationContestationSolutionEndDate,
    monitoringStartDate,
    monitoringEndDate,
    reportingStartDate,
    reportingEndDate,
    competitionInstitutions
  }
} = competitionInformationFormModel;

export const competitionInformationFormValidationSchema = () =>
  Yup.object().shape({
    [name.name]: Yup.string().required(`${name.requiredErrorMsg}`),
    [startDate.name]: Yup.date(`${startDate.dateErrorMsg}`)
      .nullable()
      .required(`${startDate.requiredErrorMsg}`),
    [endDate.name]: Yup.date(`${endDate.dateErrorMsg}`)
      .nullable()
      .required(`${endDate.requiredErrorMsg}`)
      .min(Yup.ref(startDate.name), endDate.dateOrderErrorMsg),
    [submitStartDate.name]: Yup.date(`${submitStartDate.dateErrorMsg}`)
      .nullable()
      .required(`${submitStartDate.requiredErrorMsg}`),
    [submitEndDate.name]: Yup.date(`${submitEndDate.dateErrorMsg}`)
      .nullable()
      .required(`${submitEndDate.requiredErrorMsg}`)
      .min(Yup.ref(submitStartDate.name), submitEndDate.dateOrderErrorMsg),
    [checkEligibilityStartDate.name]: Yup.date(`${checkEligibilityStartDate.dateErrorMsg}`)
      .nullable()
      .required(`${checkEligibilityStartDate.requiredErrorMsg}`),
    [checkEligibilityEndDate.name]: Yup.date(`${checkEligibilityEndDate.dateErrorMsg}`)
      .nullable()
      .required(`${checkEligibilityEndDate.requiredErrorMsg}`)
      .min(Yup.ref(checkEligibilityStartDate.name), checkEligibilityEndDate.dateOrderErrorMsg),
    [eligibilityContestationStartDate.name]: Yup.date(
      `${eligibilityContestationStartDate.dateErrorMsg}`
    )
      .nullable()
      .required(`${eligibilityContestationStartDate.requiredErrorMsg}`),
    [eligibilityContestationEndDate.name]: Yup.date(
      `${eligibilityContestationEndDate.dateErrorMsg}`
    )
      .nullable()
      .required(`${eligibilityContestationEndDate.requiredErrorMsg}`)
      .min(
        Yup.ref(eligibilityContestationStartDate.name),
        eligibilityContestationEndDate.dateOrderErrorMsg
      ),
    [technicalEvaluationProjectStartDate.name]: Yup.date(
      `${technicalEvaluationProjectStartDate.dateErrorMsg}`
    )
      .nullable()
      .required(`${technicalEvaluationProjectStartDate.requiredErrorMsg}`),
    [technicalEvaluationProjectEndDate.name]: Yup.date(
      `${technicalEvaluationProjectEndDate.dateErrorMsg}`
    )
      .nullable()
      .required(`${technicalEvaluationProjectEndDate.requiredErrorMsg}`)
      .min(
        Yup.ref(technicalEvaluationProjectStartDate.name),
        technicalEvaluationProjectEndDate.dateOrderErrorMsg
      ),
    [technicalEvaluationProgrammeStartDate.name]: Yup.date(
      `${technicalEvaluationProgrammeStartDate.dateErrorMsg}`
    )
      .nullable()
      .required(`${technicalEvaluationProgrammeStartDate.requiredErrorMsg}`),
    [technicalEvaluationProgrammeEndDate.name]: Yup.date(
      `${technicalEvaluationProgrammeEndDate.dateErrorMsg}`
    )
      .nullable()
      .required(`${technicalEvaluationProgrammeEndDate.requiredErrorMsg}`)
      .min(
        Yup.ref(technicalEvaluationProgrammeStartDate.name),
        technicalEvaluationProgrammeEndDate.dateOrderErrorMsg
      ),
    [technicalEvaluationContestationStartDate.name]: Yup.date(
      `${technicalEvaluationContestationStartDate.dateErrorMsg}`
    )
      .nullable()
      .required(`${technicalEvaluationContestationStartDate.requiredErrorMsg}`),
    [technicalEvaluationContestationEndDate.name]: Yup.date(
      `${technicalEvaluationContestationEndDate.dateErrorMsg}`
    )
      .nullable()
      .required(`${technicalEvaluationContestationEndDate.requiredErrorMsg}`)
      .min(
        Yup.ref(technicalEvaluationContestationStartDate.name),
        technicalEvaluationContestationEndDate.dateOrderErrorMsg
      ),
    [technicalEvaluationContestationSolutionStartDate.name]: Yup.date(
      `${technicalEvaluationContestationSolutionStartDate.dateErrorMsg}`
    )
      .nullable()
      .required(`${technicalEvaluationContestationSolutionStartDate.requiredErrorMsg}`),
    [technicalEvaluationContestationSolutionEndDate.name]: Yup.date(
      `${technicalEvaluationContestationSolutionEndDate.dateErrorMsg}`
    )
      .nullable()
      .required(`${technicalEvaluationContestationSolutionEndDate.requiredErrorMsg}`)
      .min(
        Yup.ref(technicalEvaluationContestationSolutionStartDate.name),
        technicalEvaluationContestationSolutionEndDate.dateOrderErrorMsg
      ),
    [monitoringStartDate.name]: Yup.date(`${monitoringStartDate.dateErrorMsg}`)
      .nullable()
      .required(`${monitoringStartDate.requiredErrorMsg}`),
    [monitoringEndDate.name]: Yup.date(`${monitoringEndDate.dateErrorMsg}`)
      .nullable()
      .required(`${monitoringEndDate.requiredErrorMsg}`)
      .min(Yup.ref(monitoringStartDate.name), monitoringEndDate.dateOrderErrorMsg),
    [reportingStartDate.name]: Yup.date(`${reportingStartDate.dateErrorMsg}`)
      .nullable()
      .required(`${reportingStartDate.requiredErrorMsg}`),
    [reportingEndDate.name]: Yup.date(`${reportingEndDate.dateErrorMsg}`)
      .nullable()
      .required(`${reportingEndDate.requiredErrorMsg}`)
      .min(Yup.ref(reportingStartDate.name), reportingEndDate.dateOrderErrorMsg),
    [competitionInstitutions.name]: Yup.array().min(
      1,
      `${competitionInstitutions.requiredErrorMsg}`
    )
  });
