// material
import { Container, Typography } from '@mui/material';
// components
import { useIntl } from 'react-intl';
import Page from '../components/Page';
//

// ----------------------------------------------------------------------

export default function Contact() {
  const intl = useIntl();
  // display contact form with title, address,email, tel
  return (
    <Page title={intl.formatMessage({ id: 'page.contact.title' })} my={2}>
      <Container>
        <Typography variant="h3">{intl.formatMessage({ id: 'page.contact.title' })}</Typography>
        <Typography>{intl.formatMessage({ id: 'page.contact.address' })} ...</Typography>
        <Typography>{intl.formatMessage({ id: 'page.contact.email' })} ...</Typography>
        <Typography>{intl.formatMessage({ id: 'page.contact.tel' })} ...</Typography>
      </Container>
    </Page>
  );
}
