import informatiiGeneraleForm from './informatiiGeneraleFormModel';

const {
  informatiiGeneraleFormFields: {
    projectTitle,
    keywords,
    responsabil,
    cnpResponsabil,
    titluResponsabil,
    functieResponsabil,
    telResponsabil,
    faxResponsabil,
    emailResponsabil,
    responsabilProiect,
    cvResponsabil,
    domenii,
    prezentareaProiectului,
    descriereaStiintificaFile,
    structuraBugetuluiFile,
    managementulRisculuiFile,
    impactFile,
    planFile,
    alteInformatiiFile
  }
} = informatiiGeneraleForm;

export const parseInformatiiGenerale = (proiect) => ({
  [projectTitle.name]: proiect[projectTitle.name],
  [domenii.name]: proiect[domenii.name],
  [prezentareaProiectului.name]: proiect[prezentareaProiectului.name],
  [descriereaStiintificaFile.name]: proiect[descriereaStiintificaFile.name],
  [structuraBugetuluiFile.name]: proiect[structuraBugetuluiFile.name],
  [managementulRisculuiFile.name]: proiect[managementulRisculuiFile.name],
  [impactFile.name]: proiect[impactFile.name],
  [planFile.name]: proiect[planFile.name],
  [alteInformatiiFile.name]: proiect[alteInformatiiFile.name],
  [keywords.name]: proiect[keywords.name],
  [responsabilProiect.name]: {
    [telResponsabil.name]: proiect[telResponsabil.name],
    [faxResponsabil.name]: proiect[faxResponsabil.name],
    [emailResponsabil.name]: proiect[emailResponsabil.name],
    [cnpResponsabil.name]: proiect[cnpResponsabil.name],
    [titluResponsabil.name]: proiect[titluResponsabil.name],
    [functieResponsabil.name]: proiect[functieResponsabil.name],
    [responsabil.name]: {
      userGuid: proiect[responsabil.name].id,
      nume: proiect[responsabil.name].label
    },
    [cvResponsabil.name]: proiect[cvResponsabil.name]
  }
});
