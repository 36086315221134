import * as React from 'react';
import { useIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { Collapse, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import AdminProgramCardDetailsRow from './AdminProgramCardDetailsRow';
// ----------------------------------------------------------------------------

const ExpandMore = styled((props) => {
  // eslint-disable-next-line no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

AdminProgramCard.propTypes = {
  entity: PropTypes.object
};

export default function AdminProgramCard({ entity }) {
  const intl = useIntl();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card key={entity.programGuid}>
      <CardHeader
        title={
          <div>
            <p>{entity.numeProgram}</p>
          </div>
        }
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      <CardContent>
        <p>{intl.formatMessage({ id: 'programCard.competition' })}</p>
        <p>{entity.competitie.numeCompetitieParinte}</p>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <AdminProgramCardDetailsRow
            startDate={entity.competitie.perioadaDepunereIntervalInferior}
            endDate={entity.competitie.perioadaDepunereIntervalSuperior}
            title={intl.formatMessage({ id: 'global.submission' })}
            onClick={() => navigate(`${entity.programGuid}`)}
          />
          <AdminProgramCardDetailsRow
            startDate={entity.competitie.perioadaVerificareEligibilitateIntervalInferior}
            endDate={entity.competitie.perioadaVerificareEligibilitateIntervalSuperior}
            title={intl.formatMessage({ id: 'global.check_eligibility' })}
          />
          <AdminProgramCardDetailsRow
            startDate={entity.competitie.perioadaContestatieEligibilitateIntervalInferior}
            endDate={entity.competitie.perioadaContestatieEligibilitateIntervalSuperior}
            title={intl.formatMessage({ id: 'global.check_eligibility_contestation' })}
          />
          <AdminProgramCardDetailsRow
            startDate={entity.competitie.perioadaEvaluareTehnicaProiecteIntervalInferior}
            endDate={entity.competitie.perioadaEvaluareTehnicaProiecteIntervalSuperior}
            title={intl.formatMessage({ id: 'global.check_projects_technical' })}
          />
          <AdminProgramCardDetailsRow
            startDate={entity.competitie.perioadaEvaluareTehnicaProgramIntervalInferior}
            endDate={entity.competitie.perioadaEvaluareTehnicaProgramIntervalSuperior}
            title={intl.formatMessage({
              id: 'global.check_program_technical'
            })}
            onClick={() => navigate(`${entity.programGuid}/evaluare-program`)}
          />
          <AdminProgramCardDetailsRow
            startDate={entity.competitie.perioadaMonitorizareIntervalInferior}
            endDate={entity.competitie.perioadaMonitorizareIntervalSuperior}
            title={intl.formatMessage({ id: 'global.check_monitoring' })}
            onClick={() => navigate(`${entity.programGuid}/monitorizare/cicluri`)}
          />
          <AdminProgramCardDetailsRow
            startDate={entity.competitie.perioadaRaportareIntervalInferior}
            endDate={entity.competitie.perioadaRaportareIntervalSuperior}
            title={intl.formatMessage({ id: 'global.check_reporting' })}
            onClick={() => navigate(`${entity.programGuid}/raportare/proiecte`)}
          />
        </CardContent>
      </Collapse>
    </Card>
  );
}
