import competitionProgramEvaluationFormModel from './competitionProgramEvaluationFormModel';

const {
  competitionProgramEvaluationField: { criteria }
} = competitionProgramEvaluationFormModel;

export const getInitialCompetitionProgramEvaluationFormValues = (competitionProgramEvaluation) => ({
  [criteria.name]: competitionProgramEvaluation.criterii
    ? competitionProgramEvaluation.criterii.map((criteriu) => ({
        [criteria.title.name]: criteriu.denumire,
        [criteria.score.name]: criteriu.scorMaxim
      }))
    : []
});
