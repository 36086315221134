import _ from 'lodash';
import projectsObjectivesForm from './projectsObjectivesFormModel';

const {
  projectsObjectivesFormFields: {
    objectiveFields: { projects, name: objectiveName },
    projectFields: { additionalInfo, name: projectName, type, length, total, results, responsible }
  }
} = projectsObjectivesForm;

export const getEmptyObjective = () => ({
  [objectiveName.name]: '',
  [projects.name]: []
});

export const getEmptyProject = (start, end) => {
  const newProject = {
    [projectName.name]: '',
    [additionalInfo.name]: {
      [type.name]: '',
      [length.name]: '',
      [total.name]: undefined,
      [results.name]: '',
      [responsible.name]: ''
    }
  };

  _.range(start, end + 1).map((year) => (newProject[additionalInfo.name][year] = undefined));
  return newProject;
};
