export const UserRoles = {
  AdministratorInstitutie: 'AdministratorInstitutie',
  AplicantProiect: 'AplicantProiect',
  EvaluatorEligibilitate: 'EvaluatorEligibilitate',
  EvaluatorContestatieEligibilitate: 'EvaluatorContestatieEligibilitate',
  EvaluatorObiectiv: 'EvaluatorObiectiv',
  EvaluatorProgram: 'EvaluatorProgram',
  EvaluatorContestatieProgram: 'EvaluatorContestatieProgram',
  Monitor: 'Monitor',
  EvaluatorRapoarte: 'EvaluatorRapoarte',
  PlatformAdministrator: 'PlatformAdministrator'
};
