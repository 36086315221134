import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';

EditableFormSelect.propTypes = {
  parentName: PropTypes.string,
  childrenNames: PropTypes.array,
  formik: PropTypes.any,
  inputLabel: PropTypes.string,
  options: PropTypes.any,
  dependentParent: PropTypes.string,
  dependentSecondParent: PropTypes.string
};

export default function EditableFormSelect({
  parentName,
  childrenNames,
  formik,
  inputLabel,
  options,
  dependentParent,
  dependentSecondParent,
  ...nativeProps
}) {
  let value =
    // eslint-disable-next-line no-nested-ternary
    parentName in formik.values ? formik.values[parentName] : nativeProps.multiple ? [] : '';
  let error = formik.errors[parentName] ? formik.errors[parentName] : false;
  let touched = formik.touched[parentName] ? formik.touched[parentName] : false;
  let errorMessageId = formik.errors[parentName] ? formik.errors[parentName] : false;
  let tag = `${parentName}`;
  let currentOptions = options;

  if (childrenNames) {
    childrenNames.forEach((childName) => {
      touched = touched ? touched[childName] : touched;
      error = error ? error[childName] : error;
      errorMessageId = errorMessageId ? errorMessageId[childName] : errorMessageId;
      tag = `${tag}.${childName}`;
      value = value[childName];
    });
  }

  if (dependentParent) {
    currentOptions =
      options.find((option) => option.id === formik.values[parentName][dependentParent]) &&
      options.find((option) => option.id === formik.values[parentName][dependentParent]).children
        ? options.find((option) => option.id === formik.values[parentName][dependentParent])
            .children
        : [];
  }
  if (dependentSecondParent) {
    currentOptions =
      options.find((option) => option.id === formik.values[parentName][dependentSecondParent]) &&
      options.find((option) => option.id === formik.values[parentName][dependentSecondParent])
        .children
        ? options.find((option) => option.id === formik.values[parentName][dependentSecondParent])
            .children
        : [];
  }

  return (
    <FormControl
      variant="outlined"
      fullWidth
      error={touched && !!error}
      required={nativeProps.required}
    >
      <InputLabel id={tag}>{inputLabel}</InputLabel>
      <Select
        {...nativeProps}
        labelId={tag}
        id={tag}
        name={tag}
        value={value}
        onChange={formik.handleChange}
        error={touched && !!error}
      >
        {currentOptions &&
          currentOptions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
      {touched && !!error && (
        <FormHelperText>
          <FormattedMessage id={errorMessageId} />
        </FormHelperText>
      )}
    </FormControl>
  );
}
