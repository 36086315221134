import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import { styled, alpha } from '@mui/material/styles';

import { extractCurrency } from '../../../utils/formatInput';

import totalValueFormPermanentFields from '../../../forms/program/totalValue/totalValueFormModel';

const {
  totalValueFormFields: { yearTable, totalValue }
} = totalValueFormPermanentFields;

const TableCellStyle = styled(TableCell)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

export default function TotalValueRow({
  year,
  index,
  disabled,
  handleChange,
  handleBlur,
  values,
  setFiledValue
}) {
  const [edit, setEditMode] = React.useState(false);
  const name = `${yearTable.name}[${index}].${yearTable.estimation}`;

  const toggleEditMode = () => {
    setEditMode(!edit);
  };

  const getEstimationCell = () => {
    if (!disabled && edit)
      return (
        <TableCell>
          <TextField
            id={name}
            name={name}
            onChange={(event) => {
              const value = extractCurrency(event.target.value);
              event.target.value = value;
              handleChange(event);
              let totalSum = 0;
              values[yearTable.name].forEach((estimation, crtIndex) => {
                if (crtIndex === index) {
                  totalSum += +value;
                } else {
                  totalSum += +estimation[yearTable.estimation];
                }
              });
              setFiledValue(totalValue.name, totalSum);
            }}
            onBlur={(e) => {
              handleBlur(e);
              toggleEditMode();
            }}
            onKeyDown={(e) => e.key === 'Enter' && e.target.blur()}
            value={values[yearTable.name][index][yearTable.estimation]}
            variant="outlined"
            fullWidth
            autoFocus
          />
        </TableCell>
      );
    if (!disabled && !edit)
      return (
        <TableCellStyle>
          <span>{values[yearTable.name][index][yearTable.estimation]}</span>
          <IconButton color="primary" aria-label="edit" component="span" onClick={toggleEditMode}>
            <EditIcon />
          </IconButton>
        </TableCellStyle>
      );
    return <TableCell>{values[yearTable.name][index][yearTable.estimation]}</TableCell>;
  };

  return (
    <TableRow>
      <TableCell>{year}</TableCell>
      {getEstimationCell()}
    </TableRow>
  );
}
