import declaratiiForm from './declaratiiFormModel';

const {
  name,
  declaratiiFormFields: { nuEsteFinantat, dateReale }
} = declaratiiForm;

export const getInitialDeclaratiiFormValues = (proiect) => ({
  [nuEsteFinantat.name]: proiect?.[name]?.[nuEsteFinantat.name] ?? false,
  [dateReale.name]: proiect?.[name]?.[dateReale.name] ?? false
});
