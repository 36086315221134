import eligibilitySheetFormModel from './eligibilitySheetFormModel';

const {
  eligibilitySheetField: { eligibilitySheet, documents, status }
} = eligibilitySheetFormModel;

export const getInitialEligibilitySheetFormValues = (eligibilitySheetData) => ({
  [eligibilitySheet.name]: {
    [eligibilitySheet.criteria.name]:
      eligibilitySheetData.fisaEligibilitate && eligibilitySheetData.fisaEligibilitate.criterii
        ? eligibilitySheetData.fisaEligibilitate.criterii.map((criteriu) => ({
            [eligibilitySheet.criteria.criteria.name]: criteriu.criteriu,
            [eligibilitySheet.criteria.value.name]: criteriu.valoare
          }))
        : [],
    [eligibilitySheet.comments.name]:
      eligibilitySheetData.fisaEligibilitate && eligibilitySheetData.fisaEligibilitate.comentarii
        ? eligibilitySheetData.fisaEligibilitate.comentarii
        : ''
  },
  [status.name]: eligibilitySheetData.statusEligibilitateProgram
    ? eligibilitySheetData.statusEligibilitateProgram === 'Admis'
    : false,
  [documents.name]: eligibilitySheetData.documenteContestatie
    ? eligibilitySheetData.documenteContestatie
    : [],
  contestationFile: eligibilitySheetData.notaDeContestare
    ? { ...eligibilitySheetData.notaDeContestare, numeFisierOriginal: 'Contestatie' }
    : null
});
