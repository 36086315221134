import { useIntl } from 'react-intl';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Input,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import Page from '../components/Page';
import { documentActions, monitoringActions } from '../actions';
import { MONITORING_STATUS } from '../utils/enums';
import ColorButton from '../components/common/ColorButton';
// ----------------------------------------------------------------------

const FileInputStyle = styled(Input)(() => ({
  display: 'none'
}));

export default function InstitutionProgramMonitoringCycleProjectResults() {
  const { idProgram, idCiclu, idProiect } = useParams();
  const monitoring = useSelector((state) => state.monitoring);
  const [selectedProject, setSelectedProject] = useState(null);
  const intl = useIntl();
  const [actAditionalFile, setActAditionalFile] = useState(null);
  const [acordRealocareFile, setAcordRealocareFile] = useState(null);

  const dispatch = useDispatch();

  const handleSubmitActAditional = () => {
    if (actAditionalFile) {
      const formData = new FormData();
      formData.append('actAditionalContractFinantare', actAditionalFile);
      dispatch(documentActions.uploadActAditionalContractFinantare(formData, idProiect));
    }
  };

  const handleSubmitAcordRealocare = () => {
    if (acordRealocareFile) {
      const formData = new FormData();
      formData.append('acordRealocare', acordRealocareFile);
      dispatch(documentActions.uploadAcordRealocare(formData, idProiect));
    }
  };

  const handleDownloadTemplateActAditionalContractFinantare = () => {
    // TODO: download act aditional in loc de anexa 2
    // dispatch(documentActions.downloadTemplateActAditionalContractFinantare());
    dispatch(documentActions.downloadAnexa2(idProgram));
  };

  useEffect(() => {
    dispatch(monitoringActions.getProgramCycleProjects(idProgram, idCiclu));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (monitoring && monitoring.projects && monitoring.projects.length > 0) {
      if (monitoring.projects.find((project) => project.proiectGuid === +idProiect)) {
        setSelectedProject(
          monitoring.projects.find((project) => project.proiectGuid === +idProiect)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitoring]);

  if (monitoring?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Page title={intl.formatMessage({ id: 'applicant.programmes' })} my={2}>
      <Container>
        {selectedProject && (
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                {!selectedProject.statusMonitorizare &&
                  intl.formatMessage({ id: 'monitoring.status.validating' })}
                {selectedProject.statusMonitorizare === MONITORING_STATUS.EVALUATING &&
                  intl.formatMessage({ id: 'monitoring.status.evaluating' })}
                {selectedProject.statusMonitorizare === MONITORING_STATUS.REJECTED &&
                  intl.formatMessage({ id: 'monitoring.status.rejected' })}
                {selectedProject.statusMonitorizare === MONITORING_STATUS.ACCEPTED &&
                  intl.formatMessage({ id: 'monitoring.status.accepted' })}
              </Typography>
            </Stack>
            {selectedProject.statusMonitorizare === MONITORING_STATUS.REJECTED && (
              <Grid container>
                <Grid item xs={12} sm={12} mb={2}>
                  <Typography variant="h5" gutterBottom>
                    {intl.formatMessage({ id: 'monitoring.status.rejected.message' })}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} mb={2}>
                  <TextField
                    id="monitor-comments"
                    label={intl.formatMessage({ id: 'monitoring.monitor.comments' })}
                    multiline
                    rows={4}
                    disabled
                    fullWidth
                    defaultValue={selectedProject.comentariuMonitor}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            )}
            {selectedProject.statusMonitorizare === MONITORING_STATUS.ACCEPTED && (
              <Grid container>
                <Grid item xs={4} sm={4} mb={2}>
                  <Button
                    variant="outlined"
                    startIcon={<DownloadIcon />}
                    onClick={handleDownloadTemplateActAditionalContractFinantare}
                  >
                    {intl.formatMessage({ id: 'global.act_aditional.contract_finantare' })}
                  </Button>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    id="program-act-aditional-file"
                    label={intl.formatMessage({ id: 'global.act_aditional.anexa.2.upload' })}
                    value={
                      // eslint-disable-next-line no-nested-ternary
                      actAditionalFile
                        ? actAditionalFile.name
                        : selectedProject && selectedProject.actAditional
                        ? selectedProject && selectedProject.actAditional.numeFisierOriginal
                        : ''
                    }
                    variant="outlined"
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={2}>
                  <FileInputStyle
                    type="file"
                    name="act-aditional"
                    id="act-aditional"
                    onChange={(event) => {
                      setActAditionalFile(event.target.files[0]);
                    }}
                  />
                  <label htmlFor="act-aditional">
                    <Box
                      height="100%"
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      {selectedProject && selectedProject.actAditional && (
                        <ColorButton
                          variant="outlined"
                          borderColor="#005db7"
                          labelColor="#005db7"
                          component="span"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              documentActions.downloadActAditionalContractFinantareSemnat(
                                selectedProject.actAditional.fisierUrl,
                                idProiect
                              )
                            );
                          }}
                        >
                          {intl.formatMessage({ id: 'global.download' })}
                        </ColorButton>
                      )}
                      {selectedProject && !selectedProject.actAditional && (
                        <ColorButton
                          variant="outlined"
                          borderColor="#005db7"
                          labelColor="#005db7"
                          component="span"
                        >
                          {intl.formatMessage({ id: 'global.upload' })}
                        </ColorButton>
                      )}
                    </Box>
                  </label>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <Button
                    variant="contained"
                    sx={{ marginTop: 2, mb: 2 }}
                    onClick={() => handleSubmitActAditional()}
                  >
                    {intl.formatMessage({ id: 'global.save' })}
                  </Button>
                </Grid>
                <Grid item xs={10} />
                <Grid item xs={9}>
                  <TextField
                    id="program-acord-realocare-file"
                    label={intl.formatMessage({ id: 'global.acrod_realocare.upload' })}
                    value={
                      // eslint-disable-next-line no-nested-ternary
                      acordRealocareFile
                        ? acordRealocareFile.name
                        : selectedProject && selectedProject.acordRealocare
                        ? selectedProject && selectedProject.acordRealocare.numeFisierOriginal
                        : ''
                    }
                    variant="outlined"
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={2}>
                  <FileInputStyle
                    type="file"
                    name="acord-relaocare"
                    id="acord-realocare"
                    onChange={(event) => {
                      setAcordRealocareFile(event.target.files[0]);
                    }}
                  />
                  <label htmlFor="acord-realocare">
                    <Box
                      height="100%"
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      {selectedProject && selectedProject.acordRealocare && (
                        <ColorButton
                          variant="outlined"
                          borderColor="#005db7"
                          labelColor="#005db7"
                          component="span"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              documentActions.downloadAcordRealocare(
                                selectedProject.acordRealocare.fisierUrl,
                                idProiect
                              )
                            );
                          }}
                        >
                          {intl.formatMessage({ id: 'global.download' })}
                        </ColorButton>
                      )}
                      {selectedProject && !selectedProject.acordRealocare && (
                        <ColorButton
                          variant="outlined"
                          borderColor="#005db7"
                          labelColor="#005db7"
                          component="span"
                        >
                          {intl.formatMessage({ id: 'global.upload' })}
                        </ColorButton>
                      )}
                    </Box>
                  </label>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <Button
                    variant="contained"
                    sx={{ marginTop: 2 }}
                    onClick={() => handleSubmitAcordRealocare()}
                  >
                    {intl.formatMessage({ id: 'global.save' })}
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Container>
    </Page>
  );
}
