const reportAssignedEvaluatorsForm = {
  formId: 'reportAssignedEvaluatorsForm',
  reportAssignedEvaluatorsField: {
    assignedEvaluators: {
      name: 'Asocieri',
      requiredErrorMsg: 'form.error.required',
      project: {
        name: 'ProiectGuid',
        label: 'global.project',
        requiredErrorMsg: 'form.error.required'
      },
      mainEvaluator: {
        name: 'RaportorPrincipalGuid',
        label: 'monitoring.assignedMonitors.main',
        requiredErrorMsg: 'form.error.required'
      },
      secondEvaluator: {
        name: 'RaportorSecundarGuid',
        label: 'monitoring.assignedMonitors.second',
        requiredErrorMsg: 'form.error.required'
      }
    }
  }
};

export default reportAssignedEvaluatorsForm;
