// eslint-disable-next-line camelcase
import { camelizeKeys } from 'humps';
import { authHeader } from '../utils/auth-header';
import { env } from '../utils/config';
import { userService } from './index';

export const reportService = {
  getProgramAvailableProjectsForReporting,
  getAdminProgramAvailableProjectsForReporting,
  getProgramAssignedReportEvaluators,
  getReportingProjectCycles,
  getReportingProjectCyclePhases,
  getReportingProjectCycleResults,
  getPhasePublications,
  getPhasePatents,
  getPhaseDocuments,
  getEvaluatorPrograms,
  getEvaluatorProjects,
  getEvaluatorProjectFinalCycles,
  getEvaluatorFinalPhaseData,
  saveAssignedEvaluators,
  savePhasePublication,
  savePhasePatent,
  savePhaseDocuments,
  saveEvaluatorReport
};

function getEvaluatorFinalPhaseData(projectId, cycleId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_RAPORTOR_BASE_PATH}/DetaliiKPI/Proiect/${projectId}/Ciclu/${cycleId}`,
    requestOptions
  ).then(handleResponse);
}

function getEvaluatorProjectFinalCycles(projectId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_RAPORTOR_BASE_PATH}/CicluriFinaleProiect/Proiect/${projectId}`,
    requestOptions
  ).then(handleResponse);
}

function getEvaluatorProjects(programId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_RAPORTOR_BASE_PATH}/ProiecteAsociate/Program/${programId}`,
    requestOptions
  ).then(handleResponse);
}

function getEvaluatorPrograms() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_RAPORTOR_BASE_PATH}/ProgrameAsignate`,
    requestOptions
  ).then(handleResponse);
}

function getPhaseDocuments(programId, phaseId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${programId}/Faza/${phaseId}/Anexe`,
    requestOptions
  ).then(handleResponse);
}

function getPhasePatents(programId, phaseId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${programId}/Faza/${phaseId}/Brevete`,
    requestOptions
  ).then(handleResponse);
}

function getPhasePublications(programId, phaseId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${programId}/Faza/${phaseId}/Publicatii`,
    requestOptions
  ).then(handleResponse);
}

function getReportingProjectCycleResults(projectId, cycleId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/RezultateRaportare/${projectId}/${cycleId}`,
    requestOptions
  ).then(handleResponse);
}

function getReportingProjectCyclePhases(projectId, cycleId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/FazeTrecuteMonitorizare/${projectId}/${cycleId}`,
    requestOptions
  ).then(handleResponse);
}

function getReportingProjectCycles(institutionId, projectId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Institutie/${institutionId}/CicluriProiect/${projectId}`,
    requestOptions
  ).then(handleResponse);
}

function getProgramAvailableProjectsForReporting(institutionId, programId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Institutie/${institutionId}/ProiecteTrecuteMonitorizarePentruProrgam/${programId}`,
    requestOptions
  ).then(handleResponse);
}

function getAdminProgramAvailableProjectsForReporting(programId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/ProiecteTrecuteMonitorizarePentruProrgam/${programId}`,
    requestOptions
  ).then(handleResponse);
}

function getProgramAssignedReportEvaluators(programId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/RaportoriAsociati/${programId}`,
    requestOptions
  ).then(handleResponse);
}

function saveAssignedEvaluators(data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/AsociazaRaportori`,
    requestOptions
  ).then(handleResponse);
}

function savePhasePublication(programId, data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${programId}/AdaugaPublicatie`,
    requestOptions
  ).then(handleResponse);
}

function savePhasePatent(programId, data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${programId}/AdaugaBrevet`,
    requestOptions
  ).then(handleResponse);
}

function savePhaseDocuments(programId, formData) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: formData
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${programId}/AdaugaAnexe`,
    requestOptions
  ).then(handleResponse);
}

function saveEvaluatorReport(formData, projectId, cycleId) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: formData
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_RAPORTOR_BASE_PATH}/Proiect/${projectId}/Ciclu/${cycleId}/SubmiteRaportare`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        window.location.reload(true);
      }
      return Promise.reject(JSON.parse(text));
    }

    const data = text && JSON.parse(text);

    if (data) {
      return camelizeKeys(data);
    }

    return text;
  });
}
