import { useIntl } from 'react-intl';
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Tab,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { lowerFirst } from 'lodash';
import { useTheme } from '@mui/material/styles';
import ReactPDF from '@react-pdf/renderer';
import Page from '../components/Page';
import { evaluationActions } from '../actions';
import EditableFormTextField from '../forms/shared/EditableFormTextField';
import projectTechnicalSheetFormModel from '../forms/projectTechnicalEvaluation/projectTechnicalSheetFormModel';
import { getInitialProjectTechnicalSheetFormValues } from '../forms/projectTechnicalEvaluation/formInitialValues';
import { expectedResultsFormValidationSchema } from '../forms/projectTechnicalEvaluation/validationSchema';
import { parseProjectTechnicalSheet } from '../forms/projectTechnicalEvaluation/formOutputParser';
import { PROJECT_EVALUATION_STATUS } from '../utils/enums';
import Document from '../components/Document';
import ProgramEvaluationAnexa25 from './ProgramEvaluationAnexa25';

// ----------------------------------------------------------------------

const {
  projectTechnicalSheetField: { projectTechnicalSheet }
} = projectTechnicalSheetFormModel;

export default function ProgramTechnicalSheet() {
  const { idProgram } = useParams();
  const intl = useIntl();
  const [isSaveDraft, setSaveDraft] = useState(null);
  const [sheetType, setSheetType] = useState('normala');
  const [evaluationType, setEvaluationType] = useState('evaluare');
  const [value, setValue] = useState('4');
  const [readOnly, setReadOnly] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState();
  const [totalScore, setTotalScore] = useState(0);
  const [projectsScore, setProjectsScore] = useState([]);
  const [passedProjectsScore, setPassedProjectsScore] = useState([]);
  const [evaluatorsInfo, setEvaluatorsInfo] = useState([]);
  const theme = useTheme();

  const evaluation = useSelector((state) => state.evaluation);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(evaluationActions.getMyProgramEvaluationPrograms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (evaluation.programs) {
      const selectedProgram = evaluation.programs.find(
        (program) => program.programGuid === +idProgram
      );
      if (selectedProgram) {
        setSelectedProgram(selectedProgram);
        if (
          'fisaTehnicaAsignata' in selectedProgram &&
          selectedProgram.fisaTehnicaAsignata.statusFisaEvaluare ===
            PROJECT_EVALUATION_STATUS.SUBMITTED
        ) {
          if ('fisaFinalaVizibilaModerator' in selectedProgram) {
            setSheetType('finala');
            if (
              selectedProgram.fisaFinalaVizibilaModerator.statusFisaEvaluare ===
              PROJECT_EVALUATION_STATUS.SUBMITTED
            ) {
              setReadOnly(true);
            }
          } else {
            setReadOnly(true);
          }
        }

        if ('notaDeContestare' in selectedProgram) {
          setEvaluationType('contestatie');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluation.programs]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formik = useFormik({
    initialValues: getInitialProjectTechnicalSheetFormValues(
      // eslint-disable-next-line no-nested-ternary
      evaluation &&
        evaluation.programs &&
        evaluation.programs.find((program) => program.programGuid === +idProgram)
        ? evaluation.programs.find((program) => program.programGuid === +idProgram)
        : {}
    ),
    enableReinitialize: true,
    validationSchema: expectedResultsFormValidationSchema(isSaveDraft),
    onSubmit: (values) => {
      const valuesCopy = parseProjectTechnicalSheet(JSON.parse(JSON.stringify(values)));
      valuesCopy.ProgramGuid = +idProgram;
      if (isSaveDraft) {
        dispatch(
          evaluationActions.saveDraftProgramEvaluation(sheetType, evaluationType, valuesCopy)
        );
      } else {
        dispatch(
          evaluationActions.submitProgramEvaluation(
            idProgram,
            sheetType,
            evaluationType,
            valuesCopy
          )
        );
      }
    }
  });

  useEffect(() => {
    if (evaluation.programs) {
      const selectedProgram = evaluation.programs.find(
        (program) => program.programGuid === +idProgram
      );
      if (selectedProgram) {
        let total = 0;
        formik.values[projectTechnicalSheet.name][projectTechnicalSheet.criteria.name].forEach(
          (criterium) => (total += +criterium[projectTechnicalSheet.criteria.value.name])
        );
        setTotalScore(total);

        if (selectedProgram.fiseTehniceVizibileModerator) {
          setEvaluatorsInfo(
            selectedProgram.fiseTehniceVizibileModerator.map((fisa) => ({
              userId: fisa.userId,
              username: fisa.username
            }))
          );
        }

        const projectsScore = [];
        selectedProgram.obiective.forEach((objective) => {
          objective.proiecte.forEach((project) => {
            const fiseProiect = [...project.fiseTehniceVizibileModerator].sort(
              (p1, p2) => p1.userId - p2.userId
            );
            const scorProiectEvaluatori = fiseProiect.map((fisa) =>
              fisa.criterii.reduce((accumulator, criteriu) => accumulator + criteriu.scor, 0)
            );
            projectsScore.push({
              projectId: project.proiectGuid,
              projectTitle: project.titluProiect,
              evaluatorsScore: fiseProiect.map((fisa, index) => ({
                userId: fisa.userId,
                score: scorProiectEvaluatori[index]
              }))
            });
          });
        });

        setProjectsScore(projectsScore);
        setPassedProjectsScore(
          projectsScore.filter(
            (projectScore) =>
              projectScore.evaluatorsScore.reduce(
                (accumulator, evaluatorScore) => accumulator + evaluatorScore.score,
                0
              ) /
                3 >=
              65
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  useEffect(() => {
    if (isSaveDraft !== null) {
      formik.submitForm().finally(() => setSaveDraft(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveDraft]);

  if (evaluation?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Page title={intl.formatMessage({ id: 'applicant.programmes' })} my={2}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            {intl.formatMessage({ id: 'page.evaluation.sheet.title' })}
          </Typography>
        </Stack>
        {formik.values.contestationFile && (
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" gutterBottom>
                {intl.formatMessage({ id: 'page.eligibility.documents.title' })}
              </Typography>
            </Stack>
            <Stack
              spacing={2}
              sx={{
                marginBottom: 5,
                borderRadius: '10px',
                border: `1px solid ${theme.palette.grey[400]}`,
                padding: '10px'
              }}
            >
              {formik.values.contestationFile && (
                <Document
                  document={formik.values.contestationFile}
                  disabled
                  isAdmin={false}
                  selectedProgram={idProgram}
                />
              )}
            </Stack>
          </>
        )}
        {sheetType === 'finala' && (
          <TabContext value={value}>
            <TabList onChange={handleChange} aria-label="tabs">
              <Tab
                label={intl.formatMessage({ id: 'evaluation.project.moderator.tab1' })}
                value="1"
              />
              <Tab
                label={intl.formatMessage({ id: 'evaluation.project.moderator.tab2' })}
                value="2"
              />
              <Tab
                label={intl.formatMessage({ id: 'evaluation.project.moderator.tab3' })}
                value="3"
              />
              <Tab
                label={intl.formatMessage({ id: 'evaluation.project.moderator.tab4' })}
                value="4"
              />
            </TabList>
            {selectedProgram.fiseTehniceVizibileModerator.map((sheet, indexEvaluator) => (
              <TabPanel value={`${indexEvaluator + 1}`} index={indexEvaluator} key={indexEvaluator}>
                <Grid container spacing={5}>
                  {sheet[lowerFirst(projectTechnicalSheet.criteria.name)].map(
                    (criterion, index) => (
                      <Grid item xs={12} sm={12} key={index}>
                        <Grid container spacing={5} alignItems="center">
                          <Grid item xs={10} sm={10}>
                            <Typography variant="h5" gutterBottom component="div">
                              {
                                sheet[lowerFirst(projectTechnicalSheet.criteria.name)][index][
                                  lowerFirst(projectTechnicalSheet.criteria.criteria.name)
                                ]
                              }
                            </Typography>
                          </Grid>
                          <Grid item xs={2} sm={2}>
                            <TextField
                              id={`score${index}`}
                              name={`score${index}`}
                              label={`${intl.formatMessage({ id: 'global.score' })} (max: ${
                                criterion[lowerFirst(projectTechnicalSheet.criteria.maxValue.name)]
                              })`}
                              value={
                                criterion[lowerFirst(projectTechnicalSheet.criteria.value.name)]
                              }
                              disabled
                              fullWidth
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <TextField
                              id={`comment${index}`}
                              name={`comment${index}`}
                              label={intl.formatMessage({
                                id: projectTechnicalSheet.criteria.comments.label
                              })}
                              value={
                                criterion[lowerFirst(projectTechnicalSheet.criteria.comments.name)]
                              }
                              variant="outlined"
                              disabled
                              fullWidth
                              multiline
                              rows={4}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  )}
                </Grid>
              </TabPanel>
            ))}
            <TabPanel value="4" index={3}>
              <Grid container spacing={5}>
                {formik.values[projectTechnicalSheet.name][projectTechnicalSheet.criteria.name].map(
                  (criterion, index) => (
                    <Grid item xs={12} sm={12} key={index}>
                      <Grid container spacing={5} alignItems="center">
                        <Grid item xs={10} sm={10}>
                          <Typography variant="h5" gutterBottom component="div">
                            {
                              formik.values[projectTechnicalSheet.name][
                                projectTechnicalSheet.criteria.name
                              ][index][projectTechnicalSheet.criteria.criteria.name]
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                          <EditableFormTextField
                            parentName={projectTechnicalSheet.name}
                            childrenNames={[
                              projectTechnicalSheet.criteria.name,
                              index,
                              projectTechnicalSheet.criteria.value.name
                            ]}
                            formik={formik}
                            label={`${intl.formatMessage({ id: 'global.score' })} (max: ${
                              formik.values[projectTechnicalSheet.name][
                                projectTechnicalSheet.criteria.name
                              ][index][projectTechnicalSheet.criteria.maxValue.name]
                            })`}
                            variant="outlined"
                            type="number"
                            fullWidth
                            disabled={readOnly}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <EditableFormTextField
                            parentName={projectTechnicalSheet.name}
                            childrenNames={[
                              projectTechnicalSheet.criteria.name,
                              index,
                              projectTechnicalSheet.criteria.comments.name
                            ]}
                            formik={formik}
                            label={intl.formatMessage({
                              id: projectTechnicalSheet.criteria.comments.label
                            })}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            disabled={readOnly}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                )}
                <Grid item xs={12} sm={12}>
                  <Typography variant="h5" gutterBottom component="div">
                    {`${intl.formatMessage({ id: 'global.totalScore' })}: ${totalScore}`}
                  </Typography>
                </Grid>

                {!readOnly && (
                  <>
                    <Grid item xs={2} sm={2}>
                      <Button variant="outlined" onClick={() => setSaveDraft(true)}>
                        {intl.formatMessage({ id: 'eligibility.save_review' })}
                      </Button>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <Button variant="contained" onClick={() => setSaveDraft(false)}>
                        {intl.formatMessage({ id: 'eligibility.submit' })}
                      </Button>
                    </Grid>
                  </>
                )}
                {readOnly && (
                  <Grid item xs={4} sm={4}>
                    <ReactPDF.PDFDownloadLink
                      document={
                        <ProgramEvaluationAnexa25
                          fise={[...selectedProgram.fiseTehniceVizibileModerator].sort(
                            (fisa1, fisa2) => fisa1.userId - fisa2.userId
                          )}
                          programeTitle={selectedProgram.titluProgram}
                          totalProgram={totalScore}
                          projectsScore={projectsScore}
                          passedProjectsScore={passedProjectsScore}
                          evaluatorsInfo={evaluatorsInfo}
                          intl={intl}
                        />
                      }
                      fileName="anexa2_5.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        intl.formatMessage({ id: 'global.anexa.2.5' })
                      }
                    </ReactPDF.PDFDownloadLink>
                  </Grid>
                )}
              </Grid>
            </TabPanel>
          </TabContext>
        )}
        {sheetType === 'normala' && (
          <Grid container spacing={5}>
            {formik.values[projectTechnicalSheet.name][projectTechnicalSheet.criteria.name].map(
              (criterion, index) => (
                <Grid item xs={12} sm={12} key={index}>
                  <Grid container spacing={5} alignItems="center">
                    <Grid item xs={10} sm={10}>
                      <Typography variant="h5" gutterBottom component="div">
                        {
                          formik.values[projectTechnicalSheet.name][
                            projectTechnicalSheet.criteria.name
                          ][index][projectTechnicalSheet.criteria.criteria.name]
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <EditableFormTextField
                        parentName={projectTechnicalSheet.name}
                        childrenNames={[
                          projectTechnicalSheet.criteria.name,
                          index,
                          projectTechnicalSheet.criteria.value.name
                        ]}
                        formik={formik}
                        label={`${intl.formatMessage({ id: 'global.score' })} (max: ${
                          formik.values[projectTechnicalSheet.name][
                            projectTechnicalSheet.criteria.name
                          ][index][projectTechnicalSheet.criteria.maxValue.name]
                        })`}
                        variant="outlined"
                        type="number"
                        fullWidth
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <EditableFormTextField
                        parentName={projectTechnicalSheet.name}
                        childrenNames={[
                          projectTechnicalSheet.criteria.name,
                          index,
                          projectTechnicalSheet.criteria.comments.name
                        ]}
                        formik={formik}
                        label={intl.formatMessage({
                          id: projectTechnicalSheet.criteria.comments.label
                        })}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        disabled={readOnly}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )
            )}
            <Grid item xs={12} sm={12}>
              <Typography variant="h5" gutterBottom component="div">
                {`${intl.formatMessage({ id: 'global.totalScore' })}: ${totalScore}`}
              </Typography>
            </Grid>

            {!readOnly && (
              <>
                <Grid item xs={2} sm={2}>
                  <Button variant="outlined" onClick={() => setSaveDraft(true)}>
                    {intl.formatMessage({ id: 'eligibility.save_review' })}
                  </Button>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <Button variant="contained" onClick={() => setSaveDraft(false)}>
                    {intl.formatMessage({ id: 'eligibility.submit' })}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Container>
    </Page>
  );
}
