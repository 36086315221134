import { camelizeKeys } from 'humps';
import { authHeader } from '../utils/auth-header';
import { env } from '../utils/config';
import { userService } from './user.service';

export const autocompleteService = {
  getKeywords,
  getUsers
};

function getKeywords(partialText) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_COMMON_BASE_PATH}/VizualizeazaCuvinteCheie/${partialText}`,
    requestOptions
  ).then(handleResponse);
}

function getUsers(partialText) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  // TODO enable when backend is available

  // return fetch(
  //   `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_COMMON_BASE_PATH}/VizualizeazaCuvinteCheie/${partialText}`,
  //   requestOptions
  // ).then(handleResponse);
  return [];
}

function handleResponse(response) {
  return response.text().then((text) => {
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        window.location.reload(true);
      }
      return Promise.reject(JSON.parse(text));
    }

    const data = text && JSON.parse(text);

    if (data) {
      return camelizeKeys(data);
    }

    return text;
  });
}
