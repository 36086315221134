import adminEligibilityAssignFormModel from './adminEligibilityAssignFormModel';

const {
  adminEligibilityAssignField: { writer, reviwer }
} = adminEligibilityAssignFormModel;

export const getInitialAdminEligibilityAssignFormValues = (adminEligibilityAssign) => ({
  [writer.name]: adminEligibilityAssign.userWriterGuid
    ? { id: adminEligibilityAssign.userWriterGuid }
    : '',
  [reviwer.name]: adminEligibilityAssign.userReviewerGuid
    ? { id: adminEligibilityAssign.userReviewerGuid }
    : ''
});
