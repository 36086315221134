// material
import { Stack, Container, Typography } from '@mui/material';
// components
import { useIntl } from 'react-intl';
import * as React from 'react';
import Page from '../components/Page';
//
import SupportingDocuments from '../components/SupportingDocuments';

// ----------------------------------------------------------------------

export default function AdminPlatformaDocumente() {
  const intl = useIntl();

  return (
    <Page
      title={`${intl.formatMessage({ id: 'admin' })} - ${intl.formatMessage({
        id: 'applicant.documents'
      })}`}
    >
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {intl.formatMessage({ id: 'page.applicant.documents.title' })}
          </Typography>
        </Stack>

        <SupportingDocuments hasFiltering isAdmin />
      </Container>
    </Page>
  );
}
