import adminProgramEvaluationAssignForm from './adminProgramEvaluationAssignFormModel';

const {
  adminProgramEvaluationAssignField: { responsibles }
} = adminProgramEvaluationAssignForm;

export const getInitialAdminProgramEvaluationAssignFormValues = (adminProgramEvaluationAssign) => ({
  [responsibles.responsible1.name]: adminProgramEvaluationAssign.userReviewer1
    ? { id: adminProgramEvaluationAssign.userReviewer1 }
    : '',
  [responsibles.responsible2.name]: adminProgramEvaluationAssign.userReviewer2
    ? { id: adminProgramEvaluationAssign.userReviewer2 }
    : '',
  [responsibles.moderator.name]: adminProgramEvaluationAssign.userModerator
    ? { id: adminProgramEvaluationAssign.userModerator }
    : ''
});
