// routes
// Internationalize React apps. This library provides React components and an API to format dates, numbers, and strings
import { IntlProvider } from 'react-intl';
// Parse, validate, manipulate, and display dates.
import moment from 'moment';
// Routing refers to how an application's endpoints (URIs) respond to client requests.
import Router from './routes';
import 'moment/locale/ro';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
// renders chart elements
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import messagesEn from './lang/en.json';
import messagesRo from './lang/ro.json';
// The simple vanilla JavaScript toast pop-up notifications module, which is fully CSS-customizable.
import Toaster from './utils/toaster';

// ----------------------------------------------------------------------

const messages = {
  ro: messagesRo,
  en: messagesEn
};
// change language to ro
moment().locale('ro');
// used to export a single function from script file.
export default function App() {
  return (
    <IntlProvider locale="ro" messages={messages.ro}>
      <Toaster />
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <Router />
      </ThemeConfig>
    </IntlProvider>
  );
}
