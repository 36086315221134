export const competitionConstants = {
  GET_ALL_ADMIN_SUCCESS: 'GET_ALL_ADMIN_SUCCESS',
  GET_ALL_ADMIN_FAILURE: 'GET_ALL_ADMIN_FAILURE',
  GET_ALL_ADMIN_REQUEST: 'GET_ALL_ADMIN_REQUEST',

  GET_INSTITUTION_COMPETITIONS_SUCCESS: 'GET_INSTITUTION_COMPETITIONS_SUCCESS',
  GET_INSTITUTION_COMPETITIONS_FAILURE: 'GET_INSTITUTION_COMPETITIONS_FAILURE',
  GET_INSTITUTION_COMPETITIONS_REQUEST: 'GET_INSTITUTION_COMPETITIONS_REQUEST',

  GET_COMPETITION_ADMIN_SUCCESS: 'GET_COMPETITION_ADMIN_SUCCESS',
  GET_COMPETITION_ADMIN_FAILURE: 'GET_COMPETITION_ADMIN_FAILURE',
  GET_COMPETITION_ADMIN_REQUEST: 'GET_COMPETITION_ADMIN_REQUEST',

  COMPETITION_ADD_SUCCESS: 'COMPETITION_ADD_SUCCESS',
  COMPETITION_ADD_FAILURE: 'COMPETITION_ADD_FAILURE',
  COMPETITION_ADD_REQUEST: 'COMPETITION_ADD_REQUEST',

  COMPETITION_UPDATE_SUCCESS: 'COMPETITION_UPDATE_SUCCESS',
  COMPETITION_UPDATE_FAILURE: 'COMPETITION_UPDATE_FAILURE',
  COMPETITION_UPDATE_REQUEST: 'COMPETITION_UPDATE_REQUEST',

  COMPETITION_ADD_ELIGIBILITY_SUCCESS: 'COMPETITION_ADD_ELIGIBILITY_SUCCESS',
  COMPETITION_ADD_ELIGIBILITY_FAILURE: 'COMPETITION_ADD_ELIGIBILITY_FAILURE',
  COMPETITION_ADD_ELIGIBILITY_REQUEST: 'COMPETITION_ADD_ELIGIBILITY_REQUEST',

  COMPETITION_ADD_PROGRAM_EVALUATION_SUCCESS: 'COMPETITION_ADD_PROGRAM_EVALUATION_SUCCESS',
  COMPETITION_ADD_PROGRAM_EVALUATION_FAILURE: 'COMPETITION_ADD_PROGRAM_EVALUATION_FAILURE',
  COMPETITION_ADD_PROGRAM_EVALUATION_REQUEST: 'COMPETITION_ADD_PROGRAM_EVALUATION_REQUEST',

  COMPETITION_ADD_PROJECT_EVALUATION_SUCCESS: 'COMPETITION_ADD_PROJECT_EVALUATION_SUCCESS',
  COMPETITION_ADD_PROJECT_EVALUATION_FAILURE: 'COMPETITION_ADD_PROJECT_EVALUATION_FAILURE',
  COMPETITION_ADD_PROJECT_EVALUATION_REQUEST: 'COMPETITION_ADD_PROJECT_EVALUATION_REQUEST',

  COMPETITION_CLEAR_SELECTED_SUCCESS: 'COMPETITION_CLEAR_SELECTED_SUCCESS'
};
