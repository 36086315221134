import adminProjectsEvaluationAssignFormModel from './adminProjectsEvaluationAssignFormModel';

const {
  adminProjectsEvaluationAssignField: { responsibles }
} = adminProjectsEvaluationAssignFormModel;

export const parseAdminProjectEvaluation = (adminProjectEvaluation, idProgram) => {
  const parsedAdminProjectEvaluation = { ...adminProjectEvaluation };
  const result = [];
  parsedAdminProjectEvaluation.responsabili
    .filter((responsabil) => {
      if (
        responsabil[responsibles.objectives.name] &&
        responsabil[responsibles.objectives.name].length > 0
      ) {
        return true;
      }
      return false;
    })
    .forEach((responsabil) => {
      result.push({
        UserModerator: responsabil[responsibles.moderator.name].id,
        UserReviewer1: responsabil[responsibles.responsible1.name].id,
        UserReviewer2: responsabil[responsibles.responsible2.name].id,
        ObiectiveGuid: responsabil[responsibles.objectives.name].map((objective) => objective.id),
        ProgramGuid: +idProgram
      });
    });
  return result;
};
