import { useIntl } from 'react-intl';
import { Backdrop, CircularProgress, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import Page from '../components/Page';
import CardList from '../components/lists/CardList';
import { monitoringActions } from '../actions';
import MonitorProgramProjectCard from '../components/cards/MonitorProgramProjectCard';
// ----------------------------------------------------------------------

export default function MonitorProgramProjects() {
  const intl = useIntl();
  const { id } = useParams();

  const [sort, setSort] = useState('ascending');
  const [searchText, setSearchText] = useState('');
  const [projectItems, setProjectItems] = useState([]);
  const monitoring = useSelector((state) => state.monitoring);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(monitoringActions.getMyMonitoringProjects(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (monitoring && monitoring.projects) {
      setProjectItems(
        monitoring.projects.sort((p1, p2) => p1.titluProiect.localeCompare(p2.titluProiect))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitoring]);

  useEffect(() => {
    let filteredProjects = [];
    if (monitoring && monitoring.projects) {
      if (searchText && searchText.length > 0) {
        filteredProjects = monitoring.projects.filter((project) =>
          project.titluProiect.toLowerCase().includes(searchText.toLowerCase())
        );
      } else {
        filteredProjects = monitoring.projects;
      }
    }

    filteredProjects = filteredProjects.sort((p1, p2) =>
      sort === 'ascending'
        ? p1.titluProiect.toLowerCase().localeCompare(p2.titluProiect.toLowerCase())
        : p2.titluProiect.toLowerCase().localeCompare(p1.titluProiect.toLowerCase())
    );

    setProjectItems([...filteredProjects]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, searchText]);

  if (monitoring?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Page title={intl.formatMessage({ id: 'program.projects.title' })} my={2}>
      <Container>
        {projectItems && (
          <CardList
            entityId="proiectGuid"
            sort={sort}
            setSort={setSort}
            searchText={searchText}
            setSearchText={setSearchText}
            sortFieldTitle={intl.formatMessage({ id: 'global.name' })}
            items={projectItems}
            Card={MonitorProgramProjectCard}
          />
        )}
      </Container>
    </Page>
  );
}
