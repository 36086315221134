const institutionForm = {
  formId: 'institutionForm',
  institutionFormField: {
    name: {
      name: 'Denumire_Completa',
      label: 'institution.full_name',
      requiredErrorMsg: 'form.error.required'
    },
    shortName: {
      name: 'Denumire_Scurta',
      label: 'institution.short_name',
      requiredErrorMsg: 'form.error.required'
    },
    fiscalCode: {
      name: 'Cod_Fiscal',
      label: 'instutition.fiscal_code',
      requiredErrorMsg: 'form.error.required',
      duplicateFiscalCodeErrorMsg: 'form.institution.fiscal_code.duplicate'
    },
    region: {
      name: 'Regiune',
      label: 'instutition.region',
      requiredErrorMsg: 'form.error.required'
    },
    county: {
      name: 'Judet',
      label: 'institution.county',
      requiredErrorMsg: 'form.error.required'
    },
    city: {
      name: 'Oras',
      label: 'institution.city',
      requiredErrorMsg: 'form.error.required'
    },
    address: {
      name: 'Adresa',
      label: 'institution.address',
      requiredErrorMsg: 'form.error.required'
    }
  }
};

export default institutionForm;
