import { FieldArray } from 'formik';

import IncomeStatusRow from './IncomeStatusRow';
import incomeStatusTableFields from '../../../../forms/program/incomeStatus/incomeStatusTableFields';
import { getEmptyRow } from '../../../../forms/program/incomeStatus/incomeStatusTableInitialValues';

const {
  rowFields: { subRows, userSubRows }
} = incomeStatusTableFields;

export default function IncomeStatusSection({ index, elem, years, name, formik, disabled, value }) {
  return (
    <FieldArray
      name={`${name}.${userSubRows.name}`}
      render={(arrayHelpers) => (
        <>
          <IncomeStatusRow
            index={index && index}
            row={elem}
            rowAction={{
              type: 'insert',
              onClick: () => {
                arrayHelpers.push(getEmptyRow(false, false, false, years, ''));
              }
            }}
            name={name}
            years={years}
            formik={formik}
            disabled={disabled}
            value={value}
          />
          {elem.itemToAdd &&
            value[userSubRows.name] &&
            value[userSubRows.name].map((subRow, subIndex) => (
              <IncomeStatusRow
                key={subIndex}
                index={`${index ? `${index}.` : ''}${subIndex + 1}`}
                row={{
                  ommitContract: false,
                  itemToAdd: elem.itemToAdd
                }}
                rowAction={{
                  type: 'remove',
                  onClick: () => {
                    arrayHelpers.remove(subIndex);
                  }
                }}
                name={`${name}.${userSubRows.name}.${subIndex}`}
                years={years}
                formik={formik}
                disabled={disabled}
                value={value[userSubRows.name][subIndex]}
              />
            ))}
          {elem[subRows.name] && (
            <FieldArray
              name={`${name}.${subRows.name}`}
              render={() =>
                elem[subRows.name].map((subRow, subIndex) => (
                  <IncomeStatusRow
                    key={subIndex}
                    index={`${index ? `${index}.` : ''}${
                      (value[userSubRows.name] ? value[userSubRows.name].length : 0) + subIndex + 1
                    }`}
                    row={subRow}
                    name={`${name}.${subRows.name}.${subIndex}`}
                    years={years}
                    formik={formik}
                    disabled={disabled}
                    value={value[subRows.name][subIndex]}
                  />
                ))
              }
            />
          )}
        </>
      )}
    />
  );
}
