import * as Yup from 'yup';
import projectsObjectivesForm from './projectsObjectivesFormModel';

const {
  projectsObjectivesFormFields: { objectives, objectiveFields, projectFields }
} = projectsObjectivesForm;

export const projectsObjectivesFormValidationSchema = () =>
  Yup.object().shape({
    [objectives.name]: Yup.array().of(
      Yup.object().shape({
        [objectiveFields.name.name]: Yup.string().required(
          `${objectiveFields.name.requiredErrorMsg}`
        ),
        [objectiveFields.projects.name]: Yup.array().of(
          Yup.object().shape({
            [projectFields.name.name]: Yup.string().required(
              `${projectFields.name.requiredErrorMsg}`
            ),
            [projectFields.additionalInfo.name]: Yup.object().shape({
              [projectFields.responsible.name]: Yup.object().required(
                `${projectFields.responsible.requiredErrorMsg}`
              )
            })
          })
        )
      })
    )
  });
