const adminProgramEvaluationAssignForm = {
  formId: 'adminProgramEvaluationAssignForm',
  adminProgramEvaluationAssignField: {
    responsibles: {
      responsible1: {
        name: 'UserReviewer1',
        label: 'project.evaluation.responsible',
        requiredErrorMsg: 'form.error.required'
      },
      responsible2: {
        name: 'UserReviewer2',
        label: 'project.evaluation.responsible',
        requiredErrorMsg: 'form.error.required'
      },
      moderator: {
        name: 'UserModerator',
        label: 'project.evaluation.moderator',
        requiredErrorMsg: 'form.error.required'
      }
    }
  }
};

export default adminProgramEvaluationAssignForm;
