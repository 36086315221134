import * as Yup from 'yup';
import adminProgramEvaluationAssignForm from './adminProgramEvaluationAssignFormModel';

const {
  adminProgramEvaluationAssignField: { responsibles }
} = adminProgramEvaluationAssignForm;

export const adminProgramEvaluationAssignFormValidationSchema = () =>
  Yup.object().shape({
    [responsibles.responsible1.name]: Yup.object()
      .nullable()
      .required(`${responsibles.responsible1.requiredErrorMsg}`),
    [responsibles.responsible2.name]: Yup.object()
      .nullable()
      .required(`${responsibles.responsible2.requiredErrorMsg}`),
    [responsibles.moderator.name]: Yup.object()
      .nullable()
      .required(`${responsibles.moderator.requiredErrorMsg}`)
  });
