import incomeStatusTableRows from './incomeStatusTableRows';
import incomeStatusTableFields from './incomeStatusTableFields';
import incomeStatusTables from './incomeStatusTables';

const {
  name,
  tableStructure: { general, total },
  rowFields: { year, subRows, userSubRows }
} = incomeStatusTableFields;

const [, program, contract] = incomeStatusTableFields.rowFields.firstCols;
const [venitDinINCD, contVenit] = incomeStatusTableFields.rowFields.lastCols;

const getEmptyRow = (isSection, itemToAdd, ommitContract, years, programName) => {
  const emptyRow = {
    [program.name]: '',
    [venitDinINCD.name]: '',
    [contVenit.name]: ''
  };
  if (programName) emptyRow[program.name] = programName;
  if (!ommitContract) emptyRow[contract.name] = '';
  if (isSection) emptyRow[subRows.name] = [];
  if (itemToAdd) emptyRow[userSubRows.name] = [];

  years.forEach((yr) => (emptyRow[`${year.namePrefix}${yr}`] = ''));
  return emptyRow;
};

const getEmptyTablePart = (years, name, emptyTable) => {
  incomeStatusTableRows[name].forEach((row, index) => {
    emptyTable[name][index] = getEmptyRow(true, row.itemToAdd, row.ommitContract, years, row.name);
    if (row[subRows.name])
      row[subRows.name].forEach((subRow, subIndex) => {
        emptyTable[name][index][subRows.name][subIndex] = getEmptyRow(
          false,
          subRow.itemToAdd,
          subRow.ommitContract,
          years,
          subRow.name
        );
      });
  });
  return emptyTable;
};

const getEmptyTable = (years) => {
  const emptyTable = {
    [general.name]: [],
    [total.name]: []
  };

  getEmptyTablePart(years, general.name, emptyTable);
  getEmptyTablePart(years, total.name, emptyTable);

  return emptyTable;
};

const getIncomeStatusInitialValues = (program, years) => {
  if (program && program !== {}) return program;
  const initialValues = {};
  incomeStatusTables.forEach((table) => (initialValues[table.name] = getEmptyTable(years)));
  return initialValues;
};

export { getEmptyRow, getEmptyTable, getIncomeStatusInitialValues };
