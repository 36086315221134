import * as React from 'react';
import { useIntl } from 'react-intl';
import { FieldArray } from 'formik';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';
import {
  Button,
  Collapse,
  IconButton,
  Typography,
  Grid,
  Stack,
  FormControl,
  Select,
  MenuItem,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import schemaRealizareForm from '../../../forms/proiect/schemaRealizare/schemaRealizareFormModel';
import { getNewRezultat } from '../../../forms/proiect/schemaRealizare/formInitialValues';
import RezultatFieldsCard from './RezultatFieldsCard';

const {
  schemaRealizareFields: { rezultateFaza, faza },
  schemaRealizareTableHead: { titlu },
  rezultateFazaFields
} = schemaRealizareForm;

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

const Stretch = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
});

const MiniCardHeader = styled(CardHeader)(() => ({
  padding: '24px 24px 24px'
}));

export default function RezultatFazaCard({ formik, elem, id, disabled }) {
  const intl = useIntl();
  const [expanded, setExpanded] = useState(true);

  const [resultPhase, setResultPhase] = useState('');
  const [result, setResult] = useState('');
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (resultPhase) {
      setResults(resultPhase[rezultateFaza.name]);
    }
  }, [resultPhase]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card>
      <MiniCardHeader
        title={
          <div>
            <p>
              {intl.formatMessage({ id: 'form.project.schema.card.titlu' })}{' '}
              {elem[faza.name][titlu.name]}
            </p>
          </div>
        }
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Stack spacing={3}>
            <FieldArray
              name={`${schemaRealizareForm.name}[${id}].${rezultateFaza.name}`}
              render={(arrayHelpers) => (
                <Stack spacing={3}>
                  <Stretch>
                    <Typography variant="h6">
                      {intl.formatMessage({ id: 'form.project.schema.rezultate' })}
                    </Typography>
                  </Stretch>
                  {!disabled && (
                    <Grid container spacing={1} alignItems="center" justify="center">
                      <Grid item xs={2} sm={2}>
                        <Button
                          variant="contained"
                          onClick={() => arrayHelpers.unshift(getNewRezultat())}
                        >
                          {intl.formatMessage({ id: 'project.add.result.new' })}
                        </Button>
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <Box display="flex" justifyContent="center">
                          <Typography variant="h6">
                            {intl.formatMessage({ id: 'global.or' })}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <FormControl fullWidth>
                          <InputLabel id="result-phase-label">
                            {intl.formatMessage({ id: 'global.phase' })}
                          </InputLabel>
                          <Select
                            labelId="result-phase-label"
                            id="result-phase-select"
                            value={resultPhase ? resultPhase[faza.name][titlu.name] : ''}
                            label="result-phase"
                            onChange={(event) => {
                              setResultPhase(
                                formik.values[schemaRealizareForm.name].find(
                                  (_phase) => _phase[faza.name][titlu.name] === event.target.value
                                )
                              );
                            }}
                          >
                            {formik.values[schemaRealizareForm.name].map((currentPhase, index) => (
                              <MenuItem value={currentPhase[faza.name][titlu.name]} key={index}>
                                {currentPhase[faza.name][titlu.name]}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <FormControl fullWidth>
                          <InputLabel id="result-label">
                            {intl.formatMessage({ id: 'global.result' })}
                          </InputLabel>
                          <Select
                            labelId="result-label"
                            id="result-select"
                            value={result ? result[rezultateFazaFields[0].name] : ''}
                            label="result"
                            onChange={(event) => {
                              setResult(
                                resultPhase[rezultateFaza.name].find(
                                  (_result) =>
                                    _result[rezultateFazaFields[0].name] === event.target.value
                                )
                              );
                            }}
                          >
                            {results.map((_result, index) => (
                              <MenuItem value={_result[rezultateFazaFields[0].name]} key={index}>
                                {_result[rezultateFazaFields[0].name]}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <Button
                          variant="contained"
                          onClick={() => arrayHelpers.insert(0, result)}
                          disabled={!resultPhase || !result}
                        >
                          {intl.formatMessage({ id: 'project.add.result.copy' })}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  {formik.values[schemaRealizareForm.name][id][rezultateFaza.name].map(
                    (result, index) => (
                      <RezultatFieldsCard
                        parentName={schemaRealizareForm.name}
                        childrenNames={[id, rezultateFaza.name]}
                        key={index}
                        formik={formik}
                        id={index}
                        disabled={disabled}
                        arrayHelpers={arrayHelpers}
                      />
                    )
                  )}
                </Stack>
              )}
            />
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  );
}
