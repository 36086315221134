const publicationsForm = {
  formId: 'publicationsForm',
  publicationsField: {
    publication: {
      name: 'Publicatie',
      id: {
        name: 'Id'
      },
      type: {
        name: 'Tip',
        label: 'publication.type',
        requiredErrorMsg: 'form.error.required'
      },
      title: {
        name: 'Titlu',
        label: 'publication.title',
        requiredErrorMsg: 'form.error.required'
      },
      description: {
        name: 'Rezumat',
        label: 'publication.description'
      },
      pagesArticle: {
        name: 'Pagini',
        label: 'publication.pages'
      },
      publishingHouse: {
        name: 'Editura',
        label: 'publication.publishing_house'
      },
      doi: {
        name: 'DOI',
        label: 'publication.doi'
      },
      wos: {
        name: 'WOS',
        label: 'publication.wos'
      },
      publicationDate: {
        name: 'DataPublicarii',
        label: 'publication.publication_date',
        dateErrorMsg: 'form.error.date'
      },
      link: {
        name: 'Link',
        label: 'publication.link'
      },
      category: {
        name: 'Categorie',
        label: 'publication.category'
      },
      keywords: {
        name: 'CuvinteCheie',
        label: 'publication.keywords',
        button: 'form.expectedResults.keywords.button.label'
      },
      authors: {
        name: 'Autori',
        label: 'publication.authors',
        button: 'form.expectedResults.keywords.button.label'
      }
    },
    cycle: {
      name: 'CicluId'
    },
    phase: {
      name: 'FazaId'
    }
  }
};

export default publicationsForm;
