import * as Yup from 'yup';
import monitoringAssignedMonitorsFormModel from './monitoringAssignedMonitorsFormModel';

const {
  monitoringAssignedMonitorsField: { assignedMonitors }
} = monitoringAssignedMonitorsFormModel;

export const monitoringAssignedMonitorsFormValidationSchema = () =>
  Yup.object().shape({
    [assignedMonitors.name]: Yup.array().of(
      Yup.object().shape({
        [assignedMonitors.project.name]: Yup.object().required(
          `${assignedMonitors.project.requiredErrorMsg}`
        ),
        [assignedMonitors.monitor.name]: Yup.object().required(
          `${assignedMonitors.monitor.requiredErrorMsg}`
        )
      })
    )
  });
