import { useIntl } from 'react-intl';
import { Backdrop, CircularProgress, Container, Stack, Typography } from '@mui/material';
// The Effect Hook performs side effects in function components:
import { useEffect, useState } from 'react';
// dispatch is used as a callback which gets invoked once some async action is complete
// useSelector allows you to extract data from the Redux store state, using a selector function.
import { useDispatch, useSelector } from 'react-redux';
// imports everything from the react module
import * as React from 'react';
// imports Page component
import Page from '../components/Page';
import CardList from '../components/lists/CardList';
import CompetitionCard from '../components/cards/CompetitionCard';
import { competitionActions } from '../actions';
import MuiTableAddEntity from '../components/table/MuiTableAddEntity';
// ----------------------------------------------------------------------

export default function AdminCompetitions() {
  const intl = useIntl();

  const [sort, setSort] = useState('descending'); // set initial State to descending
  const [searchText, setSearchText] = useState(''); // set initial State to ''
  const [competitionItems, setCompetitionItems] = useState([]); // set initial State to empty Array
  const competitions = useSelector((state) => state.competitions);
  const dispatch = useDispatch();

  // The Effect Hook performs side effects in function components:
  // dispatch is used as a callback which gets invoked once some async action is complete.
  useEffect(() => {
    dispatch(competitionActions.getAllAdmin());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // sort/order competition by durataDesfasurareIntervalInferior
  useEffect(() => {
    if (competitions && competitions.competitions) {
      setCompetitionItems(
        competitions.competitions.sort((c1, c2) =>
          c2.durataDesfasurareIntervalInferior.localeCompare(c1.durataDesfasurareIntervalInferior)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitions]);

  // filter competition by searchText
  useEffect(() => {
    let filteredCompetitions = [];
    if (competitions && competitions.competitions) {
      if (searchText && searchText.length > 0) {
        filteredCompetitions = competitions.competitions.filter((competition) =>
          competition.nume.toLowerCase().includes(searchText.toLowerCase())
        );
      } else {
        filteredCompetitions = competitions.competitions;
      }
    }

    // sort/order filtered competition by durataDesfasurareIntervalInferior
    filteredCompetitions = filteredCompetitions.sort((c1, c2) =>
      sort === 'ascending'
        ? c1.durataDesfasurareIntervalInferior.localeCompare(c2.durataDesfasurareIntervalInferior)
        : c2.durataDesfasurareIntervalInferior.localeCompare(c1.durataDesfasurareIntervalInferior)
    );

    setCompetitionItems([...filteredCompetitions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, searchText]);

  // display CircularProgress on competitions or programs loading
  if (competitions?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  // display competitionItems
  return (
    <Page title={intl.formatMessage({ id: 'institution.competitions' })} my={2}>
      <Container>
        <MuiTableAddEntity
          title={intl.formatMessage({ id: 'administrator.competitions' })}
          buttonText={intl.formatMessage({ id: 'global.competitions.add' })}
        />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {intl.formatMessage({ id: 'page.admin.competitions.title' })}
          </Typography>
        </Stack>
        {competitionItems && (
          <CardList
            entityId="competitionGuid"
            sort={sort}
            setSort={setSort}
            searchText={searchText}
            setSearchText={setSearchText}
            sortFieldTitle={intl.formatMessage({ id: 'competition.startDate' })}
            items={competitionItems}
            Card={CompetitionCard}
          />
        )}
      </Container>
    </Page>
  );
}
