const programInformationForm = {
  formId: 'programInformationForm',
  programInformationFormField: {
    id: {
      name: 'programGuid'
    },
    contractor: {
      name: 'contractor',
      label: 'form.program.contractor',
      placeholder: 'form.program.contractor.placeholder',
      requiredErrorMsg: 'form.program.contractor.error.required'
    },
    fiscalCode: {
      name: 'fiscalCode',
      label: 'form.program.fiscalCode',
      placeholder: 'form.program.fiscalCode.placeholder',
      requiredErrorMsg: 'form.program.fiscalCode.error.required'
    },
    coreProgram: {
      name: 'denumireProgramNucleu',
      label: 'form.program.coreProgram',
      placeholder: 'form.program.coreProgram.placeholder',
      requiredErrorMsg: 'form.program.coreProgram.error.required'
    },
    acronym: {
      name: 'acronim',
      label: 'form.program.acronym',
      placeholder: 'form.program.acronym.placeholder',
      requiredErrorMsg: 'form.program.acronym.error.required'
    },
    programAdmin: {
      name: 'directorulProgramului',
      label: 'form.program.programAdmin',
      placeholder: 'form.program.programAdmin.placeholder',
      requiredErrorMsg: 'form.program.programAdmin.error.required'
    },
    programAdminCV: {
      name: 'programAdminCV',
      label: 'form.program.programAdminCV',
      placeholder: 'form.program.programAdminCV.placeholder',
      requiredErrorMsg: 'form.program.programAdminCV.error.required'
    },
    description: {
      name: 'scurtaDescriere',
      label: 'form.program.description.label',
      placeholder: 'form.program.description',
      helpText: 'form.program.description.helpText',
      requiredErrorMsg: 'form.program.description.error.required'
    },
    purpose: {
      name: 'scopulProgramului',
      label: 'form.program.purpose',
      placeholder: 'form.program.purpose.placeholder',
      requiredErrorMsg: 'form.program.purpose.error.required'
    },
    length: {
      label: 'form.program.length',
      placeholder: 'form.program.length',
      requiredErrorMsg: 'form.program.length.error.required'
    },
    goals: {
      name: 'obiectiveleProgramului',
      label: 'form.program.goals',
      placeholder: 'form.program.goals',
      helpText: 'form.program.goals.helpText',
      requiredErrorMsg: 'form.program.goals.error.required'
    },
    domains: {
      name: 'domeniiPrincipalSecundare',
      label: 'form.program.mainDomain',
      placeholder: 'form.program.mainDomain',
      requiredErrorMsg: 'form.program.mainDomain.error.required'
    }
  }
};

export default programInformationForm;
