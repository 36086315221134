import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { Collapse, IconButton, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import schemaRealizareForm from '../../forms/proiect/schemaRealizare/schemaRealizareFormModel';

const { rezultateFazaFields } = schemaRealizareForm;

const ExpandMore = styled((props) => {
  // eslint-disable-next-line no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

const MiniCardHeader = styled(CardHeader)(() => ({
  padding: '24px 24px 24px'
}));

MonitorPhaseResultCard.propTypes = {
  result: PropTypes.object
};

export default function MonitorPhaseResultCard({ result }) {
  const intl = useIntl();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ mb: 2 }}>
      <MiniCardHeader
        title={<p>{result[rezultateFazaFields[0].name]}</p>}
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Stack spacing={3}>
            {rezultateFazaFields.map((field, index) => (
              <TextField
                key={index}
                label={intl.formatMessage({ id: field.label })}
                variant="outlined"
                fullWidth
                disabled
                defaultValue={result[field.name]}
                multiline
                rows={field.rows}
              />
            ))}
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  );
}
