import * as React from 'react';
import { useIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { Box, Collapse, Grid, IconButton, Typography } from '@mui/material';
import { lowerFirst } from 'lodash';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { PUBLICATION_CATEGORIES, PUBLICATION_TYPES } from '../../utils/enums';
import publicationsFormModel from '../../forms/reporting/publications/publicationsFormModel';
import { defaultConfig } from '../../utils/config';
import { reportActions } from '../../actions';
// ----------------------------------------------------------------------------

const {
  publicationsField: { publication }
} = publicationsFormModel;

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

const MiniCardHeader = styled(CardHeader)(() => ({
  padding: '24px 24px 24px'
}));

export default function InstitutionProgramReportProjectCyclePhasePublicationCard({ entity }) {
  const intl = useIntl();
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card key={entity.id.toString()}>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12}>
          <MiniCardHeader
            title={entity[lowerFirst(publication.title.name)]}
            subheader={`${intl.formatMessage({ id: 'publication.type' })}: ${
              PUBLICATION_TYPES[entity[lowerFirst(publication.type.name)]]
            }`}
            action={
              <div>
                <IconButton
                  aria-label="settings"
                  onClick={() => dispatch(reportActions.setEditPublication(entity))}
                >
                  <EditIcon color="primary" />
                </IconButton>
                <ExpandMore
                  expand={expanded}
                  aria-expanded={expanded}
                  aria-label="show more"
                  onClick={handleExpandClick}
                >
                  <ExpandMoreIcon color="primary" />
                </ExpandMore>
              </div>
            }
          />
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Box sx={{ mb: 1 }}>
                <p>
                  <Typography variant="heading5" sx={{ color: 'gray' }}>
                    {intl.formatMessage({ id: publication.description.label })}
                  </Typography>
                </p>
                <p>
                  <Typography variant="heading5" sx={{ color: 'black' }}>
                    {entity[lowerFirst(publication.description.name)]}
                  </Typography>
                </p>
              </Box>
              <Box sx={{ mb: 1 }}>
                <p>
                  <Typography variant="heading5" sx={{ color: 'gray' }}>
                    {intl.formatMessage({ id: publication.pagesArticle.label })}
                  </Typography>
                </p>
                <p>
                  <Typography variant="heading5" sx={{ color: 'black' }}>
                    {entity[lowerFirst(publication.pagesArticle.name)]}
                  </Typography>
                </p>
              </Box>
              <Box sx={{ mb: 1 }}>
                <p>
                  <Typography variant="heading5" sx={{ color: 'gray' }}>
                    {intl.formatMessage({ id: publication.publishingHouse.label })}
                  </Typography>
                </p>
                <p>
                  <Typography variant="heading5" sx={{ color: 'black' }}>
                    {entity[lowerFirst(publication.publishingHouse.name)]}
                  </Typography>
                </p>
              </Box>
              <Box sx={{ mb: 1 }}>
                <p>
                  <Typography variant="heading5" sx={{ color: 'gray' }}>
                    {intl.formatMessage({ id: publication.publicationDate.label })}
                  </Typography>
                </p>
                <p>
                  <Typography variant="heading5" sx={{ color: 'black' }}>
                    {moment(entity[lowerFirst(publication.publicationDate.name)]).format(
                      defaultConfig.dateTimeFormats.short
                    )}
                  </Typography>
                </p>
              </Box>
              <Box sx={{ mb: 1 }}>
                <p>
                  <Typography variant="heading5" sx={{ color: 'gray' }}>
                    {intl.formatMessage({ id: publication.doi.label })}
                  </Typography>
                </p>
                <p>
                  <Typography variant="heading5" sx={{ color: 'black' }}>
                    {entity[lowerFirst(publication.doi.name)]}
                  </Typography>
                </p>
              </Box>
              <Box sx={{ mb: 1 }}>
                <p>
                  <Typography variant="heading5" sx={{ color: 'gray' }}>
                    {intl.formatMessage({ id: publication.wos.label })}
                  </Typography>
                </p>
                <p>
                  <Typography variant="heading5" sx={{ color: 'black' }}>
                    {entity[lowerFirst(publication.wos.name)]}
                  </Typography>
                </p>
              </Box>
              <Box sx={{ mb: 1 }}>
                <p>
                  <Typography variant="heading5" sx={{ color: 'gray' }}>
                    {intl.formatMessage({ id: publication.category.label })}
                  </Typography>
                </p>
                <p>
                  <Typography variant="heading5" sx={{ color: 'black' }}>
                    {PUBLICATION_CATEGORIES[entity[lowerFirst(publication.category.name)]]}
                  </Typography>
                </p>
              </Box>
              <Box sx={{ mb: 1 }}>
                <p>
                  <Typography variant="heading5" sx={{ color: 'gray' }}>
                    {intl.formatMessage({ id: publication.keywords.label })}
                  </Typography>
                </p>
                <p>
                  <Typography variant="heading5" sx={{ color: 'black' }}>
                    {entity[lowerFirst(publication.keywords.name)].join(', ')}
                  </Typography>
                </p>
              </Box>
              <Box sx={{ mb: 1 }}>
                <p>
                  <Typography variant="heading5" sx={{ color: 'gray' }}>
                    {intl.formatMessage({ id: publication.authors.label })}
                  </Typography>
                </p>
                <p>
                  <Typography variant="heading5" sx={{ color: 'black' }}>
                    {entity[lowerFirst(publication.authors.name)].join(', ')}
                  </Typography>
                </p>
              </Box>
              <Box sx={{ mb: 1 }}>
                <p>
                  <Typography variant="heading5" sx={{ color: 'gray' }}>
                    {intl.formatMessage({ id: publication.link.label })}
                  </Typography>
                </p>
                <p>
                  <a href={entity[lowerFirst(publication.link.name)]}>
                    {entity[lowerFirst(publication.link.name)]}
                  </a>
                </p>
              </Box>
            </CardContent>
          </Collapse>
        </Grid>
      </Grid>
    </Card>
  );
}
