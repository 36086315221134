const resurseUmaneForm = {
  formId: 'resurseUmane',
  name: 'resurseUmane',
  resurseUmaneFormFields: [
    {
      name: 'totalPersonal',
      label: 'form.project.hr.totalPersonal'
    },
    {
      name: 'cdStudiiSuperioare',
      label: 'form.project.hr.cdStudiiSuperioare'
    },
    {
      name: 'personalAuxiliar',
      label: 'form.project.hr.personalAuxiliar'
    }
  ],
  resurseUmaneFormSubfields: [
    {
      name: 'omPerLuna',
      label: 'form.project.hr.omPerLuna'
    },
    {
      name: 'numar',
      label: 'form.project.hr.numar'
    }
  ]
};

export default resurseUmaneForm;
