import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { InputAdornment, TextField, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { extractNumber, extractPositiveNumber, extractCurrency } from '../../utils/formatInput';

EditableFormTextField.propTypes = {
  parentName: PropTypes.string,
  childrenNames: PropTypes.array,
  inputAdornmentChildrenNames: PropTypes.array,
  formik: PropTypes.any,
  tooltip: PropTypes.string,
  canBeNegative: PropTypes.bool,
  type: PropTypes.string,
  index: PropTypes.number,
  InputProps: PropTypes.any
};

export default function EditableFormTextField({
  parentName,
  childrenNames,
  inputAdornmentChildrenNames,
  formik,
  tooltip,
  canBeNegative,
  type,
  InputProps,
  index,
  ...nativeProps
}) {
  let value = parentName in formik.values ? formik.values[parentName] : '';
  let error = formik.errors[parentName] ? formik.errors[parentName] : false;
  let touched = formik.touched[parentName] ? formik.touched[parentName] : false;
  let errorMessageId = formik.errors[parentName] ? formik.errors[parentName] : false;
  let tag = `${parentName}`;
  const isNumber = type && type === 'number';
  const canBeNegativeValue = canBeNegative && canBeNegative === true;
  let inputAdornmentValue = formik.values[parentName] ? formik.values[parentName] : '';
  let hasInputAdornment = false;

  if (typeof index !== 'undefined') {
    value = value[index];
    tag = `${tag}[${index}]`;
    errorMessageId = errorMessageId[index];
    error = error[index];
    touched = touched[index];
  }

  if (childrenNames) {
    childrenNames.forEach((childName) => {
      touched = touched ? touched[childName] : touched;
      error = error ? error[childName] : error;
      errorMessageId = errorMessageId ? errorMessageId[childName] : errorMessageId;
      tag = `${tag}.${childName}`;
      value = value[childName];
    });
  }

  if (inputAdornmentChildrenNames) {
    hasInputAdornment = true;
    inputAdornmentChildrenNames.forEach((inputAdornmentChildName) => {
      inputAdornmentValue = inputAdornmentValue[inputAdornmentChildName];
    });
  }

  return (
    <TextField
      {...nativeProps}
      id={tag}
      name={tag}
      value={value}
      onChange={(event) => {
        if (isNumber) {
          if (canBeNegativeValue) {
            event.target.value = extractNumber(event.target.value);
          } else {
            event.target.value = extractPositiveNumber(event.target.value);
          }
        }
        if (type && type === 'currency') event.target.value = extractCurrency(event.target.value);

        formik.handleChange(event);
      }}
      error={touched && !!error}
      helperText={touched && !!error && <FormattedMessage id={errorMessageId} />}
      InputProps={{
        // eslint-disable-next-line no-nested-ternary
        startAdornment: hasInputAdornment ? (
          <InputAdornment position="start">{inputAdornmentValue}</InputAdornment>
        ) : tooltip ? (
          <InputAdornment position="start">
            <Tooltip arrow title={tooltip}>
              <InfoIcon />
            </Tooltip>
          </InputAdornment>
        ) : (
          ''
        ),
        ...InputProps
      }}
    />
  );
}
