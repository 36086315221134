const adminProjectsEvaluationAssignForm = {
  formId: 'adminProjectsEvaluationAssignForm',
  adminProjectsEvaluationAssignField: {
    responsibles: {
      name: 'responsabili',
      requiredErrorMsg: 'form.error.required',
      objectives: {
        name: 'obiectiveGuid',
        label: 'project.evaluation.objective'
      },
      responsible1: {
        name: 'userReviewer1',
        label: 'project.evaluation.responsible',
        requiredErrorMsg: 'form.error.required'
      },
      responsible2: {
        name: 'userReviewer2',
        label: 'project.evaluation.responsible',
        requiredErrorMsg: 'form.error.required'
      },
      moderator: {
        name: 'userModerator',
        label: 'project.evaluation.moderator',
        requiredErrorMsg: 'form.error.required'
      }
    }
  }
};

export default adminProjectsEvaluationAssignForm;
