import { userConstants } from '../constants';
import { userService } from '../services';
import { triggerError, triggerSuccess } from '../utils/toasterTriggers';
import { UserRoles } from '../domain/UserRoles';

export const userActions = {
  login,
  logout,
  getCurrentUserInfo,
  getUserInfo,
  getUserBusinessInfo,
  updateUser,
  addUser,
  getAll,
  deleteUser,
  disposeUserInfo,
  requestPasswordReset,
  resetPassword
};

function login(userCredentials, isAdmin, navigate) {
  return (dispatch) => {
    dispatch(request());
    userService.login(userCredentials, isAdmin).then(
      (user) => {
        dispatch(success(user, isAdmin));
        if (!isAdmin) {
          dispatch(userActions.getCurrentUserInfo(user.userId));
          dispatch(userActions.getUserBusinessInfo());
        }
        navigate('/', { replace: true });
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError('', error.errorMessage);
      }
    );
  };

  function request() {
    return { type: userConstants.USER_LOGIN_REQUEST };
  }
  function success(user) {
    return { type: userConstants.USER_LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.USER_LOGIN_FAILURE, error };
  }
}

function requestPasswordReset(email) {
  return (dispatch) => {
    dispatch(request());
    userService.requestPasswordReset(email).then(
      () => {
        dispatch(success());
        triggerSuccess('', 'toast.success.my_account.reset_password_request');
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError('', error.errorMessage);
      }
    );
  };

  function request() {
    return { type: userConstants.USER_REQUEST_RESET_PASSWORD_REQUEST };
  }
  function success(user) {
    return { type: userConstants.USER_REQUEST_RESET_PASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.USER_REQUEST_RESET_PASSWORD_FAILURE, error };
  }
}

function resetPassword(password, token, navigate) {
  return (dispatch) => {
    dispatch(request());
    userService.resetPassword(password, token).then(
      () => {
        dispatch(success());
        triggerSuccess('', 'toast.success.my_account.reset_password');
        navigate('/', { replace: true });
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError('', error.errorMessage);
      }
    );
  };

  function request() {
    return { type: userConstants.USER_RESET_PASSWORD_REQUEST };
  }
  function success(user) {
    return { type: userConstants.USER_RESET_PASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.USER_RESET_PASSWORD_FAILURE, error };
  }
}

function logout(navigate, isAdmin) {
  return (dispatch) => {
    dispatch(request());
    userService.logout(isAdmin).then(
      () => {
        dispatch(success());
        navigate(isAdmin ? '/login-admin' : '/login', { replace: true });
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.my_account.logout');
      }
    );
  };

  function request() {
    return { type: userConstants.USER_LOGOUT_REQUEST };
  }
  function success(user) {
    return { type: userConstants.USER_LOGOUT_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.USER_LOGOUT_FAILURE, error };
  }
}

function getCurrentUserInfo(id) {
  return (dispatch) => {
    dispatch(request());

    userService.getCurrentUserInfo(id).then(
      (user) => dispatch(success(user)),
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: userConstants.GET_CURRENT_USER_INFO_REQUEST };
  }
  function success(user) {
    return { type: userConstants.GET_CURRENT_USER_INFO_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_CURRENT_USER_INFO_FAILURE, error };
  }
}

function getUserInfo(id, isAdmin) {
  return (dispatch) => {
    dispatch(request());

    userService.getUserInfo(id).then(
      (user) => {
        if (isAdmin) {
          userService.getAllAdmin().then((adminUsers) => {
            adminUsers.forEach((adminUser) => {
              if (+id === adminUser.userId) {
                user.institutionGuid = adminUser.institutionGuid;
              }
            });
            dispatch(success(user));
          });
        } else {
          dispatch(success(user));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: userConstants.GET_USER_INFO_REQUEST };
  }
  function success(user) {
    return { type: userConstants.GET_USER_INFO_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_INFO_FAILURE, error };
  }
}

function disposeUserInfo() {
  return (dispatch) => {
    dispatch(dispose());
  };

  function dispose() {
    return { type: userConstants.GET_USER_INFO_DISPOSE };
  }
}

function getUserBusinessInfo() {
  return (dispatch) => {
    dispatch(request());

    userService.getUserBusinessInfo().then(
      (user) => dispatch(success(user)),
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: userConstants.GET_USER_BUSINESS_INFO_REQUEST };
  }
  function success(user) {
    return { type: userConstants.GET_USER_BUSINESS_INFO_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_BUSINESS_INFO_FAILURE, error };
  }
}

function updateUser(newUser, isCurrent = false, isAdmin, navigate = null, callback) {
  return (dispatch) => {
    dispatch(request());
    userService.updateUser({ ...newUser }, isCurrent).then(
      (user) => {
        if ('userType' in newUser) {
          if (isAdmin) {
            if (
              newUser.userType.includes(UserRoles.AdministratorInstitutie) &&
              newUser.userType.includes(UserRoles.AplicantProiect)
            ) {
              userService.addInstitutionAdmin(user.id, newUser.InstitutieInAdministrareGuid).then(
                () => {
                  userService
                    .addProjectApplicant(user.id, newUser.InstitutieInAdministrareGuid)
                    .then(
                      () => {
                        dispatch(success(user, isCurrent));
                        if (navigate) {
                          navigate('../../utilizatori');
                        }
                        triggerSuccess('', 'toast.success.my_account.update');
                        if (callback) {
                          callback();
                        }
                      },
                      (error) => {
                        dispatch(failure(error.toString()));
                        triggerError(
                          error.errorMessage,
                          'toast.error.my_account.add.project_applicant'
                        );
                      }
                    );
                },
                (error) => {
                  dispatch(failure(error.toString()));
                  triggerError(error.errorMessage, 'toast.error.my_account.add.institution_admin');
                }
              );
            } else if (newUser.userType.includes(UserRoles.AdministratorInstitutie)) {
              userService.addInstitutionAdmin(user.id, newUser.InstitutieInAdministrareGuid).then(
                () => {
                  dispatch(success(user, isCurrent));
                  if (navigate) {
                    navigate('../../utilizatori');
                  }
                  triggerSuccess('', 'toast.success.my_account.update');
                  if (callback) {
                    callback();
                  }
                },
                (error) => {
                  dispatch(failure(error.toString()));
                  triggerError(error.errorMessage, 'toast.error.my_account.add.institution_admin');
                }
              );
            } else if (newUser.userType.includes('AplicantProiect')) {
              userService.addProjectApplicant(user.id, newUser.InstitutieInAdministrareGuid).then(
                () => {
                  dispatch(success(user, isCurrent));
                  if (navigate) {
                    navigate('../../utilizatori');
                  }
                  triggerSuccess('', 'toast.success.my_account.update');
                  if (callback) {
                    callback();
                  }
                },
                (error) => {
                  dispatch(failure(error.toString()));
                  triggerError(error.errorMessage, 'toast.error.my_account.add.project_applicant');
                }
              );
            } else {
              dispatch(success(user, isCurrent));
              if (navigate) {
                navigate('../../utilizatori');
              }
              triggerSuccess('', 'toast.success.my_account.update');
              if (callback) {
                callback();
              }
            }
          } else {
            dispatch(success(user, isCurrent));
            if (navigate) {
              if (isAdmin) {
                navigate('../../utilizatori');
              } else {
                // institutie
                navigate('../../conturi');
              }
            }
            triggerSuccess('', 'toast.success.my_account.update');
            if (callback) {
              callback();
            }
          }
        } else {
          dispatch(success(user, isCurrent));
          if (navigate) {
            navigate('../../utilizatori');
          }
          triggerSuccess('', 'toast.success.my_account.update');
          if (callback) {
            callback();
          }
        }
      },
      (error) => {
        dispatch(failure(JSON.stringify(error)));
        triggerError(error.errorMessage, 'toast.error.my_account.update');
      }
    );
  };

  function request() {
    return { type: userConstants.USER_UPDATE_REQUEST };
  }
  function success(user, isCurrent) {
    return { type: userConstants.USER_UPDATE_SUCCESS, user, isCurrent };
  }
  function failure(error) {
    return { type: userConstants.USER_UPDATE_FAILURE, error };
  }
}

function addUser(newUser, isAdmin, navigate, callback) {
  return (dispatch) => {
    dispatch(request());
    userService.addUser({ ...newUser }).then(
      (user) => {
        if (isAdmin) {
          if (
            newUser.userType.includes(UserRoles.AdministratorInstitutie) &&
            newUser.userType.includes(UserRoles.AplicantProiect)
          ) {
            userService.addInstitutionAdmin(user.id, newUser.InstitutieInAdministrareGuid).then(
              () => {
                userService.addProjectApplicant(user.id, newUser.InstitutieInAdministrareGuid).then(
                  () => {
                    dispatch(success(user));
                    navigate('../../utilizatori');
                    triggerSuccess('', 'toast.success.my_account.add');
                    if (callback) {
                      callback();
                    }
                  },
                  (error) => {
                    dispatch(failure(error.toString()));
                    triggerError(
                      error.errorMessage,
                      'toast.error.my_account.add.project_applicant'
                    );
                  }
                );
              },
              (error) => {
                dispatch(failure(error.toString()));
                triggerError(error.errorMessage, 'toast.error.my_account.add.institution_admin');
              }
            );
          } else if (newUser.userType.includes(UserRoles.AdministratorInstitutie)) {
            userService.addInstitutionAdmin(user.id, newUser.InstitutieInAdministrareGuid).then(
              () => {
                dispatch(success(user));
                navigate('../../utilizatori');
                triggerSuccess('', 'toast.success.my_account.add');
                if (callback) {
                  callback();
                }
              },
              (error) => {
                dispatch(failure(error.toString()));
                triggerError(error.errorMessage, 'toast.error.my_account.add.institution_admin');
              }
            );
          } else if (newUser.userType.includes('AplicantProiect')) {
            userService.addProjectApplicant(user.id, newUser.InstitutieInAdministrareGuid).then(
              () => {
                dispatch(success(user));
                navigate('../../utilizatori');
                triggerSuccess('', 'toast.success.my_account.add');
                if (callback) {
                  callback();
                }
              },
              (error) => {
                dispatch(failure(error.toString()));
                triggerError(error.errorMessage, 'toast.error.my_account.add.project_applicant');
              }
            );
          } else {
            dispatch(success(user));
            navigate('../../utilizatori');
            triggerSuccess('', 'toast.success.my_account.add');
            if (callback) {
              callback();
            }
          }
        } else {
          userService.addProjectApplicant(user.id, newUser.InstitutieInAdministrareGuid).then(
            () => {
              dispatch(success(user));
              navigate('../../conturi');
              triggerSuccess('', 'toast.success.my_account.add');
              if (callback) {
                callback();
              }
            },
            (error) => {
              dispatch(failure(error.toString()));
              triggerError(error.errorMessage, 'toast.error.my_account.add.project_applicant');
            }
          );
        }
      },
      (error) => {
        dispatch(failure(JSON.stringify(error)));
        triggerError('', error.errorMessage || error.message);
      }
    );
  };

  function request() {
    return { type: userConstants.USER_ADD_REQUEST };
  }
  function success(user) {
    return { type: userConstants.USER_ADD_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.USER_ADD_FAILURE, error };
  }
}

function getAll(userRoles, idInstitutie = '') {
  return (dispatch) => {
    dispatch(request());
    const isAdmin = userRoles.includes(UserRoles.PlatformAdministrator);

    userService.getAll(isAdmin, idInstitutie).then(
      (users) => {
        if (isAdmin) {
          userService.getAllAdmin().then((adminUsers) => {
            adminUsers.forEach((adminUser) => {
              if (users.find((selectedUser) => selectedUser.id === adminUser.userGuid)) {
                users.find(
                  (selectedUser) => selectedUser.id === adminUser.userGuid
                ).institutionGuid = adminUser.institutionGuid;
              }
            });
            dispatch(success(users));
          });
        } else {
          const responseUsers = [...users];
          const promises = [];
          responseUsers.forEach((user) => {
            if (user.userType.includes(UserRoles.AplicantProiect)) {
              promises.push(
                userService
                  .getUserInfo(user.userId)
                  .then((userData) => {
                    user.id = userData.id;
                    user.firstName = userData.firstName;
                    user.lastName = userData.lastName;
                    user.address = userData.address;
                    user.phone = userData.phone;
                    user.email = userData.email;
                  })
                  .catch((error) => {
                    user.delete = true;
                    console.log(`Error fetching user: ${error}`);
                  })
              );
            }
          });
          Promise.all(promises).then(() => {
            dispatch(
              success(
                responseUsers.filter(
                  (user) => !user.delete && user.userType.includes(UserRoles.AplicantProiect)
                )
              )
            );
          });
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: userConstants.GET_USERS_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_USERS_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_USERS_FAILURE, error };
  }
}

function deleteUser(user) {
  return (dispatch) => {
    dispatch(request());

    userService.deleteUser(user.id).then(
      () => {
        dispatch(success(user.id));
        triggerSuccess('', 'toast.success.my_account.delete', {
          value: `${user.lastName} ${user.firstName}`
        });
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.delete');
      }
    );
  };

  function request() {
    return { type: userConstants.DELETE_USER_REQUEST };
  }
  function success(id) {
    return { type: userConstants.DELETE_USER_SUCCESS, id };
  }
  function failure(error) {
    return { type: userConstants.DELETE_USER_FAILURE, error };
  }
}
