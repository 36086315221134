import { useIntl } from 'react-intl';
import { Backdrop, CircularProgress, Container, Grid, TextField, Typography } from '@mui/material';
import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import Page from '../components/Page';
import { documentActions, reportActions } from '../actions';
import ColorButton from '../components/common/ColorButton';
import { REPORTING_STATUS } from '../utils/enums';
// ----------------------------------------------------------------------

export default function InstitutionProgramReportingProjectCycleResults() {
  const { idProiect, idCiclu } = useParams();
  const intl = useIntl();

  const reporting = useSelector((state) => state.report);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reportActions.getReportingProjectCycleResults(idProiect, idCiclu));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (reporting?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Page title={intl.formatMessage({ id: 'program.cycles.results.title' })} my={2}>
      <Container>
        <Grid container alignItems="center" spacing={3}>
          {reporting &&
            reporting.results &&
            reporting.results.rapoarte &&
            reporting.results.rapoarte
              .filter((report) => report.statusFisaRaportare === REPORTING_STATUS.SUBMITTED)
              .map((report, index) => (
                <Fragment key={index}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h6" component="div">
                      {intl.formatMessage({ id: 'global.reporter' })} {index + 1}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ColorButton
                      variant="outlined"
                      borderColor="#005db7"
                      labelColor="#005db7"
                      component="span"
                      onClick={(e) => {
                        dispatch(
                          documentActions.downloadAnexaRaportRaportor(report.anexa16.fisierUrl)
                        );
                      }}
                    >
                      {intl.formatMessage({ id: 'Anexa16' })}
                    </ColorButton>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id="reporting-comments"
                      label={intl.formatMessage({ id: 'global.comments' })}
                      multiline
                      rows={4}
                      value={report.comentarii}
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Fragment>
              ))}
        </Grid>
      </Container>
    </Page>
  );
}
