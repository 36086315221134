import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';

import { Button, Container, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Page from '../components/Page';

import InformatiiGenerale from '../components/form/proiect/InformatiiGenerale';
import Declaratii from '../components/form/proiect/Declaratii';
import CosturiTotale from '../components/form/proiect/CosturiTotale';
import SchemaRealizare from '../components/form/proiect/SchemaRealizare';
import Rezultate from '../components/form/proiect/Rezultate';

import { getInitialProiectFormValues } from '../forms/proiect/formInitialValues';
import { proiectValidationSchema } from '../forms/proiect/validationSchema';
import { parseProiect } from '../forms/proiect/formOutputParser';

import { ENTITIES } from '../utils/enums';

import { programActions } from '../actions';
//-------------------------------------------------------------------------------

Proiect.propTypes = {
  role: PropTypes.oneOf([
    ENTITIES.APPLICANT,
    ENTITIES.PROGRAM,
    ENTITIES.INSTITUTION,
    ENTITIES.OBJECTIVE,
    ENTITIES.ADMINISTRATOR,
    ENTITIES.ELIGIBILITY,
    ENTITIES.ELIGIBILITY_DOCUMENTS
  ])
};

export default function Proiect({ role = 'READONLY', editFaze = false }) {
  const { idProgram, idProiect } = useParams();
  const dispatch = useDispatch();

  const project = useSelector((state) => state.programs.selectedProject);
  const program = useSelector((state) => state.programs.selectedProgram);

  const [years, setYears] = React.useState({});

  const disabled =
    (role !== ENTITIES.ADMINISTRATOR &&
      role !== ENTITIES.INSTITUTION &&
      role !== ENTITIES.APPLICANT) ||
    editFaze;

  React.useEffect(() => {
    dispatch(programActions.getProject(idProiect));
    dispatch(programActions.getProgram(idProgram));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProgram, idProiect]);

  React.useEffect(() => {
    setYears({
      start: new Date(program?.detaliiProgram?.dataStartProgram).getFullYear(),
      end: new Date(program?.detaliiProgram?.dataEndProgram).getFullYear()
    });
  }, [program]);

  const formik = {
    initialValues: getInitialProiectFormValues(project, years),
    onSubmit: (values) => {
      const data = parseProiect(JSON.parse(JSON.stringify(values)));
      dispatch(programActions.updateProject(idProiect, data));
    },
    validationSchema: proiectValidationSchema
  };

  return (
    <Page title="Proiect" my={2}>
      <Container>
        <Formik
          initialValues={formik.initialValues}
          validationSchema={formik.validationSchema}
          onSubmit={formik.onSubmit}
          validateOnChange={false}
          enableReinitialize
        >
          {({
            handleChange,
            setFieldValue,
            setFieldTouched,
            handleBlur,
            values,
            errors,
            touched,
            isSubmitting,
            handleSubmit
          }) => (
            <Stack spacing={3}>
              <Typography variant="h3">{project?.titluProiect}</Typography>
              <Typography variant="h5">{program?.detaliiProgram?.denumireProgramNucleu}</Typography>

              <InformatiiGenerale
                formik={{
                  handleChange,
                  setFieldValue,
                  setFieldTouched,
                  handleBlur,
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  handleSubmit
                }}
                values={values}
                projectId={idProiect}
                disabled={disabled}
              />

              <SchemaRealizare
                formik={{
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  handleSubmit
                }}
                disabled={disabled && !editFaze}
                project={years}
              />

              <Rezultate
                formik={{
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  handleSubmit
                }}
                disabled={disabled && !editFaze}
                project={years}
              />

              <CosturiTotale
                formik={{
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  handleSubmit
                }}
                disabled={disabled}
                project={years}
              />

              <Declaratii
                formik={{
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  handleSubmit
                }}
                disabled={disabled}
              />
              {(!disabled || editFaze) && (
                <div>
                  <Button variant="outlined" onClick={handleSubmit}>
                    Salvare partiala
                  </Button>
                  <Button variant="contained" onClick={handleSubmit}>
                    Salvare
                  </Button>
                </div>
              )}
            </Stack>
          )}
        </Formik>
      </Container>
    </Page>
  );
}
