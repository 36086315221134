import { StyleSheet, Font } from '@react-pdf/renderer';
import Roboto from '../assets/fonts/Roboto-Regular.ttf';
import RobotoBold from '../assets/fonts/Roboto-Bold.ttf';

Font.register({
  family: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fonts: [
    {
      src: Roboto
    },
    {
      src: RobotoBold,
      fontWeight: 'bold'
    }
  ]
});

export const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  sectionTitle: {
    marginLeft: 30
  },
  sectionSubtitle: {
    fontSize: 10,
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },
  sectionSubtitleNormal: {
    fontSize: 10,
    fontFamily: 'Roboto',
    marginTop: 5
  },
  section1: {
    margin: 30,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '14px'
  },
  section3: {
    marginLeft: 30
  },
  section4: {
    marginTop: 20
  },
  image: {
    maxWidth: '100px',
    height: 'auto'
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    color: 'grey',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    borderBottom: '2px solid #ff9933'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
  headerBlue: {
    color: '#5787ad',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    flexGrow: 2,
    marginLeft: '80px',
    marginTop: '-5px'
  },
  title: {
    color: '#000',
    fontFamily: 'Roboto',
    fontSize: '14px',
    textAlign: 'center',
    marginTop: '10px'
  },
  topSubTitle: {
    color: '#000',
    fontFamily: 'Roboto',
    fontSize: '12px',
    textAlign: 'right'
  },
  tableHeader: {
    color: '#fff',
    backgroundColor: '#0054a4',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '11px',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1,
    borderBottomWidth: 0,
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center'
  },
  tableHeaderText: {
    padding: '5px',
    alignSelf: 'flex-start'
  },
  table: {
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  tableColHeader: {
    width: '25%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    width: '20%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol50: {
    width: '50%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColSpan3: {
    width: '75%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColSpan4: {
    width: '80%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: 'center'
  },
  tableColSpan60: {
    width: '60%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColSpan40: {
    width: '40%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: 'auto',
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    fontFamily: 'Roboto'
  },
  tableCellUnderline: {
    margin: 5,
    fontSize: 10,
    textDecoration: 'underline',
    textUnderlineOffset: '20px',
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },
  tableCellBold: {
    margin: 5,
    fontSize: 10,
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },
  thumbnail: {
    maxWidth: '100px',
    height: 'auto',
    padding: 10
  }
});
