const incomeStatusTableRows = {
  general: [
    {
      name: 'programePNCDI2',
      label: 'program.incomeStatus.table.rows.PNCDI2',
      subRows: [
        {
          name: 'p1',
          label: 'program.incomeStatus.table.rows.PNCDI2.P1'
        },
        {
          name: 'p3',
          label: 'program.incomeStatus.table.rows.PNCDI2.P3'
        },
        {
          name: 'p4',
          label: 'program.incomeStatus.table.rows.PNCDI2.P4'
        },
        {
          name: 'p5',
          label: 'program.incomeStatus.table.rows.PNCDI2.P5'
        }
      ],
      itemToAdd: 'Program'
    },
    {
      name: 'programePNCDI3',
      label: 'program.incomeStatus.table.rows.PNCDI3',
      subRows: [
        {
          name: 'p1',
          label: 'program.incomeStatus.table.rows.PNCDI3.P1'
        },
        {
          name: 'p2',
          label: 'program.incomeStatus.table.rows.PNCDI3.P2'
        },
        {
          name: 'p3',
          label: 'program.incomeStatus.table.rows.PNCDI3.P3'
        },
        {
          name: 'p4',
          label: 'program.incomeStatus.table.rows.PNCDI3.P4'
        },
        {
          name: 'p5',
          label: 'program.incomeStatus.table.rows.PNCDI3.P5'
        }
      ]
    },
    {
      name: 'programeSectoriale',
      label: 'program.incomeStatus.table.rows.ProgrameSectoriale',
      subRows: [
        {
          name: 'aiMci',
          label: 'program.incomeStatus.table.rows.ProgrameSectoriale.AiMci'
        }
      ],
      itemToAdd: 'Program'
    },
    {
      name: 'programNucleu',
      label: 'program.incomeStatus.table.rows.ProgramNucleu'
    },
    {
      name: 'instalatiiInteresNational',
      label: 'program.incomeStatus.table.rows.InstalatiiInteresNational'
    },
    {
      name: 'investitii',
      label: 'program.incomeStatus.table.rows.Investitii'
    },
    {
      name: 'fonduriEuropene',
      label: 'program.incomeStatus.table.rows.FonduriEuropene',
      subRows: [
        {
          name: 'posce',
          label: 'program.incomeStatus.table.rows.FonduriEuropene.POSCE'
        },
        {
          name: 'poc',
          label: 'program.incomeStatus.table.rows.FonduriEuropene.POC'
        },
        {
          name: 'eea',
          label: 'program.incomeStatus.table.rows.FonduriEuropene.EEA'
        }
      ],
      itemToAdd: 'Fond'
    },
    {
      name: 'programeInternationale',
      label: 'program.incomeStatus.table.rows.ProgrameInternationale',
      subRows: [
        {
          name: 'fp7',
          label: 'program.incomeStatus.table.rows.ProgrameInternationale.FP7'
        },
        {
          name: 'horizont2020',
          label: 'program.incomeStatus.table.rows.ProgrameInternationale.Horizont2020'
        }
      ],
      itemToAdd: 'Program'
    }
  ],
  total: [
    {
      name: 'total1',
      label: 'program.incomeStatus.table.rows.Total1',
      subRows: [
        {
          name: 'contracteCDStrain',
          label: 'program.incomeStatus.table.rows.Total1.ContracteCDStraine'
        },
        {
          name: 'contracteCDRomane',
          label: 'program.incomeStatus.table.rows.Total1.ContracteCDRomane'
        }
      ],
      ommitContract: true
    },
    {
      name: 'total2',
      label: 'program.incomeStatus.table.rows.Total2',
      ommitContract: true
    },
    {
      name: 'total',
      label: 'program.incomeStatus.table.rows.Total',
      ommitContract: true
    }
  ]
};

export default incomeStatusTableRows;
