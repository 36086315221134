import { lowerFirst } from 'lodash';
import projectTechnicalSheetFormModel from './projectTechnicalSheetFormModel';
import { PROJECT_EVALUATION_STATUS } from '../../utils/enums';

const {
  projectTechnicalSheetField: { projectTechnicalSheet }
} = projectTechnicalSheetFormModel;

export const getInitialProjectTechnicalSheetFormValues = (project) => {
  let sheetData = {};
  if ('fisaTehnicaAsignata' in project) {
    if (project.fisaTehnicaAsignata.statusFisaEvaluare === PROJECT_EVALUATION_STATUS.EVALUATING) {
      sheetData = project.fisaTehnicaAsignata;
    } else if ('fisaFinalaVizibilaModerator' in project) {
      sheetData = {
        [lowerFirst(projectTechnicalSheet.criteria.name)]: project.fisaFinalaVizibilaModerator[
          lowerFirst(projectTechnicalSheet.criteria.name)
        ].map((criteria, index) => {
          let value = 0;
          let comments = '';
          if (
            criteria[lowerFirst(projectTechnicalSheet.criteria.value.name)] &&
            criteria[lowerFirst(projectTechnicalSheet.criteria.value.name)] > 0
          ) {
            value = criteria[lowerFirst(projectTechnicalSheet.criteria.value.name)];
            comments = criteria[lowerFirst(projectTechnicalSheet.criteria.comments.name)];
          } else {
            project.fiseTehniceVizibileModerator.forEach((sheet, indexSheet) => {
              value +=
                sheet[lowerFirst(projectTechnicalSheet.criteria.name)][index][
                  lowerFirst(projectTechnicalSheet.criteria.value.name)
                ];
              comments += `Comentariu ${indexSheet + 1}: ${
                sheet[lowerFirst(projectTechnicalSheet.criteria.name)][index][
                  lowerFirst(projectTechnicalSheet.criteria.comments.name)
                ]
              }\n`;
            });
            value = (value / 3).toFixed(2);
          }
          return {
            [lowerFirst(projectTechnicalSheet.criteria.criteria.name)]:
              criteria[lowerFirst(projectTechnicalSheet.criteria.criteria.name)],
            [lowerFirst(projectTechnicalSheet.criteria.maxValue.name)]:
              criteria[lowerFirst(projectTechnicalSheet.criteria.maxValue.name)],
            [lowerFirst(projectTechnicalSheet.criteria.value.name)]: value,
            [lowerFirst(projectTechnicalSheet.criteria.comments.name)]: comments
          };
        })
      };
    } else if (
      project.fisaTehnicaAsignata.statusFisaEvaluare === PROJECT_EVALUATION_STATUS.SUBMITTED
    ) {
      sheetData = project.fisaTehnicaAsignata;
    }
  } else if ('fisaFinala' in project) {
    sheetData = project.fisaFinala;
  }

  return {
    [projectTechnicalSheet.name]: {
      [projectTechnicalSheet.criteria.name]: sheetData.criterii
        ? sheetData.criterii.map((criteriu) => ({
            [projectTechnicalSheet.criteria.criteria.name]: criteriu.denumire,
            [projectTechnicalSheet.criteria.value.name]: criteriu.scor,
            [projectTechnicalSheet.criteria.maxValue.name]: criteriu.scorMaxim,
            [projectTechnicalSheet.criteria.comments.name]: criteriu.comentariu
          }))
        : []
    },
    contestationFile: project.notaDeContestare
      ? { ...project.notaDeContestare, numeFisierOriginal: 'Contestatie' }
      : null
  };
};
