import * as React from 'react';
import { Stack, useTheme, Backdrop, CircularProgress } from '@mui/material/';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Document from './Document';
import FileUploadField from './FileUploadField';
import ListFilter from './ListFilter';
import { documentActions } from '../actions';

export default function SupportingDocuments({ disabled, hasFiltering, isAdmin = false }) {
  const [currentDocuments, setCurrentDocuments] = React.useState([]);
  const [sort, setSort] = React.useState('ascending');
  const [searchText, setSearchText] = React.useState('');
  const documents = useSelector((state) => state.documents);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useTheme();

  useEffect(() => {
    if (documents.documents) {
      filterDocuments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  useEffect(() => {
    if (isAdmin) {
      dispatch(documentActions.getAllAdmin());
    } else {
      dispatch(documentActions.getAll(user.info.institutieParinte));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    filterDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, searchText]);

  const filterDocuments = () => {
    setCurrentDocuments(
      documents.documents
        .filter((document) =>
          document.numeFisierOriginal.toLowerCase().includes(searchText.toLowerCase())
        )
        .sort((document1, document2) =>
          sort === 'ascending'
            ? document1.numeFisierOriginal
                .toLowerCase()
                .localeCompare(document2.numeFisierOriginal.toLowerCase())
            : document2.numeFisierOriginal
                .toLowerCase()
                .localeCompare(document1.numeFisierOriginal.toLowerCase())
        )
    );
  };

  if (documents?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <div>
      {disabled ? (
        ''
      ) : (
        <FileUploadField
          placeholder={intl.formatMessage({ id: 'page.applicant.documents.choose' })}
          isAdmin={isAdmin}
        />
      )}
      <Stack
        spacing={2}
        sx={{
          marginTop: 5,
          borderRadius: '10px',
          border: `1px solid ${theme.palette.grey[400]}`,
          padding: '10px'
        }}
      >
        {hasFiltering && (
          <ListFilter
            sort={sort}
            setSort={setSort}
            searchText={searchText}
            setSearchText={setSearchText}
            sortField={intl.formatMessage({ id: 'global.name' })}
          />
        )}
        {currentDocuments.map((document) => (
          <Document
            key={document.fisierUrl}
            document={document}
            disabled={disabled}
            isAdmin={isAdmin}
          />
        ))}
      </Stack>
    </div>
  );
}
