import { Page, Text, View, Document } from '@react-pdf/renderer';
import { styles } from '../utils/pdfStyles';

export default function ProgramEvaluationAnexa25({
  fise,
  programeTitle,
  totalProgram,
  projectsScore,
  passedProjectsScore,
  evaluatorsInfo,
  intl
}) {
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.topSubTitle}>{intl.formatMessage({ id: 'global.pdf.anexa.2.5' })}</Text>
        <Text style={styles.title}>{intl.formatMessage({ id: 'global.pdf.title' })}</Text>

        <View style={styles.section1}>
          <Text style={styles.sectionTitle}>
            {intl.formatMessage({ id: 'global.pdf.section1.title' })}
          </Text>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell} />
            </View>
            {fise.map((fisa) => (
              <View style={styles.tableCol} key={fisa.userId}>
                <Text style={styles.tableCell}>
                  {`${intl.formatMessage({ id: 'global.score2' })} ${
                    evaluatorsInfo.find((e) => e.userId === fisa.userId)?.username
                  }`}
                </Text>
              </View>
            ))}
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {intl.formatMessage({ id: 'global.pdf.section1.header4' })}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{programeTitle}</Text>
            </View>
            {fise.map((fisa) => (
              <View style={styles.tableCol} key={fisa.userId}>
                <Text style={styles.tableCell}>
                  {fisa.criterii
                    .reduce((accumulator, criteriu) => accumulator + criteriu.scor, 0)
                    .toFixed(2)}
                </Text>
              </View>
            ))}
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{totalProgram}</Text>
            </View>
          </View>
        </View>

        <View style={styles.section1}>
          <Text style={styles.sectionTitle}>
            {intl.formatMessage({ id: 'global.pdf.section2.title' })}
          </Text>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell} />
            </View>
            {fise.map((fisa) => (
              <View style={styles.tableCol} key={fisa.userId}>
                <Text style={styles.tableCell}>
                  {`${intl.formatMessage({ id: 'global.score2' })} ${
                    evaluatorsInfo.find((e) => e.userId === fisa.userId)?.username
                  }`}
                </Text>
              </View>
            ))}
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {intl.formatMessage({ id: 'global.pdf.section2.header4' })}
              </Text>
            </View>
          </View>
          {projectsScore.map((projectScore) => (
            <View style={styles.tableRow} key={projectScore.projectId}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{projectScore.projectTitle}</Text>
              </View>
              {projectScore.evaluatorsScore.map((evaluatorScore) => (
                <View style={styles.tableCol} key={evaluatorScore.userId}>
                  <Text style={styles.tableCell}>{evaluatorScore.score}</Text>
                </View>
              ))}
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {(
                    projectScore.evaluatorsScore.reduce(
                      (accumulator, evaluatorScore) => accumulator + evaluatorScore.score,
                      0
                    ) / 3
                  ).toFixed(2)}
                </Text>
              </View>
            </View>
          ))}
          <View style={styles.tableRow}>
            <View style={styles.tableColSpan4}>
              <Text style={styles.tableCell}>
                {intl.formatMessage({ id: 'global.pdf.section2.last_row' })}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {(passedProjectsScore.length > 0
                  ? passedProjectsScore
                      .map(
                        (projectScore) =>
                          projectScore.evaluatorsScore.reduce(
                            (accumulator, evaluatorScore) => accumulator + evaluatorScore.score,
                            0
                          ) / 3
                      )
                      .reduce((accumulator, score) => accumulator + score, 0) /
                    passedProjectsScore.length
                  : 0
                ).toFixed(2)}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.sectionSubtitle}>
          <Text>{intl.formatMessage({ id: 'global.pdf.section.subtitle' })}</Text>
        </View>

        <View style={styles.section1}>
          <Text style={styles.sectionTitle}>
            {intl.formatMessage({ id: 'global.pdf.section3.title' })}
          </Text>
        </View>

        <View style={styles.section3}>
          <Text style={styles.sectionSubtitle}>
            {`${intl.formatMessage({ id: 'global.score.final' })} ${
              0.5 * totalProgram +
              0.5 *
                (passedProjectsScore.length > 0
                  ? (
                      passedProjectsScore
                        .map(
                          (projectScore) =>
                            projectScore.evaluatorsScore.reduce(
                              (accumulator, evaluatorScore) => accumulator + evaluatorScore.score,
                              0
                            ) / 3
                        )
                        .reduce((accumulator, score) => accumulator + score, 0) /
                      passedProjectsScore.length
                    ).toFixed(2)
                  : 0)
            }`}
          </Text>
        </View>

        <View style={styles.section3}>
          <Text style={styles.sectionSubtitleNormal}>
            {intl.formatMessage({ id: 'global.pdf.section3.observation' })}
          </Text>
        </View>

        <View style={styles.section4}>
          <Text style={styles.sectionSubtitle}>{intl.formatMessage({ id: 'global.date' })}:</Text>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol50}>
              <Text style={styles.tableCell}>{intl.formatMessage({ id: 'global.full_name' })}</Text>
            </View>
            <View style={styles.tableCol50}>
              <Text style={styles.tableCell}>{intl.formatMessage({ id: 'global.signature' })}</Text>
            </View>
          </View>
          {evaluatorsInfo.map((evaluator) => (
            <View style={styles.tableRow} key={evaluator.userId}>
              <View style={styles.tableCol50}>
                <Text style={styles.tableCell}>{evaluator.username}</Text>
              </View>
              <View style={styles.tableCol50}>
                <Text style={styles.tableCell} />
              </View>
            </View>
          ))}
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
}
