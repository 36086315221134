import React, { useCallback, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { useIntl } from 'react-intl';

export default function Toaster() {
  const toaster = useSelector((state) => state.toaster);
  const intl = useIntl();

  const toasterCallback = useCallback(() => {
    if (toaster.type !== undefined) {
      const title =
        'values' in toaster
          ? intl.formatMessage({ id: toaster.title }, toaster.values)
          : intl.formatMessage({ id: toaster.title });
      toast(`${title}${toaster.message ? `: ${toaster.message}` : ''}`, {
        type: toaster.type.toLowerCase()
      });
    }
  }, [toaster, intl]);

  useEffect(() => {
    if (toaster.title) {
      toasterCallback();
    }
  }, [toasterCallback, toaster.title]);

  return <ToastContainer hideProgressBar autoClose={5000} />;
}
