import { useIntl } from 'react-intl';
import { Backdrop, CircularProgress, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import Page from '../components/Page';
import { institutionActions, programActions } from '../actions';
import CardList from '../components/lists/CardList';
import InstitutionProgramCard from '../components/cards/InstitutionProgramCard';
// ----------------------------------------------------------------------

export default function InstitutionPrograms() {
  const intl = useIntl();
  // useSelector extracts  data from the institutions state
  const institutions = useSelector((state) => state.institutions);
  // useSelector extracts  data from the programs state
  const programs = useSelector((state) => state.programs);
  // useSelector extracts  data from the user state
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [sort, setSort] = useState('ascending'); // set initial State to ascending for sort, setSort
  const [searchText, setSearchText] = useState(''); // set initial State to '' for searchText, setSearchText
  const [programItems, setProgramItems] = useState([]); // set initial State empty Array for programItems, setProgramItems

  useEffect(() => {
    dispatch(institutionActions.getInfo(user.info.institutieParinte));
    dispatch(programActions.getMyPrograms(user.info.institutieParinte));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // order programs by denumireProgramNucleu
  useEffect(() => {
    if (programs && programs.programs && programs.programs.length > 0) {
      setProgramItems(
        programs.programs.sort((p1, p2) =>
          // eslint-disable-next-line no-nested-ternary
          p1.detaliiProgram.denumireProgramNucleu
            ? p2.detaliiProgram.denumireProgramNucleu
              ? p1.detaliiProgram.denumireProgramNucleu.localeCompare(
                  p2.detaliiProgram.denumireProgramNucleu
                )
              : -1
            : p1.detaliiProgram.denumireProgramNucleu
            ? 1
            : 0
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programs]);

  // filter programs with searchText in denumireProgramNucleu
  useEffect(() => {
    let filteredPrograms = [];
    if (programs && programs.programs) {
      if (searchText && searchText.length > 0) {
        filteredPrograms = programs.programs.filter((program) =>
          program.detaliiProgram.denumireProgramNucleu
            .toLowerCase()
            .includes(searchText.toLowerCase())
        );
      } else {
        filteredPrograms = programs.programs;
      }
    }

    // sort filtered programs by denumireProgramNucleu
    filteredPrograms = filteredPrograms.sort((p1, p2) =>
      sort === 'ascending'
        ? p1.detaliiProgram.denumireProgramNucleu
            .toLowerCase()
            .localeCompare(p2.detaliiProgram.denumireProgramNucleu.toLowerCase())
        : p2.detaliiProgram.denumireProgramNucleu
            .toLowerCase()
            .localeCompare(p1.detaliiProgram.denumireProgramNucleu.toLowerCase())
    );
    setProgramItems([...filteredPrograms]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, searchText]);

  // display CircularProgress on institutions loading
  if (institutions?.loading || programs?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  // display institution programs form
  return (
    <Page title={intl.formatMessage({ id: 'institution.programmes' })} my={2}>
      <Container>
        {programItems && (
          <CardList
            entityId="programGuid"
            sort={sort}
            setSort={setSort}
            searchText={searchText}
            setSearchText={setSearchText}
            sortFieldTitle={intl.formatMessage({ id: 'global.name' })}
            items={programItems}
            hasCompetitions
            Card={InstitutionProgramCard}
          />
        )}
      </Container>
    </Page>
  );
}
