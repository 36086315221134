// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { camelizeKeys } from 'humps';
import { authHeader } from '../utils/auth-header';
import { env } from '../utils/config';

export const userService = {
  login,
  logout,
  getCurrentUserInfo,
  getUserInfo,
  getUserBusinessInfo,
  updateUser,
  getAll,
  getAllAdmin,
  addUser,
  addInstitutionAdmin,
  addProjectApplicant,
  deleteUser,
  requestPasswordReset,
  resetPassword
};

let timerId;

function login(userCredentials, isAdmin) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userCredentials)
  };

  const path = isAdmin
    ? `${env.REACT_APP_SERVER_IDENTITY_BASE_PATH}${env.REACT_APP_SERVER_PLATFORM_ADMINISTRATOR_BASE_PATH}/login`
    : `${env.REACT_APP_SERVER_IDENTITY_BASE_PATH}${env.REACT_APP_SERVER_USERS_BASE_PATH}/login`;
  return fetch(path, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // login successful if there's a jwt token in the response
      const data = jwt_decode(user.accessToken);
      if (user.accessToken) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        data.accessToken = user.accessToken;
        localStorage.setItem('user', JSON.stringify(data));
        renewToken(data, user.refreshToken, isAdmin);
      }

      return data;
    });
}

function requestPasswordReset(email) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email })
  };

  return fetch(
    `${env.REACT_APP_SERVER_IDENTITY_BASE_PATH}${env.REACT_APP_SERVER_USERS_BASE_PATH}/resetPasswordLink`,
    requestOptions
  ).then(handleResponse);
}

function resetPassword(password, token) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ password, token })
  };

  return fetch(
    `${env.REACT_APP_SERVER_IDENTITY_BASE_PATH}${env.REACT_APP_SERVER_USERS_BASE_PATH}/resetPassword`,
    requestOptions
  ).then(handleResponse);
}

function renewToken(data, refreshToken, isAdmin) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ refreshToken })
  };
  // set timer to refresh token when it expires
  timerId = setTimeout(() => {
    const userPath = isAdmin
      ? env.REACT_APP_SERVER_PLATFORM_ADMINISTRATOR_BASE_PATH
      : env.REACT_APP_SERVER_USERS_BASE_PATH;
    fetch(`${env.REACT_APP_SERVER_IDENTITY_BASE_PATH}${userPath}/renewtoken`, requestOptions)
      .then(handleResponse)
      .then((user) => {
        // login successful if there's a jwt token in the response
        const cachedUser = JSON.parse(localStorage.getItem('user'));
        cachedUser.accessToken = user.accessToken;
        localStorage.setItem('user', JSON.stringify(cachedUser));
        renewToken(jwt_decode(user.accessToken), user.refreshToken, isAdmin);
      });
  }, (data.exp - data.iat - 60) * 1000);
}

function logout(isAdmin) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({})
  };

  const path = isAdmin
    ? `${env.REACT_APP_SERVER_IDENTITY_BASE_PATH}${env.REACT_APP_SERVER_PLATFORM_ADMINISTRATOR_BASE_PATH}/logout`
    : `${env.REACT_APP_SERVER_IDENTITY_BASE_PATH}${env.REACT_APP_SERVER_USERS_BASE_PATH}/logout`;

  return fetch(path, requestOptions)
    .then(handleResponse)
    .then(() => {
      // remove user from local storage to log user out
      clearInterval(timerId);
      localStorage.removeItem('user');
    });
}

function getCurrentUserInfo(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(
    `${env.REACT_APP_SERVER_IDENTITY_BASE_PATH}${env.REACT_APP_SERVER_USERS_BASE_PATH}/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => parseUser(user));
}

function getUserInfo(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(
    `${env.REACT_APP_SERVER_IDENTITY_BASE_PATH}${env.REACT_APP_SERVER_USERS_BASE_PATH}/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getUserBusinessInfo() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_COMMON_BASE_PATH}/VizualizeazaDetaliiProprii`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => {
      user.id = user.userId;
      delete user.userId;
      return parseUser(user);
    });
}

function updateUser(user, isCurrent) {
  // todo: remove address when backend ready
  delete user.InstitutieInAdministrareGuid;
  const userData = { ...user };
  delete userData.id;
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(userData)
  };

  return fetch(
    `${env.REACT_APP_SERVER_IDENTITY_BASE_PATH}${env.REACT_APP_SERVER_USERS_BASE_PATH}/update/${user.id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => (isCurrent ? parseUser(user) : user));
}

function addUser(user) {
  delete user.InstitutieInAdministrareGuid;
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...user, address: '' })
  };

  return fetch(
    `${env.REACT_APP_SERVER_IDENTITY_BASE_PATH}${env.REACT_APP_SERVER_USERS_BASE_PATH}/register`,
    requestOptions
  ).then(handleResponse);
}

function addInstitutionAdmin(userId, institutionId) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ UserGuid: userId, InstitutieInAdministrareGuid: institutionId })
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/AdaugareAdministratorInstitutie`,
    requestOptions
  ).then(handleResponse);
}

function addProjectApplicant(userId, institutionId) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ UserGuid: userId, InstitutionGuid: institutionId })
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/AdaugaAplicantProiect/${institutionId}`,
    requestOptions
  ).then(handleResponse);
}

function getAll(isAdmin, idInstitutie) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  let path;
  if (isAdmin) {
    path = `${env.REACT_APP_SERVER_IDENTITY_BASE_PATH}${env.REACT_APP_SERVER_PLATFORM_ADMINISTRATOR_BASE_PATH}/GetAllUsers`;
  } else {
    path = `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Institutie/${idInstitutie}/Membri`;
  }

  return fetch(path, requestOptions).then(handleResponse);
}

function getAllAdmin() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/GetAllUsers`,
    requestOptions
  ).then(handleResponse);
}

function deleteUser(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };
  return fetch(
    `${env.REACT_APP_SERVER_IDENTITY_BASE_PATH}${env.REACT_APP_SERVER_USERS_BASE_PATH}/delete/${id}`,
    requestOptions
  ).then(handleResponse);
}

function parseUser(user) {
  const cachedUser = { ...JSON.parse(localStorage.getItem('user')), ...user };
  localStorage.setItem('user', JSON.stringify(cachedUser));
  return cachedUser;
}

function handleResponse(response) {
  return response.text().then((text) => {
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem('user');
        window.location.reload(true);
      }
      return Promise.reject(JSON.parse(text));
    }

    const data = text && JSON.parse(text);

    if (data) {
      return camelizeKeys(data);
    }

    return text;
  });
}
