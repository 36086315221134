import * as React from 'react';
import { useIntl } from 'react-intl';
import { InputLabel, Select, MenuItem, Grid, TextField, FormControl } from '@mui/material';

// ----------------------------------------------------------------------

export default function ListFilter({ sortField, sort, setSort, searchText, setSearchText }) {
  const intl = useIntl();

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <Grid container spacing={3} sx={{ marginBottom: '1rem' }}>
      <Grid item xs={12} sm={6}>
        <TextField
          id="name"
          label={intl.formatMessage({ id: 'list_filter.name.label' })}
          value={searchText}
          variant="outlined"
          onChange={handleSearchTextChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="sort-label">
            {intl.formatMessage({ id: 'list_filter.sort.label' }, { field: sortField })}
          </InputLabel>
          <Select
            labelId="sort-label"
            label={intl.formatMessage({ id: 'list_filter.sort.label' }, { field: sortField })}
            id="sort-select"
            value={sort}
            onChange={handleSortChange}
            fullWidth
          >
            <MenuItem value="ascending">
              {intl.formatMessage({ id: 'list_filter.sort.options.ascending' })}
            </MenuItem>
            <MenuItem value="descending">
              {intl.formatMessage({ id: 'list_filter.sort.options.descending' })}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}
