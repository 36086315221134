import { institutionConstants } from '../constants';
import { triggerError, triggerSuccess } from '../utils/toasterTriggers';
import { institutionService } from '../services';

export const institutionActions = {
  getAll,
  getInfo,
  addInstitution,
  updateInstitution
};

function getAll() {
  return (dispatch) => {
    dispatch(request());
    institutionService.getAll().then(
      (institutions) => {
        dispatch(success(institutions));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: institutionConstants.GET_INSTITUTIONS_REQUEST };
  }
  function success(institutions) {
    return { type: institutionConstants.GET_INSTITUTIONS_SUCCESS, institutions };
  }
  function failure(error) {
    return { type: institutionConstants.GET_INSTITUTIONS_FAILURE, error };
  }
}

function getInfo(id) {
  return (dispatch) => {
    dispatch(request());
    institutionService.getInfo(id).then(
      (institution) => {
        dispatch(success(institution));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: institutionConstants.GET_INSTITUTION_REQUEST };
  }
  function success(institution) {
    return { type: institutionConstants.GET_INSTITUTION_SUCCESS, institution };
  }
  function failure(error) {
    return { type: institutionConstants.GET_INSTITUTION_FAILURE, error };
  }
}

function addInstitution(institution, navigate) {
  return (dispatch) => {
    dispatch(request());
    institutionService.addInstitution(institution).then(
      (institution) => {
        dispatch(success(institution));
        navigate('../../institutii');
        triggerSuccess('', 'toast.success.institution.add');
      },
      (error) => {
        dispatch(failure(JSON.stringify(error)));
        triggerError('', error.errorMessage);
      }
    );
  };

  function request() {
    return { type: institutionConstants.INSTITUTION_ADD_REQUEST };
  }
  function success(institution) {
    return { type: institutionConstants.INSTITUTION_ADD_SUCCESS, institution };
  }
  function failure(error) {
    return { type: institutionConstants.INSTITUTION_ADD_FAILURE, error };
  }
}

function updateInstitution(institution, navigate = null) {
  return (dispatch) => {
    dispatch(request());
    institutionService.updateInstitution(institution).then(
      (institution) => {
        if (navigate) {
          navigate('../../institutii');
        }
        dispatch(success(institution));
        triggerSuccess('', 'toast.success.institution.update');
      },
      (error) => {
        dispatch(failure(JSON.stringify(error)));
        triggerError('', error.errorMessage);
      }
    );
  };

  function request() {
    return { type: institutionConstants.INSTITUTION_UPDATE_REQUEST };
  }
  function success(institution) {
    return { type: institutionConstants.INSTITUTION_UPDATE_SUCCESS, institution };
  }
  function failure(error) {
    return { type: institutionConstants.INSTITUTION_UPDATE_FAILURE, error };
  }
}
