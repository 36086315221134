import { useIntl } from 'react-intl';
import { Backdrop, Box, CircularProgress, Container, Grid, Input, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Page from '../components/Page';
import CardList from '../components/lists/CardList';
import { monitoringActions } from '../actions';
import InstitutionProgramMonitorCycleProjectPhaseCard from '../components/cards/InstitutionProgramMonitorCycleProjectPhaseCard';
import ColorButton from '../components/common/ColorButton';
import { ACTIONS, ENTITIES, MONITORING_STATUS } from '../utils/enums';
// ----------------------------------------------------------------------

const FileInputStyle = styled(Input)(() => ({
  display: 'none'
}));

export default function InstitutionProgramMonitoringCycleProjectPhases() {
  const { idProgram, idCiclu, idProiect } = useParams();
  const intl = useIntl();

  const [sort, setSort] = useState('ascending');
  const [searchText, setSearchText] = useState('');
  const [phaseItems, setPhaseItems] = useState([]);
  const [notaJustificativaFile, setNotaJustificativaFile] = useState(null);
  const monitoring = useSelector((state) => state.monitoring);
  const [phaseSelection, setPhaseSelection] = useState(false);
  const [selectedPhases, setSelectedPhases] = useState([]);
  const [selectedProject, setSelectedProject] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(monitoringActions.getProgramCycleProjects(idProgram, idCiclu));
    dispatch(monitoringActions.getProgramCycleProjectPhases(idCiclu, idProiect));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (monitoring && monitoring.phases && monitoring.phases.length > 0) {
      const phases = [...monitoring.phases].sort((p1, p2) => p1.titlu.localeCompare(p2.titlu));
      setPhaseItems(phases);
      if (monitoring.notaJustificativa) {
        setNotaJustificativaFile({ name: monitoring.notaJustificativa.nota.numeFisierOriginal });
      }
    }

    if (monitoring && monitoring.projects && monitoring.projects.length > 0) {
      setSelectedProject(monitoring.projects.find((project) => project.proiectGuid === +idProiect));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitoring]);

  useEffect(() => {
    let filteredPhases = [];
    if (monitoring && monitoring.phases && monitoring.phases.length > 0) {
      if (searchText && searchText.length > 0) {
        filteredPhases = [...monitoring.phases].filter((project) =>
          project.titlu.toLowerCase().includes(searchText.toLowerCase())
        );
      } else {
        filteredPhases = [...monitoring.phases];
      }
    }

    filteredPhases = filteredPhases.sort((p1, p2) =>
      sort === 'ascending'
        ? p1.titlu.toLowerCase().localeCompare(p2.titlu.toLowerCase())
        : p2.titlu.toLowerCase().localeCompare(p1.titlu.toLowerCase())
    );

    setPhaseItems([...filteredPhases]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, searchText]);

  const handleCheckboxChange = (phaseId) => {
    setSelectedPhases(
      selectedPhases.map((phase) => {
        if (phase.id === phaseId) {
          phase.selected = !phase.selected;
        }
        return phase;
      })
    );
  };

  const handleMonitoringSubmit = () => {
    if (notaJustificativaFile) {
      const formData = new FormData();
      formData.append('notaJustificativa', notaJustificativaFile);
      formData.append('ProiectGuid', idProiect);
      formData.append(
        'FazeAsociate',
        `[${selectedPhases
          .filter((phase) => phase.selected)
          .map((phase) => phase.id)
          .join(',')}]`
      );
      dispatch(monitoringActions.submitMonitoringPhases(idProgram, formData));
      setPhaseSelection(false);
    }
  };

  if (monitoring?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Page title={intl.formatMessage({ id: 'program.projects.title' })} my={2}>
      <Container>
        {phaseItems && selectedProject && (
          <>
            <Box
              sx={{ mb: 2 }}
              height="100%"
              display="flex"
              justifyContent="flex-end"
              flexDirection="row"
            >
              {!phaseSelection && (
                <>
                  <ColorButton
                    variant="contained"
                    borderColor="#005db7"
                    labelColor="#fff"
                    component="span"
                    sx={{ mr: 2 }}
                    onClick={() =>
                      navigate(
                        `/${
                          user.abilities.can(ACTIONS.VIEW, ENTITIES.INSTITUTION)
                            ? 'institutie'
                            : 'aplicant'
                        }/programe/${idProgram}/proiect/${idProiect}/faze`
                      )
                    }
                  >
                    {intl.formatMessage({ id: 'institution.monitoring.project.phases.manager' })}
                  </ColorButton>
                  <ColorButton
                    variant="contained"
                    borderColor="#005db7"
                    labelColor="#fff"
                    component="span"
                    disabled={
                      selectedProject.statusMonitorizare === MONITORING_STATUS.EVALUATING ||
                      selectedProject.statusMonitorizare === MONITORING_STATUS.ACCEPTED
                    }
                    onClick={() => {
                      setPhaseSelection(true);
                      setSelectedPhases(
                        phaseItems.map((phase) => ({
                          id: phase.fazaGuid,
                          selected: true,
                          submitted: phase.submisCatreMonitorizare
                        }))
                      );
                    }}
                  >
                    {intl.formatMessage({ id: 'institution.monitoring.phases.selection' })}
                  </ColorButton>
                </>
              )}
              {phaseSelection && (
                <>
                  <ColorButton
                    variant="contained"
                    borderColor="#005db7"
                    labelColor="#fff"
                    component="span"
                    sx={{ mr: 2 }}
                    onClick={() => setPhaseSelection(false)}
                  >
                    {intl.formatMessage({ id: 'global.cancel' })}
                  </ColorButton>
                  <ColorButton
                    variant="contained"
                    borderColor="#005db7"
                    labelColor="#fff"
                    component="span"
                    onClick={handleMonitoringSubmit}
                  >
                    {intl.formatMessage({ id: 'global.send.monitoring' })}
                  </ColorButton>
                </>
              )}
            </Box>
            {phaseSelection && (
              <Grid container sx={{ mb: 2 }}>
                <Grid item xs={9}>
                  <TextField
                    id="nota-justificativa"
                    label={intl.formatMessage({ id: 'global.nota_justificativa' })}
                    value={
                      // eslint-disable-next-line no-nested-ternary
                      notaJustificativaFile ? notaJustificativaFile.name : ''
                    }
                    variant="outlined"
                    disabled
                    fullWidth
                    error={!notaJustificativaFile}
                    helperText={
                      !notaJustificativaFile &&
                      intl.formatMessage({
                        id: 'form.program.contractor.error.required'
                      })
                    }
                  />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={2}>
                  <FileInputStyle
                    type="file"
                    name="notajustificativa"
                    id="notajustificativa"
                    onChange={(event) => {
                      setNotaJustificativaFile(event.target.files[0]);
                    }}
                  />
                  <label htmlFor="notajustificativa">
                    <Box
                      height="100%"
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <ColorButton
                        variant="outlined"
                        borderColor="#005db7"
                        labelColor="#005db7"
                        component="span"
                      >
                        {intl.formatMessage({ id: 'global.upload' })}
                      </ColorButton>
                    </Box>
                  </label>
                </Grid>
              </Grid>
            )}
            <CardList
              entityId="fazaGuid"
              sort={sort}
              setSort={setSort}
              searchText={searchText}
              setSearchText={setSearchText}
              sortFieldTitle={intl.formatMessage({ id: 'global.name' })}
              items={phaseItems}
              Card={InstitutionProgramMonitorCycleProjectPhaseCard}
              cardProps={{
                phaseSelection,
                selectedPhases,
                handleCheckboxChange
              }}
            />
          </>
        )}
      </Container>
    </Page>
  );
}
