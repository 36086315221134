import { useIntl } from 'react-intl';
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography
} from '@mui/material';
// The Effect Hook performs side effects in function components
import { useEffect } from 'react';
// dispatch is used as a callback which gets invoked once some async action is complete.
// useSelector allows you to extract data from the Redux store state, using a selector function.
import { useDispatch, useSelector } from 'react-redux';
// imports everything in the react module
import * as React from 'react';
// useParams hook returns an object of key/value pairs of the dynamic params from the current URL that were matched by the <Route path>
import { useParams } from 'react-router-dom';
// Formik uses useFormik to create the <Formik> component (which renders a React Context Provider).
import { useFormik } from 'formik';
// import page component
import Page from '../components/Page';
// import from actions
import { competitionActions, userActions, programActions } from '../actions';
// import for Autocomplete Form
import EditableFormAutocomplete from '../forms/shared/EditableFormAutocomplete';
import { UserRoles } from '../domain/UserRoles';
import adminProgramEvaluationAssignFormModel from '../forms/programs/adminProgramEvaluationAssign/adminProgramEvaluationAssignFormModel';
import { getInitialAdminProgramEvaluationAssignFormValues } from '../forms/programs/adminProgramEvaluationAssign/formInitialValues';
import { adminProgramEvaluationAssignFormValidationSchema } from '../forms/programs/adminProgramEvaluationAssign/validationSchema';
import { parseAdminProgramEvaluation } from '../forms/programs/adminProgramEvaluationAssign/formOutputParser';
// ----------------------------------------------------------------------

const {
  adminProgramEvaluationAssignField: { responsibles }
} = adminProgramEvaluationAssignFormModel;

export default function AdminCompetitionProgramEvaluationContestation() {
  // useIntl hook: Once you've declared your IntlProvider, you can get access to the intl object via calling this hook in a functional React component
  const intl = useIntl();
  // useSelector extracts  data from the competitions state
  const competitions = useSelector((state) => state.competitions);
  // useSelector extracts  data from the programs state
  const programs = useSelector((state) => state.programs);
  // useSelector extracts  data from the user state
  const user = useSelector((state) => state.user);
  // dispatch is used as a callback which gets invoked once some async action is complete.
  const dispatch = useDispatch();
  // useParams hook returns an object of key/value pairs of the dynamic params from the current URL that were matched by the <Route path>
  const { idCompetition, idProgram } = useParams();

  // The Effect Hook performs side effects in function components:
  // dispatch is used as a callback which gets invoked once some async action is complete.
  useEffect(() => {
    dispatch(competitionActions.getCompetition(idCompetition));
    dispatch(programActions.getProgram(idProgram));
    dispatch(programActions.getProgramEvaluationResponsibles(idProgram, 'contestatie'));
    dispatch(userActions.getAll(user.info.userType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useFormik() is a custom React hook that will return all Formik state and helpers directly.
  const formik = useFormik({
    initialValues: getInitialAdminProgramEvaluationAssignFormValues(
      programs.programEvaluationResponsibles || {}
    ),
    enableReinitialize: true,
    validationSchema: adminProgramEvaluationAssignFormValidationSchema(),
    onSubmit: (values) => {
      const valuesCopy = parseAdminProgramEvaluation(JSON.parse(JSON.stringify(values)), idProgram);
      dispatch(programActions.updateProgramEvaluationResponsibles(valuesCopy, 'contestatie'));
    }
  });

  // display CircularProgress on programs  or competitions loading
  if (competitions?.loading || programs?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  // display Grid with 4 items
  return (
    <Page title={intl.formatMessage({ id: 'institution.competitions' })} my={2}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            {intl.formatMessage({ id: 'page.admin.competitions.program.evaluation.contestation' })}
          </Typography>
        </Stack>
        {competitions.selectedCompetition &&
          programs.selectedProgram &&
          programs.programEvaluationResponsibles &&
          user.users &&
          user.users.data && (
            <Grid container spacing={5}>
              {/* Grid item 1 size 4/12 of viewport */}
              <Grid item xs={4} sm={4}>
                <EditableFormAutocomplete
                  parentName={responsibles.responsible1.name}
                  formik={formik}
                  required
                  label={intl.formatMessage({ id: responsibles.responsible1.label })}
                  variant="outlined"
                  fullWidth
                  options={user.users.data
                    .filter(
                      (userOption) =>
                        !userOption.userType.includes(UserRoles.PlatformAdministrator) &&
                        userOption.userType.includes(UserRoles.EvaluatorContestatieProgram)
                    )
                    .map((userOption) => ({
                      id: userOption.id,
                      label: `${userOption.firstName} ${userOption.lastName}`
                    }))}
                />
              </Grid>
              {/* Grid item 2 size 4/12 of viewport */}
              <Grid item xs={4} sm={4}>
                <EditableFormAutocomplete
                  parentName={responsibles.responsible2.name}
                  formik={formik}
                  required
                  label={intl.formatMessage({ id: responsibles.responsible2.label })}
                  variant="outlined"
                  fullWidth
                  options={user.users.data
                    .filter(
                      (userOption) =>
                        !userOption.userType.includes(UserRoles.PlatformAdministrator) &&
                        userOption.userType.includes(UserRoles.EvaluatorContestatieProgram)
                    )
                    .map((userOption) => ({
                      id: userOption.id,
                      label: `${userOption.firstName} ${userOption.lastName}`
                    }))}
                />
              </Grid>
              {/* Grid item 3 size 4/12 of viewport */}
              <Grid item xs={4} sm={4}>
                <EditableFormAutocomplete
                  parentName={responsibles.moderator.name}
                  formik={formik}
                  required
                  label={intl.formatMessage({ id: responsibles.moderator.label })}
                  variant="outlined"
                  fullWidth
                  options={user.users.data
                    .filter(
                      (userOption) =>
                        !userOption.userType.includes(UserRoles.PlatformAdministrator) &&
                        userOption.userType.includes(UserRoles.EvaluatorContestatieProgram)
                    )
                    .map((userOption) => ({
                      id: userOption.id,
                      label: `${userOption.firstName} ${userOption.lastName}`
                    }))}
                />
              </Grid>
              {/* Grid item 4 size 3/12 of viewport */}
              <Grid item xs={3} sm={3}>
                <Button variant="contained" onClick={() => formik.submitForm()}>
                  {intl.formatMessage({
                    id: 'competitions.project_evaluation.save_responsibles'
                  })}
                </Button>
              </Grid>
            </Grid>
          )}
      </Container>
    </Page>
  );
}
