// eslint-disable-next-line camelcase
import { camelizeKeys } from 'humps';
import FileDownload from 'js-file-download';
import { authHeader } from '../utils/auth-header';
import { env } from '../utils/config';
import { userService } from './index';

export const documentService = {
  getAll,
  getAllAdmin,
  deleteDocument,
  downloadAnexa2,
  deleteDocumentAdmin,
  uploadDocument,
  uploadAnexa2,
  uploadDocumentAdmin,
  uploadActAditionalContractFinantare,
  uploadAcordRealocare,
  downloadDocument,
  downloadDocumentAdmin,
  downloadContestationDocument,
  downloadProgramContestationDocument,
  downloadContractFinantareSemnat,
  downloadActAditionalContractFinantareSemnat,
  downloadAcordRealocare,
  downloadNotaJustificativa,
  downloadAnexaRaport,
  downloadAnexaRaportRaportor,
  downloadProjectResponsibleCV,
  downloadProjectDocument
};

function getAll(idInstitutie) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_APLICANT_PROIECT_BASE_PATH}/Institutie/${idInstitutie}/DocumenteAjutatoare`,
    requestOptions
  ).then(handleResponse);
}

function getAllAdmin() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/DocumenteSuport`,
    requestOptions
  ).then(handleResponse);
}

function deleteDocument(fisierUrl, idInstitutie) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_APLICANT_PROIECT_BASE_PATH}/institutie/${idInstitutie}/stergeDocumentAjutator/${fisierUrl}`,
    requestOptions
  ).then(handleResponse);
}

function deleteDocumentAdmin(fisierUrl) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/StergeDocumentSuport/${fisierUrl}`,
    requestOptions
  ).then(handleResponse);
}

function uploadDocument(formData, idInstitutie) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: formData
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_APLICANT_PROIECT_BASE_PATH}/Institutie/${idInstitutie}/IncarcaDocumenteAjutatoare`,
    requestOptions
  ).then(handleResponse);
}

function uploadAnexa2(formData, idProgram) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: formData
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/IncarcaContractFinantareSemnat/${idProgram}`,
    requestOptions
  ).then(handleResponse);
}

function uploadActAditionalContractFinantare(formData, idProiect) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: formData
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/IncarcaActAditionalAnexaContractFinantare/${idProiect}`,
    requestOptions
  ).then(handleResponse);
}

function uploadAcordRealocare(formData, idProiect) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: formData
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/IncarcaAcordRealocare/${idProiect}`,
    requestOptions
  ).then(handleResponse);
}

function uploadDocumentAdmin(formData) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: formData
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/IncarcaDocumenteSuport`,
    requestOptions
  ).then(handleResponse);
}

function downloadDocument(fisierUrl, idInstitutie) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    responseType: 'blob'
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_APLICANT_PROIECT_BASE_PATH}/Institutie/${idInstitutie}/DescarcaDocumentAjutator/${fisierUrl}`,
    requestOptions
  ).then((response) => {
    response.blob().then((data) => {
      FileDownload(
        data,
        decodeURIComponent(
          `${response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0]}.pdf`
        )
      );
    });
  });
}

function downloadAnexa2(idProgram) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    responseType: 'blob'
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/DescarcaContractFinantareGenerat/${idProgram}`,
    requestOptions
  ).then((response) => {
    response.blob().then((data) => {
      FileDownload(
        data,
        decodeURIComponent(
          `${response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0]}`
        )
      );
    });
  });
}

function downloadContestationDocument(fisierUrl, idProiect) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    responseType: 'blob'
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ELIGIBILITATE_BASE_PATH}/DescarcaDocumentContestatie/${idProiect}/${fisierUrl}`,
    requestOptions
  ).then((response) => {
    response.blob().then((data) => {
      FileDownload(
        data,
        decodeURIComponent(
          `${response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0]}.pdf`
        )
      );
    });
  });
}

function downloadProgramContestationDocument(fisierUrl, idProgram) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    responseType: 'blob'
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_COMMON_BASE_PATH}/Program/${idProgram}/DescarcaDocumentContestatieEvaluareTehnica/${fisierUrl}`,
    requestOptions
  ).then((response) => {
    response.blob().then((data) => {
      FileDownload(
        data,
        decodeURIComponent(
          `${response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0]}.pdf`
        )
      );
    });
  });
}

function downloadContractFinantareSemnat(fisierUrl, idProgram) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    responseType: 'blob'
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/DescarcaContractFinantareSemnat/${idProgram}`,
    requestOptions
  ).then((response) => {
    response.blob().then((data) => {
      FileDownload(
        data,
        decodeURIComponent(
          `${response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0]}.pdf`
        )
      );
    });
  });
}

function downloadActAditionalContractFinantareSemnat(fisierUrl, idProiect) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    responseType: 'blob'
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/DescarcaActAditionalAnexaContractFinantare/${idProiect}`,
    requestOptions
  ).then((response) => {
    response.blob().then((data) => {
      FileDownload(
        data,
        decodeURIComponent(
          `${response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0]}.pdf`
        )
      );
    });
  });
}

function downloadAcordRealocare(fisierUrl, idProiect) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    responseType: 'blob'
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/DescarcaAcordRealocare/${idProiect}`,
    requestOptions
  ).then((response) => {
    response.blob().then((data) => {
      FileDownload(
        data,
        decodeURIComponent(
          `${response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0]}.pdf`
        )
      );
    });
  });
}

function downloadNotaJustificativa(fisierUrl) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    responseType: 'blob'
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_MONITOR_BASE_PATH}/DescarcaNotaJustificativa/${fisierUrl}`,
    requestOptions
  ).then((response) => {
    response.blob().then((data) => {
      FileDownload(
        data,
        decodeURIComponent(
          `${response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0]}.pdf`
        )
      );
    });
  });
}

function downloadDocumentAdmin(fisierUrl) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    responseType: 'blob'
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/DescarcaDocumentSuport/${fisierUrl}`,
    requestOptions
  ).then((response) => {
    response.blob().then((data) => {
      FileDownload(
        data,
        decodeURIComponent(
          `${response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0]}.pdf`
        )
      );
    });
  });
}

function downloadAnexaRaport(programId, phaseId, cycleId, documentType) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    responseType: 'blob'
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${programId}/DescarcaAnexa/${phaseId}/${cycleId}/${documentType}`,
    requestOptions
  ).then((response) => {
    response.blob().then((data) => {
      FileDownload(
        data,
        decodeURIComponent(
          `${response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0]}.pdf`
        )
      );
    });
  });
}

function downloadAnexaRaportRaportor(fileUrl) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    responseType: 'blob'
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_RAPORTOR_BASE_PATH}/DescarcaAnexa/${fileUrl}`,
    requestOptions
  ).then((response) => {
    response.blob().then((data) => {
      FileDownload(
        data,
        decodeURIComponent(
          `${response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0]}.pdf`
        )
      );
    });
  });
}

function downloadProjectResponsibleCV(projectId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    responseType: 'blob'
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_APLICANT_PROIECT_BASE_PATH}/DescarcaCvResponsabilProiect/${projectId}`,
    requestOptions
  ).then((response) => {
    response.blob().then((data) => {
      FileDownload(
        data,
        decodeURIComponent(
          `${response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0]}.pdf`
        )
      );
    });
  });
}

function downloadProjectDocument(projectId, type) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    responseType: 'blob'
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_APLICANT_PROIECT_BASE_PATH}/DescarcaDocumentProiect/${projectId}/tip/${type}`,
    requestOptions
  ).then((response) => {
    response.blob().then((data) => {
      FileDownload(
        data,
        decodeURIComponent(
          `${response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0]}.pdf`
        )
      );
    });
  });
}

function handleResponse(response) {
  return response.text().then((text) => {
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        window.location.reload(true);
      }
      return Promise.reject(JSON.parse(text));
    }

    const data = text && JSON.parse(text);

    if (data) {
      return camelizeKeys(data);
    }

    return text;
  });
}
