import { eligibilityConstants } from '../constants';

export function eligibility(
  state = {
    loading: false,
    saving: false,
    programs: [],
    eligibilitySheet: null,
    selectedProgram: null
  },
  action
) {
  switch (action.type) {
    case eligibilityConstants.GET_ASSIGNED_PROGRAMS_REQUEST:
    case eligibilityConstants.GET_ELIGIBILITY_SHEET_REQUEST:
    case eligibilityConstants.GET_PROGRAM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case eligibilityConstants.GET_ASSIGNED_PROGRAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        programs: action.programs.programe
      };
    case eligibilityConstants.GET_ELIGIBILITY_SHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        eligibilitySheet: action.eligibilitySheet
      };
    case eligibilityConstants.GET_PROGRAM_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedProgram: {
          ...action.program,
          role: state.programs.filter(
            (program) => program.programGuid === action.program.programGuid
          )?.[0]?.role,
          statusFisaEligibilitate: state.programs.filter(
            (program) => program.programGuid === action.program.programGuid
          )?.[0]?.statusFisaEligibilitate
        }
      };
    case eligibilityConstants.GET_ASSIGNED_PROGRAMS_FAILURE:
    case eligibilityConstants.GET_PROGRAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case eligibilityConstants.GET_ELIGIBILITY_SHEET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        eligibilitySheet: null
      };
    default:
      return state;
  }
}
