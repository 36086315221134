const myAccountForm = {
  formId: 'myAccountForm',
  myAccountFormField: {
    lastName: {
      name: 'lastName',
      label: 'global.name',
      requiredErrorMsg: 'form.my_account.lastname.error.required'
    },
    firstName: {
      name: 'firstName',
      label: 'form.my_account.firstname',
      requiredErrorMsg: 'form.my_account.firstname.error.required'
    },
    email: {
      name: 'email',
      label: 'form.my_account.email',
      requiredErrorMsg: 'form.my_account.email.error.required',
      emailErrorMsg: 'form.my_account.email.error.email'
    },
    phone: {
      name: 'phone',
      label: 'form.my_account.phone',
      requiredErrorMsg: 'form.my_account.phone.error.required'
    }
  }
};

export default myAccountForm;
