import { useIntl } from 'react-intl';
import { Backdrop, CircularProgress, Container, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import everything from the react module
import * as React from 'react';
// import Page component
import Page from '../components/Page';
import CardList from '../components/lists/CardList';
import { competitionActions } from '../actions';
import InstitutionCompetitionCard from '../components/cards/InstitutionCompetitionCard';
// ----------------------------------------------------------------------

export default function InstitutionCompetitions() {
  const intl = useIntl();

  const [sort, setSort] = useState('descending'); // set initial State to descending
  const [searchText, setSearchText] = useState(''); // set initial State to ''
  const [competitionItems, setCompetitionItems] = useState([]); // set initial State to empty Array
  // useSelector extracts  data from the competitions state
  const competitions = useSelector((state) => state.competitions);
  // useSelector extracts  data from the user state
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // getInstitutionCompetitions by user.info.institutieParinte
  useEffect(() => {
    dispatch(competitionActions.getInstitutionCompetitions(user.info.institutieParinte));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // order competitions by durataDesfasurareIntervalInferior
  useEffect(() => {
    if (competitions && competitions.competitions) {
      setCompetitionItems(
        competitions.competitions.sort((c1, c2) =>
          c2.durataDesfasurareIntervalInferior.localeCompare(c1.durataDesfasurareIntervalInferior)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitions]);

  // filter competitions with nume that has searchText in it
  useEffect(() => {
    let filteredCompetitions = [];
    if (competitions && competitions.competitions) {
      if (searchText && searchText.length > 0) {
        filteredCompetitions = competitions.competitions.filter((competition) =>
          competition.nume.toLowerCase().includes(searchText.toLowerCase())
        );
      } else {
        filteredCompetitions = competitions.competitions;
      }
    }

    // order  filtered competitions by durataDesfasurareIntervalInferior
    filteredCompetitions = filteredCompetitions.sort((c1, c2) =>
      sort === 'ascending'
        ? c1.durataDesfasurareIntervalInferior.localeCompare(c2.durataDesfasurareIntervalInferior)
        : c2.durataDesfasurareIntervalInferior.localeCompare(c1.durataDesfasurareIntervalInferior)
    );

    setCompetitionItems([...filteredCompetitions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, searchText]);

  // display CircularProgress on competitions or programs loading
  if (competitions?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  // display institution competitions
  return (
    <Page title={intl.formatMessage({ id: 'institution.competitions' })} my={2}>
      <Container>
        {/* <MuiTableAddEntity
          title={intl.formatMessage({ id: 'administrator.competitions' })}
          buttonText={intl.formatMessage({ id: 'global.competitions.add' })}
        /> */}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {intl.formatMessage({ id: 'page.admin.competitions.title' })}
          </Typography>
        </Stack>
        {competitionItems && (
          <CardList
            entityId="competitionGuid"
            sort={sort}
            setSort={setSort}
            searchText={searchText}
            setSearchText={setSearchText}
            sortFieldTitle={intl.formatMessage({ id: 'global.name' })}
            items={competitionItems}
            Card={InstitutionCompetitionCard}
          />
        )}
      </Container>
    </Page>
  );
}
