import * as React from 'react';
import _ from 'lodash';

import { FieldArray } from 'formik';
import { useIntl } from 'react-intl';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import IncomeStatusSection from './IncomeStatusSection';
import incomeStatusTableRows from '../../../../forms/program/incomeStatus/incomeStatusTableRows';
import incomeStatusTableFields from '../../../../forms/program/incomeStatus/incomeStatusTableFields';

const {
  tableStructure: { general, total },
  rowFields: { firstCols, lastCols }
} = incomeStatusTableFields;

export default function IncomeStatusTable({ disabled, name, formik, years }) {
  const intl = useIntl();
  const cols = firstCols.concat(years).concat(lastCols);

  return (
    <Table>
      <TableHead>
        <TableRow>
          {cols.map((elem, index) => (
            <TableCell key={index}>
              {elem.label ? intl.formatMessage({ id: elem.label }) : elem}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <FieldArray
          name={`${name}.${general.name}`}
          render={() =>
            incomeStatusTableRows[general.name].map((elem, index) => (
              <IncomeStatusSection
                key={index}
                index={index + 1}
                elem={elem}
                years={years}
                value={formik.values[name][general.name][index]}
                name={`${name}.${general.name}.${index}`}
                formik={formik}
                disabled={disabled}
              />
            ))
          }
        />
        <FieldArray
          name={`${name}.${total.name}`}
          render={() =>
            incomeStatusTableRows[total.name].map((elem, index) => (
              <IncomeStatusSection
                key={index}
                elem={elem}
                years={years}
                name={`${name}.${total.name}.${index}`}
                value={formik.values[name][total.name][index]}
                formik={formik}
                disabled={disabled}
              />
            ))
          }
        />
      </TableBody>
    </Table>
  );
}
