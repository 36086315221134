const monitoringCycleForm = {
  formId: 'monitoringCycleForm',
  monitoringCycleField: {
    cycle: {
      name: 'Cicluri',
      requiredErrorMsg: 'form.error.required',
      datesOrderErrorMsg: 'form.error.cycle.dates.order',
      id: {
        name: 'CicluId'
      },
      title: {
        name: 'Denumire',
        label: 'monitoring.cycle.name',
        requiredErrorMsg: 'form.error.required'
      },
      startDate: {
        name: 'DataIncepere',
        label: 'global.startDate',
        requiredErrorMsg: 'form.error.required',
        wrongOrderErrorMsg: 'form.error.date.order.start'
      },
      endDate: {
        name: 'DataFinalizare',
        label: 'global.endDate',
        requiredErrorMsg: 'form.error.required',
        wrongOrderErrorMsg: 'form.error.date.order.end'
      },
      final: {
        name: 'CicluFinal',
        label: 'monitoring.cycle.final'
      },
      projects: {
        name: 'Proiecte',
        requiredErrorMsg: 'form.error.monitoring.project.required',
        project: {
          name: 'ProiectAsignatGuid',
          label: 'global.project',
          requiredErrorMsg: 'form.error.required'
        },
        budget: {
          name: 'SumaAlocata',
          label: 'monitoring.cycle.budget',
          requiredErrorMsg: 'form.error.required'
        },
        monitor: {
          name: 'MonitorGuid',
          label: 'monitoring.cycle.responsible',
          requiredErrorMsg: 'form.error.required'
        }
      }
    }
  }
};

export default monitoringCycleForm;
