const eligibilitySheetForm = {
  formId: 'eligibilitySheetForm',
  eligibilitySheetField: {
    eligibilitySheet: {
      name: 'FisaEligibilitate',
      criteria: {
        name: 'Criterii',
        label: 'competition.criteria',
        value: {
          name: 'Valoare'
        },
        criteria: {
          name: 'Criteriu'
        }
      },
      comments: {
        name: 'Comentarii',
        label: 'global.comments'
      }
    },
    documents: {
      name: 'DocumenteContestatie',
      url: {
        name: 'FisierUrl'
      },
      filename: {
        name: 'NumeFisierOriginal'
      }
    },
    status: {
      name: 'Status',
      label: 'global.eligible'
    }
  }
};

export default eligibilitySheetForm;
