import declaratiiForm from './declaratiiFormModel';

const {
  name,
  declaratiiFormFields: { nuEsteFinantat, dateReale }
} = declaratiiForm;

export const parseDeclaratii = (proiect) => ({
  [name]: {
    [nuEsteFinantat.name]: proiect[nuEsteFinantat.name],
    [dateReale.name]: proiect[dateReale.name]
  }
});
