import PropTypes from 'prop-types';
import { useState } from 'react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Box, List, ListItemText, ListItemButton, Stack, Button, Menu } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useIntl } from 'react-intl';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 30,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  marginRight: theme.spacing(1),
  color: theme.palette.common.black,
  flex: '0 1 auto'
}));

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
  onClose: PropTypes.func
};

function NavItem({ item, active, onClose }) {
  const theme = useTheme();
  const intl = useIntl();
  const { pathname } = useLocation();
  const isActiveRoot = active(item.path);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { title, path, info } = item;
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  const activeRootStyle = {
    color: theme.palette.common.white,
    fontWeight: 'fontWeightMedium',
    bgcolor: theme.palette.primary.main,
    '&:before': { display: 'block' },
    marginLeft: '8px',
    '&:hover': { color: 'black' }
  };

  const rootStyle = {
    marginLeft: '8px'
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {'nested' in item && (
        <>
          <Button
            aria-controls={open ? 'menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{
              ...(isActiveRoot && activeRootStyle),
              ...(!isActiveRoot && rootStyle)
            }}
          >
            <span>{intl.formatMessage({ id: title })}</span>
            <ExpandMoreIcon />
          </Button>
          <Menu
            id="menu"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {item.nested.map((item) => (
              <NavItem key={item.title} item={item} active={match} onClose={handleClose} />
            ))}
          </Menu>
        </>
      )}
      {!('nested' in item) && (
        <ListItemStyle
          component={RouterLink}
          to={path}
          sx={{
            ...(isActiveRoot && activeRootStyle),
            ...(!isActiveRoot && rootStyle)
          }}
          onClick={onClose || handleClose}
        >
          <ListItemText disableTypography primary={intl.formatMessage({ id: title })} />
          {info && info}
        </ListItemStyle>
      )}
    </>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other}>
      <List component={Stack} direction="row">
        {navConfig
          .filter(
            (menuItem) =>
              !Object.prototype.hasOwnProperty.call(menuItem, 'display') ||
              (menuItem.display && menuItem.display())
          )
          .map((item) => (
            <NavItem key={item.title} item={item} active={match} />
          ))}
      </List>
    </Box>
  );
}
