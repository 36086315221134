import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import AlertDialog from '../common/AlertDialog';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

MuiTableListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterText: PropTypes.string,
  onFilter: PropTypes.func,
  handleDelete: PropTypes.func,
  deleteConfirmationTitle: PropTypes.string,
  deleteConfirmationMessage: PropTypes.string,
  searchPlaceholder: PropTypes.string
};

export default function MuiTableListToolbar({
  numSelected,
  filterText,
  onFilter,
  handleDelete,
  deleteConfirmationTitle,
  deleteConfirmationMessage,
  searchPlaceholder
}) {
  const [alertOpen, setAlertOpen] = useState(false);
  const intl = useIntl();

  return (
    <>
      <AlertDialog
        open={alertOpen}
        title={deleteConfirmationTitle}
        message={deleteConfirmationMessage}
        handleClose={() => setAlertOpen(false)}
        handleOk={() => {
          setAlertOpen(false);
          handleDelete();
        }}
      />
      <RootStyle
        sx={{
          ...(numSelected > 0 && {
            color: 'primary.main',
            bgcolor: 'primary.lighter'
          })
        }}
      >
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} {intl.formatMessage({ id: 'global.selected' })}
          </Typography>
        ) : (
          <SearchStyle
            value={filterText}
            onChange={onFilter}
            placeholder={intl.formatMessage({
              id: searchPlaceholder || 'global.search'
            })}
            startAdornment={
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
        )}

        {numSelected > 0 && (
          <Tooltip title={intl.formatMessage({ id: 'global.delete' })}>
            <IconButton onClick={() => setAlertOpen(true)}>
              <Icon icon={trash2Fill} />
            </IconButton>
          </Tooltip>
        )}
      </RootStyle>
    </>
  );
}
