import reportAssignedEvaluatorsForm from './reportAssignedEvaluatorsFormModel';

const {
  reportAssignedEvaluatorsField: { assignedEvaluators }
} = reportAssignedEvaluatorsForm;

export const parseReportAssignedEvaluators = (reportAssignedEvaluatorsValues) => {
  const parseReportAssignedEvaluatorsForm = { ...reportAssignedEvaluatorsValues };

  if (reportAssignedEvaluatorsValues[assignedEvaluators.name]) {
    parseReportAssignedEvaluatorsForm[assignedEvaluators.name] = parseReportAssignedEvaluatorsForm[
      assignedEvaluators.name
    ].map((assignedMonitorsItem) => ({
      [assignedEvaluators.project.name]: assignedMonitorsItem[assignedEvaluators.project.name].id,
      [assignedEvaluators.mainEvaluator.name]:
        assignedMonitorsItem[assignedEvaluators.mainEvaluator.name].id,
      [assignedEvaluators.secondEvaluator.name]:
        assignedMonitorsItem[assignedEvaluators.secondEvaluator.name].id
    }));
  }

  return parseReportAssignedEvaluatorsForm;
};
