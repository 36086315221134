import { useIntl } from 'react-intl';
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Grid,
  Tab,
  TextField,
  Typography
} from '@mui/material';
// The Effect Hook performs side effects in function components:
import { useEffect, useState } from 'react';
// dispatch is used as a callback which gets invoked once some async action is complete
// useSelector allows you to extract data from the Redux store state, using a selector function.
import { useDispatch, useSelector } from 'react-redux';
// imports everything from the react module
import * as React from 'react';
import { useParams } from 'react-router-dom';
// imports Tab components
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTheme } from '@mui/material/styles';
// import PDF component
import ReactPDF from '@react-pdf/renderer';
// import Page component
import Page from '../components/Page';
import { programActions } from '../actions';
import InstitutionProgramEvaluationObjectives from '../components/_dashboard/institutions/InstitutionProgramEvaluationObjectives';
import { ELIGIBILITY_STATUS, PROGRAM_EVALUATION_STATUS } from '../utils/enums';
import ProgramEvaluationAnexa25 from './ProgramEvaluationAnexa25';
// ----------------------------------------------------------------------

export default function AdminCompetitionProgramsEvaluationSheets() {
  const intl = useIntl();
  // useSelector extracts  data from the programs state
  const programs = useSelector((state) => state.programs);
  // dispatch is used as a callback which gets invoked once some async action is complete.
  const dispatch = useDispatch();
  const theme = useTheme();
  const { idProgram } = useParams();
  const [value, setValue] = useState('1'); // initial state 1
  const [subtabValue, setSubtabValue] = useState('1'); // initial state 1
  const [totalScore, setTotalScore] = useState(0); // initial state 0
  const [projectsScore, setProjectsScore] = useState([]); // initial state empty Array
  const [passedProjectsScore, setPassedProjectsScore] = useState([]); // initial state empty Array
  const [evaluatorsInfo, setEvaluatorsInfo] = useState([]); // initial state empty Array

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeSubtab = (event, newValue) => {
    setSubtabValue(newValue);
  };

  // The Effect Hook performs side effects in function components:
  // dispatch is used as a callback which gets invoked once some async action is complete.
  useEffect(() => {
    dispatch(programActions.getAdminProgramEvaluation(idProgram));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setProgramEvaluationStatus = (status) => {
    dispatch(programActions.setProgramEvaluationStatus(idProgram, status));
  };

  useEffect(() => {
    if (programs.selectedProgram) {
      let total = 0;
      programs.selectedProgram.fisaEvaluareFinala.criterii.forEach(
        (criterium) => (total += +criterium.scor)
      );
      setTotalScore(total);

      if (programs.selectedProgram.fiseEvaluatori) {
        setEvaluatorsInfo(
          programs.selectedProgram.fiseEvaluatori.map((fisa) => ({
            userId: fisa.userId,
            username: fisa.username
          }))
        );
      }

      const projectsScore = [];
      programs.selectedProgram.obiective.forEach((objective) => {
        objective.proiecte.forEach((project) => {
          const fiseProiect = [...project.fiseEvaluatori].sort((p1, p2) => p1.userId - p2.userId);
          const scorProiectEvaluatori = fiseProiect.map((fisa) =>
            fisa.criterii.reduce((accumulator, criteriu) => accumulator + criteriu.scor, 0)
          );
          projectsScore.push({
            projectId: project.proiectGuid,
            projectTitle: project.titluProiect,
            evaluatorsScore: fiseProiect.map((fisa, index) => ({
              userId: fisa.userId,
              score: scorProiectEvaluatori[index]
            }))
          });
        });
      });

      setProjectsScore(projectsScore);
      setPassedProjectsScore(
        projectsScore.filter(
          (projectScore) =>
            projectScore.evaluatorsScore.reduce(
              (accumulator, evaluatorScore) => accumulator + evaluatorScore.score,
              0
            ) /
              3 >=
            65
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programs]);

  // display CircularProgress on programs loading
  if (programs?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  // display TabPanel with tabs each with Grid items
  return (
    <Page title={intl.formatMessage({ id: 'institution.competitions' })} my={2}>
      <Container>
        <TabContext value={value}>
          <TabList onChange={handleChange} aria-label="tabs">
            <Tab
              label={intl.formatMessage({ id: 'institution.program.evaluation.tab1' })}
              value="1"
            />
            <Tab
              label={intl.formatMessage({ id: 'institution.program.evaluation.tab2' })}
              value="2"
            />
          </TabList>
          <TabPanel value="1" index={0}>
            <Grid container spacing={2}>
              {programs && programs.selectedProgram && (
                <>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={2} sm={2}>
                        <Typography variant="h6">
                          {intl.formatMessage({ id: 'global.status' })}:{' '}
                          {programs &&
                          programs.selectedProgram &&
                          programs.selectedProgram.statusEvaluareTehnica
                            ? programs.selectedProgram.statusEvaluareTehnica
                            : 'N/A'}
                        </Typography>
                      </Grid>
                      {(programs.selectedProgram.statusEvaluareTehnica ===
                        ELIGIBILITY_STATUS.EVALUATING ||
                        programs.selectedProgram.statusEvaluareTehnica ===
                          PROGRAM_EVALUATION_STATUS.REJECTED) && (
                        <Grid item xs={3} sm={3}>
                          <Button
                            variant="outlined"
                            onClick={() => setProgramEvaluationStatus(true)}
                            startIcon={<CheckIcon sx={{ color: theme.palette.success.dark }} />}
                          >
                            {`${
                              programs.selectedProgram.statusEvaluareTehnica
                                ? intl.formatMessage({ id: 'global.changeStatus' })
                                : ''
                            }${intl.formatMessage({ id: 'global.admitted' })}`}
                          </Button>
                        </Grid>
                      )}
                      {(programs.selectedProgram.statusEvaluareTehnica ===
                        ELIGIBILITY_STATUS.EVALUATING ||
                        programs.selectedProgram.statusEvaluareTehnica ===
                          PROGRAM_EVALUATION_STATUS.ACCEPTED) && (
                        <Grid item xs={3} sm={3}>
                          <Button
                            variant="outlined"
                            onClick={() => setProgramEvaluationStatus(false)}
                            startIcon={<CancelIcon sx={{ color: theme.palette.error.dark }} />}
                          >
                            {`${
                              programs.selectedProgram.statusEvaluareTehnica
                                ? intl.formatMessage({ id: 'global.changeStatus' })
                                : ''
                            }${intl.formatMessage({ id: 'global.rejected' })}`}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                    {programs &&
                      programs.selectedProgram &&
                      programs.selectedProgram.fiseEvaluatori && (
                        <Grid item xs={4} sm={4}>
                          <ReactPDF.PDFDownloadLink
                            document={
                              <ProgramEvaluationAnexa25
                                fise={[...programs.selectedProgram.fiseEvaluatori].sort(
                                  (fisa1, fisa2) => fisa1.userId - fisa2.userId
                                )}
                                programeTitle={programs.selectedProgram.numeProgram}
                                totalProgram={totalScore}
                                projectsScore={projectsScore}
                                passedProjectsScore={passedProjectsScore}
                                evaluatorsInfo={evaluatorsInfo}
                                intl={intl}
                              />
                            }
                            fileName="anexa2_5.pdf"
                          >
                            {({ blob, url, loading, error }) =>
                              intl.formatMessage({ id: 'global.anexa.2.5' })
                            }
                          </ReactPDF.PDFDownloadLink>
                        </Grid>
                      )}
                  </Grid>
                  {programs.selectedProgram.fisaEvaluareFinala && (
                    <TabContext value={subtabValue}>
                      <TabList onChange={handleChangeSubtab} aria-label="tabs">
                        <Tab
                          label={intl.formatMessage({ id: 'admin.evaluation.subtab1' })}
                          value="1"
                        />
                        <Tab
                          label={intl.formatMessage({ id: 'admin.evaluation.subtab2' })}
                          value="2"
                        />
                        <Tab
                          label={intl.formatMessage({ id: 'admin.evaluation.subtab3' })}
                          value="3"
                        />
                        <Tab
                          label={intl.formatMessage({ id: 'admin.evaluation.subtab4' })}
                          value="4"
                        />
                      </TabList>
                      {programs.selectedProgram.fiseEvaluatori.map((fisaEvaluator, fisaIndex) => (
                        <TabPanel value={`${fisaIndex + 1}`} index={fisaIndex} key={fisaIndex}>
                          <Grid container spacing={2}>
                            {fisaEvaluator.criterii.map((criterion, index) => (
                              <Grid item xs={12} sm={12} key={index}>
                                <Grid container spacing={5} alignItems="center">
                                  <Grid item xs={10} sm={10}>
                                    <Typography variant="h5" gutterBottom component="div">
                                      {criterion.denumire}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2} sm={2}>
                                    <TextField
                                      id={`score${index}`}
                                      name={`score${index}`}
                                      label={`${intl.formatMessage({ id: 'global.score' })} (max: ${
                                        criterion.scorMaxim
                                      })`}
                                      value={criterion.scor}
                                      disabled
                                      fullWidth
                                      variant="outlined"
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={12}>
                                    <TextField
                                      id={`comment${index}`}
                                      name={`comment${index}`}
                                      label={intl.formatMessage({ id: 'global.comments' })}
                                      value={criterion.comentariu}
                                      variant="outlined"
                                      disabled
                                      fullWidth
                                      multiline
                                      rows={4}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        </TabPanel>
                      ))}
                      <TabPanel value="4" index={3}>
                        <Grid container spacing={2}>
                          {programs.selectedProgram.fisaEvaluareFinala.criterii.map(
                            (criterion, index) => (
                              <Grid item xs={12} sm={12} key={index}>
                                <Grid container spacing={5} alignItems="center">
                                  <Grid item xs={10} sm={10}>
                                    <Typography variant="h5" gutterBottom component="div">
                                      {criterion.denumire}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2} sm={2}>
                                    <TextField
                                      id={`score${index}`}
                                      name={`score${index}`}
                                      label={`${intl.formatMessage({ id: 'global.score' })} (max: ${
                                        criterion.scorMaxim
                                      })`}
                                      value={criterion.scor}
                                      disabled
                                      fullWidth
                                      variant="outlined"
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={12}>
                                    <TextField
                                      id={`comment${index}`}
                                      name={`comment${index}`}
                                      label={intl.formatMessage({ id: 'global.comments' })}
                                      value={criterion.comentariu}
                                      variant="outlined"
                                      disabled
                                      fullWidth
                                      multiline
                                      rows={4}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </TabPanel>
                    </TabContext>
                  )}
                </>
              )}
            </Grid>
          </TabPanel>
          <TabPanel value="2" index={1}>
            {programs && programs.selectedProgram && programs.selectedProgram.obiective && (
              <InstitutionProgramEvaluationObjectives
                objectives={programs.selectedProgram.obiective.sort((o1, o2) =>
                  o1.titluObiectiv.localeCompare(o2.titluObiectiv)
                )}
                programId={idProgram}
              />
            )}
          </TabPanel>
        </TabContext>
      </Container>
    </Page>
  );
}
