import { competitionConstants } from '../constants';

export function competitions(
  state = {
    loading: false,
    saving: false,
    competitions: [],
    selectedCompetition: {},
    selectedCompetitionEligibility: {},
    selectedCompetitionProgramEvaluation: {},
    selectedcompetitionProjectEvaluation: {}
  },
  action
) {
  switch (action.type) {
    case competitionConstants.GET_ALL_ADMIN_REQUEST:
    case competitionConstants.GET_INSTITUTION_COMPETITIONS_REQUEST:
    case competitionConstants.COMPETITION_ADD_REQUEST:
    case competitionConstants.COMPETITION_ADD_ELIGIBILITY_REQUEST:
    case competitionConstants.COMPETITION_ADD_PROGRAM_EVALUATION_REQUEST:
    case competitionConstants.COMPETITION_ADD_PROJECT_EVALUATION_REQUEST:
    case competitionConstants.GET_COMPETITION_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case competitionConstants.GET_ALL_ADMIN_SUCCESS:
    case competitionConstants.GET_INSTITUTION_COMPETITIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        competitions: action.competitions
      };
    case competitionConstants.COMPETITION_ADD_SUCCESS:
    case competitionConstants.GET_COMPETITION_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedCompetition: action.competition
      };
    case competitionConstants.COMPETITION_ADD_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedCompetitionEligibility: action.competitionEligibility
      };
    case competitionConstants.COMPETITION_ADD_PROGRAM_EVALUATION_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedCompetitionProgramEvaluation: action.programEvaluation
      };
    case competitionConstants.COMPETITION_ADD_PROJECT_EVALUATION_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedCompetitionProjectEvaluation: action.projectEvaluation
      };
    case competitionConstants.COMPETITION_CLEAR_SELECTED_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedCompetition: {},
        selectedCompetitionProgramEvaluation: {},
        selectedCompetitionEligibility: {},
        selectedCompetitionProjectEvaluation: {}
      };
    case competitionConstants.GET_ALL_ADMIN_FAILURE:
    case competitionConstants.GET_INSTITUTION_COMPETITIONS_FAILURE:
      return {
        ...state,
        loading: false,
        competitions: [],
        error: action.error
      };
    case competitionConstants.COMPETITION_ADD_FAILURE:
    case competitionConstants.COMPETITION_ADD_ELIGIBILITY_FAILURE:
    case competitionConstants.COMPETITION_ADD_PROJECT_EVALUATION_FAILURE:
    case competitionConstants.COMPETITION_ADD_PROGRAM_EVALUATION_FAILURE:
    case competitionConstants.GET_COMPETITION_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
