import * as React from 'react';
import { useIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
// ----------------------------------------------------------------------------

export default function ProgramEvaluationProjectCard({ entity }) {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Card key={entity.proiectGuid.toString()}>
      <CardHeader
        title={
          <div>
            <p>{entity.titluProiect}</p>
          </div>
        }
        action={
          <Button variant="outlined" onClick={() => navigate(`${entity.proiectGuid}/fisa`)}>
            {intl.formatMessage({ id: 'programEvaluation.view.projects.sheet' })}
          </Button>
        }
      />
      <CardContent>
        <p>{intl.formatMessage({ id: 'global.obiectiv' })}</p>
        <p>{entity.numeObiectiv}</p>
      </CardContent>
    </Card>
  );
}
