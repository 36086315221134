// material
import { Box, Button, ButtonGroup, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

// ----------------------------------------------------------------------

DashboardFooter.propTypes = {};

const FooterItem = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  textTransform: 'none'
}));

export default function DashboardFooter() {
  const intl = useIntl();

  return (
    <Box
      sx={{
        marginTop: '1rem',
        padding: '1rem',
        backgroundColor: 'common.black',
        color: 'common.white',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        zIndex: 100
      }}
    >
      <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <ButtonGroup
            variant="text"
            aria-label="footer button group"
            sx={{ color: 'common.white' }}
          >
            <FooterItem component={Link} to="/contact">
              {intl.formatMessage({ id: 'global.contact' })}
            </FooterItem>
            <FooterItem component={Link} to="/terms">
              {intl.formatMessage({ id: 'global.terms' })}
            </FooterItem>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Box>
  );
}
