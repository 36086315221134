import * as Yup from 'yup';
import adminProjectsEvaluationAssignForm from './adminProjectsEvaluationAssignFormModel';

const {
  adminProjectsEvaluationAssignField: { responsibles }
} = adminProjectsEvaluationAssignForm;

export const adminProjectsEvaluationAssignFormValidationSchema = () =>
  Yup.object().shape({
    [responsibles.name]: Yup.array()
      .of(
        Yup.object().shape({
          [responsibles.responsible1.name]: Yup.object()
            .nullable()
            .required(`${responsibles.responsible1.requiredErrorMsg}`),
          [responsibles.responsible2.name]: Yup.object()
            .nullable()
            .required(`${responsibles.responsible2.requiredErrorMsg}`),
          [responsibles.moderator.name]: Yup.object()
            .nullable()
            .required(`${responsibles.moderator.requiredErrorMsg}`)
        })
      )
      .min(1, `${responsibles.requiredErrorMsg}`)
  });
