import { useIntl } from 'react-intl';
import { Backdrop, CircularProgress, Container, Grid, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import Page from '../components/Page';
import { evaluationActions } from '../actions';
import EditableFormTextField from '../forms/shared/EditableFormTextField';
import projectTechnicalSheetFormModel from '../forms/projectTechnicalEvaluation/projectTechnicalSheetFormModel';
import { getInitialProjectTechnicalSheetFormValues } from '../forms/projectTechnicalEvaluation/formInitialValues';
import { expectedResultsFormValidationSchema } from '../forms/projectTechnicalEvaluation/validationSchema';
// ----------------------------------------------------------------------

const {
  projectTechnicalSheetField: { projectTechnicalSheet }
} = projectTechnicalSheetFormModel;

export default function ProgramsEvaluationProjectSheet() {
  const { idProgram, idObiectiv, idProiect } = useParams();
  const intl = useIntl();

  const evaluation = useSelector((state) => state.evaluation);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(evaluationActions.getMyProgramEvaluationPrograms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: getInitialProjectTechnicalSheetFormValues(
      // eslint-disable-next-line no-nested-ternary
      evaluation &&
        evaluation.programs &&
        evaluation.programs.find((program) => program.programGuid === +idProgram) &&
        evaluation.programs.find((program) => program.programGuid === +idProgram).obiective &&
        evaluation.programs
          .find((program) => program.programGuid === +idProgram)
          .obiective.find((obiectiv) => obiectiv.obiectivGuid === +idObiectiv) &&
        evaluation.programs
          .find((program) => program.programGuid === +idProgram)
          .obiective.find((obiectiv) => obiectiv.obiectivGuid === +idObiectiv).proiecte
        ? evaluation.programs
            .find((program) => program.programGuid === +idProgram)
            .obiective.find((obiectiv) => obiectiv.obiectivGuid === +idObiectiv)
            .proiecte.find((proiect) => proiect.proiectGuid === +idProiect)
        : {}
    ),
    enableReinitialize: true,
    validationSchema: expectedResultsFormValidationSchema()
  });

  if (evaluation?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Page title={intl.formatMessage({ id: 'applicant.programmes' })} my={2}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            {intl.formatMessage({ id: 'page.evaluation.sheet.title' })}
          </Typography>
        </Stack>
        <Grid container spacing={5}>
          {formik.values[projectTechnicalSheet.name][projectTechnicalSheet.criteria.name].map(
            (criterion, index) => (
              <Grid item xs={12} sm={12} key={index}>
                <Grid container spacing={5} alignItems="center">
                  <Grid item xs={10} sm={10}>
                    <Typography variant="h5" gutterBottom component="div">
                      {
                        formik.values[projectTechnicalSheet.name][
                          projectTechnicalSheet.criteria.name
                        ][index][projectTechnicalSheet.criteria.criteria.name]
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <EditableFormTextField
                      parentName={projectTechnicalSheet.name}
                      childrenNames={[
                        projectTechnicalSheet.criteria.name,
                        index,
                        projectTechnicalSheet.criteria.value.name
                      ]}
                      formik={formik}
                      label={`${intl.formatMessage({ id: 'global.score' })} (max: ${
                        formik.values[projectTechnicalSheet.name][
                          projectTechnicalSheet.criteria.name
                        ][index][projectTechnicalSheet.criteria.maxValue.name]
                      })`}
                      variant="outlined"
                      type="number"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <EditableFormTextField
                      parentName={projectTechnicalSheet.name}
                      childrenNames={[
                        projectTechnicalSheet.criteria.name,
                        index,
                        projectTechnicalSheet.criteria.comments.name
                      ]}
                      formik={formik}
                      label={intl.formatMessage({
                        id: projectTechnicalSheet.criteria.comments.label
                      })}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            )
          )}
        </Grid>
      </Container>
    </Page>
  );
}
