import * as React from 'react';
import { useIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { Button, Collapse, IconButton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import schemaRealizareForm from '../../../forms/proiect/schemaRealizare/schemaRealizareFormModel';
import EditableFormTextField from '../../../forms/shared/EditableFormTextField';

const { rezultateFazaFields } = schemaRealizareForm;

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

const MiniCardHeader = styled(CardHeader)(({ theme }) => ({
  padding: '24px 24px 24px'
}));

export default function RezultatFazaCard({
  formik,
  id,
  parentName,
  childrenNames,
  disabled,
  arrayHelpers
}) {
  const intl = useIntl();
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card>
      <MiniCardHeader
        title={
          <p>
            {
              formik.values[parentName][childrenNames[0]][childrenNames[1]][id][
                rezultateFazaFields[0].name
              ]
            }
          </p>
        }
        action={
          <>
            {!disabled && (
              <Button variant="contained" onClick={() => arrayHelpers.remove(id)}>
                {intl.formatMessage({ id: 'global.delete' })}
              </Button>
            )}
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Stack spacing={3}>
            {rezultateFazaFields.map((field, index) => (
              <EditableFormTextField
                key={index}
                parentName={parentName}
                childrenNames={[...childrenNames, id, field.name]}
                formik={formik}
                label={intl.formatMessage({ id: field.label })}
                variant="outlined"
                fullWidth
                disabled={disabled}
                multiline
                rows={field.rows}
              />
            ))}
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  );
}
