const projectTechnicalSheetForm = {
  formId: 'projectTechnicalSheetForm',
  projectTechnicalSheetField: {
    projectTechnicalSheet: {
      name: 'FisaTehnicaProiect',
      criteria: {
        name: 'Criterii',
        label: 'competition.criteria',
        value: {
          name: 'Scor',
          maxValueError: 'score.max.error',
          requiredError: 'form.error.required'
        },
        maxValue: {
          name: 'ScorMaxim'
        },
        criteria: {
          name: 'Denumire'
        },
        comments: {
          name: 'Comentariu',
          label: 'global.comments',
          requiredError: 'form.error.required',
          requiredLengthError: 'form.error.length.min.100'
        }
      }
    }
  }
};

export default projectTechnicalSheetForm;
