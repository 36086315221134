import * as Yup from 'yup';
import expectedResultsForm from './expectedResultsFormModel';

const {
  expectedResultsFormFields: { expectedResults, keywords }
} = expectedResultsForm;

export const expectedResultsFormValidationSchema = () =>
  Yup.object().shape({
    [expectedResults.name]: Yup.string().required(`${expectedResults.requiredErrorMsg}`)
  });
