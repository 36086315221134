import _ from 'lodash';
import costuriTotaleForm from './costuriTotaleFormModel';

const {
  costuriTotaleTableHead: { total },
  costuriTotaleFormFields
} = costuriTotaleForm;

export const getInitialCosturiTotaleFormValues = (proiect, years) => {
  const initialValues = {};
  costuriTotaleFormFields.forEach((field) => {
    initialValues[field.name] = {};
    initialValues[field.name][total.name] =
      proiect?.[costuriTotaleForm.name]?.[field.name]?.[total.name] ?? 0;
    _.range(years.start, years.end + 1).forEach((year) => {
      initialValues[field.name][year] =
        proiect?.[costuriTotaleForm.name]?.[field.name]?.[year] ?? 0;
    });
  });

  return { [costuriTotaleForm.name]: initialValues };
};
