import * as React from 'react';
import { FieldArray } from 'formik';

import { useIntl } from 'react-intl';
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import KeywordField from '../../KeywordField';
import EditableFormTextField from '../../../forms/shared/EditableFormTextField';
import informatiiGeneraleForm from '../../../forms/proiect/informatiiGenerale/informatiiGeneraleFormModel';
import FileUploadField from '../FileUploadField';
import { documentActions } from '../../../actions';
import { ProjectDocumentType } from '../../../utils/enums';

//--------------------------------------------------------------

const {
  informatiiGeneraleFormFields: {
    projectTitle,
    keywords,
    responsabil,
    cnpResponsabil,
    titluResponsabil,
    functieResponsabil,
    telResponsabil,
    faxResponsabil,
    emailResponsabil,
    cvResponsabil,
    domenii,
    prezentareaProiectului,
    descriereaStiintificaFile,
    structuraBugetuluiFile,
    managementulRisculuiFile,
    impactFile,
    planFile,
    alteInformatiiFile
  }
} = informatiiGeneraleForm;

export default function InformatiiGenerale({ disabled, formik, values, projectId }) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleUploadFile = (fileFieldName, formData) => {
    const file = formData.get(fileFieldName);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      formik.setFieldValue(fileFieldName, {
        numeFisierOriginal: file.name,
        base64EncodedData: reader.result.substring(reader.result.indexOf('base64,') + 7)
      });
      formik.setFieldTouched(fileFieldName, true);
    };
    reader.onerror = async (error) => {
      console.log('Error: ', error);
    };
  };

  return (
    <div>
      <Typography variant="h3">{intl.formatMessage({ id: 'page.project.info.title' })}</Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <EditableFormTextField
            parentName={projectTitle.name}
            formik={formik}
            label={intl.formatMessage({ id: projectTitle.label })}
            variant="outlined"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FieldArray
            name={keywords.name}
            render={(arrayHelpers) => (
              <KeywordField
                parentName={keywords.name}
                disabled={disabled}
                values={values}
                keywordField={keywords}
                arrayHelpers={arrayHelpers}
              />
            )}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <EditableFormTextField
            parentName={responsabil.name}
            childrenNames={['label']}
            formik={formik}
            label={intl.formatMessage({ id: responsabil.label })}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <EditableFormTextField
            parentName={cnpResponsabil.name}
            formik={formik}
            label={intl.formatMessage({ id: cnpResponsabil.label })}
            variant="outlined"
            fullWidth
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <EditableFormTextField
            parentName={titluResponsabil.name}
            formik={formik}
            label={intl.formatMessage({ id: titluResponsabil.label })}
            variant="outlined"
            fullWidth
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <EditableFormTextField
            parentName={functieResponsabil.name}
            formik={formik}
            label={intl.formatMessage({ id: functieResponsabil.label })}
            variant="outlined"
            fullWidth
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <EditableFormTextField
            parentName={telResponsabil.name}
            formik={formik}
            label={intl.formatMessage({ id: telResponsabil.label })}
            variant="outlined"
            fullWidth
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <EditableFormTextField
            parentName={faxResponsabil.name}
            formik={formik}
            label={intl.formatMessage({ id: faxResponsabil.label })}
            variant="outlined"
            fullWidth
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <EditableFormTextField
            parentName={emailResponsabil.name}
            formik={formik}
            label={intl.formatMessage({ id: emailResponsabil.label })}
            variant="outlined"
            fullWidth
            disabled={disabled}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FileUploadField
            formDataName={cvResponsabil.name}
            disabled={disabled}
            buttonLabel="Upload"
            buttonLabelDownload="Download"
            label={intl.formatMessage({ id: cvResponsabil.label })}
            handleUpload={(formData) => handleUploadFile(cvResponsabil.name, formData)}
            handleDownload={() => dispatch(documentActions.downloadProjectResponsibleCV(projectId))}
            currentFile=""
            currentFileName={formik.values[cvResponsabil.name].numeFisierOriginal}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <EditableFormTextField
            parentName={domenii.name}
            formik={formik}
            label={intl.formatMessage({ id: domenii.label })}
            variant="outlined"
            fullWidth
            disabled={disabled}
            multiline
            rows={3}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <EditableFormTextField
            parentName={prezentareaProiectului.name}
            formik={formik}
            label={intl.formatMessage({ id: prezentareaProiectului.label })}
            variant="outlined"
            fullWidth
            disabled={disabled}
            multiline
            rows={3}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FileUploadField
            formDataName={descriereaStiintificaFile.name}
            disabled={disabled}
            buttonLabel="Upload"
            buttonLabelDownload="Download"
            label={intl.formatMessage({ id: descriereaStiintificaFile.label })}
            handleUpload={(formData) => handleUploadFile(descriereaStiintificaFile.name, formData)}
            handleDownload={() =>
              dispatch(
                documentActions.downloadProjectDocument(
                  projectId,
                  ProjectDocumentType.DescriereaStiintificaFile
                )
              )
            }
            currentFile=""
            currentFileName={formik.values[descriereaStiintificaFile.name].numeFisierOriginal}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FileUploadField
            formDataName={structuraBugetuluiFile.name}
            disabled={disabled}
            buttonLabel="Upload"
            buttonLabelDownload="Download"
            label={intl.formatMessage({ id: structuraBugetuluiFile.label })}
            handleUpload={(formData) => handleUploadFile(structuraBugetuluiFile.name, formData)}
            handleDownload={() =>
              dispatch(
                documentActions.downloadProjectDocument(
                  projectId,
                  ProjectDocumentType.StructuraBugetuluiFile
                )
              )
            }
            currentFile=""
            currentFileName={formik.values[structuraBugetuluiFile.name].numeFisierOriginal}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FileUploadField
            formDataName={managementulRisculuiFile.name}
            disabled={disabled}
            buttonLabel="Upload"
            buttonLabelDownload="Download"
            label={intl.formatMessage({ id: managementulRisculuiFile.label })}
            handleUpload={(formData) => handleUploadFile(managementulRisculuiFile.name, formData)}
            handleDownload={() =>
              dispatch(
                documentActions.downloadProjectDocument(
                  projectId,
                  ProjectDocumentType.ManagementulRisculuiFile
                )
              )
            }
            currentFile=""
            currentFileName={formik.values[managementulRisculuiFile.name].numeFisierOriginal}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FileUploadField
            formDataName={impactFile.name}
            disabled={disabled}
            buttonLabel="Upload"
            buttonLabelDownload="Download"
            label={intl.formatMessage({ id: impactFile.label })}
            handleUpload={(formData) => handleUploadFile(impactFile.name, formData)}
            handleDownload={() =>
              dispatch(
                documentActions.downloadProjectDocument(projectId, ProjectDocumentType.ImpactFile)
              )
            }
            currentFile=""
            currentFileName={formik.values[impactFile.name].numeFisierOriginal}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FileUploadField
            formDataName={planFile.name}
            disabled={disabled}
            buttonLabel="Upload"
            buttonLabelDownload="Download"
            label={intl.formatMessage({ id: planFile.label })}
            handleUpload={(formData) => handleUploadFile(planFile.name, formData)}
            handleDownload={() =>
              dispatch(
                documentActions.downloadProjectDocument(projectId, ProjectDocumentType.PlanFile)
              )
            }
            currentFile=""
            currentFileName={formik.values[planFile.name].numeFisierOriginal}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FileUploadField
            formDataName={alteInformatiiFile.name}
            disabled={disabled}
            buttonLabel="Upload"
            buttonLabelDownload="Download"
            label={intl.formatMessage({ id: alteInformatiiFile.label })}
            handleUpload={(formData) => handleUploadFile(alteInformatiiFile.name, formData)}
            handleDownload={() =>
              dispatch(
                documentActions.downloadProjectDocument(
                  projectId,
                  ProjectDocumentType.AlteInformatiiFile
                )
              )
            }
            currentFile=""
            currentFileName={formik.values[alteInformatiiFile.name].numeFisierOriginal}
          />
        </Grid>
      </Grid>
    </div>
  );
}
