// material
import { Button, Box } from '@mui/material';
// components
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../actions';
import { ACTIONS, ENTITIES } from '../../utils/enums';
import { UserRoles } from '../../domain/UserRoles';
// ----------------------------------------------------------------------

export default function AccountPopover() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  return (
    <>
      {user.abilities.can(ACTIONS.VIEW, ENTITIES.MY_ACCOUNT) && (
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" component={Link} to="/my-account">
            {intl.formatMessage({ id: 'global.my_account' })}
          </Button>
        </Box>
      )}
      <Box sx={{ p: 2, pt: 1.5 }}>
        <Button
          fullWidth
          color="inherit"
          variant="outlined"
          onClick={() =>
            dispatch(
              userActions.logout(
                navigate,
                user.info.userType.includes(UserRoles.PlatformAdministrator)
              )
            )
          }
        >
          {intl.formatMessage({ id: 'global.logout' })}
        </Button>
      </Box>
    </>
  );
}
