import PropTypes from 'prop-types';
import * as _ from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray, Formik } from 'formik';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import EditableTableCell from '../../EditableTableCell';

import projectsObjectivesForm from '../../../forms/program/projectsObjectives/projectsObjectivesFormModel';
import { getInitialProjectsObjectivesFormValues } from '../../../forms/program/projectsObjectives/formInitialValues';
import {
  getEmptyObjective,
  getEmptyProject
} from '../../../forms/program/projectsObjectives/projectsObjectivesFormHelper';

import ProjectsObjectivesTableHeader from './projectsObjectives/ProjectsObjectivesTableHeader';
import ProjectTableRow from './projectsObjectives/ProjectTableRow';
import ProjectActionButton from './projectsObjectives/ProjectActionButton';
import ObjectiveActionButton from './projectsObjectives/ObjectiveActionButton';

import { programActions } from '../../../actions';
import { FORM_IDS } from '../../../utils/enums';
import { parseProjectsObjectives } from '../../../forms/program/projectsObjectives/formOutputParser';
import { projectsObjectivesFormValidationSchema } from '../../../forms/program/projectsObjectives/validationSchema';

// ----------------------------------------------------------------------------

const {
  projectsObjectivesFormFields: {
    objectives,
    total,
    objectiveFields: { projects, name: objectiveName },
    projectFields: { additionalInfo }
  }
} = projectsObjectivesForm;

ProjectsObjectives.propTypes = {
  program: PropTypes.object,
  disabled: PropTypes.bool,
  isNew: PropTypes.bool
};

export default function ProjectsObjectives({ program, disabled, isNew }) {
  const intl = useIntl();
  const { id } = useParams();
  const navigate = useNavigate();
  const programState = useSelector((state) => state.programs.selectedProgram);
  const dispatch = useDispatch();

  const formik = {
    initialValues: getInitialProjectsObjectivesFormValues(
      programState?.obiectiveSiProiecte,
      program.start,
      program.end
    ),
    validationSchema: projectsObjectivesFormValidationSchema(),
    onSubmit: (values) => {
      const data = JSON.parse(JSON.stringify(values));
      const parsedData = parseProjectsObjectives(data);

      if (isNew) {
        dispatch(
          programActions.createProgram(id, (program) => {
            dispatch(
              programActions.updateProgramProjectsObjectives(program.programGuid, parsedData)
            );
            navigate(`../../../programe/${program.programGuid.toString()}`, { replace: true });
          })
        );
      } else {
        dispatch(programActions.updateProgramProjectsObjectives(id, parsedData));
      }
    }
  };

  const getNumberOfYears = () => program.end - program.start + 1;
  const getNumberOfColumns = () => 8 + getNumberOfYears();
  const getActionColSpan = () => getNumberOfColumns() - 1;

  return (
    <Formik
      initialValues={formik.initialValues}
      onSubmit={formik.onSubmit}
      validationSchema={formik.validationSchema}
    >
      {({ values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue }) => (
        <form id={FORM_IDS.PROGRAM_PROJECTS_OBJECTIVES} onSubmit={handleSubmit} noValidate>
          <TableContainer>
            <Table>
              <ProjectsObjectivesTableHeader
                program={program}
                noOfYears={getNumberOfYears()}
                disabled={disabled}
              />
              <TableBody>
                <FieldArray
                  name={objectives.name}
                  render={(arrayHelpers) => (
                    <>
                      {values[objectives.name].map((objective, index) => (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell>{index + 1}</TableCell>
                            <EditableTableCell
                              getValue={() => objective[objectiveName.name] ?? ''}
                              colSpan={getNumberOfColumns() - 2}
                              disabled={disabled}
                              formik={{
                                values,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                errors,
                                touched
                              }}
                              name={`${objectives.name}.${index}.${objectiveName.name}`}
                              placeholder={intl.formatMessage({ id: objectiveName.placeholder })}
                            />
                            {!disabled && (
                              <TableCell>
                                <ObjectiveActionButton
                                  actionType="remove"
                                  onClick={() => arrayHelpers.remove(index)}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                          <FieldArray
                            name={`${objectives.name}.${index}.${projects.name}`}
                            render={(projectHelpers) => (
                              <>
                                {values[objectives.name][index][projects.name].map(
                                  (project, projectIndex) => (
                                    <ProjectTableRow
                                      key={projectIndex}
                                      name={`${objectives.name}.${index}.${projects.name}.${projectIndex}`}
                                      disabled={disabled}
                                      formik={{
                                        values,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        errors,
                                        touched,
                                        setFieldValue
                                      }}
                                      project={project}
                                      index={projectIndex}
                                      objectiveIndex={index}
                                      arrayHelpers={projectHelpers}
                                      program={program}
                                    />
                                  )
                                )}
                                <TableRow>
                                  <TableCell colSpan={getActionColSpan()} />
                                  {!disabled && (
                                    <TableCell>
                                      <ProjectActionButton
                                        actionType="insert"
                                        onClick={() =>
                                          projectHelpers.push(
                                            getEmptyProject(program.start, program.end)
                                          )
                                        }
                                      />
                                    </TableCell>
                                  )}
                                </TableRow>
                              </>
                            )}
                          />
                        </React.Fragment>
                      ))}
                      <TableRow>
                        <TableCell />
                        <TableCell colSpan={3}>Total</TableCell>
                        <EditableTableCell
                          getValue={() => {
                            let total = 0;
                            values[objectives.name].forEach((objective) => {
                              objective[projects.name].forEach((project) => {
                                _.range(program.start, program.end + 1).forEach((year) => {
                                  if (project[additionalInfo.name][year]) {
                                    total += +project[additionalInfo.name][year];
                                  }
                                });
                              });
                            });
                            return total;
                          }}
                          disabled
                          formik={{
                            values,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            errors,
                            touched
                          }}
                          name={`${total.name}.${total.name}`}
                        />
                        {_.range(program.start, program.end + 1).map((year) => (
                          <EditableTableCell
                            key={year}
                            getValue={() => {
                              let total = 0;
                              values[objectives.name].forEach((objective) => {
                                objective[projects.name].forEach((project) => {
                                  if (project[additionalInfo.name][year]) {
                                    total += +project[additionalInfo.name][year];
                                  }
                                });
                              });
                              return total;
                            }}
                            disabled
                            formik={{
                              values,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              errors,
                              touched
                            }}
                            name={`${total.name}.${year}`}
                          />
                        ))}
                        <TableCell colSpan={disabled ? 1 : 2} />
                        <TableCell>
                          {!disabled && (
                            <ObjectiveActionButton
                              actionType="insert"
                              onClick={() => arrayHelpers.push(getEmptyObjective())}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </form>
      )}
    </Formik>
  );
}
