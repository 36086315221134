import { useFormik } from 'formik';
// material
import { Backdrop, Button, CircularProgress, Container, Grid, Typography } from '@mui/material';
// components
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../components/Page';
//
import EditableFormTextField from '../forms/shared/EditableFormTextField';
import { institutionActions, userActions } from '../actions';
import userFormModel from '../forms/user/userFormModel';
import { getInitialUserFormValues } from '../forms/user/formInitialValues';
import { userFormValidationSchema } from '../forms/user/validationSchema';
import EditableFormSelect from '../forms/shared/EditableFormSelect';
import { UserRoles } from '../domain/UserRoles';

// ----------------------------------------------------------------------
// define const userFormModel
const {
  userFormField: { lastName, firstName, email, phone, roles, institution }
} = userFormModel;

export default function UserForm() {
  const intl = useIntl();
  const { id } = useParams();
  // useSelector extracts  data from the user state
  const user = useSelector((state) => state.user);
  // useSelector extracts  data from the institutions state
  const institutions = useSelector((state) => state.institutions);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get user info
  useEffect(() => {
    if (id !== '0') {
      dispatch(
        userActions.getUserInfo(id, user.info.userType.includes(UserRoles.PlatformAdministrator))
      );
    } else {
      dispatch(userActions.disposeUserInfo());
    }

    if (
      user.info.userType.includes(UserRoles.PlatformAdministrator) &&
      (!institutions || !institutions.institutions || !institutions.institutions.data)
    ) {
      dispatch(institutionActions.getAll());
    } else {
      // institution
      dispatch(userActions.getAll(user.info.userType, user.info.institutieParinte));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const formik = useFormik({
    initialValues: getInitialUserFormValues(user.selectedUser || {}),
    enableReinitialize: true,
    validationSchema: userFormValidationSchema(
      user.info.userType.includes(UserRoles.PlatformAdministrator),
      user && user.users && user.users.data ? user.users.data : []
    ),
    onSubmit: (values) => {
      const valuesCopy = JSON.parse(JSON.stringify(values));
      if (id === '0') {
        if (!user.info.userType.includes(UserRoles.PlatformAdministrator)) {
          // institutie
          valuesCopy.InstitutieInAdministrareGuid = user.info.institutieParinte;
        }
        dispatch(
          userActions.addUser(
            valuesCopy,
            user.info.userType.includes(UserRoles.PlatformAdministrator),
            navigate,
            () => formik.resetForm()
          )
        );
      } else {
        dispatch(
          userActions.updateUser(
            valuesCopy,
            false,
            user.info.userType.includes(UserRoles.PlatformAdministrator),
            navigate,
            () => formik.resetForm()
          )
        );
      }
    }
  });

  // display CircularProgress on competitions or users loading
  if (user.users?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  // display add_user form
  return (
    <Page title={intl.formatMessage({ id: 'page.add_user.title' })} my={2}>
      <Container>
        <Typography variant="h3">{intl.formatMessage({ id: 'user.title' })}</Typography>
        <Grid container spacing={3}>
          {user.info.userType.includes(UserRoles.PlatformAdministrator) && (
            <>
              {/* user type (role) select with options */}
              <Grid item xs={12} sm={6}>
                <EditableFormSelect
                  parentName={roles.name}
                  formik={formik}
                  inputLabel={intl.formatMessage({ id: roles.label })}
                  options={[
                    { id: UserRoles.AdministratorInstitutie, label: 'Administrator institutie' },
                    { id: UserRoles.AplicantProiect, label: 'Aplicant proiect' },
                    {
                      id: UserRoles.EvaluatorContestatieEligibilitate,
                      label: 'Evaluator contestatie eligibilitate'
                    },
                    {
                      id: UserRoles.EvaluatorContestatieProgram,
                      label: 'Evaluator contestatie program'
                    },
                    { id: UserRoles.EvaluatorEligibilitate, label: 'Evaluator eligibilitate' },
                    { id: UserRoles.EvaluatorObiectiv, label: 'Evaluator obiectiv' },
                    { id: UserRoles.EvaluatorProgram, label: 'Evaluator program' },
                    { id: UserRoles.EvaluatorRapoarte, label: 'Evaluator rapoarte' },
                    { id: UserRoles.Monitor, label: 'Monitor' }
                  ]}
                  multiple
                  label={intl.formatMessage({ id: roles.label })}
                />
              </Grid>
              {(formik.values[roles.name].includes(UserRoles.AdministratorInstitutie) ||
                formik.values[roles.name].includes(UserRoles.AplicantProiect)) && (
                // institution
                <Grid item xs={12} sm={6}>
                  <EditableFormSelect
                    parentName={institution.name}
                    formik={formik}
                    inputLabel={intl.formatMessage({ id: institution.label })}
                    options={
                      institutions &&
                      institutions.institutions &&
                      institutions.institutions.data &&
                      institutions.institutions.data.map((institution) => ({
                        id: institution.institutieGuid,
                        label: institution.denumireCompleta
                      }))
                    }
                    label={intl.formatMessage({ id: institution.label })}
                    required
                  />
                </Grid>
              )}
            </>
          )}
          {/* lastName */}
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={lastName.name}
              formik={formik}
              label={intl.formatMessage({ id: lastName.label })}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
          {/* firstName */}
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={firstName.name}
              formik={formik}
              label={intl.formatMessage({ id: firstName.label })}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
          {/* email */}
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={email.name}
              formik={formik}
              label={intl.formatMessage({ id: email.label })}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
          {/* phone */}
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={phone.name}
              formik={formik}
              label={intl.formatMessage({ id: phone.label })}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <Button variant="contained" onClick={() => formik.submitForm()} sx={{ marginTop: 2 }}>
          {intl.formatMessage({ id: 'global.save' })}
        </Button>
      </Container>
    </Page>
  );
}
