import informatiiStiintificeForm from './informatiiStiintificeFormModel';

const {
  parentName,
  informatiiStiintificeFormFields: {
    prezentare,
    situatieTara,
    situatieStrainatate,
    contributie,
    dotariExistente,
    dotariNecesare
  }
} = informatiiStiintificeForm;

export const getInitialInformatiiStiintificeFormValues = (proiect) => ({
  [prezentare.name]: proiect?.[parentName]?.[prezentare.name] ?? '',
  [situatieTara.name]: proiect?.[parentName]?.[situatieTara.name] ?? '',
  [situatieStrainatate.name]: proiect?.[parentName]?.[situatieStrainatate.name] ?? '',
  [contributie.name]: proiect?.[parentName]?.[contributie.name] ?? '',
  [dotariExistente.name]: proiect?.[parentName]?.[dotariExistente.name] ?? '',
  [dotariNecesare.name]: proiect?.[parentName]?.[dotariNecesare.name] ?? ''
});
