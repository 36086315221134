import { ACTIONS, ENTITIES } from '../../utils/enums';
import store from '../../utils/store';

const sidebarConfig = [
  {
    title: 'applicant',
    path: '/aplicant',
    display: () => store.getState().user.abilities.can(ACTIONS.VIEW, ENTITIES.APPLICANT),
    nested: [
      {
        title: 'applicant.programmes',
        path: '/aplicant/programe'
      },
      {
        title: 'applicant.documents',
        path: '/aplicant/documente'
      }
    ]
  },
  {
    title: 'eligibility',
    path: '/eligibilitate',
    display: () => store.getState().user.abilities.can(ACTIONS.VIEW, ENTITIES.ELIGIBILITY),
    nested: [
      {
        title: 'applicant.programmes',
        path: '/eligibilitate/programe'
      }
    ]
  },
  {
    title: 'objectives',
    path: '/evaluare-obiective',
    display: () => store.getState().user.abilities.can(ACTIONS.VIEW, ENTITIES.OBJECTIVE),
    nested: [
      {
        title: 'applicant.programmes',
        path: '/evaluare-obiectiv/programe'
      }
    ]
  },
  {
    title: 'programs.evaluation',
    path: '/evaluare-programe',
    display: () => store.getState().user.abilities.can(ACTIONS.VIEW, ENTITIES.PROGRAM),
    nested: [
      {
        title: 'applicant.programmes',
        path: '/evaluare-programe/programe'
      }
    ]
  },
  {
    title: 'monitoring',
    path: '/monitorizare',
    display: () => store.getState().user.abilities.can(ACTIONS.VIEW, ENTITIES.MONITOR),
    nested: [
      {
        title: 'applicant.programmes',
        path: '/monitorizare/programe'
      }
    ]
  },
  {
    title: 'reporting',
    path: '/raportare',
    display: () => store.getState().user.abilities.can(ACTIONS.VIEW, ENTITIES.REPORT),
    nested: [
      {
        title: 'applicant.programmes',
        path: '/raportare/programe'
      }
    ]
  },
  {
    title: 'institution',
    path: '/institutie',
    display: () => store.getState().user.abilities.can(ACTIONS.VIEW, ENTITIES.INSTITUTION),
    nested: [
      {
        title: 'institution.accounts',
        path: '/institutie/conturi'
      },
      {
        title: 'institution.information',
        path: '/institutie/informatii'
      },
      {
        title: 'institution.competitions',
        path: '/institutie/competitii'
      },
      {
        title: 'institution.programmes',
        path: '/institutie/programe'
      },
      {
        title: 'institution.documents',
        path: '/institutie/documente'
      }
    ]
  },
  {
    title: 'administrator',
    path: '/administrator',
    display: () => store.getState().user.abilities.can(ACTIONS.VIEW, ENTITIES.ADMINISTRATOR),
    nested: [
      {
        title: 'administrator.competitions',
        path: '/administrator/competitii'
      },
      {
        title: 'administrator.users',
        path: '/administrator/utilizatori'
      },
      {
        title: 'administrator.institutions',
        path: '/administrator/institutii'
      },
      {
        title: 'administrator.documents',
        path: '/administrator/documente'
      }
    ]
  }
];

export default sidebarConfig;
