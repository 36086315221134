// material
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography
} from '@mui/material';
// components
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
// import Page component
import Page from '../components/Page';

import { programActions } from '../actions';
import { ELIGIBILITY_STATUS } from '../utils/enums';
import ColorButton from '../components/common/ColorButton';

// ----------------------------------------------------------------------

const FileInputStyle = styled(Input)(() => ({
  display: 'none'
}));

export default function InstitutionProgramEligibility() {
  const intl = useIntl();
  const programs = useSelector((state) => state.programs);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [contestationFile, setContestationFile] = useState(null); // set initial state to null
  const [supportDocuments, setSupportDocuments] = useState([]); // set initial state to empty Array

  useEffect(() => {
    dispatch(programActions.getProgramWithEligibility(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // display CircularProgress on programs loading
  if (programs?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  const handleSubmit = () => {
    if (contestationFile) {
      const formData = new FormData();
      formData.append('contestatie', contestationFile);
      if (supportDocuments && supportDocuments.length > 0) {
        supportDocuments.forEach((supportDocument) =>
          formData.append('documenteAditionale', supportDocument)
        );
      }
      dispatch(programActions.contestEligibility(formData, id));
    }
  };
  // display institution_eligibility form
  return (
    <Page title={intl.formatMessage({ id: 'page.institution_eligibility.title' })} my={2}>
      {programs && programs.selectedProgram && (
        <Container>
          <Typography variant="h3">{programs.selectedProgram.eligibility.status}</Typography>
          {programs.selectedProgram.eligibility.status === ELIGIBILITY_STATUS.REJECTED && (
            <>
              <Grid container spacing={3} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <TextField
                    id="eligibility-comments"
                    label={intl.formatMessage({ id: 'global.comments' })}
                    multiline
                    rows={4}
                    defaultValue={programs.selectedProgram.eligibility.comentarii}
                    variant="outlined"
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    id="eligibility-contestation-file"
                    label={intl.formatMessage({ id: 'global.contestation' })}
                    value={contestationFile ? contestationFile.name : ''}
                    variant="outlined"
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <FileInputStyle
                    type="file"
                    name="contestatie"
                    id="contestatie"
                    onChange={(event) => {
                      setContestationFile(event.target.files[0]);
                    }}
                  />
                  <label htmlFor="contestatie">
                    <Box
                      height="100%"
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <ColorButton
                        variant="outlined"
                        borderColor="#005db7"
                        labelColor="#005db7"
                        component="span"
                      >
                        {intl.formatMessage({ id: 'global.upload' })}
                      </ColorButton>
                    </Box>
                  </label>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    id="eligibility-support-files"
                    label={intl.formatMessage({ id: 'global.support_documents' })}
                    value={
                      supportDocuments && supportDocuments.length > 0
                        ? supportDocuments[supportDocuments.length - 1].name
                        : ''
                    }
                    variant="outlined"
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <FileInputStyle
                    type="file"
                    name="support-documents"
                    id="support-documents"
                    onChange={(event) => {
                      setSupportDocuments([...supportDocuments, event.target.files[0]]);
                    }}
                  />
                  <label htmlFor="support-documents">
                    <Box
                      height="100%"
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <ColorButton
                        variant="outlined"
                        borderColor="#005db7"
                        labelColor="#005db7"
                        component="span"
                      >
                        {intl.formatMessage({ id: 'global.upload' })}
                      </ColorButton>
                    </Box>
                  </label>
                </Grid>
                <Grid item xs={12}>
                  {supportDocuments && supportDocuments.length > 0 && (
                    <List dense>
                      {supportDocuments.map((supportDocument) => (
                        <>
                          <ListItem
                            secondaryAction={
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => {
                                  setSupportDocuments(
                                    supportDocuments.filter(
                                      (document) => document.name !== supportDocument.name
                                    )
                                  );
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            }
                          >
                            <ListItemText primary={supportDocument.name} />
                          </ListItem>
                          <Divider />
                        </>
                      ))}
                    </List>
                  )}
                </Grid>
              </Grid>
              <Button variant="contained" sx={{ marginTop: 2 }} onClick={() => handleSubmit()}>
                {intl.formatMessage({ id: 'global.save' })}
              </Button>
            </>
          )}
          {programs.selectedProgram.eligibility.status === ELIGIBILITY_STATUS.ACCEPTED && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} />
            </Grid>
          )}
        </Container>
      )}
    </Page>
  );
}
