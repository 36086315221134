import { Navigate, useRoutes, Outlet } from 'react-router-dom';
// layouts
import { useSelector } from 'react-redux';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import User from './pages/User';
import NotFound from './pages/Page404';
import Contact from './pages/Contact';
import Terms from './pages/Terms';
import ProgrameleMele from './pages/Programe';
import Program from './pages/Program';
import MyAccount from './pages/MyAccount';
import { ACTIONS, ENTITIES } from './utils/enums';
import AplicantDocumente from './pages/AplicantDocumente';
import AdminPlatformaDocumente from './pages/AdminPlatformaDocumente';
import UserForm from './pages/UserForm';
import Institutions from './pages/Institutions';
import InstitutionForm from './pages/InstitutionForm';
import InstitutionInfoForm from './pages/InstitutionInfoForm';
import AdminInstitutieDocumente from './pages/AdminInstitutieDocumente';
import AdminCompetitions from './pages/AdminCompetitions';
import AdminCompetitionForm from './pages/AdminCompetitionForm';
import EligibilityPrograms from './pages/EligibilityPrograms';
import EligibilitySheet from './pages/EligibilitySheet';
import AdminCompetitionPrograms from './pages/AdminCompetitionPrograms';
import AdminCompetitionProgramsEligibility from './pages/AdminCompetitionProgramsEligibility';
import AdminCompetitionProgramsEligibilityContestation from './pages/AdminCompetitionProgramsEligibilityContestation';
import AdminCompetitionProgramsProjectEvaluation from './pages/AdminCompetitionProgramsProjectEvaluation';
import InstitutionPrograms from './pages/InstitutionPrograms';
import InstitutionCompetitions from './pages/InstitutionCompetitions';
import Proiect from './pages/Proiect';
import InstitutionProgramEligibility from './pages/InstitutionProgramEligibility';
import ObjectivePrograms from './pages/ObjectivePrograms';
import ProgramObjectives from './pages/ProgramObjectives';
import ProjectsEvaluationSheets from './pages/ProjectsEvaluationSheets';
import ProjectTechnicalSheet from './pages/ProjectTechnicalSheet';
import AdminCompetitionProgramEvaluation from './pages/AdminCompetitionProgramEvaluation';
import AdminCompetitionProgramEvaluationContestation from './pages/AdminCompetitionProgramEvaluationContestation';
import ProgramsEvaluation from './pages/ProgramsEvaluation';
import ProgramTechnicalSheet from './pages/ProgramTechnicalSheet';
import ProgramEvaluationObjectives from './pages/ProgramEvaluationObjectives';
import ProgramsEvaluationProjectsSheets from './pages/ProgramsEvaluationProjectsSheets';
import ProgramsEvaluationProjectSheet from './pages/ProgramsEvaluationProjectSheet';
import InstitutionProgramEvaluation from './pages/InstitutionProgramEvaluation';
import AdminCompetitionProgramsEvaluationSheets from './pages/AdminCompetitionProgramsEvaluationSheets';
import AdminCompetitionProgramMonitoring from './pages/AdminCompetitionProgramMonitoring';
import MonitorPrograms from './pages/MonitorPrograms';
import MonitorProgramProjects from './pages/MonitorProgramProjects';
import MonitorProgramProjectCycles from './pages/MonitorProgramProjectCycles';
import MonitorProgramProjectCyclePhases from './pages/MonitorProgramProjectCyclePhases';
import MonitorProgramProjectCyclePhase from './pages/MonitorProgramProjectCyclePhase';
import InstitutionProgramMonitoringCycles from './pages/InstitutionProgramMonitoringCycles';
import InstitutionProgramMonitoringCycleProjects from './pages/InstitutionProgramMonitoringCycleProjects';
import InstitutionProgramMonitoringCycleProjectPhases from './pages/InstitutionProgramMonitoringCycleProjectPhases';
import InstitutionProgramMonitoringCycleProjectResults from './pages/InstitutionProgramMonitoringCycleProjectResults';
import AdminCompetitionProgramReporting from './pages/AdminCompetitionProgramReporting';
import InstitutionProgramReportingProjects from './pages/InstitutionProgramReportingProjects';
import InstitutionProgramReportingProjectCycles from './pages/InstitutionProgramReportingProjectCycles';
import InstitutionProgramReportingProjectCyclePhases from './pages/InstitutionProgramReportingProjectCyclePhases';
import InstitutionProgramReportProjectCyclePhaseReporting from './pages/InstitutionProgramReportProjectCyclePhaseReporting';
import ReporterPrograms from './pages/ReporterPrograms';
import ReporterProgramProjects from './pages/ReporterProgramProjects';
import ReporterProgramProjectCycles from './pages/ReporterProgramProjectCycles';
import ReporterProgramProjectCycleReport from './pages/ReporterProgramProjectCycleReport';
import InstitutionProgramReportingProjectCycleResults from './pages/InstitutionProgramReportingProjectCycleResults';
import ResetPassword from './pages/ResetPassword';

// ----------------------------------------------------------------------

export default function Router() {
  const isLoggedIn =
    localStorage.getItem('user') &&
    JSON.parse(localStorage.getItem('user')).accessToken &&
    JSON.parse(localStorage.getItem('user')).accessToken.length > 0;

  const user = useSelector((state) => state.user);

  return useRoutes([
    {
      path: '/',
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: isLoggedIn
        ? [
            {
              path: 'aplicant',
              element: user.abilities.can(ACTIONS.VIEW, ENTITIES.APPLICANT) ? (
                <Outlet />
              ) : (
                <Navigate to="/" />
              ),
              children: [
                {
                  path: 'programe',
                  element: <Outlet />,
                  children: [
                    { path: '', element: <ProgrameleMele /> },
                    { path: ':id', element: <Program role={ENTITIES.APPLICANT} /> },
                    {
                      path: ':idProgram/proiect/:idProiect',
                      element: <Proiect role={ENTITIES.APPLICANT} />
                    },
                    {
                      path: ':idProgram/proiect/:idProiect/faze',
                      element: <Proiect role={ENTITIES.APPLICANT} editFaze />
                    },
                    {
                      path: ':idProgram/evaluare-program',
                      element: <InstitutionProgramEvaluation />
                    },
                    {
                      path: ':idProgram/monitorizare/cicluri',
                      element: <InstitutionProgramMonitoringCycles />
                    },
                    {
                      path: ':idProgram/monitorizare/cicluri/:idCiclu/proiecte',
                      element: <InstitutionProgramMonitoringCycleProjects />
                    },
                    {
                      path: ':idProgram/monitorizare/cicluri/:idCiclu/proiecte/:idProiect/faze',
                      element: <InstitutionProgramMonitoringCycleProjectPhases />
                    },
                    {
                      path: ':idProgram/monitorizare/cicluri/:idCiclu/proiecte/:idProiect/rezultate',
                      element: <InstitutionProgramMonitoringCycleProjectResults />
                    },
                    {
                      path: ':idProgram/raportare/proiecte',
                      element: <InstitutionProgramReportingProjects />
                    },
                    {
                      path: ':idProgram/raportare/proiecte/:idProiect/cicluri',
                      element: <InstitutionProgramReportingProjectCycles />
                    },
                    {
                      path: ':idProgram/raportare/proiecte/:idProiect/cicluri/:idCiclu/faze',
                      element: <InstitutionProgramReportingProjectCyclePhases />
                    },
                    {
                      path: ':idProgram/raportare/proiecte/:idProiect/cicluri/:idCiclu/faze/:idFaza/raportare',
                      element: <InstitutionProgramReportProjectCyclePhaseReporting />
                    },
                    {
                      path: ':idProgram/raportare/proiecte/:idProiect/cicluri/:idCiclu/rezultate',
                      element: <InstitutionProgramReportingProjectCycleResults />
                    }
                  ]
                },
                { path: 'documente', element: <AplicantDocumente /> }
              ]
            },
            {
              path: 'eligibilitate',
              element: user.abilities.can(ACTIONS.VIEW, ENTITIES.ELIGIBILITY) ? (
                <Outlet />
              ) : (
                <Navigate to="/" />
              ),
              children: [
                {
                  path: 'programe',
                  element: <Outlet />,
                  children: [
                    { path: '', element: <EligibilityPrograms /> },
                    { path: 'fisa-eligibilitate/:id', element: <EligibilitySheet /> },
                    { path: ':id', element: <Program /> },
                    { path: ':idProgram/proiect/:idProiect', element: <Proiect /> }
                  ]
                }
              ]
            },
            {
              path: 'evaluare-obiectiv',
              element: user.abilities.can(ACTIONS.VIEW, ENTITIES.OBJECTIVE) ? (
                <Outlet />
              ) : (
                <Navigate to="/" />
              ),
              children: [
                {
                  path: 'programe',
                  element: <Outlet />,
                  children: [
                    { path: '', element: <ObjectivePrograms /> },
                    { path: ':id/obiective', element: <ProgramObjectives /> },
                    {
                      path: ':idProgram/obiective/:idObiectiv/proiecte',
                      element: <ProjectsEvaluationSheets />
                    },
                    {
                      path: ':idProgram/obiective/:idObiectiv/proiecte/:idProiect',
                      element: <Proiect />
                    },
                    {
                      path: ':idProgram/obiective/:idObiectiv/proiecte/:idProiect/fisa',
                      element: <ProjectTechnicalSheet />
                    }
                  ]
                }
              ]
            },
            {
              path: 'evaluare-programe',
              element: user.abilities.can(ACTIONS.VIEW, ENTITIES.PROGRAM) ? (
                <Outlet />
              ) : (
                <Navigate to="/" />
              ),
              children: [
                {
                  path: 'programe',
                  element: <Outlet />,
                  children: [
                    { path: '', element: <ProgramsEvaluation /> },
                    { path: ':id', element: <Program /> },
                    {
                      path: ':idProgram/proiect/:idProiect',
                      element: <Proiect />
                    },
                    { path: ':id/obiective', element: <ProgramEvaluationObjectives /> },
                    {
                      path: ':idProgram/obiective/:idObiectiv/proiecte',
                      element: <ProgramsEvaluationProjectsSheets />
                    },
                    {
                      path: ':idProgram/obiective/:idObiectiv/proiecte/:idProiect/fisa',
                      element: <ProgramsEvaluationProjectSheet />
                    },
                    {
                      path: ':idProgram/fisa',
                      element: <ProgramTechnicalSheet />
                    }
                  ]
                }
              ]
            },
            {
              path: 'monitorizare',
              element: user.abilities.can(ACTIONS.VIEW, ENTITIES.MONITOR) ? (
                <Outlet />
              ) : (
                <Navigate to="/" />
              ),
              children: [
                {
                  path: 'programe',
                  element: <Outlet />,
                  children: [
                    { path: '', element: <MonitorPrograms /> },
                    { path: ':id/proiecte', element: <MonitorProgramProjects /> },
                    {
                      path: ':idProgram/proiecte/:idProiect/cicluri',
                      element: <MonitorProgramProjectCycles />
                    },
                    {
                      path: ':idProgram/proiecte/:idProiect/cicluri/:idCiclu/faze',
                      element: <MonitorProgramProjectCyclePhases />
                    },
                    {
                      path: ':idProgram/proiecte/:idProiect/cicluri/:idCiclu/faze/:idFaza',
                      element: <MonitorProgramProjectCyclePhase />
                    }
                  ]
                }
              ]
            },
            {
              path: 'raportare',
              element: user.abilities.can(ACTIONS.VIEW, ENTITIES.REPORT) ? (
                <Outlet />
              ) : (
                <Navigate to="/" />
              ),
              children: [
                {
                  path: 'programe',
                  element: <Outlet />,
                  children: [
                    { path: '', element: <ReporterPrograms /> },
                    { path: ':id/proiecte', element: <ReporterProgramProjects /> },
                    {
                      path: ':idProgram/proiecte/:idProiect/cicluri',
                      element: <ReporterProgramProjectCycles />
                    },
                    {
                      path: ':idProgram/proiecte/:idProiect/cicluri/:idCiclu/date',
                      element: <ReporterProgramProjectCycleReport />
                    }
                  ]
                }
              ]
            },
            {
              path: 'institutie',
              element: user.abilities.can(ACTIONS.VIEW, ENTITIES.INSTITUTION) ? (
                <Outlet />
              ) : (
                <Navigate to="/" />
              ),
              children: [
                {
                  path: 'conturi',
                  element: <Outlet />,
                  children: [
                    { path: '', element: <User /> },
                    { path: ':id', element: <UserForm /> }
                  ]
                },
                { path: 'informatii', element: <InstitutionInfoForm /> },
                {
                  path: 'competitii',
                  element: <Outlet />,
                  children: [
                    { path: '', element: <InstitutionCompetitions /> },
                    {
                      path: ':id/aplica',
                      element: <Program role={ENTITIES.INSTITUTION} isNew />
                    }
                  ]
                },
                {
                  path: 'programe',
                  element: <Outlet />,
                  children: [
                    { path: '', element: <InstitutionPrograms /> },
                    { path: ':id/eligibilitate', element: <InstitutionProgramEligibility /> },
                    { path: 'new', element: <Program role={ENTITIES.INSTITUTION} isNew /> },
                    { path: ':id', element: <Program role={ENTITIES.INSTITUTION} /> },
                    {
                      path: ':idProgram/proiect/:idProiect',
                      element: <Proiect role={ENTITIES.INSTITUTION} />
                    },
                    {
                      path: ':idProgram/proiect/:idProiect/faze',
                      element: <Proiect role={ENTITIES.INSTITUTION} editFaze />
                    },
                    {
                      path: ':idProgram/evaluare-program',
                      element: <InstitutionProgramEvaluation />
                    },
                    {
                      path: ':idProgram/monitorizare/cicluri',
                      element: <InstitutionProgramMonitoringCycles />
                    },
                    {
                      path: ':idProgram/monitorizare/cicluri/:idCiclu/proiecte',
                      element: <InstitutionProgramMonitoringCycleProjects />
                    },
                    {
                      path: ':idProgram/monitorizare/cicluri/:idCiclu/proiecte/:idProiect/faze',
                      element: <InstitutionProgramMonitoringCycleProjectPhases />
                    },
                    {
                      path: ':idProgram/monitorizare/cicluri/:idCiclu/proiecte/:idProiect/rezultate',
                      element: <InstitutionProgramMonitoringCycleProjectResults />
                    },
                    {
                      path: ':idProgram/raportare/proiecte',
                      element: <InstitutionProgramReportingProjects />
                    },
                    {
                      path: ':idProgram/raportare/proiecte/:idProiect/cicluri',
                      element: <InstitutionProgramReportingProjectCycles />
                    },
                    {
                      path: ':idProgram/raportare/proiecte/:idProiect/cicluri/:idCiclu/faze',
                      element: <InstitutionProgramReportingProjectCyclePhases />
                    },
                    {
                      path: ':idProgram/raportare/proiecte/:idProiect/cicluri/:idCiclu/faze/:idFaza/raportare',
                      element: <InstitutionProgramReportProjectCyclePhaseReporting />
                    },
                    {
                      path: ':idProgram/raportare/proiecte/:idProiect/cicluri/:idCiclu/rezultate',
                      element: <InstitutionProgramReportingProjectCycleResults />
                    }
                  ]
                },
                { path: 'documente', element: <AdminInstitutieDocumente /> }
              ]
            },
            {
              path: 'administrator',
              element: user.abilities.can(ACTIONS.VIEW, ENTITIES.ADMINISTRATOR) ? (
                <Outlet />
              ) : (
                <Navigate to="/" />
              ),
              children: [
                {
                  path: 'competitii',
                  element: <Outlet />,
                  children: [
                    { path: '', element: <AdminCompetitions /> },
                    { path: ':id', element: <AdminCompetitionForm /> },
                    {
                      path: ':id/programe',
                      element: <AdminCompetitionPrograms />
                    },
                    {
                      path: ':idCompetition/programe/:idProgram/eligibilitate',
                      element: <AdminCompetitionProgramsEligibility />
                    },
                    {
                      path: ':idCompetition/programe/:idProgram/contestatie',
                      element: <AdminCompetitionProgramsEligibilityContestation />
                    },
                    {
                      path: ':idCompetition/programe/:idProgram/evaluare-proiect',
                      element: <AdminCompetitionProgramsProjectEvaluation />
                    },
                    {
                      path: ':idCompetition/programe/:idProgram/evaluare-program',
                      element: <AdminCompetitionProgramEvaluation />
                    },
                    {
                      path: ':idCompetition/programe/:idProgram/evaluare-program-contestatie',
                      element: <AdminCompetitionProgramEvaluationContestation />
                    },
                    {
                      path: ':idCompetition/programe/:idProgram/fise-evaluare',
                      element: <AdminCompetitionProgramsEvaluationSheets />
                    },
                    {
                      path: ':idCompetition/programe/:idProgram/monitorizare',
                      element: <AdminCompetitionProgramMonitoring />
                    },
                    {
                      path: ':idCompetition/programe/:idProgram/raportare',
                      element: <AdminCompetitionProgramReporting />
                    }
                  ]
                },
                {
                  path: 'programe',
                  element: <Outlet />,
                  children: [
                    {
                      path: ':id',
                      element: <Program role={ENTITIES.ADMINISTRATOR} />
                    },
                    {
                      path: ':idProgram/proiect/:idProiect',
                      element: <Proiect role={ENTITIES.ADMINISTRATOR} />
                    }
                  ]
                },
                {
                  path: 'utilizatori',
                  element: <Outlet />,
                  children: [
                    { path: '', element: <User /> },
                    { path: ':id', element: <UserForm /> }
                  ]
                },
                {
                  path: 'institutii',
                  element: <Outlet />,
                  children: [
                    { path: '', element: <Institutions /> },
                    { path: ':id', element: <InstitutionForm /> }
                  ]
                },
                { path: 'documente', element: <AdminPlatformaDocumente /> }
              ]
            },
            { path: 'contact', element: <Contact /> },
            { path: 'terms', element: <Terms /> },
            { path: 'my-account', element: <MyAccount /> }
          ]
        : []
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'login-admin', element: <Login isAdmin /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/" /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
