import * as React from 'react';
import { Stack, Backdrop, CircularProgress, Typography } from '@mui/material/';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Document from './Document';
import FileUploadField from '../FileUploadField';

import { programActions } from '../../../actions/index';

// ----------------------------------------------------------------------------

export default function SupportingDocuments({ disabled, isNew }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { id } = useParams();
  const currentDocuments = useSelector(
    (state) => state.programs.selectedProgram?.documenteAditionale
  );
  const navigate = useNavigate();

  if (currentDocuments?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <div>
      <Stack spacing={2}>
        {disabled ? (
          ''
        ) : (
          <>
            <Typography variant="h6" style={{ whiteSpace: 'pre-line' }}>
              {intl.formatMessage({ id: 'program.supportingDocuments.info' })}
            </Typography>
            <FileUploadField
              formDataName="files"
              hideFilenameInput
              label={intl.formatMessage({ id: 'form.program.supportingDocuments.helperText' })}
              buttonLabel={intl.formatMessage({ id: 'page.applicant.documents.choose' })}
              handleUpload={(formData) => {
                if (isNew) {
                  dispatch(
                    programActions.createProgram(id, (program) => {
                      dispatch(
                        programActions.uploadSupportingDocuments(
                          program.programGuid,
                          formData,
                          () =>
                            navigate(`../../../programe/${program.programGuid.toString()}`, {
                              replace: true
                            })
                        )
                      );
                    })
                  );
                } else {
                  dispatch(
                    programActions.uploadSupportingDocuments(id, formData, () =>
                      dispatch(programActions.getProgram(id))
                    )
                  );
                }
              }}
            />
          </>
        )}
        {currentDocuments &&
          currentDocuments.map((document) => (
            <Document
              key={document.fisierUrl}
              document={document}
              disabled={disabled}
              handleDelete={(url) => dispatch(programActions.deleteSupportingDocument(id, url))}
              handleDownload={(url) => dispatch(programActions.downloadSupportingDocument(id, url))}
            />
          ))}
      </Stack>
    </div>
  );
}
