import { Box, Breadcrumbs, Container, Link, Typography } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const MCIBreadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const intl = useIntl();
  const breadcrumbNameMap = {
    '/': intl.formatMessage({ id: 'home' }),
    '/aplicant': intl.formatMessage({ id: 'applicant' }),
    '/aplicant/programe': intl.formatMessage({ id: 'applicant.programmes' }),
    '/aplicant/documente': intl.formatMessage({ id: 'applicant.documents' }),
    '/eligibilitate': intl.formatMessage({ id: 'eligibility' }),
    '/eligibilitate/programe': intl.formatMessage({ id: 'applicant.programmes' }),
    '/eligibilitate/programe/fisa-eligibilitate': intl.formatMessage({
      id: 'competition.eligibility'
    }),
    '/institutie': intl.formatMessage({ id: 'institution' }),
    '/institutie/conturi': intl.formatMessage({ id: 'institution.accounts' }),
    '/institutie/informatii': intl.formatMessage({ id: 'institution.information' }),
    '/institutie/competitii': intl.formatMessage({ id: 'institution.competitions' }),
    '/institutie/programe': intl.formatMessage({ id: 'institution.programmes' }),
    '/institutie/documente': intl.formatMessage({ id: 'applicant.documents' }),
    '/administrator': intl.formatMessage({ id: 'administrator' }),
    '/administrator/competitii': intl.formatMessage({
      id: 'administrator.competitions'
    }),
    '/administrator/utilizatori': intl.formatMessage({ id: 'administrator.users' }),
    '/administrator/institutii': intl.formatMessage({
      id: 'administrator.institutions'
    }),
    '/administrator/documente': intl.formatMessage({ id: 'administrator.documents' }),
    '/contact': intl.formatMessage({ id: 'contact' }),
    '/terms': intl.formatMessage({ id: 'terms' }),
    '/my-account': intl.formatMessage({ id: 'my_account' })
  };

  return (
    <Container maxWidth="false">
      <Box>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkRouter underline="hover" color="inherit" to="/" key="/">
            {breadcrumbNameMap['/']}
          </LinkRouter>
          {pathnames.map((value, index) => {
            const last = index === pathnames.length - 1;
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;
            let breadcrumb = breadcrumbNameMap[to];
            if (!breadcrumb && to.includes('programe') && last) {
              // TODO: cand o sa avem backend, sa luam numele din redux al programului curent
              breadcrumb = 'Programe';
            } else if (!breadcrumb) {
              return '';
            }

            return last ? (
              <Typography color="text.primary" key={to}>
                {breadcrumb}
              </Typography>
            ) : (
              <LinkRouter underline="hover" color="inherit" to={to} key={to}>
                {breadcrumb}
              </LinkRouter>
            );
          })}
        </Breadcrumbs>
      </Box>
    </Container>
  );
};

export default MCIBreadcrumbs;
