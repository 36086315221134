import * as React from 'react';
import { Stack } from '@mui/material';
import ListFilter from '../ListFilter';
// ----------------------------------------------------------------------

export default function CardList({
  entityId,
  sort,
  setSort,
  searchText,
  setSearchText,
  sortFieldTitle,
  items,
  Card,
  cardProps
}) {
  return (
    <>
      {sort && (
        <ListFilter
          sort={sort}
          setSort={setSort}
          searchText={searchText}
          setSearchText={setSearchText}
          sortField={sortFieldTitle}
        />
      )}
      <Stack spacing={2}>
        {items.map((item) => {
          if (cardProps) {
            return <Card key={item[entityId]} entity={item} cardProps={cardProps} />;
          }
          return <Card key={item[entityId]} entity={item} />;
        })}
      </Stack>
    </>
  );
}
