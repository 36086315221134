import { camelCase } from 'lodash';
import expectedResultsForm from './expectedResultsFormModel';

const {
  expectedResultsFormFields: { expectedResults, keywords }
} = expectedResultsForm;

export const getInitialExpectedResultsFormValues = (form) => ({
  [expectedResults.name]: form[expectedResults.name] ?? '',
  [keywords.name]: form[keywords.name] ?? []
});
