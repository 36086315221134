import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useIntl } from 'react-intl';

export default function AlertDialog({ open, title, message, handleOk, handleClose }) {
  const intl = useIntl();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{intl.formatMessage({ id: 'global.cancel' })}</Button>
        <Button onClick={handleOk} autoFocus>
          {intl.formatMessage({ id: 'global.ok' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
