import * as React from 'react';
import { useIntl } from 'react-intl';
// components
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { Button, ButtonGroup } from '@mui/material';

// -------------------------

export default function StepperForm({ steps, completed, disabled }) {
  const intl = useIntl();

  const [activeStep, setActiveStep] = React.useState(0);

  const totalSteps = () => steps.length;
  const completedSteps = () => Object.keys(completed).length;
  const isFirstStep = () => activeStep === 0;
  const isLastStep = () => activeStep === totalSteps() - 1;
  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    // If it's the last step, but not all steps have been completed,
    // find the first step that has been completed
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const handleStep = (stepIndex) => () => {
    if (completed[`step${stepIndex}`] || completed[`step${stepIndex - 1}`]) {
      setActiveStep(stepIndex);
    }
  };

  return (
    <>
      <Stack>
        <Stepper nonLinear activeStep={activeStep} sx={{ marginBottom: '2rem' }}>
          {steps.map((step, index) => (
            <Step key={step.label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {intl.formatMessage({ id: step.label })}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Stack>
      <>
        {steps[activeStep].element}
        <Container sx={{ display: 'flex', justifyContent: 'center' }}>
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
            sx={{ margin: '2rem', textAlign: 'center' }}
          >
            <Button variant="contained" onClick={() => handlePrevious()} disabled={isFirstStep()}>
              {intl.formatMessage({ id: 'form.previous_step' })}
            </Button>
            {!disabled && (
              <Button type="submit" form={steps[activeStep].formId} variant="outlined">
                {intl.formatMessage({ id: 'global.save' })}
              </Button>
            )}
            <Button
              variant="contained"
              onClick={() => handleNext()}
              disabled={isLastStep() || !completed[`step${activeStep}`]}
            >
              {intl.formatMessage({ id: 'form.next_step' })}
            </Button>
          </ButtonGroup>
        </Container>
      </>
    </>
  );
}
