import * as React from 'react';
import { useIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { Collapse, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import AdminProgramCardDetailsRow from './AdminProgramCardDetailsRow';
// ----------------------------------------------------------------------------

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

export default function AdminProgramCard({ program, competition }) {
  const intl = useIntl();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      {program && (
        <Card key={program.programGuid} sx={{ mb: 1 }}>
          <CardHeader
            title={
              <div>
                <p>{program.numeProgram}</p>
              </div>
            }
            action={
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            }
          />
          <CardContent>
            <p>{intl.formatMessage({ id: 'programCard.competition' })}</p>
            <p>{competition.nume}</p>
          </CardContent>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <AdminProgramCardDetailsRow
                startDate={competition.perioadaDepunereIntervalInferior}
                endDate={competition.perioadaDepunereIntervalSuperior}
                title={intl.formatMessage({ id: 'global.submission' })}
                onClick={() => navigate(`../../../programe/${program.programGuid}`)}
              />
              <AdminProgramCardDetailsRow
                startDate={competition.perioadaVerificareEligibilitateIntervalInferior}
                endDate={competition.perioadaVerificareEligibilitateIntervalSuperior}
                title={intl.formatMessage({ id: 'global.check_eligibility' })}
                onClick={() => navigate(`${program.programGuid}/eligibilitate`)}
              />
              <AdminProgramCardDetailsRow
                startDate={competition.perioadaContestatieEligibilitateIntervalInferior}
                endDate={competition.perioadaContestatieEligibilitateIntervalSuperior}
                title={intl.formatMessage({ id: 'global.check_eligibility_contestation' })}
                onClick={() => navigate(`${program.programGuid}/contestatie`)}
              />
              <AdminProgramCardDetailsRow
                startDate={competition.perioadaEvaluareTehnicaProiecteIntervalInferior}
                endDate={competition.perioadaEvaluareTehnicaProiecteIntervalSuperior}
                title={intl.formatMessage({ id: 'global.check_projects_technical' })}
                onClick={() => navigate(`${program.programGuid}/evaluare-proiect`)}
              />
              <AdminProgramCardDetailsRow
                startDate={competition.perioadaEvaluareTehnicaProgramIntervalInferior}
                endDate={competition.perioadaEvaluareTehnicaProgramIntervalSuperior}
                title={intl.formatMessage({ id: 'global.check_program_technical' })}
                onClick={() => navigate(`${program.programGuid}/evaluare-program`)}
              />
              <AdminProgramCardDetailsRow
                startDate={competition.perioadaMonitorizareIntervalInferior}
                endDate={competition.perioadaMonitorizareIntervalSuperior}
                title={intl.formatMessage({ id: 'global.check_monitoring' })}
                onClick={() => navigate(`${program.programGuid}/monitorizare`)}
              />
              <AdminProgramCardDetailsRow
                startDate={competition.perioadaRaportareIntervalInferior}
                endDate={competition.perioadaRaportareIntervalSuperior}
                title={intl.formatMessage({ id: 'global.check_reporting' })}
                onClick={() => navigate(`${program.programGuid}/raportare`)}
              />
            </CardContent>
          </Collapse>
        </Card>
      )}
    </>
  );
}
