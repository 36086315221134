import adminProjectsEvaluationAssignForm from './adminProjectsEvaluationAssignFormModel';

const {
  adminProjectsEvaluationAssignField: { responsibles }
} = adminProjectsEvaluationAssignForm;

export const getInitialAdminProjectsEvaluationAssignFormValues = (
  adminProjectsEvaluationAssign
) => ({
  [responsibles.name]: adminProjectsEvaluationAssign.mapping
    ? adminProjectsEvaluationAssign.mapping.map((responsabil) => ({
        [responsibles.objectives.name]: responsabil.obiectiveGuid.map((idObiectiv) => ({
          id: idObiectiv
        })),
        [responsibles.responsible1.name]: { id: responsabil.userReviewer1 },
        [responsibles.responsible2.name]: { id: responsabil.userReviewer2 },
        [responsibles.moderator.name]: { id: responsabil.userModerator }
      }))
    : []
});
