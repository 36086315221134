import { useIntl } from 'react-intl';
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Stack,
  Tab,
  Typography
} from '@mui/material';
// The Effect Hook performs side effects in function components:
import { useEffect, useState } from 'react';
// dispatch is used as a callback which gets invoked once some async action is complete
// useSelector allows you to extract data from the Redux store state, using a selector function.
import { useDispatch, useSelector } from 'react-redux';
// imports everything from the react module
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import DownloadIcon from '@mui/icons-material/Download';
// imports everything from the xlsx module
import * as XLSX from 'xlsx';
// import Page component
import Page from '../components/Page';
import { competitionActions } from '../actions';
import AdminProgramCard from '../components/cards/AdminProgramCard';
import AdminProgramEvaluationCard from '../components/cards/AdminProgramEvaluationCard';
// ----------------------------------------------------------------------

export default function AdminCompetitionPrograms() {
  const intl = useIntl();
  // useSelector extracts  data from the competitions state
  const competitions = useSelector((state) => state.competitions);
  // dispatch is used as a callback which gets invoked once some async action is complete.
  const dispatch = useDispatch();
  const { id } = useParams();
  const [value, setValue] = useState('1'); // set initial State to 1

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const downloadExcel = (programs) => {
    const header = [
      intl.formatMessage({ id: 'institution' }),
      intl.formatMessage({ id: 'global.program' }),
      intl.formatMessage({ id: 'global.result' })
    ];
    const excelData = programs.map((program) => ({
      institution: program.numeInstitutie,
      program: program.numeProgram,
      result: `${program.fisaEvaluareFinala.criterii.reduce(
        (prev, curr) => prev + curr.scor,
        0
      )} / ${program.fisaEvaluareFinala.criterii.reduce((prev, curr) => prev + curr.scorMaxim, 0)}`
    }));
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [header]);
    XLSX.utils.sheet_add_json(ws, excelData, { origin: 'A2', skipHeader: true });
    const wb = {
      Sheets: { [intl.formatMessage({ id: 'institution.program.evaluation.tab4' })]: ws },
      SheetNames: [`${intl.formatMessage({ id: 'institution.program.evaluation.tab4' })}`]
    };
    XLSX.writeFile(wb, `${intl.formatMessage({ id: 'institution.program.evaluation.tab4' })}.xlsx`);
  };

  // The Effect Hook performs side effects in function components:
  // dispatch is used as a callback which gets invoked once some async action is complete.
  useEffect(() => {
    dispatch(competitionActions.getCompetition(id, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // display CircularProgress on competitions loading
  if (competitions?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  // display Panel with 2 Tabs
  return (
    <Page title={intl.formatMessage({ id: 'institution.competitions' })} my={2}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            {intl.formatMessage({ id: 'page.admin.competitions.title' })}
          </Typography>
        </Stack>
        <TabContext value={value}>
          <TabList onChange={handleChange} aria-label="tabs">
            <Tab label={intl.formatMessage({ id: 'admin.competition.program.tab1' })} value="1" />
            <Tab label={intl.formatMessage({ id: 'admin.competition.program.tab2' })} value="2" />
          </TabList>
          {/* TabPanel item 1 */}
          <TabPanel value="1" index={0}>
            {competitions &&
              competitions.selectedCompetition &&
              competitions.selectedCompetition.programs &&
              competitions.selectedCompetition.programs.map((program) => (
                <AdminProgramCard
                  key={program.programGuid}
                  program={program}
                  competition={competitions.selectedCompetition}
                />
              ))}
          </TabPanel>
          {/* TabPanel item 2 */}
          <TabPanel value="2" index={1}>
            {competitions &&
              competitions.selectedCompetition &&
              competitions.selectedCompetition.programs && (
                <>
                  <Button
                    variant="outlined"
                    sx={{ mb: 2 }}
                    onClick={() => downloadExcel(competitions.selectedCompetition.programs)}
                    startIcon={<DownloadIcon />}
                  >
                    {intl.formatMessage({ id: 'admin.program.evaluation.export' })}
                  </Button>
                  {competitions.selectedCompetition.programs.map((program) => (
                    <AdminProgramEvaluationCard
                      key={program.programGuid}
                      program={program}
                      competition={competitions.selectedCompetition}
                    />
                  ))}
                </>
              )}
          </TabPanel>
        </TabContext>
      </Container>
    </Page>
  );
}
