import { documentConstants } from '../constants';

export function documents(state = { loading: false, saving: false, documents: [] }, action) {
  switch (action.type) {
    case documentConstants.DOCUMENT_GET_ALL_REQUEST:
    case documentConstants.DOCUMENT_GET_ALL_ADMIN_REQUEST:
    case documentConstants.DOCUMENT_DELETE_REQUEST:
    case documentConstants.DOCUMENT_DELETE_ADMIN_REQUEST:
    case documentConstants.DOCUMENT_DOWNLOAD_REPORT_DOCUMENT_REQUEST:
    case documentConstants.DOCUMENT_DOWNLOAD_PROJECT_RESPONSIBLE_CV_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case documentConstants.DOCUMENT_UPLOAD_REQUEST:
    case documentConstants.DOCUMENT_UPLOAD_ADMIN_REQUEST:
    case documentConstants.DOCUMENT_UPLOAD_ANEXA2_REQUEST:
    case documentConstants.DOCUMENT_UPLOAD_ACORD_REALOCARE_REQUEST:
    case documentConstants.DOCUMENT_UPLOAD_ACT_ADITIONAL_CONTRACT_FINANTARE_REQUEST:
      return {
        ...state,
        saving: true
      };
    case documentConstants.DOCUMENT_GET_ALL_SUCCESS:
    case documentConstants.DOCUMENT_GET_ALL_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: action.documents
      };
    case documentConstants.DOCUMENT_DELETE_SUCCESS:
    case documentConstants.DOCUMENT_DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: state.documents.filter((document) => document.fisierUrl !== action.fisierUrl)
      };

    case documentConstants.DOCUMENT_UPLOAD_SUCCESS:
    case documentConstants.DOCUMENT_UPLOAD_ADMIN_SUCCESS:
      return {
        ...state,
        saving: false,
        documents: [...state.documents, ...action.documents]
      };
    case documentConstants.DOCUMENT_UPLOAD_ANEXA2_SUCCESS:
    case documentConstants.DOCUMENT_UPLOAD_ACT_ADITIONAL_CONTRACT_FINANTARE_SUCCESS:
    case documentConstants.DOCUMENT_UPLOAD_ACORD_REALOCARE_SUCCESS:
      return {
        ...state,
        saving: false
      };
    case documentConstants.DOWNLOAD_PROGRAM_DOCUMENT_SUCCESS:
    case documentConstants.DOCUMENT_DOWNLOAD_REPORT_DOCUMENT_SUCCESS:
    case documentConstants.DOCUMENT_DOWNLOAD_PROJECT_RESPONSIBLE_CV_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case documentConstants.DOCUMENT_GET_ALL_FAILURE:
    case documentConstants.DOCUMENT_GET_ALL_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        documents: [],
        error: action.error
      };
    case documentConstants.DOCUMENT_DELETE_FAILURE:
    case documentConstants.DOCUMENT_DOWNLOAD_FAILURE:
    case documentConstants.DOCUMENT_DELETE_ADMIN_FAILURE:
    case documentConstants.DOCUMENT_DOWNLOAD_ADMIN_FAILURE:
    case documentConstants.DOCUMENT_DOWNLOAD_REPORT_DOCUMENT_FAILURE:
    case documentConstants.DOCUMENT_DOWNLOAD_PROJECT_RESPONSIBLE_CV_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case documentConstants.DOCUMENT_UPLOAD_FAILURE:
    case documentConstants.DOCUMENT_UPLOAD_ADMIN_FAILURE:
    case documentConstants.DOCUMENT_UPLOAD_ANEXA2_FAILURE:
    case documentConstants.DOCUMENT_UPLOAD_ACT_ADITIONAL_CONTRACT_FINANTARE_FAILURE:
    case documentConstants.DOCUMENT_UPLOAD_ACORD_REALOCARE_FAILURE:
      return {
        ...state,
        saving: false,
        error: action.error
      };
    default:
      return state;
  }
}
