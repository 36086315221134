import { useIntl } from 'react-intl';
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import { Fragment, useEffect } from 'react';
// dispatch is used as a callback which gets invoked once some async action is complete
// useSelector allows you to extract data from the Redux store state, using a selector function.
import { useDispatch, useSelector } from 'react-redux';
// imports everything from the react module
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
// import Page component
import Page from '../components/Page';
import { competitionActions, userActions, programActions } from '../actions';
import EditableFormAutocomplete from '../forms/shared/EditableFormAutocomplete';
import { UserRoles } from '../domain/UserRoles';
import adminProjectsEvaluationAssignFormModel from '../forms/programs/adminProjectsEvaluationAssign/adminProjectsEvaluationAssignFormModel';
import { getInitialAdminProjectsEvaluationAssignFormValues } from '../forms/programs/adminProjectsEvaluationAssign/formInitialValues';
import { adminProjectsEvaluationAssignFormValidationSchema } from '../forms/programs/adminProjectsEvaluationAssign/validationSchema';
import { parseAdminProjectEvaluation } from '../forms/programs/adminProjectsEvaluationAssign/formOutputParser';
// ----------------------------------------------------------------------

const {
  adminProjectsEvaluationAssignField: { responsibles }
} = adminProjectsEvaluationAssignFormModel;

export default function AdminCompetitionProgramsProjectEvaluation() {
  const intl = useIntl();
  // useSelector extracts  data from the competitions state
  const competitions = useSelector((state) => state.competitions);
  // useSelector extracts  data from the programs state
  const programs = useSelector((state) => state.programs);
  // useSelector extracts  data from the user state
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { idCompetition, idProgram } = useParams();

  // The Effect Hook performs side effects in function components:
  // dispatch is used as a callback which gets invoked once some async action is complete.
  useEffect(() => {
    dispatch(competitionActions.getCompetition(idCompetition)); // get competition by  id
    dispatch(programActions.getProgram(idProgram)); // get Program by  id
    dispatch(programActions.getProgramProjectsEvaluationResponsibles(idProgram)); // get ProgramEligibility Responsibles by Program id
    dispatch(userActions.getAll(user.info.userType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: getInitialAdminProjectsEvaluationAssignFormValues(
      programs.projectsEvaluationResponsibles || {}
    ),
    enableReinitialize: true,
    validationSchema: adminProjectsEvaluationAssignFormValidationSchema(),
    onSubmit: (values) => {
      const valuesCopy = JSON.parse(JSON.stringify(values));
      dispatch(
        programActions.updateProgramProjectsEvaluationResponsibles(
          parseAdminProjectEvaluation(valuesCopy, idProgram)
        )
      );
    }
  });
  // display CircularProgress on competitions or  programs loading
  if (competitions?.loading || programs?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  let hasUnassignedObjectives = false;

  if (programs && programs.selectedProgram) {
    // eslint-disable-next-line no-restricted-syntax
    const objectiveIds = [];
    programs.selectedProgram.obiectiveSiProiecte.obiectiveSiProiecte.forEach((obiectiv) => {
      objectiveIds.push(obiectiv.obiectivGuid);
    });
    // eslint-disable-next-line no-restricted-syntax
    for (const objectiveId of objectiveIds) {
      let assigned = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const responsible of formik.values[responsibles.name]) {
        if (
          responsible[responsibles.objectives.name].find(
            (assignedObjective) => assignedObjective.id === objectiveId
          )
        ) {
          assigned = true;
          break;
        }
      }
      if (!assigned) {
        hasUnassignedObjectives = true;
        break;
      }
    }
  }

  // display Grid with 4 items
  return (
    <Page title={intl.formatMessage({ id: 'institution.competitions' })} my={2}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            {intl.formatMessage({ id: 'page.admin.competitions.program.project.evaluation' })}
          </Typography>
        </Stack>
        {competitions.selectedCompetition &&
          programs.selectedProgram &&
          programs.projectsEvaluationResponsibles &&
          user.users &&
          user.users.data && (
            <Grid container spacing={5}>
              {formik.values[responsibles.name].map((responsible, index) => (
                <Fragment key={index}>
                  {/* Grid item 1 size 12/12 (full) of viewport */}
                  <Grid item xs={12} sm={12}>
                    <EditableFormAutocomplete
                      parentName={responsibles.name}
                      childrenNames={[responsibles.objectives.name]}
                      formik={formik}
                      required
                      label={intl.formatMessage({ id: responsibles.objectives.label })}
                      variant="outlined"
                      fullWidth
                      multiple
                      index={index}
                      options={programs.selectedProgram.obiectiveSiProiecte.obiectiveSiProiecte
                        .map((obiectiv) => ({
                          id: obiectiv.obiectivGuid,
                          label: obiectiv.titluObiectiv
                        }))
                        .filter((obiectiv) => {
                          if (index === 0 && formik.values[responsibles.name].length > 1) {
                            for (let i = 1; i < formik.values[responsibles.name].length; i += 1) {
                              if (
                                formik.values[responsibles.name][i][
                                  responsibles.objectives.name
                                ].find((o) => o.id === obiectiv.id)
                              ) {
                                return false;
                              }
                            }
                          }
                          for (let i = 0; i < index; i += 1) {
                            if (
                              formik.values[responsibles.name][i][
                                responsibles.objectives.name
                              ].find((p) => p.id === obiectiv.id)
                            ) {
                              return false;
                            }
                          }
                          return true;
                        })}
                    />
                  </Grid>
                  {/* Grid item 2 size 4/12 of viewport */}
                  <Grid item xs={4} sm={4}>
                    <EditableFormAutocomplete
                      parentName={responsibles.name}
                      childrenNames={[responsibles.responsible1.name]}
                      formik={formik}
                      required
                      label={intl.formatMessage({ id: responsibles.responsible1.label })}
                      variant="outlined"
                      fullWidth
                      index={index}
                      options={user.users.data
                        .filter(
                          (userOption) =>
                            !userOption.userType.includes(UserRoles.PlatformAdministrator) &&
                            userOption.userType.includes(UserRoles.EvaluatorObiectiv)
                        )
                        .map((userOption) => ({
                          id: userOption.id,
                          label: `${userOption.firstName} ${userOption.lastName}`
                        }))}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <EditableFormAutocomplete
                      parentName={responsibles.name}
                      childrenNames={[responsibles.responsible2.name]}
                      formik={formik}
                      required
                      label={intl.formatMessage({ id: responsibles.responsible2.label })}
                      variant="outlined"
                      fullWidth
                      index={index}
                      options={user.users.data
                        .filter(
                          (userOption) =>
                            !userOption.userType.includes(UserRoles.PlatformAdministrator) &&
                            userOption.userType.includes(UserRoles.EvaluatorObiectiv)
                        )
                        .map((userOption) => ({
                          id: userOption.id,
                          label: `${userOption.firstName} ${userOption.lastName}`
                        }))}
                    />
                  </Grid>
                  {/* Grid item 3 size 4/12 of viewport */}
                  <Grid item xs={4} sm={4}>
                    <EditableFormAutocomplete
                      parentName={responsibles.name}
                      childrenNames={[responsibles.moderator.name]}
                      formik={formik}
                      required
                      label={intl.formatMessage({ id: responsibles.moderator.label })}
                      variant="outlined"
                      fullWidth
                      index={index}
                      options={user.users.data
                        .filter(
                          (userOption) =>
                            !userOption.userType.includes(UserRoles.PlatformAdministrator) &&
                            userOption.userType.includes(UserRoles.EvaluatorObiectiv)
                        )
                        .map((userOption) => ({
                          id: userOption.id,
                          label: `${userOption.firstName} ${userOption.lastName}`
                        }))}
                    />
                  </Grid>
                </Fragment>
              ))}
              {hasUnassignedObjectives && (
                // Grid item 4 size 4/12  of  viewport    if hasUnassignedObjectives
                <Grid item xs={3} sm={3}>
                  <Button
                    variant="contained"
                    onClick={() =>
                      formik.setFieldValue(responsibles.name, [
                        ...formik.values[responsibles.name],
                        {
                          [responsibles.objectives.name]: [],
                          [responsibles.responsible1.name]: null,
                          [responsibles.responsible2.name]: null,
                          [responsibles.moderator.name]: null
                        }
                      ])
                    }
                  >
                    {intl.formatMessage({
                      id: 'competitions.project_evaluation.add_responsibles_set'
                    })}
                  </Button>
                </Grid>
              )}
              {!hasUnassignedObjectives && (
                // Grid item 4 size 4/12  of  viewport   if doesn't hasUnassignedObjectives
                <Grid item xs={3} sm={3}>
                  <Button variant="contained" onClick={() => formik.submitForm()}>
                    {intl.formatMessage({
                      id: 'competitions.project_evaluation.save_responsibles'
                    })}
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
      </Container>
    </Page>
  );
}
