// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {};

export default function Logo() {
  return <Box component="img" src="/static/logo.png" style={{ maxWidth: 'fit-content' }} />;
}
