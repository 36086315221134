// components

import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';
// imports everything in the react module
import * as React from 'react';
import Page from '../components/Page';
// stepper component for multi-step forms
import StepperForm from '../components/form/StepperForm';
// CompetitionInformation component
import CompetitionInformation from '../components/form/competition/CompetitionInformation';
// import list of constants
import { FORM_IDS } from '../utils/enums';
// import CompetitionEligibility
import CompetitionEligibility from '../components/form/competition/CompetitionEligibility';
// import CompetitionProgramEvaluation component
import CompetitionProgramEvaluation from '../components/form/competition/CompetitionProgramEvaluation';
// import CompetitionProjectEvaluation component
import CompetitionProjectEvaluation from '../components/form/competition/CompetitionProjectEvaluation';

// ----------------------------------------------------------------------

export default function AdminCompetitionForm() {
  // The useParams hook returns an object of key/value pairs of the dynamic params from the current URL that were matched by the <Route path>. Child routes inherit all params from their parent routes.
  const { id } = useParams();
  // useIntl hook: Once you've declared your IntlProvider, you can get access to the intl object via calling this hook in a functional React component
  const intl = useIntl();
  // useState is a React Hook that lets you add a state variable to your component.
  const [completed, setCompleted] = React.useState({
    step0: false,
    step1: false,
    step2: false,
    step3: false
  });
  // 4 steps in the StepperForm
  //   step1 -->  competition.information //form Informatii competitie
  //   step2 -->  competition.eligibility //form Fisa eligibilitate
  //   step3 -->  competition.program_evaluation //form Fisa evaluare tehnica program
  //   step4 -->  competition.project_evaluation //form Fisa evaluare tehnica proiect

  return (
    <Page title={intl.formatMessage({ id: 'page.add_competition.title' })} my={2}>
      <Container>
        <StepperForm
          completed={completed}
          steps={[
            {
              //   step1 -->  competition.information //form Informatii competitie
              label: intl.formatMessage({ id: 'competition.information' }),
              element: (
                <CompetitionInformation
                  id={id}
                  completeStep={() => setCompleted({ ...completed, step0: true })}
                />
              ),
              formId: FORM_IDS.ADMIN_COMPETITION_INFO
            },
            {
              //   step2 -->  competition.eligibility //form Fisa eligibilitate
              label: intl.formatMessage({ id: 'competition.eligibility' }),
              element: (
                <CompetitionEligibility
                  id={id}
                  completeStep={() => setCompleted({ ...completed, step1: true })}
                />
              ),
              formId: FORM_IDS.ADMIN_COMPETITION_ELIGIBILITY
            },
            {
              //   step3 -->  competition.program_evaluation //form Fisa evaluare tehnica program
              label: intl.formatMessage({ id: 'competition.program_evaluation' }),
              element: (
                <CompetitionProgramEvaluation
                  id={id}
                  completeStep={() => setCompleted({ ...completed, step2: true })}
                />
              ),
              formId: FORM_IDS.ADMIN_COMPETITION_PROGRAM_EVALUATION
            },
            {
              //   step4 -->  competition.project_evaluation //form Fisa evaluare tehnica proiect
              label: intl.formatMessage({ id: 'competition.project_evaluation' }),
              element: (
                <CompetitionProjectEvaluation
                  id={id}
                  completeStep={() => setCompleted({ ...completed, step3: true })}
                />
              ),
              formId: FORM_IDS.ADMIN_COMPETITION_PROJECT_EVALUATION
            }
          ]}
        />
      </Container>
    </Page>
  );
}
