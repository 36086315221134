import adminEligibilityContestationAssignFormModel from './adminEligibilityContestationAssignFormModel';

const {
  adminEligibilityContestationAssignField: { writer, reviwer }
} = adminEligibilityContestationAssignFormModel;

export const getInitialAdminEligibilityContestationAssignFormValues = (
  adminEligibilityContestationAssign
) => ({
  [writer.name]: adminEligibilityContestationAssign.userContestatieWriterGuid
    ? { id: adminEligibilityContestationAssign.userContestatieWriterGuid }
    : '',
  [reviwer.name]: adminEligibilityContestationAssign.userContestatieReviewerGuid
    ? { id: adminEligibilityContestationAssign.userContestatieReviewerGuid }
    : ''
});
