import * as React from 'react';
import { useIntl } from 'react-intl';
import { Box, Divider, Grid } from '@mui/material';
import moment from 'moment';
import Button from '@mui/material/Button';
import { defaultConfig } from '../../utils/config';
// ----------------------------------------------------------------------------

export default function AdminProgramCardDetailsRow({
  startDate,
  endDate,
  title,
  onClick,
  buttonText
}) {
  const intl = useIntl();

  return (
    <>
      <Divider />
      <Box sx={{ mt: 1, mb: 1 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={9} sm={9}>
            <Grid item xs={6} sm={6}>
              <p style={{ color: 'gray' }}>
                {`${moment(startDate).format(defaultConfig.dateTimeFormats.card)}
                 - 
                 ${moment(endDate).format(defaultConfig.dateTimeFormats.card)}
                 `}
              </p>
            </Grid>
            <Grid item xs={6} sm={6}>
              <p>{title}</p>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Grid container item xs={12} sm={12} justifyContent="flex-end">
              {onClick && (
                <Button variant="outlined" onClick={onClick}>
                  {buttonText || intl.formatMessage({ id: 'global.details' })}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
