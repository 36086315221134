import * as React from 'react';
import { useIntl } from 'react-intl';

import { Typography } from '@mui/material';
import EditableFormCheckbox from '../../../forms/shared/EditableFormCheckbox';

import declaratiiForm from '../../../forms/proiect/declaratii/declaratiiFormModel';

const {
  declaratiiFormFields: { nuEsteFinantat, dateReale }
} = declaratiiForm;

export default function Declaratii({ formik, disabled }) {
  const intl = useIntl();

  return (
    <div>
      <Typography variant="h6">
        {intl.formatMessage({ id: 'page.project.declarations.title' })}
      </Typography>
      <p>{intl.formatMessage({ id: 'page.project.declarations.intro' })}</p>
      <EditableFormCheckbox
        parentName={nuEsteFinantat.name}
        formik={formik}
        inputLabel={intl.formatMessage({ id: nuEsteFinantat.label })}
        variant="outlined"
        disabled={disabled}
      />
      <EditableFormCheckbox
        parentName={dateReale.name}
        formik={formik}
        inputLabel={intl.formatMessage({ id: dateReale.label })}
        variant="outlined"
        disabled={disabled}
      />
    </div>
  );
}
