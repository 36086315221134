import { Grid } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';

import { useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useNavigate } from 'react-router-dom';
import { getInitialCompetitionInformationFormValues } from '../../../forms/competitions/competitionInformation/formInitialValues';
import { competitionInformationFormValidationSchema } from '../../../forms/competitions/competitionInformation/validationSchema';
import competitionInformationFormModel from '../../../forms/competitions/competitionInformation/competitionInformationFormModel';
import EditableFormTextField from '../../../forms/shared/EditableFormTextField';
import { defaultConfig } from '../../../utils/config';
import { FORM_IDS } from '../../../utils/enums';
import EditableFormDatePicker from '../../../forms/shared/EditableFormDatePicker';
import { competitionActions, institutionActions } from '../../../actions';
import EditableFormAutocomplete from '../../../forms/shared/EditableFormAutocomplete';

// ----------------------------------------------------------------------------

const {
  competitionInformationFormField: {
    name,
    startDate,
    endDate,
    submitStartDate,
    submitEndDate,
    checkEligibilityStartDate,
    checkEligibilityEndDate,
    eligibilityContestationStartDate,
    eligibilityContestationEndDate,
    technicalEvaluationProjectStartDate,
    technicalEvaluationProjectEndDate,
    technicalEvaluationProgrammeStartDate,
    technicalEvaluationProgrammeEndDate,
    technicalEvaluationContestationStartDate,
    technicalEvaluationContestationEndDate,
    technicalEvaluationContestationSolutionStartDate,
    technicalEvaluationContestationSolutionEndDate,
    monitoringStartDate,
    monitoringEndDate,
    reportingStartDate,
    reportingEndDate,
    competitionInstitutions
  }
} = competitionInformationFormModel;

export default function CompetitionInformation({ id, disabled, completeStep }) {
  const intl = useIntl();
  const navigate = useNavigate();
  const competitions = useSelector((state) => state.competitions);
  const institutions = useSelector((state) => state.institutions);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id !== '0') {
      dispatch(competitionActions.getCompetition(id, true));
      completeStep();
    } else {
      dispatch(competitionActions.clearSelectedCompetition());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!institutions || !institutions.institutions || !institutions.institutions.data) {
      dispatch(institutionActions.getAll());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: getInitialCompetitionInformationFormValues(
      competitions.selectedCompetition || {}
    ),
    validationSchema: competitionInformationFormValidationSchema(),
    enableReinitialize: true,
    onSubmit: (values) => {
      const valuesCopy = JSON.parse(JSON.stringify(values));
      if (id !== '0') {
        valuesCopy.CompetitionGuid = valuesCopy.id;
        delete valuesCopy.id;
        dispatch(
          competitionActions.updateCompetition(valuesCopy, (newCompetitionId) => {
            completeStep();
            navigate(`../${newCompetitionId}`);
          })
        );
      } else {
        dispatch(
          competitionActions.addCompetition(valuesCopy, (newCompetitionId) => {
            completeStep();
            navigate(`../${newCompetitionId}`);
          })
        );
      }
    }
  });

  return (
    <form id={FORM_IDS.ADMIN_COMPETITION_INFO} onSubmit={formik.handleSubmit} noValidate>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12}>
            <EditableFormTextField
              parentName={name.name}
              formik={formik}
              label={intl.formatMessage({ id: name.label })}
              variant="outlined"
              fullWidth
              disabled={disabled}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormDatePicker
              parentName={startDate.name}
              formik={formik}
              required
              label={intl.formatMessage({ id: startDate.label })}
              variant="outlined"
              format={defaultConfig.dateTimeFormats.short}
              value={formik.values[startDate.name]}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormDatePicker
              parentName={endDate.name}
              formik={formik}
              required
              label={intl.formatMessage({ id: endDate.label })}
              variant="outlined"
              format={defaultConfig.dateTimeFormats.short}
              value={formik.values[endDate.name]}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormDatePicker
              parentName={submitStartDate.name}
              formik={formik}
              required
              label={intl.formatMessage({ id: submitStartDate.label })}
              variant="outlined"
              format={defaultConfig.dateTimeFormats.short}
              value={formik.values[submitStartDate.name]}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormDatePicker
              parentName={submitEndDate.name}
              formik={formik}
              required
              label={intl.formatMessage({ id: submitEndDate.label })}
              variant="outlined"
              format={defaultConfig.dateTimeFormats.short}
              value={formik.values[submitEndDate.name]}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormDatePicker
              parentName={checkEligibilityStartDate.name}
              formik={formik}
              required
              label={intl.formatMessage({ id: checkEligibilityStartDate.label })}
              variant="outlined"
              format={defaultConfig.dateTimeFormats.short}
              value={formik.values[checkEligibilityStartDate.name]}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormDatePicker
              parentName={checkEligibilityEndDate.name}
              formik={formik}
              required
              label={intl.formatMessage({ id: checkEligibilityEndDate.label })}
              variant="outlined"
              format={defaultConfig.dateTimeFormats.short}
              value={formik.values[checkEligibilityEndDate.name]}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormDatePicker
              parentName={eligibilityContestationStartDate.name}
              formik={formik}
              required
              label={intl.formatMessage({ id: eligibilityContestationStartDate.label })}
              variant="outlined"
              format={defaultConfig.dateTimeFormats.short}
              value={formik.values[eligibilityContestationStartDate.name]}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormDatePicker
              parentName={eligibilityContestationEndDate.name}
              formik={formik}
              required
              label={intl.formatMessage({ id: eligibilityContestationEndDate.label })}
              variant="outlined"
              format={defaultConfig.dateTimeFormats.short}
              value={formik.values[eligibilityContestationEndDate.name]}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormDatePicker
              parentName={technicalEvaluationProjectStartDate.name}
              formik={formik}
              required
              label={intl.formatMessage({ id: technicalEvaluationProjectStartDate.label })}
              variant="outlined"
              format={defaultConfig.dateTimeFormats.short}
              value={formik.values[technicalEvaluationProjectStartDate.name]}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormDatePicker
              parentName={technicalEvaluationProjectEndDate.name}
              formik={formik}
              required
              label={intl.formatMessage({ id: technicalEvaluationProjectEndDate.label })}
              variant="outlined"
              format={defaultConfig.dateTimeFormats.short}
              value={formik.values[technicalEvaluationProjectEndDate.name]}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormDatePicker
              parentName={technicalEvaluationProgrammeStartDate.name}
              formik={formik}
              required
              label={intl.formatMessage({ id: technicalEvaluationProgrammeStartDate.label })}
              variant="outlined"
              format={defaultConfig.dateTimeFormats.short}
              value={formik.values[technicalEvaluationProgrammeStartDate.name]}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormDatePicker
              parentName={technicalEvaluationProgrammeEndDate.name}
              formik={formik}
              required
              label={intl.formatMessage({ id: technicalEvaluationProgrammeEndDate.label })}
              variant="outlined"
              format={defaultConfig.dateTimeFormats.short}
              value={formik.values[technicalEvaluationProgrammeEndDate.name]}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormDatePicker
              parentName={technicalEvaluationContestationStartDate.name}
              formik={formik}
              required
              label={intl.formatMessage({ id: technicalEvaluationContestationStartDate.label })}
              variant="outlined"
              format={defaultConfig.dateTimeFormats.short}
              value={formik.values[technicalEvaluationContestationStartDate.name]}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormDatePicker
              parentName={technicalEvaluationContestationEndDate.name}
              formik={formik}
              required
              label={intl.formatMessage({ id: technicalEvaluationContestationEndDate.label })}
              variant="outlined"
              format={defaultConfig.dateTimeFormats.short}
              value={formik.values[technicalEvaluationContestationEndDate.name]}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormDatePicker
              parentName={technicalEvaluationContestationSolutionStartDate.name}
              formik={formik}
              required
              label={intl.formatMessage({
                id: technicalEvaluationContestationSolutionStartDate.label
              })}
              variant="outlined"
              format={defaultConfig.dateTimeFormats.short}
              value={formik.values[technicalEvaluationContestationSolutionStartDate.name]}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormDatePicker
              parentName={technicalEvaluationContestationSolutionEndDate.name}
              formik={formik}
              required
              label={intl.formatMessage({
                id: technicalEvaluationContestationSolutionEndDate.label
              })}
              variant="outlined"
              format={defaultConfig.dateTimeFormats.short}
              value={formik.values[technicalEvaluationContestationSolutionEndDate.name]}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormDatePicker
              parentName={monitoringStartDate.name}
              formik={formik}
              required
              label={intl.formatMessage({ id: monitoringStartDate.label })}
              variant="outlined"
              format={defaultConfig.dateTimeFormats.short}
              value={formik.values[monitoringStartDate.name]}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormDatePicker
              parentName={monitoringEndDate.name}
              formik={formik}
              required
              label={intl.formatMessage({ id: monitoringEndDate.label })}
              variant="outlined"
              format={defaultConfig.dateTimeFormats.short}
              value={formik.values[monitoringEndDate.name]}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormDatePicker
              parentName={reportingStartDate.name}
              formik={formik}
              required
              label={intl.formatMessage({ id: reportingStartDate.label })}
              variant="outlined"
              format={defaultConfig.dateTimeFormats.short}
              value={formik.values[reportingStartDate.name]}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableFormDatePicker
              parentName={reportingEndDate.name}
              formik={formik}
              required
              label={intl.formatMessage({ id: reportingEndDate.label })}
              variant="outlined"
              format={defaultConfig.dateTimeFormats.short}
              value={formik.values[reportingEndDate.name]}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          {institutions && institutions.institutions && institutions.institutions.data && (
            <Grid item xs={12} sm={6}>
              <EditableFormAutocomplete
                multiple
                parentName={competitionInstitutions.name}
                formik={formik}
                required
                label={intl.formatMessage({ id: competitionInstitutions.label })}
                variant="outlined"
                format={defaultConfig.dateTimeFormats.short}
                fullWidth
                disabled={disabled}
                options={institutions.institutions.data.map((institution) => ({
                  id: institution.institutieGuid,
                  label: institution.denumireCompleta
                }))}
              />
            </Grid>
          )}
        </Grid>
      </LocalizationProvider>
    </form>
  );
}
