import { eligibilityConstants } from '../constants';
import { triggerError, triggerSuccess } from '../utils/toasterTriggers';
import { eligibilityService, programService } from '../services';

export const eligibilityActions = {
  getAssignedPrograms,
  getEligibilitySheet,
  getProgram,
  submit,
  saveDraft
};

function getAssignedPrograms() {
  return (dispatch) => {
    dispatch(request());
    eligibilityService.getAssignedPrograms().then(
      (programs) => {
        dispatch(success(programs));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: eligibilityConstants.GET_ASSIGNED_PROGRAMS_REQUEST };
  }
  function success(programs) {
    return { type: eligibilityConstants.GET_ASSIGNED_PROGRAMS_SUCCESS, programs };
  }
  function failure(error) {
    return { type: eligibilityConstants.GET_ASSIGNED_PROGRAMS_FAILURE, error };
  }
}

function getEligibilitySheet(id, role) {
  return (dispatch) => {
    dispatch(request());
    eligibilityService.getEligibilitySheet(id, role).then(
      (eligibilitySheet) => {
        dispatch(success(eligibilitySheet));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: eligibilityConstants.GET_ELIGIBILITY_SHEET_REQUEST };
  }
  function success(eligibilitySheet) {
    return { type: eligibilityConstants.GET_ELIGIBILITY_SHEET_SUCCESS, eligibilitySheet };
  }
  function failure(error) {
    return { type: eligibilityConstants.GET_ELIGIBILITY_SHEET_FAILURE, error };
  }
}

function getProgram(id) {
  return (dispatch) => {
    dispatch(request());
    programService.getProgram(id).then(
      (program) => {
        dispatch(success(program));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: eligibilityConstants.GET_PROGRAM_REQUEST };
  }
  function success(program) {
    return { type: eligibilityConstants.GET_PROGRAM_SUCCESS, program };
  }
  function failure(error) {
    return { type: eligibilityConstants.GET_PROGRAM_FAILURE, error };
  }
}

function submit(programId, role, data) {
  return (dispatch) => {
    dispatch(request());
    eligibilityService.submit(programId, role, data).then(
      () => {
        dispatch(eligibilityActions.getEligibilitySheet(programId, role));
        dispatch(success());
        triggerSuccess('', 'toast.success.eligibility.save');
      },
      (error) => {
        dispatch(failure(JSON.stringify(error)));
        triggerError(error.errorMessage, 'toast.error.eligibility.save');
      }
    );
  };

  function request() {
    return { type: eligibilityConstants.SAVE_ELIGIBILITY_SHEET_REQUEST };
  }
  function success() {
    return {
      type: eligibilityConstants.SAVE_ELIGIBILITY_SHEET_SUCCESS
    };
  }
  function failure(error) {
    return { type: eligibilityConstants.SAVE_ELIGIBILITY_SHEET_FAILURE, error };
  }
}

function saveDraft(programId, role, data) {
  return (dispatch) => {
    dispatch(request());
    eligibilityService.saveDraft(programId, role, data).then(
      () => {
        dispatch(eligibilityActions.getEligibilitySheet(programId, role));
        dispatch(success());
        triggerSuccess('', 'toast.success.eligibility.save');
      },
      (error) => {
        dispatch(failure(JSON.stringify(error)));
        triggerError(error.errorMessage, 'toast.error.eligibility.save');
      }
    );
  };

  function request() {
    return { type: eligibilityConstants.SAVE_DRAFT_ELIGIBILITY_SHEET_REQUEST };
  }
  function success() {
    return {
      type: eligibilityConstants.SAVE_DRAFT_ELIGIBILITY_SHEET_SUCCESS
    };
  }
  function failure(error) {
    return { type: eligibilityConstants.SAVE_DRAFT_ELIGIBILITY_SHEET_FAILURE, error };
  }
}
