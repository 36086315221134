import { Ability, AbilityBuilder } from '@casl/ability';
import { ACTIONS, ENTITIES } from './enums';
import { UserRoles } from '../domain/UserRoles';

const checkAbilities = (roles) => {
  const { can, cannot, rules } = new AbilityBuilder(Ability);
  can(ACTIONS.VIEW, ENTITIES.MY_ACCOUNT);

  cannot(ACTIONS.VIEW, ENTITIES.APPLICANT);
  cannot(ACTIONS.EDIT, ENTITIES.APPLICANT);
  cannot(ACTIONS.DELETE, ENTITIES.APPLICANT);

  cannot(ACTIONS.VIEW, ENTITIES.ELIGIBILITY);
  cannot(ACTIONS.EDIT, ENTITIES.ELIGIBILITY);
  cannot(ACTIONS.DELETE, ENTITIES.ELIGIBILITY);

  cannot(ACTIONS.VIEW, ENTITIES.INSTITUTION);
  cannot(ACTIONS.EDIT, ENTITIES.INSTITUTION);
  cannot(ACTIONS.DELETE, ENTITIES.INSTITUTION);

  cannot(ACTIONS.VIEW, ENTITIES.ADMINISTRATOR);
  cannot(ACTIONS.EDIT, ENTITIES.ADMINISTRATOR);
  cannot(ACTIONS.DELETE, ENTITIES.ADMINISTRATOR);

  cannot(ACTIONS.VIEW, ENTITIES.ELIGIBILITY_DOCUMENTS);

  roles.forEach((role) => {
    switch (role) {
      case UserRoles.AplicantProiect:
        can(ACTIONS.VIEW, ENTITIES.APPLICANT);
        can(ACTIONS.EDIT, ENTITIES.APPLICANT);
        can(ACTIONS.DELETE, ENTITIES.APPLICANT);
        break;
      case UserRoles.EvaluatorEligibilitate:
        can(ACTIONS.VIEW, ENTITIES.ELIGIBILITY);
        can(ACTIONS.EDIT, ENTITIES.ELIGIBILITY);
        can(ACTIONS.DELETE, ENTITIES.ELIGIBILITY);
        break;
      case UserRoles.EvaluatorContestatieEligibilitate:
        can(ACTIONS.VIEW, ENTITIES.ELIGIBILITY);
        can(ACTIONS.EDIT, ENTITIES.ELIGIBILITY);
        can(ACTIONS.DELETE, ENTITIES.ELIGIBILITY);

        can(ACTIONS.VIEW, ENTITIES.ELIGIBILITY_DOCUMENTS);
        break;
      case UserRoles.EvaluatorObiectiv:
        can(ACTIONS.VIEW, ENTITIES.OBJECTIVE);
        can(ACTIONS.EDIT, ENTITIES.OBJECTIVE);
        can(ACTIONS.DELETE, ENTITIES.OBJECTIVE);
        break;
      case UserRoles.EvaluatorProgram:
        can(ACTIONS.VIEW, ENTITIES.PROGRAM);
        can(ACTIONS.EDIT, ENTITIES.PROGRAM);
        can(ACTIONS.DELETE, ENTITIES.PROGRAM);
        break;
      case UserRoles.Monitor:
        can(ACTIONS.VIEW, ENTITIES.MONITOR);
        can(ACTIONS.EDIT, ENTITIES.MONITOR);
        can(ACTIONS.DELETE, ENTITIES.MONITOR);
        break;
      case UserRoles.EvaluatorRapoarte:
        can(ACTIONS.VIEW, ENTITIES.REPORT);
        can(ACTIONS.EDIT, ENTITIES.REPORT);
        can(ACTIONS.DELETE, ENTITIES.REPORT);
        break;
      case UserRoles.AdministratorInstitutie:
        can(ACTIONS.VIEW, ENTITIES.INSTITUTION);
        can(ACTIONS.EDIT, ENTITIES.INSTITUTION);
        can(ACTIONS.DELETE, ENTITIES.INSTITUTION);
        break;
      case UserRoles.PlatformAdministrator:
        cannot(ACTIONS.VIEW, ENTITIES.MY_ACCOUNT);

        can(ACTIONS.VIEW, ENTITIES.ADMINISTRATOR);
        can(ACTIONS.EDIT, ENTITIES.ADMINISTRATOR);
        can(ACTIONS.DELETE, ENTITIES.ADMINISTRATOR);
        break;
      default:
        break;
    }
  });

  return new Ability(rules);
};

export default checkAbilities;
