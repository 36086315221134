import * as React from 'react';
import { useIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// ----------------------------------------------------------------------------

export default function CompetitionCard({ entity }) {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Card key={entity.competitionGuid.toString()}>
      <CardHeader
        title={
          <div>
            <p>{entity.nume}</p>
          </div>
        }
        action={
          <Button variant="outlined" onClick={() => navigate(`${entity.competitionGuid}/programe`)}>
            {intl.formatMessage({ id: 'competitionCard.button.label' })}
          </Button>
        }
      />
      <CardContent>
        <p>{intl.formatMessage({ id: 'global.competition_period' })}</p>
        <p>
          {moment(entity.durataDesfasurareIntervalInferior).format('D MMMM YYYY')} -{' '}
          {moment(entity.durataDesfasurareIntervalSuperior).format('D MMMM YYYY')}
        </p>
      </CardContent>
    </Card>
  );
}
