const costuriTotaleForm = {
  formId: 'costuriTotale',
  name: 'costuriTotale',
  costuriTotaleTableHead: {
    total: {
      name: 'total',
      label: 'form.project.cost.header.total'
    }
  },
  costuriTotaleFormFields: [
    {
      name: 'salariiContributii',
      label: 'form.project.cost.salariiContributii'
    },
    {
      name: 'materialeServicii',
      label: 'form.project.cost.materialeServicii'
    },
    {
      name: 'deplasari',
      label: 'form.project.cost.deplasari'
    },
    {
      name: 'cheltuieliIndirecte',
      label: 'form.project.cost.cheltuieliIndirecte'
    },
    {
      name: 'capital',
      label: 'form.project.cost.capital'
    },
    {
      name: 'total',
      label: 'form.project.cost.total'
    }
  ]
};

export default costuriTotaleForm;
