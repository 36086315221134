export const documentConstants = {
  DOCUMENT_GET_ALL_SUCCESS: 'DOCUMENT_GET_ALL_SUCCESS',
  DOCUMENT_GET_ALL_FAILURE: 'DOCUMENT_GET_ALL_FAILURE',
  DOCUMENT_GET_ALL_REQUEST: 'DOCUMENT_GET_ALL_REQUEST',

  DOCUMENT_GET_ALL_ADMIN_SUCCESS: 'DOCUMENT_GET_ALL_ADMIN_SUCCESS',
  DOCUMENT_GET_ALL_ADMIN_FAILURE: 'DOCUMENT_GET_ALL_ADMIN_FAILURE',
  DOCUMENT_GET_ALL_ADMIN_REQUEST: 'DOCUMENT_GET_ALL_ADMIN_REQUEST',

  DOCUMENT_DELETE_SUCCESS: 'DOCUMENT_DELETE_SUCCESS',
  DOCUMENT_DELETE_FAILURE: 'DOCUMENT_DELETE_FAILURE',
  DOCUMENT_DELETE_REQUEST: 'DOCUMENT_DELETE_REQUEST',

  DOCUMENT_DELETE_ADMIN_SUCCESS: 'DOCUMENT_DELETE_ADMIN_SUCCESS',
  DOCUMENT_DELETE_ADMIN_FAILURE: 'DOCUMENT_DELETE_ADMIN_FAILURE',
  DOCUMENT_DELETE_ADMIN_REQUEST: 'DOCUMENT_DELETE_ADMIN_REQUEST',

  DOCUMENT_UPLOAD_SUCCESS: 'DOCUMENT_UPLOAD_SUCCESS',
  DOCUMENT_UPLOAD_FAILURE: 'DOCUMENT_UPLOAD_FAILURE',
  DOCUMENT_UPLOAD_REQUEST: 'DOCUMENT_UPLOAD_REQUEST',

  DOCUMENT_UPLOAD_ANEXA2_SUCCESS: 'DOCUMENT_UPLOAD_ANEXA2_SUCCESS',
  DOCUMENT_UPLOAD_ANEXA2_FAILURE: 'DOCUMENT_UPLOAD_ANEXA2_FAILURE',
  DOCUMENT_UPLOAD_ANEXA2_REQUEST: 'DOCUMENT_UPLOAD_ANEXA2_REQUEST',

  DOCUMENT_UPLOAD_ACT_ADITIONAL_CONTRACT_FINANTARE_SUCCESS:
    'DOCUMENT_UPLOAD_ACT_ADITIONAL_CONTRACT_FINANTARE_SUCCESS',
  DOCUMENT_UPLOAD_ACT_ADITIONAL_CONTRACT_FINANTARE_FAILURE:
    'DOCUMENT_UPLOAD_ACT_ADITIONAL_CONTRACT_FINANTARE_FAILURE',
  DOCUMENT_UPLOAD_ACT_ADITIONAL_CONTRACT_FINANTARE_REQUEST:
    'DOCUMENT_UPLOAD_ACT_ADITIONAL_CONTRACT_FINANTARE_REQUEST',

  DOCUMENT_UPLOAD_ACORD_REALOCARE_SUCCESS: 'DOCUMENT_UPLOAD_ACORD_REALOCARE_SUCCESS',
  DOCUMENT_UPLOAD_ACORD_REALOCARE_FAILURE: 'DOCUMENT_UPLOAD_ACORD_REALOCARE_FAILURE',
  DOCUMENT_UPLOAD_ACORD_REALOCARE_REQUEST: 'DOCUMENT_UPLOAD_ACORD_REALOCARE_REQUEST',

  DOCUMENT_UPLOAD_ADMIN_SUCCESS: 'DOCUMENT_UPLOAD_ADMIN_SUCCESS',
  DOCUMENT_UPLOAD_ADMIN_FAILURE: 'DOCUMENT_UPLOAD_ADMIN_FAILURE',
  DOCUMENT_UPLOAD_ADMIN_REQUEST: 'DOCUMENT_UPLOAD_ADMIN_REQUEST',

  DOCUMENT_DOWNLOAD_SUCCESS: 'DOCUMENT_DOWNLOAD_SUCCESS',
  DOCUMENT_DOWNLOAD_FAILURE: 'DOCUMENT_DOWNLOAD_FAILURE',
  DOCUMENT_DOWNLOAD_REQUEST: 'DOCUMENT_DOWNLOAD_REQUEST',

  SUPPORT_DOCUMENT_DOWNLOAD_SUCCESS: 'SUPPORT_DOCUMENT_DOWNLOAD_SUCCESS',
  SUPPORT_DOCUMENT_DOWNLOAD_FAILURE: 'SUPPORT_DOCUMENT_DOWNLOAD_FAILURE',
  SUPPORT_DOCUMENT_DOWNLOAD_REQUEST: 'SUPPORT_DOCUMENT_DOWNLOAD_REQUEST',

  DOCUMENT_DOWNLOAD_ADMIN_SUCCESS: 'DOCUMENT_DOWNLOAD_ADMIN_SUCCESS',
  DOCUMENT_DOWNLOAD_ADMIN_FAILURE: 'DOCUMENT_DOWNLOAD_ADMIN_FAILURE',
  DOCUMENT_DOWNLOAD_ADMIN_REQUEST: 'DOCUMENT_DOWNLOAD_ADMIN_REQUEST',

  DOCUMENT_DOWNLOAD_REPORT_DOCUMENT_SUCCESS: 'DOCUMENT_DOWNLOAD_REPORT_DOCUMENT_SUCCESS',
  DOCUMENT_DOWNLOAD_REPORT_DOCUMENT_FAILURE: 'DOCUMENT_DOWNLOAD_REPORT_DOCUMENT_FAILURE',
  DOCUMENT_DOWNLOAD_REPORT_DOCUMENT_REQUEST: 'DOCUMENT_DOWNLOAD_REPORT_DOCUMENT_REQUEST',

  DOCUMENT_DOWNLOAD_PROJECT_RESPONSIBLE_CV_DOCUMENT_SUCCESS:
    'DOCUMENT_DOWNLOAD_PROJECT_RESPONSIBLE_CV_DOCUMENT_SUCCESS',
  DOCUMENT_DOWNLOAD_PROJECT_RESPONSIBLE_CV_DOCUMENT_FAILURE:
    'DOCUMENT_DOWNLOAD_PROJECT_RESPONSIBLE_CV_DOCUMENT_FAILURE',
  DOCUMENT_DOWNLOAD_PROJECT_RESPONSIBLE_CV_DOCUMENT_REQUEST:
    'DOCUMENT_DOWNLOAD_PROJECT_RESPONSIBLE_CV_DOCUMENT_REQUEST',

  DOCUMENT_DOWNLOAD_PROJECT_DOCUMENT_SUCCESS: 'DOCUMENT_DOWNLOAD_PROJECT_DOCUMENT_SUCCESS',
  DOCUMENT_DOWNLOAD_PROJECT_DOCUMENT_FAILURE: 'DOCUMENT_DOWNLOAD_PROJECT_DOCUMENT_FAILURE',
  DOCUMENT_DOWNLOAD_PROJECT_DOCUMENT_REQUEST: 'DOCUMENT_DOWNLOAD_PROJECT_DOCUMENT_REQUEST'
};
