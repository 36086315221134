import * as React from 'react';
import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material/';
import { useTheme, styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

// ----------------------------------------------------------------------------

const CardStyle = styled(Card)(() => ({
  width: '100%',
  margin: '2px',
  borderRadius: '6px',
  border: '2px solid rgba(157, 157, 157, 0.2)',
  boxShadow: 'none'
}));

const CardHeaderStyle = styled(CardHeader)(() => ({
  padding: '12px 16px'
}));

const NameStyle = styled('span')(() => ({
  fontSize: '16px',
  color: '#0D0D0D'
}));

// ----------------------------------------------------------------------------

export default function Document({ document, disabled, handleDelete, handleDownload }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {intl.formatMessage({ id: 'global.confirm' })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {intl.formatMessage(
              { id: 'document.confirm.delete' },
              { field: document.numeFisierOriginal }
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> {intl.formatMessage({ id: 'global.cancel' })}</Button>
          <Button onClick={() => handleDelete(document.fisierUrl)} autoFocus>
            {intl.formatMessage({ id: 'global.ok' })}
          </Button>
        </DialogActions>
      </Dialog>
      <CardStyle key={document.fisierUrl} variant="outlined">
        <CardHeaderStyle
          title={
            <>
              <NameStyle>{document.numeFisierOriginal} </NameStyle>
            </>
          }
          action={
            <>
              <IconButton color="primary" aria-label="download">
                <DownloadIcon onClick={() => handleDownload(document.fisierUrl)} />
              </IconButton>
              {!disabled ? (
                <IconButton
                  style={{ color: theme.palette.error.main }}
                  aria-label="delete"
                  onClick={handleClickOpen}
                >
                  <DeleteIcon />
                </IconButton>
              ) : (
                ''
              )}
            </>
          }
        />
      </CardStyle>
    </>
  );
}
