export const env = typeof window.ENV !== 'undefined' ? window.ENV : process.env;

export const defaultConfig = {
  dateTimeFormats: {
    short: 'DD/MM/YYYY',
    shortWithTime: 'DD/MM/yyyy HH:mm',
    chartShort: 'DD.MM.YYYY',
    form: 'YYYY-MM-DDTHH:mm:ssZ',
    card: 'D MMMM YYYY'
  }
};
