import _ from 'lodash';
import totalValueFormPermanentFields from './totalValueFormModel';

const {
  totalValueFormFields: { yearTable, totalValue }
} = totalValueFormPermanentFields;

export const getInitialTotalValueFormValues = (start, end, program) => ({
  [totalValue.name]: program?.[totalValue.name]?.[totalValue.name] ?? 0,
  [yearTable.name]:
    program?.[totalValue.name]?.[yearTable.name] ??
    _.range(start, end + 1).map((year) => ({
      [yearTable.year]: year,
      [yearTable.estimation]: null
    }))
});
