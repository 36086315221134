export const institutionConstants = {
  GET_INSTITUTION_SUCCESS: 'GET_INSTITUTION_SUCCESS',
  GET_INSTITUTION_FAILURE: 'GET_INSTITUTION_FAILURE',
  GET_INSTITUTION_REQUEST: 'GET_INSTITUTION_REQUEST',

  GET_INSTITUTIONS_SUCCESS: 'GET_INSTITUTIONS_SUCCESS',
  GET_INSTITUTIONS_FAILURE: 'GET_INSTITUTIONS_FAILURE',
  GET_INSTITUTIONS_REQUEST: 'GET_INSTITUTIONS_REQUEST',

  INSTITUTION_ADD_SUCCESS: 'INSTITUTION_ADD_SUCCESS',
  INSTITUTION_ADD_FAILURE: 'INSTITUTION_ADD_FAILURE',
  INSTITUTION_ADD_REQUEST: 'INSTITUTION_ADD_REQUEST',

  INSTITUTION_UPDATE_SUCCESS: 'INSTITUTION_UPDATE_SUCCESS',
  INSTITUTION_UPDATE_FAILURE: 'INSTITUTION_UPDATE_FAILURE',
  INSTITUTION_UPDATE_REQUEST: 'INSTITUTION_UPDATE_REQUEST'
};
