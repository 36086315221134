export const eligibilityConstants = {
  GET_ASSIGNED_PROGRAMS_SUCCESS: 'GET_ASSIGNED_PROGRAMS_SUCCESS',
  GET_ASSIGNED_PROGRAMS_FAILURE: 'GET_ASSIGNED_PROGRAMS_FAILURE',
  GET_ASSIGNED_PROGRAMS_REQUEST: 'GET_ASSIGNED_PROGRAMS_REQUEST',

  GET_ELIGIBILITY_SHEET_SUCCESS: 'GET_ELIGIBILITY_SHEET_SUCCESS',
  GET_ELIGIBILITY_SHEET_FAILURE: 'GET_ELIGIBILITY_SHEET_FAILURE',
  GET_ELIGIBILITY_SHEET_REQUEST: 'GET_ELIGIBILITY_SHEET_REQUEST',

  GET_PROGRAM_SUCCESS: 'GET_PROGRAM_ELIGIBILITY_SUCCESS',
  GET_PROGRAM_FAILURE: 'GET_PROGRAM_ELIGIBILITY_FAILURE',
  GET_PROGRAM_REQUEST: 'GET_PROGRAM_ELIGIBILITY_REQUEST',

  SAVE_ELIGIBILITY_SHEET_SUCCESS: 'SAVE_ELIGIBILITY_SHEET_SUCCESS',
  SAVE_ELIGIBILITY_SHEET_FAILURE: 'SAVE_ELIGIBILITY_SHEET_FAILURE',
  SAVE_ELIGIBILITY_SHEET_REQUEST: 'SAVE_ELIGIBILITY_SHEET_REQUEST',

  SAVE_DRAFT_ELIGIBILITY_SHEET_SUCCESS: 'SAVE_DRAFT_ELIGIBILITY_SHEET_SUCCESS',
  SAVE_DRAFT_ELIGIBILITY_SHEET_FAILURE: 'SAVE_DRAFT_ELIGIBILITY_SHEET_FAILURE',
  SAVE_DRAFT_ELIGIBILITY_SHEET_REQUEST: 'SAVE_DRAFT_ELIGIBILITY_SHEET_REQUEST'
};
