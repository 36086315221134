import adminEligibilityContestationAssignFormModel from './adminEligibilityContestationAssignFormModel';

const {
  adminEligibilityContestationAssignField: { writer, reviwer }
} = adminEligibilityContestationAssignFormModel;

export const parseAdminEligibilityContestation = (adminEligibilityContestation) => {
  const parsedAdminEligibilityContestation = { ...adminEligibilityContestation };
  if (adminEligibilityContestation[writer.name]) {
    parsedAdminEligibilityContestation[writer.name] = adminEligibilityContestation[writer.name].id;
  }

  if (adminEligibilityContestation[reviwer.name]) {
    parsedAdminEligibilityContestation[reviwer.name] =
      adminEligibilityContestation[reviwer.name].id;
  }

  return parsedAdminEligibilityContestation;
};
