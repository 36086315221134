import { evaluationConstants } from '../constants';
import { triggerError, triggerSuccess } from '../utils/toasterTriggers';
import { evaluationService, userService } from '../services';

export const evaluationActions = {
  getMyTechnicalEvaluationPrograms,
  getMyProgramEvaluationPrograms,
  getInstitutionProgramEvaluation,
  saveDraftProjectEvaluation,
  submitProjectEvaluation,
  saveDraftProgramEvaluation,
  submitProgramEvaluation,
  contestProgramEvaluation,
  sendProjectToMonitoring
};

function getMyTechnicalEvaluationPrograms() {
  return (dispatch) => {
    dispatch(request());
    evaluationService.getMyTechnicalEvaluationPrograms().then(
      (programs) => {
        dispatch(success(programs));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: evaluationConstants.GET_OBJECTIVES_REQUEST };
  }
  function success(programs) {
    return { type: evaluationConstants.GET_OBJECTIVES_SUCCESS, programs };
  }
  function failure(error) {
    return { type: evaluationConstants.GET_OBJECTIVES_FAILURE, error };
  }
}

function getMyProgramEvaluationPrograms() {
  return (dispatch) => {
    dispatch(request());
    evaluationService.getMyProgramEvaluationPrograms().then(
      (programs) => {
        const p = [];
        programs.programe.forEach((program) => {
          if (program.fiseTehniceVizibileModerator) {
            program.fiseTehniceVizibileModerator.forEach((fisa) => {
              p.push(
                userService.getUserInfo(fisa.userId).then((user) => {
                  fisa.username = `${user.firstName} ${user.lastName}`;
                })
              );
            });
          } else {
            dispatch(success(programs));
          }
        });
        Promise.all(p).then(() => {
          dispatch(success(programs));
        });
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: evaluationConstants.GET_EVALUATION_PROGRAMS_REQUEST };
  }
  function success(programs) {
    return { type: evaluationConstants.GET_EVALUATION_PROGRAMS_SUCCESS, programs };
  }
  function failure(error) {
    return { type: evaluationConstants.GET_EVALUATION_PROGRAMS_FAILURE, error };
  }
}

function getInstitutionProgramEvaluation(programId, type) {
  return (dispatch) => {
    dispatch(request());
    evaluationService.getInstitutionProgramEvaluation(programId, type).then(
      (programEvaluation) => {
        dispatch(success(programEvaluation));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError('', error.errorMessage);
      }
    );
  };

  function request() {
    return { type: evaluationConstants.GET_PROGRAM_EVALUATION_REQUEST };
  }
  function success(programEvaluation) {
    return { type: evaluationConstants.GET_PROGRAM_EVALUATION_SUCCESS, programEvaluation };
  }
  function failure(error) {
    return { type: evaluationConstants.GET_PROGRAM_EVALUATION_FAILURE, error };
  }
}

function saveDraftProjectEvaluation(type, projectEvaluationSheet) {
  return (dispatch) => {
    dispatch(request());
    evaluationService.saveDraftProjectEvaluation(type, projectEvaluationSheet).then(
      () => {
        dispatch(success());
        triggerSuccess('', 'toast.success.save');
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: evaluationConstants.SAVE_DRAFT_PROJECT_EVALUATION_REQUEST };
  }
  function success() {
    return {
      type: evaluationConstants.SAVE_DRAFT_PROJECT_EVALUATION_SUCCESS
    };
  }
  function failure(error) {
    return { type: evaluationConstants.SAVE_DRAFT_PROJECT_EVALUATION_FAILURE, error };
  }
}

function submitProjectEvaluation(projectId, type, projectEvaluationSheet) {
  return (dispatch) => {
    dispatch(request());
    evaluationService.submitProjectEvaluation(projectId, type, projectEvaluationSheet).then(
      () => {
        dispatch(success());
        triggerSuccess('', 'toast.success.save');
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: evaluationConstants.SUBMIT_PROJECT_EVALUATION_REQUEST };
  }
  function success() {
    return {
      type: evaluationConstants.SUBMIT_PROJECT_EVALUATION_SUCCESS
    };
  }
  function failure(error) {
    return { type: evaluationConstants.SUBMIT_PROJECT_EVALUATION_FAILURE, error };
  }
}

function saveDraftProgramEvaluation(sheetType, type, programEvaluationSheet) {
  return (dispatch) => {
    dispatch(request());
    evaluationService.saveDraftProgramEvaluation(sheetType, type, programEvaluationSheet).then(
      () => {
        dispatch(success());
        triggerSuccess('', 'toast.success.save');
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: evaluationConstants.SAVE_DRAFT_PROGRAM_EVALUATION_REQUEST };
  }
  function success() {
    return {
      type: evaluationConstants.SAVE_DRAFT_PROGRAM_EVALUATION_SUCCESS
    };
  }
  function failure(error) {
    return { type: evaluationConstants.SAVE_DRAFT_PROGRAM_EVALUATION_FAILURE, error };
  }
}

function submitProgramEvaluation(programId, sheetType, type, programEvaluationSheet) {
  return (dispatch) => {
    dispatch(request());
    evaluationService
      .submitProgramEvaluation(programId, sheetType, type, programEvaluationSheet)
      .then(
        () => {
          dispatch(success());
          triggerSuccess('', 'toast.success.save');
        },
        (error) => {
          dispatch(failure(error.toString()));
          triggerError(error.errorMessage, 'toast.error.get');
        }
      );
  };

  function request() {
    return { type: evaluationConstants.SUBMIT_PROGRAM_EVALUATION_REQUEST };
  }
  function success() {
    return {
      type: evaluationConstants.SUBMIT_PROGRAM_EVALUATION_SUCCESS
    };
  }
  function failure(error) {
    return { type: evaluationConstants.SUBMIT_PROGRAM_EVALUATION_FAILURE, error };
  }
}

function contestProgramEvaluation(formData, idProgram) {
  return (dispatch) => {
    dispatch(request());

    evaluationService.contestProgramEvaluation(formData, idProgram).then(
      () => {
        dispatch(success());
        triggerSuccess('', 'toast.success.save');
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.save');
      }
    );
  };

  function request() {
    return { type: evaluationConstants.CONTEST_PROGRAM_EVALUATION_REQUEST };
  }
  function success() {
    return { type: evaluationConstants.CONTEST_PROGRAM_EVALUATION_SUCCESS };
  }
  function failure(error) {
    return { type: evaluationConstants.CONTEST_PROGRAM_EVALUATION_FAILURE, error };
  }
}

function sendProjectToMonitoring(projectId, programId) {
  return (dispatch) => {
    dispatch(request());
    evaluationService.sendProjectToMonitoring(projectId, programId).then(
      () => {
        dispatch(success());
        dispatch(getInstitutionProgramEvaluation(programId, 'evaluare'));
        triggerSuccess('', 'toast.success.save');
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: evaluationConstants.SEND_PROJECT_TO_MONITORING_REQUEST };
  }
  function success() {
    return {
      type: evaluationConstants.SEND_PROJECT_TO_MONITORING_SUCCESS
    };
  }
  function failure(error) {
    return { type: evaluationConstants.SEND_PROJECT_TO_MONITORING_FAILURE, error };
  }
}
