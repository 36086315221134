import { useEffect } from 'react';
// material
import { Container } from '@mui/material';
// components
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../components/Page';
//
import { institutionActions } from '../actions';
import MuiTableAddEntity from '../components/table/MuiTableAddEntity';
import MuiTable from '../components/table/MuiTable';
import { InstitutionMoreMenu } from '../components/_dashboard/institutions';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'institutieGuid', label: 'users.id', alignRight: false },
  { id: 'denumireCompleta', label: 'global.name', alignRight: false },
  { id: 'actions', label: 'users.actions', alignRight: true, sortable: false }
];

// ----------------------------------------------------------------------

export default function Institutions() {
  const institutions = useSelector((state) => state.institutions);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(institutionActions.getAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const intl = useIntl();

  // display institutions form
  return (
    <Page title={intl.formatMessage({ id: 'global.institutions' })}>
      {institutions && institutions.institutions && institutions.institutions.data && (
        <Container>
          <MuiTableAddEntity
            title={intl.formatMessage({ id: 'global.institutions' })}
            buttonText={intl.formatMessage({ id: 'global.institutions.add' })}
          />

          <MuiTable
            tableHead={TABLE_HEAD}
            itemIdentifier="InstitutieGuid"
            defaultOrderBy="denumireCompleta"
            defaultOrder="asc"
            data={institutions.institutions.data}
            filterFunction={(query) => (_institution) =>
              _institution.denumireCompleta.toLowerCase().indexOf(query.toLowerCase()) !== -1}
            MoreMenu={InstitutionMoreMenu}
          />
        </Container>
      )}
    </Page>
  );
}
