import { useIntl } from 'react-intl';
import { Backdrop, CircularProgress, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import Page from '../components/Page';
import { programActions } from '../actions';
import CardList from '../components/lists/CardList';
import AplicantProgramCard from '../components/cards/AplicantProgramCard';
// ----------------------------------------------------------------------

export default function ProgrameleMele() {
  const intl = useIntl();

  const programs = useSelector((state) => state.programs);
  const loading = useSelector((state) => state.programs.loading);

  const dispatch = useDispatch();

  const [sort, setSort] = React.useState('ascending');
  const [searchText, setSearchText] = React.useState('');
  const [programItems, setProgramItems] = useState([]);

  useEffect(() => {
    dispatch(programActions.getProgramsApplicant());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      programs &&
      programs.programs &&
      programs.programs.length > 0 &&
      'numeProgram' in programs.programs[0]
    ) {
      setProgramItems(
        programs.programs.sort((c1, c2) => c1.numeProgram.localeCompare(c2.numeProgram))
      );
    }
  }, [programs]);

  useEffect(() => {
    let filteredPrograms = [];
    if (
      programs &&
      programs.programs &&
      programs.programs.length > 0 &&
      'numeProgram' in programs.programs[0]
    ) {
      if (searchText && searchText.length > 0) {
        filteredPrograms = programs.programs.filter((competition) =>
          competition.numeProgram.toLowerCase().includes(searchText.toLowerCase())
        );
      } else {
        filteredPrograms = programs.programs;
      }
    }

    filteredPrograms = filteredPrograms.sort((c1, c2) =>
      sort === 'ascending'
        ? c1.numeProgram.toLowerCase().localeCompare(c2.numeProgram.toLowerCase())
        : c2.numeProgram.toLowerCase().localeCompare(c1.numeProgram.toLowerCase())
    );
    setProgramItems([...filteredPrograms]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, searchText]);

  if (loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Page title="Programele mele" my={2}>
      <Container>
        {programItems && (
          <CardList
            entityId="programGuid"
            sort={sort}
            setSort={setSort}
            searchText={searchText}
            setSearchText={setSearchText}
            sortFieldTitle={intl.formatMessage({ id: 'program.submissionDate' })}
            items={programItems}
            Card={AplicantProgramCard}
          />
        )}
      </Container>
    </Page>
  );
}
