import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, TextField } from '@mui/material';

EditableFormAutocomplete.propTypes = {
  parentName: PropTypes.string,
  childrenNames: PropTypes.array,
  formik: PropTypes.any,
  inputLabel: PropTypes.string,
  dependentParent: PropTypes.string,
  dependentSecondParent: PropTypes.string,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  index: PropTypes.number,
  onChangeHandle: PropTypes.func
};

export default function EditableFormAutocomplete({
  parentName,
  childrenNames,
  formik,
  multiple,
  required,
  label,
  options,
  disabled,
  index,
  onChangeHandle
}) {
  const intl = useIntl();
  // eslint-disable-next-line no-nested-ternary
  let value = formik.values[parentName] ? formik.values[parentName] : multiple ? [] : '';
  let error = formik.errors[parentName] ? formik.errors[parentName] : false;
  let touched = formik.touched[parentName] ? formik.touched[parentName] : false;
  let errorMessageId = formik.errors[parentName] ? formik.errors[parentName] : false;
  let tag = `${parentName}`;

  if (typeof index !== 'undefined') {
    value = value[index];
    error = error[index];
    touched = touched[index];
    errorMessageId = errorMessageId[index];
    tag = `${tag}[${index}]`;
  }

  if (childrenNames) {
    childrenNames.forEach((childName) => {
      touched = touched ? touched[childName] : touched;
      error = error ? error[childName] : error;
      errorMessageId = errorMessageId ? errorMessageId[childName] : errorMessageId;
      tag = `${tag}.${childName}`;
      value = value[childName];
    });
  }

  // eslint-disable-next-line no-nested-ternary
  const optionValue = value
    ? multiple
      ? options.filter((option) => value.find((v) => v.id === option.id))
      : options.find((option) => option.id === value.id)
    : null;
  return (
    <FormControl variant="outlined" fullWidth error={touched && !!error} required={required}>
      <Autocomplete
        multiple={multiple}
        filterSelectedOptions
        options={options}
        id="autocomplete"
        value={optionValue}
        isOptionEqualToValue={(option, value) => value && option.id === value.id}
        onChange={(event, option) =>
          onChangeHandle ? onChangeHandle(tag, option) : formik.setFieldValue(tag, option)
        }
        fullWidth
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            label={label}
            variant="outlined"
            error={touched && !!error}
            InputProps={{
              ...params.InputProps
            }}
          />
        )}
        noOptionsText={intl.formatMessage({ id: 'global.no_options' })}
      />
      {touched && !!error && (
        <FormHelperText>
          <FormattedMessage id={errorMessageId} values={{ field: label }} />
        </FormHelperText>
      )}
    </FormControl>
  );
}
