const incomeStatusTableFields = {
  name: 'situatiaVeniturilor',
  tableStructure: {
    general: {
      name: 'general'
    },
    total: {
      name: 'total'
    }
  },
  rowFields: {
    firstCols: [
      { label: 'table.head.number' },
      {
        name: 'program',
        label: 'program.incomeStatus.table.head.program'
      },
      {
        name: 'contract',
        label: 'program.incomeStatus.table.head.contractNo'
      }
    ],
    lastCols: [
      {
        name: 'venitDinINCD',
        label: 'program.incomeStatus.table.head.incomeINCD'
      },
      {
        name: 'contVenit',
        label: 'program.incomeStatus.table.head.incomeAccount'
      },
      { label: 'table.head.actions' }
    ],
    year: {
      namePrefix: 'valoare'
    },
    subRows: {
      name: 'subRows'
    },
    userSubRows: {
      name: 'userSubRows'
    }
  }
};

export default incomeStatusTableFields;
