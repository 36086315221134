// components
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Button, Container, Divider, FormHelperText, Grid, Tab } from '@mui/material';
// import everything from the react module
import * as React from 'react';
// dispatch is used as a callback which gets invoked once some async action is complete.
// useSelector allows you to extract data from the Redux store state, using a selector function.
import { useDispatch, useSelector } from 'react-redux';
// The Effect Hook performs side effects in function components
// Fragments are used to group a list of children without adding extra nodes to the DOM.
import { Fragment, useEffect, useMemo, useState } from 'react';
// Formik uses useFormik to create the <Formik> component (which renders a React Context Provider).
import { useFormik } from 'formik';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { monitoringActions, programActions, userActions } from '../actions';
import EditableFormTextField from '../forms/shared/EditableFormTextField';
import monitoringCycleFormModel from '../forms/monitoring/adminCycles/monitoringCycleFormModel';
import { getInitialMonitoringCycleFormValues } from '../forms/monitoring/adminCycles/formInitialValues';
import { monitoringCycleFormValidationSchema } from '../forms/monitoring/adminCycles/validationSchema';
import { defaultConfig } from '../utils/config';
import EditableFormDatePicker from '../forms/shared/EditableFormDatePicker';
import { UserRoles } from '../domain/UserRoles';
import EditableFormAutocomplete from '../forms/shared/EditableFormAutocomplete';
import Page from '../components/Page';
import { parseMonitoringCycles } from '../forms/monitoring/adminCycles/formOutputParser';
import monitoringAssignedMonitorsFormModel from '../forms/monitoring/adminAssignedMonitors/monitoringAssignedMonitorsFormModel';
import { getInitialMonitoringAssignedMonitorsFormValues } from '../forms/monitoring/adminAssignedMonitors/formInitialValues';
import { monitoringAssignedMonitorsFormValidationSchema } from '../forms/monitoring/adminAssignedMonitors/validationSchema';
import { parseMonitoringAssignedMonitors } from '../forms/monitoring/adminAssignedMonitors/formOutputParser';
import EditableFormCheckbox from '../forms/shared/EditableFormCheckbox';

// ----------------------------------------------------------------------

const {
  monitoringCycleField: { cycle }
} = monitoringCycleFormModel;

const {
  monitoringAssignedMonitorsField: { assignedMonitors }
} = monitoringAssignedMonitorsFormModel;

export default function AdminCompetitionProgramMonitoring() {
  const { idCompetition, idProgram } = useParams();
  // useIntl hook: Once you've declared your IntlProvider, you can get access to the intl object via calling this hook in a functional React component
  const intl = useIntl();
  // useSelector extracts  data from the programs state
  const programs = useSelector((state) => state.programs);
  // useSelector extracts  data from the monitoring state
  const monitoring = useSelector((state) => state.monitoring);
  // useSelector extracts  data from the user state
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [cyclesError, setCyclesError] = useState('');
  const [value, setValue] = useState('1');

  // The Effect Hook performs side effects in function components:
  // dispatch is used as a callback which gets invoked once some async action is complete.
  useEffect(() => {
    dispatch(userActions.getAll(user.info.userType));
    dispatch(monitoringActions.getProgramCycles(idProgram));
    dispatch(monitoringActions.getProgramAssignedMonitors(idProgram));
    dispatch(programActions.getProjectsAvailableForMonitoring(idProgram));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProgram]);

  const saveCycles = (values) => {
    setCyclesError('');
    const valuesCopy = parseMonitoringCycles(JSON.parse(JSON.stringify(values)), idCompetition);
    dispatch(monitoringActions.saveCycles(valuesCopy));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const initialValues = useMemo(
    () => getInitialMonitoringCycleFormValues(monitoring.programCycles || {}),
    [monitoring.programCycles]
  );

  // useFormik() is a custom React hook that will return all Formik state and helpers directly.
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: monitoringCycleFormValidationSchema(),
    onSubmit: (values) => {
      if (values[cycle.name].length > 1) {
        if (
          values[cycle.name][values[cycle.name].length - 1][cycle.startDate.name] &&
          moment(values[cycle.name][values[cycle.name].length - 1][cycle.startDate.name]).diff(
            moment(values[cycle.name][values[cycle.name].length - 2][cycle.endDate.name])
          ) <= 0
        ) {
          setCyclesError('form.error.cycle.dates.order');
        } else {
          saveCycles(values);
        }
      } else {
        saveCycles(values);
      }
    }
  });

  const formikAssignedMonitors = useFormik({
    initialValues: getInitialMonitoringAssignedMonitorsFormValues(
      monitoring.assignedMonitors || []
    ),
    enableReinitialize: true,
    validationSchema: monitoringAssignedMonitorsFormValidationSchema(),
    onSubmit: (values) => {
      const valuesCopy = parseMonitoringAssignedMonitors(JSON.parse(JSON.stringify(values)));
      dispatch(monitoringActions.saveAssignedMonitors(valuesCopy));
    }
  });

  return (
    <Page title={intl.formatMessage({ id: 'monitoring.page.title' })} my={2}>
      <Container>
        {user.users && user.users.data && programs && programs.monitoringProjects && (
          <TabContext value={value}>
            <TabList onChange={handleChange} aria-label="tabs">
              <Tab label={intl.formatMessage({ id: 'admin.monitoring.cycles.tab1' })} value="1" />
              <Tab label={intl.formatMessage({ id: 'admin.monitoring.cycles.tab2' })} value="2" />
            </TabList>
            <TabPanel value="1" index={0} key={0}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Grid container spacing={5}>
                  {formik.values[cycle.name].map((cycleItem, index) => (
                    <Fragment key={index}>
                      {cyclesError && index === formik.values[cycle.name].length - 1 && (
                        // Grid item 1 size 12/12 (full) of viewport
                        <Grid item xs={12} sm={12}>
                          <FormHelperText id="component-helper-text" error>
                            {intl.formatMessage({
                              id: cyclesError
                            })}
                          </FormHelperText>
                        </Grid>
                      )}
                      {/* Grid item 2 size 3/12 of viewport */}
                      <Grid item xs={3} sm={3}>
                        <EditableFormTextField
                          parentName={cycle.name}
                          childrenNames={[cycle.title.name]}
                          index={index}
                          formik={formik}
                          label={intl.formatMessage({ id: cycle.title.label })}
                          variant="outlined"
                          fullWidth
                          disabled={[cycle.id.name] in cycleItem}
                          required
                        />
                      </Grid>
                      {/* Grid item 3 size 3/12 of viewport */}
                      <Grid item xs={3} sm={3}>
                        <EditableFormDatePicker
                          parentName={cycle.name}
                          childrenNames={[index, cycle.startDate.name]}
                          formik={formik}
                          required
                          label={intl.formatMessage({ id: cycle.startDate.label })}
                          variant="outlined"
                          format={defaultConfig.dateTimeFormats.short}
                          value={formik.values[cycle.startDate.name]}
                          fullWidth
                          disabled={[cycle.id.name] in cycleItem}
                        />
                      </Grid>
                      {/* Grid item 4 size 3/12 of viewport */}
                      <Grid item xs={3} sm={3}>
                        <EditableFormDatePicker
                          parentName={cycle.name}
                          childrenNames={[index, cycle.endDate.name]}
                          formik={formik}
                          required
                          label={intl.formatMessage({ id: cycle.endDate.label })}
                          variant="outlined"
                          format={defaultConfig.dateTimeFormats.short}
                          value={formik.values[cycle.endDate.name]}
                          fullWidth
                          disabled={[cycle.id.name] in cycleItem}
                        />
                      </Grid>
                      {/* Grid item 5 size 1/12 of viewport */}
                      <Grid item xs={1} sm={1}>
                        <EditableFormCheckbox
                          parentName={cycle.name}
                          childrenNames={[cycle.final.name]}
                          index={index}
                          indexParent={cycle.name}
                          formik={formik}
                          inputLabel={intl.formatMessage({ id: cycle.final.label })}
                          value={formik.values[cycle.final.name]}
                          variant="outlined"
                          disabled={[cycle.id.name] in cycleItem}
                          fullWidth
                        />
                      </Grid>
                      {/* Grid item 6 size 2/12 of viewport */}
                      <Grid item xs={2} sm={2}>
                        <Button
                          variant="contained"
                          disabled={[cycle.id.name] in cycleItem}
                          onClick={() =>
                            formik.setFieldValue(
                              cycle.name,
                              formik.values[cycle.name].filter((cycle, i) => i !== index)
                            )
                          }
                        >
                          {intl.formatMessage({ id: 'global.delete' })}
                        </Button>
                      </Grid>
                      {cycleItem[cycle.projects.name].map((projectItem, indexProject) => (
                        <Fragment key={indexProject}>
                          {/* Grid item 7 size 4/12 of viewport */}
                          <Grid item xs={4} sm={4}>
                            <EditableFormAutocomplete
                              parentName={cycle.name}
                              childrenNames={[
                                index,
                                cycle.projects.name,
                                indexProject,
                                cycle.projects.project.name
                              ]}
                              formik={formik}
                              required
                              label={intl.formatMessage({ id: cycle.projects.project.label })}
                              variant="outlined"
                              fullWidth
                              disabled={!projectItem.isNew}
                              onChangeHandle={(tag, option) => {
                                let monitor = null;
                                formik.values[cycle.name].forEach((currentCycle) => {
                                  currentCycle[cycle.projects.name].forEach((project) => {
                                    if (project[cycle.projects.project.name].id === option.id) {
                                      monitor = project[cycle.projects.monitor.name];
                                    }
                                  });
                                });
                                if (monitor) {
                                  formik.setFieldValue(
                                    `${cycle.name}[${index}].${cycle.projects.name}[${indexProject}].${cycle.projects.monitor.name}`,
                                    monitor
                                  );
                                  formik.setFieldValue(
                                    `${cycle.name}[${index}].${cycle.projects.name}[${indexProject}].disabled`,
                                    true
                                  );
                                }
                                formik.setFieldValue(tag, option);
                              }}
                              options={programs.monitoringProjects
                                .filter(
                                  (project) =>
                                    !cycleItem[cycle.projects.name].find(
                                      (selectedProject, indexSelectedProject) =>
                                        indexSelectedProject !== indexProject &&
                                        project.proiectGuid ===
                                          selectedProject[cycle.projects.project.name].id
                                    )
                                )
                                .map((projectOption) => ({
                                  id: projectOption.proiectGuid,
                                  label: projectOption.numeProiect
                                }))}
                            />
                          </Grid>
                          {/* Grid item 8 size 3/12 of viewport */}
                          <Grid item xs={3} sm={3}>
                            <EditableFormTextField
                              parentName={cycle.name}
                              childrenNames={[
                                index,
                                cycle.projects.name,
                                indexProject,
                                cycle.projects.budget.name
                              ]}
                              formik={formik}
                              label={intl.formatMessage({ id: cycle.projects.budget.label })}
                              variant="outlined"
                              fullWidth
                              type="number"
                              disabled={!projectItem.isNew}
                              required
                            />
                          </Grid>
                          {/* Grid item 9 size 3/12 of viewport */}
                          <Grid item xs={3} sm={3}>
                            <EditableFormAutocomplete
                              parentName={cycle.name}
                              childrenNames={[
                                index,
                                cycle.projects.name,
                                indexProject,
                                cycle.projects.monitor.name
                              ]}
                              formik={formik}
                              required
                              label={intl.formatMessage({ id: cycle.projects.monitor.label })}
                              variant="outlined"
                              fullWidth
                              disabled={!projectItem.isNew || projectItem.disabled}
                              options={user.users.data
                                .filter((userOption) =>
                                  userOption.userType.includes(UserRoles.Monitor)
                                )
                                .map((userOption) => ({
                                  id: userOption.id,
                                  label: `${userOption.firstName} ${userOption.lastName}`
                                }))}
                            />
                          </Grid>
                          {/* Grid item 10 size 2/12 of viewport */}
                          <Grid item xs={2} sm={2}>
                            <Button
                              variant="contained"
                              disabled={!projectItem.isNew}
                              onClick={() =>
                                formik.setFieldValue(
                                  cycle.name,
                                  formik.values[cycle.name].map((crtCycle, iCycle) => {
                                    if (iCycle === index) {
                                      crtCycle[cycle.projects.name] = crtCycle[
                                        cycle.projects.name
                                      ].filter((project, i) => i !== indexProject);
                                    }
                                    return crtCycle;
                                  })
                                )
                              }
                            >
                              {intl.formatMessage({ id: 'global.delete' })}
                            </Button>
                          </Grid>
                        </Fragment>
                      ))}
                      {formik.errors[cycle.name] &&
                        formik.errors[cycle.name][index] &&
                        !Array.isArray(formik.errors[cycle.name][index][cycle.projects.name]) && (
                          <Grid item xs={12} sm={12}>
                            <FormHelperText id="component-helper-text" error>
                              {intl.formatMessage({
                                id: formik.errors[cycle.name][index][cycle.projects.name]
                              })}
                            </FormHelperText>
                          </Grid>
                        )}
                      {/* Grid item 11 size 12/12 (full) of viewport */}
                      <Grid item xs={12} sm={12}>
                        <Button
                          variant="contained"
                          onClick={() =>
                            formik.setFieldValue(cycle.name, [
                              ...formik.values[cycle.name].map((currentCycle, idx) => {
                                if (idx === index) {
                                  currentCycle[cycle.projects.name].push({
                                    [cycle.projects.project.name]: { id: 0 },
                                    [cycle.projects.monitor.name]: { id: 0 },
                                    [cycle.projects.budget.name]: '',
                                    isNew: true
                                  });
                                }
                                return currentCycle;
                              })
                            ])
                          }
                        >
                          {intl.formatMessage({ id: 'monitoring.project.add' })}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Divider />
                      </Grid>
                    </Fragment>
                  ))}
                  <Grid item xs={12} sm={12}>
                    <Button
                      variant="contained"
                      onClick={() =>
                        formik.setFieldValue(cycle.name, [
                          ...formik.values[cycle.name],
                          {
                            [cycle.title.name]: '',
                            [cycle.startDate.name]: '',
                            [cycle.endDate.name]: '',
                            [cycle.final.name]: false,
                            [cycle.projects.name]: []
                          }
                        ])
                      }
                    >
                      {intl.formatMessage({ id: 'monitoring.cycle.add' })}
                    </Button>
                    {formik.errors[cycle.name] && !Array.isArray(formik.errors[cycle.name]) && (
                      <FormHelperText id="component-helper-text" error>
                        {intl.formatMessage({ id: formik.errors[cycle.name] })}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Button variant="contained" onClick={() => formik.submitForm()}>
                      {intl.formatMessage({
                        id: 'global.save'
                      })}
                    </Button>
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </TabPanel>
            <TabPanel value="2" index={1} key={1}>
              {formikAssignedMonitors.values[assignedMonitors.name].map(
                (assignedMonitor, indexAssignedMonitor) => (
                  <Grid container spacing={5} key={indexAssignedMonitor}>
                    <Grid item xs={6} sm={6}>
                      <EditableFormAutocomplete
                        parentName={assignedMonitors.name}
                        childrenNames={[indexAssignedMonitor, assignedMonitors.project.name]}
                        formik={formikAssignedMonitors}
                        required
                        label={intl.formatMessage({ id: cycle.projects.project.label })}
                        variant="outlined"
                        fullWidth
                        disabled
                        options={programs.monitoringProjects.map((projectOption) => ({
                          id: projectOption.proiectGuid,
                          label: projectOption.numeProiect
                        }))}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <EditableFormAutocomplete
                        parentName={assignedMonitors.name}
                        childrenNames={[indexAssignedMonitor, assignedMonitors.monitor.name]}
                        formik={formikAssignedMonitors}
                        required
                        label={intl.formatMessage({ id: cycle.projects.monitor.label })}
                        variant="outlined"
                        fullWidth
                        options={user.users.data
                          .filter((userOption) => userOption.userType.includes(UserRoles.Monitor))
                          .map((userOption) => ({
                            id: userOption.id,
                            label: `${userOption.firstName} ${userOption.lastName}`
                          }))}
                      />
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <Button
                        variant="contained"
                        onClick={() => formikAssignedMonitors.submitForm()}
                      >
                        {intl.formatMessage({
                          id: 'global.save'
                        })}
                      </Button>
                    </Grid>
                  </Grid>
                )
              )}
            </TabPanel>
          </TabContext>
        )}
      </Container>
    </Page>
  );
}
