import { useFormik } from 'formik';
// material
import { Backdrop, Button, CircularProgress, Container, Grid, Typography } from '@mui/material';
// components
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../components/Page';
//
import EditableFormTextField from '../forms/shared/EditableFormTextField';
import { institutionActions } from '../actions';
import EditableFormSelect from '../forms/shared/EditableFormSelect';
import institutionFormModel from '../forms/institution/institutionFormModel';
import { getInitialInstitutionFormValues } from '../forms/institution/formInitialValues';
import { institutionFormValidationSchema } from '../forms/institution/validationSchema';
import regiuni from '../utils/regiuni.json';
import judete from '../utils/judete.json';
import localitati from '../utils/localitati.json';

// ----------------------------------------------------------------------

const {
  institutionFormField: { name, shortName, fiscalCode, region, county, city, address }
} = institutionFormModel;

export default function InstitutionForm() {
  const intl = useIntl();
  const { id } = useParams();
  const [selectedInstitution, setSelectedInstitution] = useState(0); // set initial State to 0
  // useSelector extracts  data from the institutions state
  const institutions = useSelector((state) => state.institutions);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(institutionActions.getAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id !== '0' && institutions && institutions.institutions && institutions.institutions.data) {
      if (
        institutions.institutions.data.find((institution) => institution.institutieGuid === +id)
      ) {
        setSelectedInstitution(
          institutions.institutions.data.find((institution) => institution.institutieGuid === +id)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, institutions]);

  const formik = useFormik({
    initialValues: getInitialInstitutionFormValues(selectedInstitution || {}),
    enableReinitialize: true,
    validationSchema: institutionFormValidationSchema(
      institutions && institutions.institutions && institutions.institutions.data
        ? institutions.institutions.data
        : []
    ),
    onSubmit: (values) => {
      const valuesCopy = JSON.parse(JSON.stringify(values));
      if (id === '0') {
        dispatch(institutionActions.addInstitution(valuesCopy, navigate));
      } else {
        dispatch(institutionActions.updateInstitution(valuesCopy, navigate));
      }
    }
  });

  // display CircularProgress on institutions loading
  if (institutions.institutions?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  // display add_institution form in a grid with 7 items
  return (
    <Page title={intl.formatMessage({ id: 'page.add_institution.title' })} my={2}>
      <Container>
        <Typography variant="h3">{intl.formatMessage({ id: 'institution.title' })}</Typography>
        <Grid container spacing={3}>
          {/* Grid item name size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={name.name}
              formik={formik}
              label={intl.formatMessage({ id: name.label })}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
          {/* Grid item shortName size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={shortName.name}
              formik={formik}
              label={intl.formatMessage({ id: shortName.label })}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
          {/* Grid item fiscalCode size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={fiscalCode.name}
              formik={formik}
              label={intl.formatMessage({ id: fiscalCode.label })}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
          {/* Grid item region size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormSelect
              parentName={region.name}
              formik={formik}
              inputLabel={intl.formatMessage({ id: region.label })}
              options={regiuni.regiuni.sort().map((regiune) => ({ id: regiune, label: regiune }))}
              label={intl.formatMessage({ id: region.label })}
              required
            />
          </Grid>
          {/* Grid item county size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormSelect
              parentName={county.name}
              formik={formik}
              inputLabel={intl.formatMessage({ id: county.label })}
              options={
                formik.values[region.name]
                  ? judete.judete
                      .filter((judet) => judet.regiune === formik.values[region.name])
                      .sort((j1, j2) => j1.nume.toLowerCase().localeCompare(j2.nume.toLowerCase()))
                      .map((judet) => ({ id: judet.nume, label: judet.nume }))
                  : []
              }
              label={intl.formatMessage({ id: county.label })}
              required
            />
          </Grid>
          {/* Grid item city size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormSelect
              parentName={city.name}
              formik={formik}
              inputLabel={intl.formatMessage({ id: city.label })}
              options={
                formik.values[county.name] &&
                localitati.filter((judet) => judet.nume === formik.values[county.name]).length === 1
                  ? localitati
                      .filter((judet) => judet.nume === formik.values[county.name])[0]
                      .localitati.sort((l1, l2) =>
                        l1.nume.toLowerCase().localeCompare(l2.nume.toLowerCase())
                      )
                      .map((localitate) => ({
                        id: localitate.simplu ? localitate.simplu : localitate.nume,
                        label: localitate.simplu ? localitate.simplu : localitate.nume
                      }))
                  : []
              }
              label={intl.formatMessage({ id: city.label })}
              required
            />
          </Grid>
          {/* Grid item address size 12/12(full) of viewport */}
          <Grid item xs={12} sm={12}>
            <EditableFormTextField
              parentName={address.name}
              formik={formik}
              label={intl.formatMessage({ id: address.label })}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <Button variant="contained" onClick={() => formik.submitForm()} sx={{ marginTop: 2 }}>
          {intl.formatMessage({ id: 'global.save' })}
        </Button>
      </Container>
    </Page>
  );
}
