const declaratiiForm = {
  formId: 'declaratii',
  name: 'decalartiileSolicitantului',
  declaratiiFormFields: {
    nuEsteFinantat: {
      name: 'nuEsteFinantat',
      label: 'page.project.declarations.primaFinantare'
    },
    dateReale: {
      name: 'dateReale',
      label: 'page.project.declarations.dateReale'
    }
  }
};

export default declaratiiForm;
