import * as React from 'react';
import { useIntl } from 'react-intl';
// components
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';
import Page from '../components/Page';

import StepperForm from '../components/form/StepperForm';
import { FORM_IDS, ENTITIES } from '../utils/enums';

import SupportingDocuments from '../components/form/program/SupportingDocuments';
import ProgramInformation from '../components/form/program/ProgramInformation';
import TotalValue from '../components/form/program/TotalValue';
import ExpectedResults from '../components/form/program/ExpectedResults';
import ProjectsObjectives from '../components/form/program/ProjectsObjectives';
import IncomeStatus from '../components/form/program/IncomeStatus';
import ProjectProposition from '../components/form/program/ProjectProposition';
import { programActions, competitionActions } from '../actions';

// ----------------------------------------------------------------------

export default function Program({ role = 'READONLY', isNew = false }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const intl = useIntl();

  const program = useSelector((state) => state.programs?.selectedProgram);
  const competition = useSelector((state) => state.competitions?.selectedCompetition);

  const years = !isNew
    ? {
        start: program?.detaliiProgram?.dataStartProgram,
        end: program?.detaliiProgram?.dataEndProgram
      }
    : {
        start: competition?.durataDesfasurareIntervalInferior,
        end: competition?.durataDesfasurareIntervalSuperior
      };

  const programValues = {
    start: new Date(years.start).getFullYear(),
    end: new Date(years.end).getFullYear()
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(programActions.getProgram(id));
    } else if (id) {
      dispatch(competitionActions.getCompetition(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isNew]);

  const [completed, setCompleted] = React.useState({
    step0: true,
    step1: true,
    step2: true,
    step3: true,
    step4: true,
    step5: true,
    step6: true,
    step7: true,
    step8: true,
    step9: true,
    step10: true
  });

  return (
    <Page title={intl.formatMessage({ id: 'page.add_competition.title' })} my={2}>
      <Container>
        <StepperForm
          completed={completed}
          disabled={role !== ENTITIES.ADMINISTRATOR && role !== ENTITIES.INSTITUTION}
          steps={[
            {
              label: 'program.programInformation.title',
              element: (
                <ProgramInformation
                  disabled={role !== ENTITIES.ADMINISTRATOR && role !== ENTITIES.INSTITUTION}
                  isNew={isNew}
                />
              ),
              formId: FORM_IDS.PROGRAM_INFORMATION
            },
            {
              label: 'program.supportingDocuments.title',
              element: (
                <SupportingDocuments
                  disabled={role !== ENTITIES.ADMINISTRATOR && role !== ENTITIES.INSTITUTION}
                  isNew={isNew}
                />
              ),
              formId: FORM_IDS.PROGRAM_SUPPORTING_DOCUMENTS
            },
            {
              label: 'program.totalValue.title',
              element: (
                <TotalValue
                  program={programValues}
                  disabled={role !== ENTITIES.ADMINISTRATOR && role !== ENTITIES.INSTITUTION}
                  isNew={isNew}
                />
              ),
              formId: FORM_IDS.PROGRAM_TOTAL_VALUE
            },
            {
              label: 'program.expectedResults.title',
              element: (
                <ExpectedResults
                  disabled={role !== ENTITIES.ADMINISTRATOR && role !== ENTITIES.INSTITUTION}
                  isNew={isNew}
                />
              ),
              formId: FORM_IDS.PROGRAM_EXPECTED_RESULTS
            },
            {
              label: 'program.projects.title',
              element: (
                <ProjectsObjectives
                  program={programValues}
                  disabled={role !== ENTITIES.ADMINISTRATOR && role !== ENTITIES.INSTITUTION}
                />
              ),
              formId: FORM_IDS.PROGRAM_PROJECTS_OBJECTIVES
            },
            {
              label: 'program.suggestedProjects.title',
              element: <ProjectProposition id={id} role={role} />,
              formId: FORM_IDS.PROGRAM_PROJECT_PROPOSITION
            }
          ]}
        />
      </Container>
    </Page>
  );
}
