import { useFormik } from 'formik';
// material
import { Backdrop, Button, CircularProgress, Container, Grid, Typography } from '@mui/material';
// components
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
// import Page component
import Page from '../components/Page';
import EditableFormTextField from '../forms/shared/EditableFormTextField';
import { institutionActions } from '../actions';
import EditableFormSelect from '../forms/shared/EditableFormSelect';
import institutionInfoFormModel from '../forms/institutionInfo/institutionInfoFormModel';
import { getInitialInstitutionInfoFormValues } from '../forms/institutionInfo/formInitialValues';
import { institutionInfoFormValidationSchema } from '../forms/institutionInfo/validationSchema';
import regiuni from '../utils/regiuni.json';
import judete from '../utils/judete.json';
import localitati from '../utils/localitati.json';
import { parseInstitutionInfoSheet } from '../forms/institutionInfo/formOutputParser';

// ----------------------------------------------------------------------
// define const
const {
  institutionInfoFormField: {
    name,
    shortName,
    fiscalCode,
    region,
    county,
    city,
    address,
    directorLastName,
    directorFirstName,
    directorEmail,
    directorPhone,
    directorTitle,
    economicDirectorLastName,
    economicDirectorFirstName,
    economicDirectorEmail,
    economicDirectorPhone,
    economicDirectorTitle
  }
} = institutionInfoFormModel;

export default function InstitutionInfoForm() {
  const intl = useIntl();
  const institutions = useSelector((state) => state.institutions);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(institutionActions.getInfo(user.info.institutieParinte));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: getInitialInstitutionInfoFormValues(institutions.institution || {}),
    enableReinitialize: true,
    validationSchema: institutionInfoFormValidationSchema(),
    onSubmit: (values) => {
      const valuesCopy = parseInstitutionInfoSheet(JSON.parse(JSON.stringify(values)));
      dispatch(institutionActions.updateInstitution(valuesCopy));
    }
  });

  // display CircularProgress on institutions loading
  if (institutions?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  // display update_institution_info form in a grid with 7 items and the Director info form(also in a grid)
  return (
    <Page title={intl.formatMessage({ id: 'page.update_institution_info.title' })} my={2}>
      <Container>
        <Typography variant="h3">
          {intl.formatMessage({ id: 'page.update_institution_info.title' })}
        </Typography>
        <Grid container spacing={3}>
          {/* Grid item name size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={name.name}
              formik={formik}
              label={intl.formatMessage({ id: name.label })}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
          {/* Grid item shortName size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={shortName.name}
              formik={formik}
              label={intl.formatMessage({ id: shortName.label })}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
          {/* Grid item fiscalCode size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={fiscalCode.name}
              formik={formik}
              label={intl.formatMessage({ id: fiscalCode.label })}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
          {/* Grid item region size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormSelect
              parentName={region.name}
              formik={formik}
              inputLabel={intl.formatMessage({ id: region.label })}
              options={regiuni.regiuni.sort().map((regiune) => ({ id: regiune, label: regiune }))}
              label={intl.formatMessage({ id: region.label })}
              required
            />
          </Grid>
          {/* Grid item county size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormSelect
              parentName={county.name}
              formik={formik}
              inputLabel={intl.formatMessage({ id: county.label })}
              options={
                formik.values[region.name]
                  ? judete.judete
                      .filter((judet) => judet.regiune === formik.values[region.name])
                      .sort((j1, j2) => j1.nume.toLowerCase().localeCompare(j2.nume.toLowerCase()))
                      .map((judet) => ({ id: judet.nume, label: judet.nume }))
                  : []
              }
              label={intl.formatMessage({ id: county.label })}
              required
            />
          </Grid>
          {/* Grid item city size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormSelect
              parentName={city.name}
              formik={formik}
              inputLabel={intl.formatMessage({ id: city.label })}
              options={
                formik.values[county.name] &&
                localitati.filter((judet) => judet.nume === formik.values[county.name]).length === 1
                  ? localitati
                      .filter((judet) => judet.nume === formik.values[county.name])[0]
                      .localitati.sort((l1, l2) =>
                        l1.nume.toLowerCase().localeCompare(l2.nume.toLowerCase())
                      )
                      .map((localitate) => ({
                        id: localitate.simplu ? localitate.simplu : localitate.nume,
                        label: localitate.simplu ? localitate.simplu : localitate.nume
                      }))
                  : []
              }
              label={intl.formatMessage({ id: city.label })}
              required
            />
          </Grid>
          {/* Grid item name size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={12}>
            <EditableFormTextField
              parentName={address.name}
              formik={formik}
              label={intl.formatMessage({ id: address.label })}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <Typography variant="h3" sx={{ marginTop: '1rem' }}>
          {intl.formatMessage({ id: 'page.update_institution_info.general_director.title' })}
        </Typography>
        <Grid container spacing={3}>
          {/* Grid item directorLastName size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={directorLastName.name}
              formik={formik}
              label={intl.formatMessage({ id: directorLastName.label })}
              variant="outlined"
              fullWidth
            />
          </Grid>
          {/* Grid item directorFirstName size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={directorFirstName.name}
              formik={formik}
              label={intl.formatMessage({ id: directorFirstName.label })}
              variant="outlined"
              fullWidth
            />
          </Grid>
          {/* Grid item directorEmail size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={directorEmail.name}
              formik={formik}
              label={intl.formatMessage({ id: directorEmail.label })}
              variant="outlined"
              fullWidth
            />
          </Grid>
          {/* Grid item directorPhone size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={directorPhone.name}
              formik={formik}
              label={intl.formatMessage({ id: directorPhone.label })}
              variant="outlined"
              fullWidth
            />
          </Grid>
          {/* Grid item directorTitle size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={directorTitle.name}
              formik={formik}
              label={intl.formatMessage({ id: directorTitle.label })}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Typography variant="h3" sx={{ marginTop: '1rem' }}>
          {intl.formatMessage({ id: 'page.update_institution_info.economic_director.title' })}
        </Typography>
        <Grid container spacing={3}>
          {/* Grid item economicDirectorLastName size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={economicDirectorLastName.name}
              formik={formik}
              label={intl.formatMessage({ id: economicDirectorLastName.label })}
              variant="outlined"
              fullWidth
            />
          </Grid>
          {/* Grid item economicDirectorFirstName size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={economicDirectorFirstName.name}
              formik={formik}
              label={intl.formatMessage({ id: economicDirectorFirstName.label })}
              variant="outlined"
              fullWidth
            />
          </Grid>
          {/* Grid item economicDirectorEmail size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={economicDirectorEmail.name}
              formik={formik}
              label={intl.formatMessage({ id: economicDirectorEmail.label })}
              variant="outlined"
              fullWidth
            />
          </Grid>
          {/* Grid item economicDirectorPhone size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={economicDirectorPhone.name}
              formik={formik}
              label={intl.formatMessage({ id: economicDirectorPhone.label })}
              variant="outlined"
              fullWidth
            />
          </Grid>
          {/* Grid item economicDirectorTitle size 6/12 of viewport, size 12/12(full) of extrasmall viewport */}
          <Grid item xs={12} sm={6}>
            <EditableFormTextField
              parentName={economicDirectorTitle.name}
              formik={formik}
              label={intl.formatMessage({ id: economicDirectorTitle.label })}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Button variant="contained" onClick={() => formik.submitForm()} sx={{ marginTop: 2 }}>
          {intl.formatMessage({ id: 'global.save' })}
        </Button>
      </Container>
    </Page>
  );
}
