import monitoringAssignedMonitorsFormModel from './monitoringAssignedMonitorsFormModel';

const {
  monitoringAssignedMonitorsField: { assignedMonitors }
} = monitoringAssignedMonitorsFormModel;

export const getInitialMonitoringAssignedMonitorsFormValues = (programAssignedMonitors) => ({
  [assignedMonitors.name]: programAssignedMonitors
    ? programAssignedMonitors.map((assignedMonitor) => ({
        [assignedMonitors.project.name]: { id: assignedMonitor.proiectGuid },
        [assignedMonitors.monitor.name]: { id: assignedMonitor.monitorGuid }
      }))
    : []
});
