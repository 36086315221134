import PropTypes from 'prop-types';

export const Error = 'Error';
export const Success = 'Success';
export const Warning = 'Warning';
export const Info = 'Info';

export const SET_TOASTER = 'toast/SET_TOASTER';
export const DISPOSE_TOASTER = 'toast/DISPOSE_TOASTER';

toasterReducer.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
  values: PropTypes.any
};

export function toasterReducer(state = {}, action) {
  switch (action.type) {
    case SET_TOASTER:
      return {
        type: action.payload?.type,
        message: action.payload?.message,
        title: action.payload?.title,
        ...(action.payload?.values && { values: action.payload?.values })
      };
    case DISPOSE_TOASTER:
      return {};
    default:
      return state;
  }
}

export function setToaster(payload) {
  return async (dispatch) => {
    dispatch({
      type: SET_TOASTER,
      payload
    });
  };
}

export function disposeToaster() {
  return async (dispatch) => {
    dispatch({
      type: DISPOSE_TOASTER
    });
  };
}
