import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';

EditableFormCheckbox.propTypes = {
  parentName: PropTypes.string,
  childrenNames: PropTypes.array,
  formik: PropTypes.any,
  inputLabel: PropTypes.string,
  dependentParent: PropTypes.string,
  index: PropTypes.number,
  indexParent: PropTypes.string
};

export default function EditableFormCheckbox({
  parentName,
  childrenNames,
  formik,
  inputLabel,
  index,
  indexParent,
  ...nativeProps
}) {
  let value = formik.values[parentName];
  let tag = `${parentName}`;

  if (typeof index !== 'undefined' && indexParent === parentName) {
    value = value[index];
    tag = `${tag}[${index}]`;
  }

  if (childrenNames) {
    childrenNames.forEach((childName) => {
      value = value[childName];
      tag = `${tag}.${childName}`;
      if (typeof index !== 'undefined' && indexParent === childName) {
        value = value[index];
        tag = `${tag}[${index}]`;
      }
    });
  }

  return (
    <FormControlLabel
      control={
        <Checkbox {...nativeProps} checked={value} onChange={formik.handleChange} name={tag} />
      }
      label={inputLabel}
      labelPlacement="end"
    />
  );
}
