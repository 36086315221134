import * as React from 'react';
import { Button, Stack, Typography } from '@mui/material/';

import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';

// ----------------------------------------------------------------------------

export default function MuiTableAddEntity({ title, buttonText }) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
      <Typography variant="h4" gutterBottom>
        {title}
      </Typography>
      <Button
        variant="contained"
        component={RouterLink}
        to="0"
        startIcon={<Icon icon={plusFill} />}
      >
        {buttonText}
      </Button>
    </Stack>
  );
}
