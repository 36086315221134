import { useIntl } from 'react-intl';
import { Backdrop, CircularProgress, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import Page from '../components/Page';
import CardList from '../components/lists/CardList';
import { evaluationActions } from '../actions';
import ProgramsEvaluationObjectiveCard from '../components/cards/ProgramsEvaluationObjectiveCard';
// ----------------------------------------------------------------------

export default function ProgramEvaluationObjectives() {
  const intl = useIntl();
  const { id } = useParams();

  const [sort, setSort] = useState('ascending');
  const [searchText, setSearchText] = useState('');
  const [objectiveItems, setObjectiveItems] = useState([]);
  const evaluation = useSelector((state) => state.evaluation);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(evaluationActions.getMyProgramEvaluationPrograms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (evaluation && evaluation.programs && evaluation.programs.length > 0) {
      const programName = evaluation.programs.find(
        (program) => program.programGuid === +id
      ).titluProgram;
      const objectives = [
        ...[...evaluation.programs].find((program) => program.programGuid === +id).obiective
      ].sort((o1, o2) => o1.titluObiectiv.localeCompare(o2.titluObiectiv));

      setObjectiveItems(
        objectives.map((objective) => ({
          ...objective,
          numeProgram: programName
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluation]);

  useEffect(() => {
    let filteredObjectives = [];
    if (evaluation && evaluation.programs && evaluation.programs.length > 0) {
      const programName = evaluation.programs.find(
        (program) => program.programGuid === +id
      ).titluProgram;

      if (searchText && searchText.length > 0) {
        filteredObjectives = [...evaluation.programs]
          .find((program) => program.programGuid === +id)
          .obiective.filter((obiectiv) =>
            obiectiv.titluObiectiv.toLowerCase().includes(searchText.toLowerCase())
          );
      } else {
        filteredObjectives = [...evaluation.programs].find(
          (program) => program.programGuid === +id
        ).obiective;
      }

      filteredObjectives = filteredObjectives.map((objective) => ({
        ...objective,
        numeProgram: programName
      }));
    }

    filteredObjectives = [...filteredObjectives].sort((o1, o2) =>
      sort === 'ascending'
        ? o1.titluObiectiv.toLowerCase().localeCompare(o2.titluObiectiv.toLowerCase())
        : o2.titluObiectiv.toLowerCase().localeCompare(o1.titluObiectiv.toLowerCase())
    );

    setObjectiveItems([...filteredObjectives]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, searchText]);

  if (evaluation?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Page title={intl.formatMessage({ id: 'applicant.programmes' })} my={2}>
      <Container>
        {objectiveItems && (
          <CardList
            entityId="obiectivGuid"
            sort={sort}
            setSort={setSort}
            searchText={searchText}
            setSearchText={setSearchText}
            sortFieldTitle={intl.formatMessage({ id: 'global.name' })}
            items={objectiveItems}
            Card={ProgramsEvaluationObjectiveCard}
          />
        )}
      </Container>
    </Page>
  );
}
