export const evaluationConstants = {
  GET_OBJECTIVES_SUCCESS: 'GET_OBJECTIVES_SUCCESS',
  GET_OBJECTIVES_FAILURE: 'GET_OBJECTIVES_FAILURE',
  GET_OBJECTIVES_REQUEST: 'GET_OBJECTIVES_REQUEST',

  GET_EVALUATION_PROGRAMS_SUCCESS: 'GET_EVALUATION_PROGRAMS_SUCCESS',
  GET_EVALUATION_PROGRAMS_FAILURE: 'GET_EVALUATION_PROGRAMS_FAILURE',
  GET_EVALUATION_PROGRAMS_REQUEST: 'GET_EVALUATION_PROGRAMS_REQUEST',

  GET_PROGRAM_EVALUATION_SUCCESS: 'GET_PROGRAM_EVALUATION_SUCCESS',
  GET_PROGRAM_EVALUATION_FAILURE: 'GET_PROGRAM_EVALUATION_FAILURE',
  GET_PROGRAM_EVALUATION_REQUEST: 'GET_PROGRAM_EVALUATION_REQUEST',

  SAVE_DRAFT_PROJECT_EVALUATION_SUCCESS: 'SAVE_DRAFT_PROJECT_EVALUATION_SUCCESS',
  SAVE_DRAFT_PROJECT_EVALUATION_FAILURE: 'SAVE_DRAFT_PROJECT_EVALUATION_FAILURE',
  SAVE_DRAFT_PROJECT_EVALUATION_REQUEST: 'SAVE_DRAFT_PROJECT_EVALUATION_REQUEST',

  SUBMIT_PROJECT_EVALUATION_SUCCESS: 'SUBMIT_PROJECT_EVALUATION_SUCCESS',
  SUBMIT_PROJECT_EVALUATION_FAILURE: 'SUBMIT_PROJECT_EVALUATION_FAILURE',
  SUBMIT_PROJECT_EVALUATION_REQUEST: 'SUBMIT_PROJECT_EVALUATION_REQUEST',

  SAVE_DRAFT_PROGRAM_EVALUATION_SUCCESS: 'SAVE_DRAFT_PROGRAM_EVALUATION_SUCCESS',
  SAVE_DRAFT_PROGRAM_EVALUATION_FAILURE: 'SAVE_DRAFT_PROGRAM_EVALUATION_FAILURE',
  SAVE_DRAFT_PROGRAM_EVALUATION_REQUEST: 'SAVE_DRAFT_PROGRAM_EVALUATION_REQUEST',

  SUBMIT_PROGRAM_EVALUATION_SUCCESS: 'SUBMIT_PROGRAM_EVALUATION_SUCCESS',
  SUBMIT_PROGRAM_EVALUATION_FAILURE: 'SUBMIT_PROGRAM_EVALUATION_FAILURE',
  SUBMIT_PROGRAM_EVALUATION_REQUEST: 'SUBMIT_PROGRAM_EVALUATION_REQUEST',

  CONTEST_PROGRAM_EVALUATION_SUCCESS: 'CONTEST_PROGRAM_EVALUATION_SUCCESS',
  CONTEST_PROGRAM_EVALUATION_FAILURE: 'CONTEST_PROGRAM_EVALUATION_FAILURE',
  CONTEST_PROGRAM_EVALUATION_REQUEST: 'CONTEST_PROGRAM_EVALUATION_REQUEST',

  SEND_PROJECT_TO_MONITORING_SUCCESS: 'SEND_PROJECT_TO_MONITORING_SUCCESS',
  SEND_PROJECT_TO_MONITORING_FAILURE: 'SEND_PROJECT_TO_MONITORING_FAILURE',
  SEND_PROJECT_TO_MONITORING_REQUEST: 'SEND_PROJECT_TO_MONITORING_REQUEST'
};
