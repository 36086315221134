import * as React from 'react';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { autocompleteService } from '../services';

const ContainerStyle = styled('div')({
  display: 'flex'
});

const filter = createFilterOptions();

const title = { name: 'cuvantCheie' };

export default function KeywordField({
  disabled,
  values,
  keywordField,
  parentName,
  childrenNames,
  arrayHelpers
}) {
  const intl = useIntl();
  const [indexToDelete, setIndex] = React.useState(null);
  const [value, setValue] = React.useState('');
  const [keywordOptions, setKeywordOptions] = React.useState([]);

  let chipValues = parentName in values ? values[parentName] : '';
  if (childrenNames) {
    childrenNames.forEach((childName) => {
      chipValues = chipValues[childName];
    });
  }

  React.useEffect(() => {
    if (indexToDelete !== null) {
      arrayHelpers.remove(indexToDelete);
      setIndex(null);
    }
  }, [indexToDelete]);

  React.useEffect(() => {
    (async () => {
      const options = await autocompleteService.getKeywords('');
      setKeywordOptions(options);
    })();
  }, []);

  const getOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option[title.name];
  };

  const addToKeywords = () => () => {
    if (value) {
      arrayHelpers.unshift(value);
      setValue('');
    }
  };

  const onValueChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      setValue(newValue);
    } else if (newValue && newValue.inputValue) {
      setValue(newValue.inputValue);
    } else {
      setValue(newValue[title.name]);
    }
  };

  const filterOptions = (options, params) => filter(options, params);

  return (
    <>
      {disabled ? (
        <InputLabel id="keywords-label">
          {intl.formatMessage({ id: keywordField.label })}
        </InputLabel>
      ) : (
        <ContainerStyle>
          <Autocomplete
            value={value}
            onChange={onValueChange}
            filterOptions={filterOptions}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="keywords-field"
            options={keywordOptions}
            getOptionLabel={getOptionLabel}
            renderOption={(props, option) => <li {...props}>{option[title.name]}</li>}
            freeSolo
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={(e) => onValueChange(e, e.target.value)}
                label={intl.formatMessage({ id: keywordField.label })}
              />
            )}
          />
          <Button variant="outlined" onClick={addToKeywords()} sx={{ ml: 2 }}>
            {intl.formatMessage({ id: keywordField.button })}
          </Button>
        </ContainerStyle>
      )}
      {chipValues && chipValues.length !== 0 ? (
        <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
          {chipValues.map((keyword, index) => (
            <Chip
              key={keyword}
              label={keyword}
              onDelete={disabled ? undefined : () => setIndex(index)}
            />
          ))}
        </Stack>
      ) : (
        disabled && <p>{intl.formatMessage({ id: 'global.no_entry' })}</p>
      )}
    </>
  );
}
