import * as Yup from 'yup';
import publicationsFormModel from './publicationsFormModel';

const {
  publicationsField: { publication }
} = publicationsFormModel;

export const publicationFormValidationSchema = () =>
  Yup.object().shape({
    [publication.name]: Yup.object().shape({
      [publication.type.name]: Yup.string().required(`${publication.type.requiredErrorMsg}`),
      [publication.title.name]: Yup.string().required(`${publication.title.requiredErrorMsg}`),
      [publication.publicationDate.name]: Yup.date()
        .nullable()
        .typeError(`${publication.publicationDate.dateErrorMsg}`)
    })
  });
