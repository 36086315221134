import eligibilitySheetFormModel from './eligibilitySheetFormModel';

const {
  eligibilitySheetField: { documents, status }
} = eligibilitySheetFormModel;

export const parseEligibilitySheet = (eligibilitySheetValues) => {
  const parseEligibilitySheetForm = { ...eligibilitySheetValues };

  if (parseEligibilitySheetForm[documents.name]) {
    delete parseEligibilitySheetForm[documents.name];
  }

  parseEligibilitySheetForm[status.name] = parseEligibilitySheetForm[status.name]
    ? 'Admis'
    : 'Respins';

  return parseEligibilitySheetForm;
};
