import informatiiGeneraleForm from './informatiiGeneraleFormModel';

const {
  informatiiGeneraleFormFields: {
    projectTitle,
    keywords,
    responsabil,
    cnpResponsabil,
    titluResponsabil,
    functieResponsabil,
    telResponsabil,
    faxResponsabil,
    emailResponsabil,
    responsabilProiect,
    cvResponsabil,
    domenii,
    prezentareaProiectului,
    descriereaStiintificaFile,
    structuraBugetuluiFile,
    managementulRisculuiFile,
    impactFile,
    planFile,
    alteInformatiiFile
  }
} = informatiiGeneraleForm;

export const getInitialInformatiiGeneraleFormValues = (proiect) => ({
  [projectTitle.name]: proiect?.[projectTitle.name] ?? '',
  [keywords.name]: proiect?.[keywords.name] ?? [],
  [domenii.name]: proiect?.[domenii.name] ?? '',
  [prezentareaProiectului.name]: proiect?.[prezentareaProiectului.name] ?? '',
  [descriereaStiintificaFile.name]: proiect?.[descriereaStiintificaFile.name] ?? '',
  [structuraBugetuluiFile.name]: proiect?.[structuraBugetuluiFile.name] ?? '',
  [managementulRisculuiFile.name]: proiect?.[managementulRisculuiFile.name] ?? '',
  [impactFile.name]: proiect?.[impactFile.name] ?? '',
  [planFile.name]: proiect?.[planFile.name] ?? '',
  [alteInformatiiFile.name]: proiect?.[alteInformatiiFile.name] ?? '',
  [telResponsabil.name]: proiect?.[responsabilProiect.name]?.[telResponsabil.name] ?? '',
  [faxResponsabil.name]: proiect?.[responsabilProiect.name]?.[faxResponsabil.name] ?? '',
  [emailResponsabil.name]: proiect?.[responsabilProiect.name]?.[emailResponsabil.name] ?? '',
  [cnpResponsabil.name]: proiect?.[responsabilProiect.name]?.[cnpResponsabil.name] ?? '',
  [titluResponsabil.name]: proiect?.[responsabilProiect.name]?.[titluResponsabil.name] ?? '',
  [functieResponsabil.name]: proiect?.[responsabilProiect.name]?.[functieResponsabil.name] ?? '',
  [cvResponsabil.name]: proiect?.[responsabilProiect.name]?.[cvResponsabil.name] ?? '',
  [responsabil.name]:
    proiect &&
    proiect[responsabilProiect.name] &&
    proiect[responsabilProiect.name][responsabil.name]
      ? {
          id: proiect[responsabilProiect.name][responsabil.name].userGuid,
          label: proiect[responsabilProiect.name][responsabil.name].nume
        }
      : {}
});
