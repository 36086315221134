import * as Yup from 'yup';
import adminEligibilityContestationAssignFormModel from './adminEligibilityContestationAssignFormModel';

const {
  adminEligibilityContestationAssignField: { writer, reviwer }
} = adminEligibilityContestationAssignFormModel;

export const adminEligibilityContestationAssignFormValidationSchema = () =>
  Yup.object().shape({
    [writer.name]: Yup.object().nullable().required(`${writer.requiredErrorMsg}`),
    [reviwer.name]: Yup.object().nullable().required(`${reviwer.requiredErrorMsg}`)
  });
