const adminEligibilityContestationAssignForm = {
  formId: 'adminEligibilityContestationAssignForm',
  adminEligibilityContestationAssignField: {
    writer: {
      name: 'UserWriterGuid',
      label: 'eligibility.contestation.reponsible.writer',
      requiredErrorMsg: 'form.error.required'
    },
    reviwer: {
      name: 'UserReviewerGuid',
      label: 'eligibility.contestation.reponsible.reviwer',
      requiredErrorMsg: 'form.error.required'
    }
  }
};

export default adminEligibilityContestationAssignForm;
