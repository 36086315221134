import { useEffect, useState } from 'react';
// material
import { Container } from '@mui/material';
// components
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../components/Page';
import { UserMoreMenu } from '../components/_dashboard/user';
//
import { institutionActions, userActions } from '../actions';
import MuiTableAddEntity from '../components/table/MuiTableAddEntity';
import MuiTable from '../components/table/MuiTable';
import { UserRoles } from '../domain/UserRoles';

// ----------------------------------------------------------------------
// define consts array of Objects
const TABLE_HEAD_INSTITUTION = [
  { id: 'id', label: 'users.id', alignRight: false },
  { id: 'lastName', label: 'global.name', alignRight: false },
  { id: 'firstName', label: 'form.my_account.firstname', alignRight: false },
  { id: 'email', label: 'form.my_account.email', alignRight: false },
  { id: 'phone', label: 'form.my_account.phone', alignRight: false },
  { id: 'actions', label: 'users.actions', alignRight: true, sortable: false }
];

const TABLE_HEAD_ADMIN = [
  { id: 'id', label: 'users.id', alignRight: false },
  { id: 'lastName', label: 'global.name', alignRight: false },
  { id: 'firstName', label: 'form.my_account.firstname', alignRight: false },
  { id: 'email', label: 'form.my_account.email', alignRight: false },
  { id: 'phone', label: 'form.my_account.phone', alignRight: false },
  { id: 'roles', label: 'users.roles', alignRight: false },
  { id: 'actions', label: 'users.actions', alignRight: true, sortable: false }
];

// ----------------------------------------------------------------------

export default function User() {
  const [userRows, setUserRows] = useState([]);
  // useSelector extracts  data from the user state
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && user.info) {
      dispatch(userActions.getAll(user.info.userType, user.info.institutieParinte));
      if (user.info.userType.includes(UserRoles.PlatformAdministrator)) {
        dispatch(institutionActions.getAll());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && user.users && user.users.data) {
      setUserRows(
        user.users.data.map((user) => ({
          ...user,
          roles: user.userType.join(' ,')
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // deleteUser
  const handleDeleteUsers = (usersEmails) => {
    usersEmails.forEach((email) => {
      const selectedUser = user.users.data.find((user) => user.email === email);
      if (selectedUser) {
        dispatch(userActions.deleteUser(selectedUser));
      }
    });
  };

  const intl = useIntl();
  // display user form
  return (
    <Page title={intl.formatMessage({ id: 'global.users' })} my={2}>
      <Container>
        <MuiTableAddEntity
          title={intl.formatMessage({ id: 'global.users' })}
          buttonText={intl.formatMessage({ id: 'global.users.add' })}
        />
        {user && user.users && user.users.data && (
          <MuiTable
            tableHead={
              user.info.userType.includes(UserRoles.PlatformAdministrator)
                ? TABLE_HEAD_ADMIN
                : TABLE_HEAD_INSTITUTION
            }
            searchPlaceholder="search.admin.user"
            itemIdentifier="email"
            defaultOrderBy="lastName"
            defaultOrder="asc"
            data={userRows}
            filterFunction={(query) => (_user) =>
              `${_user.lastName} ${_user.firstName}`.toLowerCase().indexOf(query.toLowerCase()) !==
              -1}
            MoreMenu={UserMoreMenu}
            // todo: disabled delete functionality until further notice
            // checkboxActive
            // handleDeleteEntity={handleDeleteUsers}
            // deleteConfirmationTitle={intl.formatMessage({ id: 'users.delete.title' })}
            // deleteConfirmationMessage={intl.formatMessage({ id: 'user.delete.selected' })}
          />
        )}
      </Container>
    </Page>
  );
}
