import * as React from 'react';
import { useIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { ELIGIBILITY_STATUS, EVALUATOR_ROLES } from '../../utils/enums';
// ----------------------------------------------------------------------------

export default function EligibilityProgramCard({ entity }) {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Card key={entity.programGuid.toString()}>
      <CardHeader
        title={
          <div>
            <a href={`programe/${entity.programGuid.toString()}`}>{entity.numeProgram}</a>
          </div>
        }
        action={
          <Button
            variant="outlined"
            onClick={() => navigate(`fisa-eligibilitate/${entity.programGuid}`)}
          >
            {intl.formatMessage({
              id:
                entity.role !== EVALUATOR_ROLES.USER_REVIEWER &&
                entity.role !== EVALUATOR_ROLES.USER_CONTESTATIE_REVIEWER &&
                entity.statusFisaEligibilitate !== ELIGIBILITY_STATUS.SUBMITTED
                  ? 'eligibilityProgramCard.button.label'
                  : 'eligibilityProgramCard.view.button.label'
            })}
          </Button>
        }
      />
      <CardContent>
        <p>{intl.formatMessage({ id: 'programCard.competition' })}</p>
        <p>{entity.numeCompetitieParinte}</p>
      </CardContent>
    </Card>
  );
}
