import monitoringCycleForm from './monitoringCycleFormModel';

const {
  monitoringCycleField: { cycle }
} = monitoringCycleForm;

export const parseMonitoringCycles = (monitoringCyclesValues, idCompetition) => {
  const parseMonitoringCyclesForm = { ...monitoringCyclesValues };

  if (parseMonitoringCyclesForm[cycle.name]) {
    parseMonitoringCyclesForm[cycle.name].forEach((cycleItem) => {
      cycleItem.CompetitionGuid = idCompetition;
      cycleItem[cycle.projects.name] = cycleItem[cycle.projects.name].map((project) => {
        delete project.disabled;
        delete project.isNew;
        return {
          ...project,
          [cycle.projects.project.name]: project[cycle.projects.project.name].id,
          [cycle.projects.monitor.name]: project[cycle.projects.monitor.name].id
        };
      });
    });
  }

  return parseMonitoringCyclesForm;
};
