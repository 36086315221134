export const reportConstants = {
  GET_AVAILABLE_PROJECTS_SUCCESS: 'GET_AVAILABLE_PROJECTS_SUCCESS',
  GET_AVAILABLE_PROJECTS_FAILURE: 'GET_AVAILABLE_PROJECTS_FAILURE',
  GET_AVAILABLE_PROJECTS_REQUEST: 'GET_AVAILABLE_PROJECTS_REQUEST',

  GET_FINAL_CYCLES_SUCCESS: 'GET_FINAL_CYCLES_SUCCESS',
  GET_FINAL_CYCLES_FAILURE: 'GET_FINAL_CYCLES_FAILURE',
  GET_FINAL_CYCLES_REQUEST: 'GET_FINAL_CYCLES_REQUEST',

  GET_PROJECT_CYCLES_SUCCESS: 'GET_PROJECT_CYCLES_SUCCESS',
  GET_PROJECT_CYCLES_FAILURE: 'GET_PROJECT_CYCLES_FAILURE',
  GET_PROJECT_CYCLES_REQUEST: 'GET_PROJECT_CYCLES_REQUEST',

  GET_CYCLE_PHASES_SUCCESS: 'GET_CYCLE_PHASES_SUCCESS',
  GET_CYCLE_PHASES_FAILURE: 'GET_CYCLE_PHASES_FAILURE',
  GET_CYCLE_PHASES_REQUEST: 'GET_CYCLE_PHASES_REQUEST',

  GET_CYCLE_RESULTS_SUCCESS: 'GET_CYCLE_RESULTS_SUCCESS',
  GET_CYCLE_RESULTS_FAILURE: 'GET_CYCLE_RESULTS_FAILURE',
  GET_CYCLE_RESULTS_REQUEST: 'GET_CYCLE_RESULTS_REQUEST',

  GET_PHASE_DATA_SUCCESS: 'GET_PHASE_DATA_SUCCESS',
  GET_PHASE_DATA_FAILURE: 'GET_PHASE_DATA_FAILURE',
  GET_PHASE_DATA_REQUEST: 'GET_PHASE_DATA_REQUEST',

  GET_EVALUATOR_PROGRAMS_SUCCESS: 'GET_EVALUATOR_PROGRAMS_SUCCESS',
  GET_EVALUATOR_PROGRAMS_FAILURE: 'GET_EVALUATOR_PROGRAMS_FAILURE',
  GET_EVALUATOR_PROGRAMS_REQUEST: 'GET_EVALUATOR_PROGRAMS_REQUEST',

  GET_PHASE_PUBLICATIONS_SUCCESS: 'GET_PHASE_PUBLICATIONS_SUCCESS',
  GET_PHASE_PUBLICATIONS_FAILURE: 'GET_PHASE_PUBLICATIONS_FAILURE',
  GET_PHASE_PUBLICATIONS_REQUEST: 'GET_PHASE_PUBLICATIONS_REQUEST',

  GET_PHASE_DOCUMENTS_SUCCESS: 'GET_PHASE_DOCUMENTS_SUCCESS',
  GET_PHASE_DOCUMENTS_FAILURE: 'GET_PHASE_DOCUMENTS_FAILURE',
  GET_PHASE_DOCUMENTS_REQUEST: 'GET_PHASE_DOCUMENTS_REQUEST',

  GET_PHASE_PATENTS_SUCCESS: 'GET_PHASE_PATENTS_SUCCESS',
  GET_PHASE_PATENTS_FAILURE: 'GET_PHASE_PATENTS_FAILURE',
  GET_PHASE_PATENTS_REQUEST: 'GET_PHASE_PATENTS_REQUEST',

  GET_ASSIGNED_REPORT_EVALUATORS_SUCCESS: 'GET_ASSIGNED_REPORT_EVALUATORS_SUCCESS',
  GET_ASSIGNED_REPORT_EVALUATORS_FAILURE: 'GET_ASSIGNED_REPORT_EVALUATORS_FAILURE',
  GET_ASSIGNED_REPORT_EVALUATORS_REQUEST: 'GET_ASSIGNED_REPORT_EVALUATORS_REQUEST',

  SAVE_PHASE_PUBLICATION_SUCCESS: 'SAVE_PHASE_PUBLICATION_SUCCESS',
  SAVE_PHASE_PUBLICATION_FAILURE: 'SAVE_PHASE_PUBLICATION_FAILURE',
  SAVE_PHASE_PUBLICATION_REQUEST: 'SAVE_PHASE_PUBLICATION_REQUEST',

  UPDATE_PHASE_PUBLICATION_SUCCESS: 'UPDATE_PHASE_PUBLICATION_SUCCESS',
  UPDATE_PHASE_PUBLICATION_FAILURE: 'UPDATE_PHASE_PUBLICATION_FAILURE',
  UPDATE_PHASE_PUBLICATION_REQUEST: 'UPDATE_PHASE_PUBLICATION_REQUEST',

  SAVE_PHASE_PATENT_SUCCESS: 'SAVE_PHASE_PATENT_SUCCESS',
  SAVE_PHASE_PATENT_FAILURE: 'SAVE_PHASE_PATENT_FAILURE',
  SAVE_PHASE_PATENT_REQUEST: 'SAVE_PHASE_PATENT_REQUEST',

  UPDATE_PHASE_PATENT_SUCCESS: 'UPDATE_PHASE_PATENT_SUCCESS',
  UPDATE_PHASE_PATENT_FAILURE: 'UPDATE_PHASE_PATENT_FAILURE',
  UPDATE_PHASE_PATENT_REQUEST: 'UPDATE_PHASE_PATENT_REQUEST',

  SAVE_PHASE_DOCUMENTS_SUCCESS: 'SAVE_PHASE_DOCUMENTS_SUCCESS',
  SAVE_PHASE_DOCUMENTS_FAILURE: 'SAVE_PHASE_DOCUMENTS_FAILURE',
  SAVE_PHASE_DOCUMENTS_REQUEST: 'SAVE_PHASE_DOCUMENTS_REQUEST',

  SAVE_EVALUATOR_REPORT_SUCCESS: 'SAVE_EVALUATOR_REPORT_SUCCESS',
  SAVE_EVALUATOR_REPORT_FAILURE: 'SAVE_EVALUATOR_REPORT_FAILURE',
  SAVE_EVALUATOR_REPORT_REQUEST: 'SAVE_EVALUATOR_REPORT_REQUEST',

  SAVE_ASSIGNED_EVALUATORS_SUCCESS: 'SAVE_ASSIGNED_EVALUATORS_SUCCESS',
  SAVE_ASSIGNED_EVALUATORS_FAILURE: 'SAVE_ASSIGNED_EVALUATORS_FAILURE',
  SAVE_ASSIGNED_EVALUATORS_REQUEST: 'SAVE_ASSIGNED_EVALUATORS_REQUEST',

  SET_EDIT_PUBLICATION: 'SET_EDIT_PUBLICATION',
  SET_EDIT_PATENT: 'SET_EDIT_PATENT'
};
