import { camelizeKeys } from 'humps';
import { competitionConstants } from '../constants';
import { triggerError, triggerSuccess } from '../utils/toasterTriggers';
import { competitionService } from '../services';

export const competitionActions = {
  getAllAdmin,
  getInstitutionCompetitions,
  getCompetition,
  addCompetition,
  updateCompetition,
  addCompetitionEligibility,
  addCompetitionProgramEvaluation,
  addCompetitionProjectEvaluation,
  clearSelectedCompetition
};

function getAllAdmin() {
  return (dispatch) => {
    dispatch(request());
    competitionService.getAllAdmin().then(
      (competitions) => {
        dispatch(success(competitions.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: competitionConstants.GET_ALL_ADMIN_REQUEST };
  }
  function success(competitions) {
    return { type: competitionConstants.GET_ALL_ADMIN_SUCCESS, competitions };
  }
  function failure(error) {
    return { type: competitionConstants.GET_ALL_ADMIN_FAILURE, error };
  }
}

function getInstitutionCompetitions(institutionId) {
  return (dispatch) => {
    dispatch(request());
    competitionService.getInstitutionCompetitions(institutionId).then(
      (competitions) => {
        dispatch(success(competitions));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: competitionConstants.GET_INSTITUTION_COMPETITIONS_REQUEST };
  }
  function success(competitions) {
    return { type: competitionConstants.GET_INSTITUTION_COMPETITIONS_SUCCESS, competitions };
  }
  function failure(error) {
    return { type: competitionConstants.GET_INSTITUTION_COMPETITIONS_FAILURE, error };
  }
}

function getCompetition(id, isAdmin = false) {
  return (dispatch) => {
    dispatch(request());
    competitionService.getCompetition(id).then(
      (competition) => {
        if (isAdmin) {
          competitionService.getCompetitionPrograms(id).then(
            (programsInfo) => {
              competition.programs = programsInfo.programe;
              dispatch(success(competition));
            },
            (error) => {
              dispatch(failure(error.toString()));
              triggerError(error.errorMessage, 'toast.error.get');
            }
          );
        } else {
          dispatch(success(competition));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: competitionConstants.GET_COMPETITION_ADMIN_REQUEST };
  }
  function success(competition) {
    return { type: competitionConstants.GET_COMPETITION_ADMIN_SUCCESS, competition };
  }
  function failure(error) {
    return { type: competitionConstants.GET_COMPETITION_ADMIN_FAILURE, error };
  }
}

function addCompetition(competition, callback) {
  return (dispatch) => {
    dispatch(request());
    competitionService.addCompetition(competition).then(
      (competition) => {
        dispatch(success(competition));
        triggerSuccess('', 'toast.success.competition.add');
        if (callback) {
          callback(competition.competitionGuid);
        }
      },
      (error) => {
        dispatch(failure(JSON.stringify(error)));
        triggerError(error.errorMessage, 'toast.error.competition.add');
      }
    );
  };

  function request() {
    return { type: competitionConstants.COMPETITION_ADD_REQUEST };
  }
  function success(competition) {
    return { type: competitionConstants.COMPETITION_ADD_SUCCESS, competition };
  }
  function failure(error) {
    return { type: competitionConstants.COMPETITION_ADD_FAILURE, error };
  }
}

function updateCompetition(competition, callback) {
  return (dispatch) => {
    dispatch(request());
    competitionService.updateCompetition(competition).then(
      (competition) => {
        dispatch(success(competition));
        triggerSuccess('', 'toast.success.competition.add');
        if (callback) {
          callback(competition.competitionGuid);
        }
      },
      (error) => {
        dispatch(failure(JSON.stringify(error)));
        triggerError(error.errorMessage, 'toast.error.competition.add');
      }
    );
  };

  function request() {
    return { type: competitionConstants.COMPETITION_UPDATE_REQUEST };
  }
  function success(competition) {
    return { type: competitionConstants.COMPETITION_UPDATE_SUCCESS, competition };
  }
  function failure(error) {
    return { type: competitionConstants.COMPETITION_UPDATE_FAILURE, error };
  }
}

function addCompetitionEligibility(competitionEligibility, competitionId) {
  return (dispatch) => {
    dispatch(request());
    competitionService.addCompetitionEligibility(competitionEligibility, competitionId).then(
      () => {
        dispatch(success(camelizeKeys(competitionEligibility)));
        triggerSuccess('', 'toast.success.competition.eligibility.add');
      },
      (error) => {
        dispatch(failure(JSON.stringify(error)));
        triggerError(error.errorMessage, 'toast.error.competition.eligibility.add');
      }
    );
  };

  function request() {
    return { type: competitionConstants.COMPETITION_ADD_ELIGIBILITY_REQUEST };
  }
  function success(competitionEligibility) {
    return {
      type: competitionConstants.COMPETITION_ADD_ELIGIBILITY_SUCCESS,
      competitionEligibility
    };
  }
  function failure(error) {
    return { type: competitionConstants.COMPETITION_ADD_ELIGIBILITY_FAILURE, error };
  }
}

function addCompetitionProgramEvaluation(programEvaluation, competitionId) {
  return (dispatch) => {
    dispatch(request());
    competitionService.addCompetitionProgramEvaluation(programEvaluation, competitionId).then(
      () => {
        dispatch(success(camelizeKeys(programEvaluation)));
        triggerSuccess('', 'toast.success.competition.program_evaluation.add');
      },
      (error) => {
        dispatch(failure(JSON.stringify(error)));
        triggerError(error.errorMessage, 'toast.error.competition.program_evaluation.add');
      }
    );
  };

  function request() {
    return { type: competitionConstants.COMPETITION_ADD_PROGRAM_EVALUATION_REQUEST };
  }
  function success(programEvaluation) {
    return {
      type: competitionConstants.COMPETITION_ADD_PROGRAM_EVALUATION_SUCCESS,
      programEvaluation
    };
  }
  function failure(error) {
    return { type: competitionConstants.COMPETITION_ADD_PROGRAM_EVALUATION_FAILURE, error };
  }
}

function addCompetitionProjectEvaluation(projectEvaluation, competitionId) {
  return (dispatch) => {
    dispatch(request());
    competitionService.addCompetitionProjectEvaluation(projectEvaluation, competitionId).then(
      () => {
        dispatch(success(camelizeKeys(projectEvaluation)));
        triggerSuccess('', 'toast.success.competition.project_evaluation.add');
      },
      (error) => {
        dispatch(failure(JSON.stringify(error)));
        triggerError(error.errorMessage, 'toast.error.competition.project_evaluation.add');
      }
    );
  };

  function request() {
    return { type: competitionConstants.COMPETITION_ADD_PROJECT_EVALUATION_REQUEST };
  }
  function success(projectEvaluation) {
    return {
      type: competitionConstants.COMPETITION_ADD_PROJECT_EVALUATION_SUCCESS,
      projectEvaluation
    };
  }
  function failure(error) {
    return { type: competitionConstants.COMPETITION_ADD_PROJECT_EVALUATION_FAILURE, error };
  }
}

function clearSelectedCompetition() {
  return (dispatch) => {
    dispatch(success());
  };

  function success() {
    return { type: competitionConstants.COMPETITION_CLEAR_SELECTED_SUCCESS };
  }
}
