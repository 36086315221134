import * as React from 'react';
import { useIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PROJECT_EVALUATION_STATUS } from '../../utils/enums';
// ----------------------------------------------------------------------------

export default function ProjectEvaluationCard({ entity }) {
  const intl = useIntl();
  const navigate = useNavigate();
  const [buttonText, setButtonText] = useState('projectEvaluationSheet.button.label');

  useEffect(() => {
    if ('fisaTehnicaAsignata' in entity) {
      if ('fisaFinalaVizibilaModerator' in entity) {
        if (
          entity.fisaFinalaVizibilaModerator.statusFisaEvaluare ===
          PROJECT_EVALUATION_STATUS.SUBMITTED
        ) {
          setButtonText('projectEvaluationSheet.view.button.label');
        } else if (
          entity.fisaTehnicaAsignata.statusFisaEvaluare !== PROJECT_EVALUATION_STATUS.SUBMITTED
        ) {
          setButtonText('projectEvaluationSheet.button.label');
        } else {
          setButtonText('projectEvaluationSheet.final.button.label');
        }
      } else if (
        entity.fisaTehnicaAsignata.statusFisaEvaluare === PROJECT_EVALUATION_STATUS.SUBMITTED
      ) {
        setButtonText('projectEvaluationSheet.view.button.label');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  return (
    <Card key={entity.proiectGuid.toString()}>
      <CardHeader
        title={
          <div>
            <a href={`proiecte/${entity.proiectGuid.toString()}`}>{entity.titluProiect}</a>
          </div>
        }
        action={
          <Button variant="outlined" onClick={() => navigate(`${entity.proiectGuid}/fisa`)}>
            {intl.formatMessage({ id: buttonText })}
          </Button>
        }
      />
      <CardContent>
        <p>{intl.formatMessage({ id: 'global.obiectiv' })}</p>
        <p>{entity.numeObiectiv}</p>
      </CardContent>
    </Card>
  );
}
