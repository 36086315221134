const projectsObjectivesForm = {
  formId: 'projectObjectivesForm',
  projectsObjectivesFormFields: {
    objectives: {
      name: 'obiectiveSiProiecte',
      label: 'form.projectsObjectives.objective.label'
    },
    total: {
      name: 'total'
    },
    objectiveFields: {
      guid: {
        name: 'obiectivGuid'
      },
      name: {
        name: 'titluObiectiv',
        placeholder: 'form.projectsObjectives.objective.name.placeholder',
        requiredErrorMsg: 'form.error.required'
      },
      additionalInfo: {
        name: 'informatiiAditionale'
      },
      projects: {
        name: 'proiecte',
        label: 'form.projectsObjectives.project.label'
      }
    },
    projectFields: {
      guid: {
        name: 'proiectGuid'
      },
      additionalInfo: {
        name: 'informatiiAditionale'
      },
      name: {
        name: 'titluProiect',
        requiredErrorMsg: 'form.error.required'
      },
      type: {
        name: 'tipActivitate'
      },
      length: {
        name: 'durata'
      },
      total: {
        name: 'valoareTotala'
      },
      results: {
        name: 'rezultateEstimate'
      },
      responsible: {
        name: 'responsabil',
        requiredErrorMsg: 'form.error.required'
      }
    }
  }
};

export default projectsObjectivesForm;
