import * as Yup from 'yup';
import myAccountFormModel from './myAccountFormModel';

const {
  myAccountFormField: { lastName, firstName, email, phone }
} = myAccountFormModel;

export const myAccountFormValidationSchema = () =>
  Yup.object().shape({
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [email.name]: Yup.string().email(email.emailErrorMsg).required(`${email.requiredErrorMsg}`),
    [phone.name]: Yup.string().required(`${phone.requiredErrorMsg}`)
  });
