import { programConstants } from '../constants';
import { competitionService, programService, userService } from '../services';
import { triggerError, triggerSuccess } from '../utils/toasterTriggers';

export const programActions = {
  updateProgram,
  getProgram,
  getProgramWithEligibility,
  getMyPrograms,
  getProgramsApplicant,
  getProgramEligibilityResponsibles,
  getProgramProjectsEvaluationResponsibles,
  getProgramEvaluationResponsibles,
  updateProgramEligibilityResponsibles,
  updateProgramProjectsEvaluationResponsibles,
  updateProgramEvaluationResponsibles,
  createProgram,
  updateProgramInformation,
  updateProgramTotalValue,
  updateProgramExpectedResults,
  updateProgramIncomeStatus,
  updateProgramProjectsObjectives,
  uploadProgramAdminCv,
  downloadProgramAdminCv,
  getSupportingDocuments,
  uploadSupportingDocuments,
  downloadSupportingDocument,
  deleteSupportingDocument,
  getProject,
  updateProject,
  submitForEligibility,
  contestEligibility,
  getAdminProgramEvaluation,
  setProgramEvaluationStatus,
  getProjectsAvailableForMonitoring
};

function getProgram(id) {
  return (dispatch) => {
    dispatch(request());
    programService.getProgram(id).then(
      (program) => {
        dispatch(success(program));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: programConstants.GET_PROGRAM_REQUEST };
  }
  function success(program) {
    return { type: programConstants.GET_PROGRAM_SUCCESS, program };
  }
  function failure(error) {
    return { type: programConstants.GET_PROGRAM_FAILURE, error };
  }
}

function getAdminProgramEvaluation(id) {
  return (dispatch) => {
    dispatch(request());
    programService.getAdminProgramEvaluation(id).then(
      (program) => {
        const p = [];
        if (program.fiseEvaluatori) {
          program.fiseEvaluatori.forEach((fisa) => {
            p.push(
              userService.getUserInfo(fisa.userId).then((user) => {
                fisa.username = `${user.firstName} ${user.lastName}`;
              })
            );
          });
        } else {
          dispatch(success(program));
        }
        Promise.all(p).then(() => {
          dispatch(success(program));
        });
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: programConstants.GET_ADMIN_PROGRAM_EVALUATION_REQUEST };
  }
  function success(program) {
    return { type: programConstants.GET_ADMIN_PROGRAM_EVALUATION_SUCCESS, program };
  }
  function failure(error) {
    return { type: programConstants.GET_ADMIN_PROGRAM_EVALUATION_FAILURE, error };
  }
}

function getProjectsAvailableForMonitoring(idProgram) {
  return (dispatch) => {
    dispatch(request());
    programService.getProjectsAvailableForMonitoring(idProgram).then(
      (projects) => {
        dispatch(success(projects));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: programConstants.GET_MONITORING_PROJECTS_REQUEST };
  }
  function success(projects) {
    return { type: programConstants.GET_MONITORING_PROJECTS_SUCCESS, projects };
  }
  function failure(error) {
    return { type: programConstants.GET_MONITORING_PROJECTS_FAILURE, error };
  }
}

function getProgramWithEligibility(id) {
  return (dispatch) => {
    dispatch(request());
    programService.getProgram(id).then(
      (program) => {
        programService.checkProgramEligibility(program.programGuid).then((eligibility) => {
          program.eligibility = eligibility;
          dispatch(success(program));
        });
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: programConstants.GET_PROGRAM_REQUEST };
  }
  function success(program) {
    return { type: programConstants.GET_PROGRAM_SUCCESS, program };
  }
  function failure(error) {
    return { type: programConstants.GET_PROGRAM_FAILURE, error };
  }
}

function getMyPrograms(institutionId) {
  return (dispatch) => {
    dispatch(request());
    programService.getMyPrograms(institutionId).then(
      (programs) => {
        const responsePrograms = [...programs];
        const promises = [];
        responsePrograms.forEach((program) => {
          promises.push(
            competitionService.getCompetition(program.competitieGuid).then(
              (competition) => {
                program.competition = competition;
              },
              (error) => {
                dispatch(failure(error.toString()));
                triggerError(error.errorMessage, 'toast.error.get');
              }
            )
          );

          promises.push(
            programService.checkProgramEligibility(program.programGuid).then((eligibility) => {
              program.eligibility = eligibility;
            })
          );
        });
        Promise.all(promises).then(() => {
          dispatch(success(responsePrograms));
        });
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: programConstants.GET_PROGRAMS_REQUEST };
  }
  function success(programs) {
    return { type: programConstants.GET_PROGRAMS_SUCCESS, programs };
  }
  function failure(error) {
    return { type: programConstants.GET_PROGRAMS_FAILURE, error };
  }
}

function getProgramsApplicant() {
  return (dispatch) => {
    dispatch(request());
    programService.getProgramsApplicant().then(
      (programs) => {
        dispatch(success(programs.programe));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: programConstants.GET_PROGRAMS_APPLICANT_REQUEST };
  }
  function success(programs) {
    return { type: programConstants.GET_PROGRAMS_APPLICANT_SUCCESS, programs };
  }
  function failure(error) {
    return { type: programConstants.GET_PROGRAMS_APPLICANT_FAILURE, error };
  }
}

function createProgram(competitionGuid, callbackFn = undefined) {
  return (dispatch) => {
    dispatch(request());
    programService.createProgram(competitionGuid).then(
      (program) => {
        dispatch(success(program));
        if (callbackFn) callbackFn(program);
        dispatch(getProgram(program.programGuid));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get'); // TODO change message
      }
    );
  };

  function request() {
    return { type: programConstants.CREATE_PROGRAM_REQUEST };
  }
  function success(program) {
    return { type: programConstants.CREATE_PROGRAM_SUCCESS, program };
  }
  function failure(error) {
    return { type: programConstants.CREATE_PROGRAM_FAILURE, error };
  }
}

function getProgramEligibilityResponsibles(id) {
  return (dispatch) => {
    dispatch(request());
    programService.getProgramEligibilityResponsibles(id).then(
      (eligibilityResponsibles) => {
        dispatch(success(eligibilityResponsibles));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: programConstants.GET_PROGRAM_ELIGIBILITY_RESPONSIBLES_REQUEST };
  }
  function success(eligibilityResponsibles) {
    return {
      type: programConstants.GET_PROGRAM_ELIGIBILITY_RESPONSIBLES_SUCCESS,
      eligibilityResponsibles
    };
  }
  function failure(error) {
    return { type: programConstants.GET_PROGRAM_ELIGIBILITY_RESPONSIBLES_FAILURE, error };
  }
}

function getProgramProjectsEvaluationResponsibles(id) {
  return (dispatch) => {
    dispatch(request());
    programService.getProgramProjectsEvaluationResponsibles(id).then(
      (projectsEvaluationResponsibles) => {
        dispatch(success(projectsEvaluationResponsibles));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: programConstants.GET_PROGRAM_PROJECTS_EVALUATION_RESPONSIBLES_REQUEST };
  }
  function success(projectsEvaluationResponsibles) {
    return {
      type: programConstants.GET_PROGRAM_PROJECTS_EVALUATION_RESPONSIBLES_SUCCESS,
      projectsEvaluationResponsibles
    };
  }
  function failure(error) {
    return { type: programConstants.GET_PROGRAM_PROJECTS_EVALUATION_RESPONSIBLES_FAILURE, error };
  }
}

function getProgramEvaluationResponsibles(id, type) {
  return (dispatch) => {
    dispatch(request());
    programService.getProgramEvaluationResponsibles(id, type).then(
      (programEvaluationResponsibles) => {
        dispatch(success(programEvaluationResponsibles));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: programConstants.GET_PROGRAM_EVALUATION_RESPONSIBLES_REQUEST };
  }
  function success(programEvaluationResponsibles) {
    return {
      type: programConstants.GET_PROGRAM_EVALUATION_RESPONSIBLES_SUCCESS,
      programEvaluationResponsibles
    };
  }
  function failure(error) {
    return { type: programConstants.GET_PROGRAM_EVALUATION_RESPONSIBLES_FAILURE, error };
  }
}

function updateProgramEligibilityResponsibles(data, programId, type) {
  return (dispatch) => {
    dispatch(request());
    programService.updateProgramEligibilityResponsibles(data, programId, type).then(
      () => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: programConstants.UPDATE_PROGRAM_ELIGIBILITY_RESPONSIBLES_REQUEST };
  }
  function success() {
    return {
      type: programConstants.UPDATE_PROGRAM_ELIGIBILITY_RESPONSIBLES_SUCCESS
    };
  }
  function failure(error) {
    return { type: programConstants.UPDATE_PROGRAM_ELIGIBILITY_RESPONSIBLES_FAILURE, error };
  }
}

function updateProgramProjectsEvaluationResponsibles(data) {
  return (dispatch) => {
    dispatch(request());
    programService.updateProgramProjectsEvaluationResponsibles(data).then(
      () => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.update');
      }
    );
  };

  function request() {
    return { type: programConstants.UPDATE_PROGRAM_PROJECT_EVALUATION_RESPONSIBLES_REQUEST };
  }
  function success() {
    return {
      type: programConstants.UPDATE_PROGRAM_PROJECT_EVALUATION_RESPONSIBLES_SUCCESS
    };
  }
  function failure(error) {
    return { type: programConstants.UPDATE_PROGRAM_PROJECT_EVALUATION_RESPONSIBLES_FAILURE, error };
  }
}

function updateProgramEvaluationResponsibles(data, type) {
  return (dispatch) => {
    dispatch(request());
    programService.updateProgramEvaluationResponsibles(data, type).then(
      () => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.update');
      }
    );
  };

  function request() {
    return { type: programConstants.UPDATE_PROGRAM_PROJECT_EVALUATION_RESPONSIBLES_REQUEST };
  }
  function success() {
    return {
      type: programConstants.UPDATE_PROGRAM_PROJECT_EVALUATION_RESPONSIBLES_SUCCESS
    };
  }
  function failure(error) {
    return { type: programConstants.UPDATE_PROGRAM_PROJECT_EVALUATION_RESPONSIBLES_FAILURE, error };
  }
}

function updateProgram(program) {
  return (dispatch) => {
    dispatch(request());
    programService.addProgram(program).then(
      (newProgram) => {
        program.id = newProgram.id;
        dispatch(success(program));
        triggerSuccess('', 'toast.success.program.update');
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.program.update');
      }
    );
  };

  function request() {
    return { type: programConstants.PROGRAM_UPDATE_REQUEST };
  }
  function success(program) {
    return { type: programConstants.PROGRAM_UPDATE_SUCCESS, program };
  }
  function failure(error) {
    return { type: programConstants.PROGRAM_UPDATE_FAILURE, error };
  }
}

function submitForEligibility(programId) {
  return (dispatch) => {
    dispatch(request());
    programService.submitForEligibility(programId).then(
      () => {
        dispatch(success());
        triggerSuccess('', 'toast.success.program.update');
        window.location.reload(true);
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.program.update');
      }
    );
  };

  function request() {
    return { type: programConstants.PROGRAM_SUBMIT_FOR_ELIGIBILITY_REQUEST };
  }
  function success() {
    return { type: programConstants.PROGRAM_SUBMIT_FOR_ELIGIBILITY_SUCCESS };
  }
  function failure(error) {
    return { type: programConstants.PROGRAM_SUBMIT_FOR_ELIGIBILITY_FAILURE, error };
  }
}

function contestEligibility(formData, idProgram) {
  return (dispatch) => {
    dispatch(request());

    programService.contestEligibility(formData, idProgram).then(
      () => {
        dispatch(success());
        triggerSuccess('', 'toast.success.save');
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.save');
      }
    );
  };

  function request() {
    return { type: programConstants.CONTEST_ELIGIBILITY_REQUEST };
  }
  function success() {
    return { type: programConstants.CONTEST_ELIGIBILITY_SUCCESS };
  }
  function failure(error) {
    return { type: programConstants.CONTEST_ELIGIBILITY_FAILURE, error };
  }
}

function updateProgramInformation(id, program, cvFile) {
  return (dispatch) => {
    dispatch(request());
    if (cvFile instanceof FormData) {
      programService.uploadProgramAdminCv(id, cvFile).then(
        () => {
          programService.updateProgramInformation(id, program).then(
            (newProgram) => {
              program.id = newProgram.programGuid;
              dispatch(success(program));
              triggerSuccess('', 'toast.success.program.update');
              dispatch(getProgram(id));
            },
            (error) => {
              dispatch(failure(error.toString()));
              triggerError(error.errorMessage, 'toast.error.program.update');
            }
          );
        },
        (error) => {
          dispatch(failure(error.toString()));
          triggerError(error.errorMessage, 'toast.error.program.update');
        }
      );
    } else {
      programService.updateProgramInformation(id, program).then(
        (newProgram) => {
          program.id = newProgram.programGuid;
          dispatch(success(program));
          triggerSuccess('', 'toast.success.program.update');
          dispatch(getProgram(id));
        },
        (error) => {
          dispatch(failure(error.toString()));
          triggerError(error.errorMessage, 'toast.error.program.update');
        }
      );
    }
  };

  function request() {
    return { type: programConstants.UPDATE_PROGRAM_INFORMATION_REQUEST };
  }
  function success(program) {
    return { type: programConstants.UPDATE_PROGRAM_INFORMATION_SUCCESS, program };
  }
  function failure(error) {
    return { type: programConstants.UPDATE_PROGRAM_INFORMATION_FAILURE, error };
  }
}

function updateProgramTotalValue(id, program) {
  return (dispatch) => {
    dispatch(request());
    programService.updateProgramTotalValue(id, program).then(
      (newProgram) => {
        program.id = newProgram.id;
        dispatch(success(program));
        triggerSuccess('', 'toast.success.program.update');
        dispatch(getProgram(id));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.program.update');
      }
    );
  };

  function request() {
    return { type: programConstants.UPDATE_PROGRAM_TOTAL_VALUE_REQUEST };
  }
  function success(program) {
    return { type: programConstants.UPDATE_PROGRAM_TOTAL_VALUE_SUCCESS, program };
  }
  function failure(error) {
    return { type: programConstants.UPDATE_PROGRAM_TOTAL_VALUE_FAILURE, error };
  }
}

function updateProgramIncomeStatus(id, program) {
  return (dispatch) => {
    dispatch(request());
    programService.updateProgramIncomeStatus(id, program).then(
      (newProgram) => {
        program.id = newProgram.id;
        dispatch(success(program));
        triggerSuccess('', 'toast.success.program.update');
        dispatch(getProgram(id));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.program.update');
      }
    );
  };

  function request() {
    return { type: programConstants.UPDATE_PROGRAM_INCOME_STATUS_REQUEST };
  }
  function success(program) {
    return { type: programConstants.UPDATE_PROGRAM_INCOME_STATUS_SUCCESS, program };
  }
  function failure(error) {
    return { type: programConstants.UPDATE_PROGRAM_INCOME_STATUS_FAILURE, error };
  }
}

function updateProgramExpectedResults(id, program) {
  return (dispatch) => {
    dispatch(request());
    programService.updateProgramExpectedResults(id, program).then(
      (newProgram) => {
        program.id = newProgram.id;
        dispatch(success(program));
        triggerSuccess('', 'toast.success.program.update');
        dispatch(getProgram(id));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.program.update');
      }
    );
  };

  function request() {
    return { type: programConstants.UPDATE_PROGRAM_EXPECTED_RESULTS_REQUEST };
  }
  function success(program) {
    return { type: programConstants.UPDATE_PROGRAM_EXPECTED_RESULTS_SUCCESS, program };
  }
  function failure(error) {
    return { type: programConstants.UPDATE_PROGRAM_EXPECTED_RESULTS_FAILURE, error };
  }
}

function updateProgramProjectsObjectives(id, program) {
  return (dispatch) => {
    dispatch(request());
    programService.updateProgramProjectsObjectives(id, program).then(
      (newProgram) => {
        dispatch(
          success({
            ...program,
            id: newProgram.id
          })
        );
        triggerSuccess('', 'toast.success.program.update');
        dispatch(getProgram(id));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.program.update');
      }
    );
  };

  function request() {
    return { type: programConstants.UPDATE_PROGRAM_PROJECTS_OBJECTIVES_REQUEST };
  }
  function success(program) {
    return { type: programConstants.UPDATE_PROGRAM_PROJECTS_OBJECTIVES_SUCCESS, program };
  }
  function failure(error) {
    return { type: programConstants.UPDATE_PROGRAM_PROJECTS_OBJECTIVES_FAILURE, error };
  }
}

function uploadProgramAdminCv(id, formData) {
  return (dispatch) => {
    dispatch(request());
    programService.uploadProgramAdminCv(id, formData).then(
      () => {
        dispatch(success());
        triggerSuccess('', 'toast.success.program.update');
        dispatch(getProgram(id));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.program.update');
      }
    );
  };

  function request() {
    return { type: programConstants.UPLOAD_PROGRAM_ADMIN_CV_REQUEST };
  }
  function success() {
    return { type: programConstants.UPLOAD_PROGRAM_ADMIN_CV_SUCCESS };
  }
  function failure(error) {
    return { type: programConstants.UPLOAD_PROGRAM_ADMIN_CV_FAILURE, error };
  }
}

function downloadProgramAdminCv(id) {
  return (dispatch) => {
    dispatch(request());
    programService.downloadProgramAdminCv(id).then(
      () => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.program.update');
      }
    );
  };

  function request() {
    return { type: programConstants.DOWNLOAD_PROGRAM_ADMIN_CV_REQUEST };
  }
  function success() {
    return { type: programConstants.DOWNLOAD_PROGRAM_ADMIN_CV_SUCCESS };
  }
  function failure(error) {
    return { type: programConstants.DOWNLOAD_PROGRAM_ADMIN_CV_FAILURE, error };
  }
}

function getSupportingDocuments(id) {
  return (dispatch) => {
    dispatch(request());
    programService.getSupportingDocuments(id).then(
      () => {
        dispatch(success());
        triggerSuccess('', 'toast.success.program.update');
        dispatch(getProgram(id));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.program.update');
      }
    );
  };

  function request() {
    return { type: programConstants.GET_PROGRAM_DOCUMENTS_REQUEST };
  }
  function success() {
    return { type: programConstants.GET_PROGRAM_DOCUMENTS_SUCCESS };
  }
  function failure(error) {
    return { type: programConstants.GET_PROGRAM_DOCUMENTS_FAILURE, error };
  }
}

function uploadSupportingDocuments(id, formData, callback) {
  return (dispatch) => {
    dispatch(request());
    programService.uploadSupportingDocuments(id, formData).then(
      () => {
        dispatch(success());
        triggerSuccess('', 'toast.success.program.update');
        if (callback) {
          callback();
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.program.update');
      }
    );
  };

  function request() {
    return { type: programConstants.UPLOAD_PROGRAM_DOCUMENTS_REQUEST };
  }
  function success() {
    return { type: programConstants.UPLOAD_PROGRAM_DOCUMENTS_SUCCESS };
  }
  function failure(error) {
    return { type: programConstants.UPLOAD_PROGRAM_DOCUMENTS_FAILURE, error };
  }
}

function downloadSupportingDocument(id, fileUrl) {
  return (dispatch) => {
    dispatch(request());
    programService.downloadSupportingDocument(id, fileUrl).then(
      () => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.program.update');
      }
    );
  };

  function request() {
    return { type: programConstants.DOWNLOAD_PROGRAM_DOCUMENT_REQUEST };
  }
  function success() {
    return { type: programConstants.DOWNLOAD_PROGRAM_DOCUMENT_SUCCESS };
  }
  function failure(error) {
    return { type: programConstants.DOWNLOAD_PROGRAM_DOCUMENT_FAILURE, error };
  }
}

function deleteSupportingDocument(id, fileUrl) {
  return (dispatch) => {
    dispatch(request());
    programService.deleteSupportingDocument(id, fileUrl).then(
      () => {
        dispatch(success());
        triggerSuccess('', 'toast.success.program.update');
        dispatch(getProgram(id));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.program.update');
      }
    );
  };

  function request() {
    return { type: programConstants.DELETE_PROGRAM_DOCUMENT_REQUEST };
  }
  function success() {
    return { type: programConstants.DELETE_PROGRAM_DOCUMENT_SUCCESS };
  }
  function failure(error) {
    return { type: programConstants.DELETE_PROGRAM_DOCUMENT_FAILURE, error };
  }
}

function getProject(id) {
  return (dispatch) => {
    dispatch(request());
    programService.getProject(id).then(
      (project) => {
        dispatch(success(project));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: programConstants.GET_PROJECT_REQUEST };
  }
  function success(project) {
    return { type: programConstants.GET_PROJECT_SUCCESS, project };
  }
  function failure(error) {
    return { type: programConstants.GET_PROJECT_FAILURE, error };
  }
}

function updateProject(id, data) {
  return (dispatch) => {
    dispatch(request());
    programService.updateProject(id, data).then(
      (project) => {
        triggerSuccess('', 'toast.success.project.save');
        dispatch(success(project));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: programConstants.UPDATE_PROJECT_REQUEST };
  }
  function success(project) {
    return { type: programConstants.UPDATE_PROJECT_SUCCESS, project };
  }
  function failure(error) {
    return { type: programConstants.UPDATE_PROJECT_FAILURE, error };
  }
}

function setProgramEvaluationStatus(id, status) {
  return (dispatch) => {
    dispatch(request());
    programService.setProgramEvaluationStatus(id, status).then(
      () => {
        dispatch(success());
        window.location.reload();
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: programConstants.SET_PROGRAM_STATUS_REQUEST };
  }
  function success(project) {
    return { type: programConstants.SET_PROGRAM_STATUS_SUCCESS, project };
  }
  function failure(error) {
    return { type: programConstants.SET_PROGRAM_STATUS_FAILURE, error };
  }
}
