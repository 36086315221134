import { pascalizeKeys } from 'humps';
import { reportConstants } from '../constants';
import { triggerError, triggerSuccess } from '../utils/toasterTriggers';
import { reportService } from '../services';

export const reportActions = {
  getProgramAvailableProjectsForReporting,
  getAdminProgramAvailableProjectsForReporting,
  getProgramAssignedReportEvaluators,
  saveAssignedEvaluators,
  getReportingProjectCycles,
  getReportingProjectCyclePhases,
  getReportingProjectCycleResults,
  getPhasePublications,
  getPhasePatents,
  getPhaseDocuments,
  getEvaluatorPrograms,
  getEvaluatorProjects,
  getEvaluatorProjectFinalCycles,
  getEvaluatorFinalPhaseData,
  savePhasePublication,
  savePhasePatent,
  savePhaseDocuments,
  saveEvaluatorReport,
  setEditPublication,
  setEditPatent
};

function getEvaluatorFinalPhaseData(projectId, cycleId) {
  return (dispatch) => {
    dispatch(request());
    reportService.getEvaluatorFinalPhaseData(projectId, cycleId).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: reportConstants.GET_PHASE_DATA_REQUEST };
  }
  function success(data) {
    return { type: reportConstants.GET_PHASE_DATA_SUCCESS, data };
  }
  function failure(error) {
    return { type: reportConstants.GET_PHASE_DATA_FAILURE, error };
  }
}

function getEvaluatorProjectFinalCycles(projectId) {
  return (dispatch) => {
    dispatch(request());
    reportService.getEvaluatorProjectFinalCycles(projectId).then(
      (cycles) => {
        dispatch(success(cycles));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: reportConstants.GET_FINAL_CYCLES_REQUEST };
  }
  function success(cycles) {
    return { type: reportConstants.GET_FINAL_CYCLES_SUCCESS, cycles };
  }
  function failure(error) {
    return { type: reportConstants.GET_FINAL_CYCLES_FAILURE, error };
  }
}

function getEvaluatorProjects(programId) {
  return (dispatch) => {
    dispatch(request());
    reportService.getEvaluatorProjects(programId).then(
      (projects) => {
        dispatch(success(projects));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: reportConstants.GET_AVAILABLE_PROJECTS_REQUEST };
  }
  function success(projects) {
    return { type: reportConstants.GET_AVAILABLE_PROJECTS_SUCCESS, projects };
  }
  function failure(error) {
    return { type: reportConstants.GET_AVAILABLE_PROJECTS_FAILURE, error };
  }
}

function getEvaluatorPrograms() {
  return (dispatch) => {
    dispatch(request());
    reportService.getEvaluatorPrograms().then(
      (programs) => {
        dispatch(success(programs));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: reportConstants.GET_EVALUATOR_PROGRAMS_REQUEST };
  }
  function success(programs) {
    return { type: reportConstants.GET_EVALUATOR_PROGRAMS_SUCCESS, programs };
  }
  function failure(error) {
    return { type: reportConstants.GET_EVALUATOR_PROGRAMS_FAILURE, error };
  }
}

function getPhaseDocuments(programId, phaseId) {
  return (dispatch) => {
    dispatch(request());
    reportService.getPhaseDocuments(programId, phaseId).then(
      (documents) => {
        dispatch(success(documents));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: reportConstants.GET_PHASE_DOCUMENTS_REQUEST };
  }
  function success(documents) {
    return { type: reportConstants.GET_PHASE_DOCUMENTS_SUCCESS, documents };
  }
  function failure(error) {
    return { type: reportConstants.GET_PHASE_DOCUMENTS_FAILURE, error };
  }
}

function getPhasePatents(programId, phaseId) {
  return (dispatch) => {
    dispatch(request());
    reportService.getPhasePatents(programId, phaseId).then(
      (patents) => {
        dispatch(success(patents));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: reportConstants.GET_PHASE_PATENTS_REQUEST };
  }
  function success(patents) {
    return { type: reportConstants.GET_PHASE_PATENTS_SUCCESS, patents };
  }
  function failure(error) {
    return { type: reportConstants.GET_PHASE_PATENTS_FAILURE, error };
  }
}

function getPhasePublications(programId, phaseId) {
  return (dispatch) => {
    dispatch(request());
    reportService.getPhasePublications(programId, phaseId).then(
      (publications) => {
        dispatch(success(publications));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: reportConstants.GET_PHASE_PUBLICATIONS_REQUEST };
  }
  function success(publications) {
    return { type: reportConstants.GET_PHASE_PUBLICATIONS_SUCCESS, publications };
  }
  function failure(error) {
    return { type: reportConstants.GET_PHASE_PUBLICATIONS_FAILURE, error };
  }
}

function getReportingProjectCycleResults(projectId, cycleId) {
  return (dispatch) => {
    dispatch(request());
    reportService.getReportingProjectCycleResults(projectId, cycleId).then(
      (results) => {
        dispatch(success(results));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: reportConstants.GET_CYCLE_RESULTS_REQUEST };
  }
  function success(results) {
    return { type: reportConstants.GET_CYCLE_RESULTS_SUCCESS, results };
  }
  function failure(error) {
    return { type: reportConstants.GET_CYCLE_RESULTS_FAILURE, error };
  }
}

function getReportingProjectCyclePhases(projectId, cycleId) {
  return (dispatch) => {
    dispatch(request());
    reportService.getReportingProjectCyclePhases(projectId, cycleId).then(
      (phases) => {
        dispatch(success(phases));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: reportConstants.GET_CYCLE_PHASES_REQUEST };
  }
  function success(phases) {
    return { type: reportConstants.GET_CYCLE_PHASES_SUCCESS, phases };
  }
  function failure(error) {
    return { type: reportConstants.GET_CYCLE_PHASES_FAILURE, error };
  }
}

function getReportingProjectCycles(institutionId, projectId) {
  return (dispatch) => {
    dispatch(request());
    reportService.getReportingProjectCycles(institutionId, projectId).then(
      (cycles) => {
        dispatch(success(cycles));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: reportConstants.GET_PROJECT_CYCLES_REQUEST };
  }
  function success(cycles) {
    return { type: reportConstants.GET_PROJECT_CYCLES_SUCCESS, cycles };
  }
  function failure(error) {
    return { type: reportConstants.GET_PROJECT_CYCLES_FAILURE, error };
  }
}

function getProgramAvailableProjectsForReporting(institutionId, programId) {
  return (dispatch) => {
    dispatch(request());
    reportService.getProgramAvailableProjectsForReporting(institutionId, programId).then(
      (projects) => {
        dispatch(success(projects));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: reportConstants.GET_AVAILABLE_PROJECTS_REQUEST };
  }
  function success(projects) {
    return { type: reportConstants.GET_AVAILABLE_PROJECTS_SUCCESS, projects };
  }
  function failure(error) {
    return { type: reportConstants.GET_AVAILABLE_PROJECTS_FAILURE, error };
  }
}

function getAdminProgramAvailableProjectsForReporting(programId) {
  return (dispatch) => {
    dispatch(request());
    reportService.getAdminProgramAvailableProjectsForReporting(programId).then(
      (projects) => {
        dispatch(success(projects));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: reportConstants.GET_AVAILABLE_PROJECTS_REQUEST };
  }
  function success(projects) {
    return { type: reportConstants.GET_AVAILABLE_PROJECTS_SUCCESS, projects };
  }
  function failure(error) {
    return { type: reportConstants.GET_AVAILABLE_PROJECTS_FAILURE, error };
  }
}

function getProgramAssignedReportEvaluators(programId) {
  return (dispatch) => {
    dispatch(request());
    reportService.getProgramAssignedReportEvaluators(programId).then(
      (evaluators) => {
        dispatch(success(evaluators));
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: reportConstants.GET_ASSIGNED_REPORT_EVALUATORS_REQUEST };
  }
  function success(evaluators) {
    return { type: reportConstants.GET_ASSIGNED_REPORT_EVALUATORS_SUCCESS, evaluators };
  }
  function failure(error) {
    return { type: reportConstants.GET_ASSIGNED_REPORT_EVALUATORS_FAILURE, error };
  }
}

function saveAssignedEvaluators(data) {
  return (dispatch) => {
    dispatch(request());
    reportService.saveAssignedEvaluators(data).then(
      () => {
        dispatch(success());
        triggerSuccess('', 'toast.success.save');
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: reportConstants.SAVE_ASSIGNED_EVALUATORS_REQUEST };
  }
  function success() {
    return { type: reportConstants.SAVE_ASSIGNED_EVALUATORS_SUCCESS };
  }
  function failure(error) {
    return { type: reportConstants.SAVE_ASSIGNED_EVALUATORS_FAILURE, error };
  }
}

function savePhasePublication(programId, data, callback) {
  return (dispatch) => {
    dispatch(request());
    reportService.savePhasePublication(programId, data).then(
      () => {
        dispatch(success());
        triggerSuccess('', 'toast.success.save');
        if (callback) {
          callback();
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: reportConstants.SAVE_PHASE_PUBLICATION_REQUEST };
  }
  function success() {
    return { type: reportConstants.SAVE_PHASE_PUBLICATION_SUCCESS };
  }
  function failure(error) {
    return { type: reportConstants.SAVE_PHASE_PUBLICATION_FAILURE, error };
  }
}

function savePhasePatent(programId, data, callback) {
  return (dispatch) => {
    dispatch(request());
    reportService.savePhasePatent(programId, data).then(
      () => {
        dispatch(success());
        triggerSuccess('', 'toast.success.save');
        if (callback) {
          callback();
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: reportConstants.UPDATE_PHASE_PUBLICATION_REQUEST };
  }
  function success() {
    return { type: reportConstants.UPDATE_PHASE_PUBLICATION_SUCCESS };
  }
  function failure(error) {
    return { type: reportConstants.UPDATE_PHASE_PUBLICATION_FAILURE, error };
  }
}

function savePhaseDocuments(programId, data, callback) {
  return (dispatch) => {
    dispatch(request());
    reportService.savePhaseDocuments(programId, data).then(
      () => {
        dispatch(success());
        triggerSuccess('', 'toast.success.save');
        if (callback) {
          callback();
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: reportConstants.SAVE_PHASE_DOCUMENTS_REQUEST };
  }
  function success() {
    return { type: reportConstants.SAVE_PHASE_DOCUMENTS_SUCCESS };
  }
  function failure(error) {
    return { type: reportConstants.SAVE_PHASE_DOCUMENTS_FAILURE, error };
  }
}

function saveEvaluatorReport(formData, projectId, cycleId, callback) {
  return (dispatch) => {
    dispatch(request());
    reportService.saveEvaluatorReport(formData, projectId, cycleId).then(
      () => {
        dispatch(success());
        triggerSuccess('', 'toast.success.save');
        if (callback) {
          callback();
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        triggerError(error.errorMessage, 'toast.error.get');
      }
    );
  };

  function request() {
    return { type: reportConstants.SAVE_EVALUATOR_REPORT_REQUEST };
  }
  function success() {
    return { type: reportConstants.SAVE_EVALUATOR_REPORT_SUCCESS };
  }
  function failure(error) {
    return { type: reportConstants.SAVE_EVALUATOR_REPORT_FAILURE, error };
  }
}

function setEditPublication(publication) {
  return (dispatch) => {
    dispatch(success(publication ? { Publicatie: pascalizeKeys(publication) } : null));
  };

  function success(publication) {
    return { type: reportConstants.SET_EDIT_PUBLICATION, publication };
  }
}

function setEditPatent(patent) {
  return (dispatch) => {
    dispatch(success(patent ? { Brevet: pascalizeKeys(patent) } : null));
  };

  function success(patent) {
    return { type: reportConstants.SET_EDIT_PATENT, patent };
  }
}
