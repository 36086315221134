const userForm = {
  formId: 'userForm',
  userFormField: {
    lastName: {
      name: 'lastName',
      label: 'global.name',
      requiredErrorMsg: 'form.my_account.lastname.error.required'
    },
    firstName: {
      name: 'firstName',
      label: 'form.my_account.firstname',
      requiredErrorMsg: 'form.my_account.firstname.error.required'
    },
    email: {
      name: 'email',
      label: 'form.my_account.email',
      requiredErrorMsg: 'form.my_account.email.error.required',
      emailErrorMsg: 'form.my_account.email.error.email',
      duplicateEmailErrorMsg: 'form.my_account.email.error.duplicate'
    },
    phone: {
      name: 'phone',
      label: 'form.my_account.phone',
      requiredErrorMsg: 'form.my_account.phone.error.required',
      phoneErrorMsg: 'form.phone.error'
    },
    roles: {
      name: 'userType',
      label: 'form.my_account.roles',
      requiredErrorMsg: 'form.my_account.roles.error.required'
    },
    institution: {
      name: 'InstitutieInAdministrareGuid',
      label: 'form.my_account.institution',
      requiredErrorMsg: 'form.my_account.institution.error.required'
    }
  }
};

export default userForm;
