import { useIntl } from 'react-intl';
import { Box, Grid, Input, TextField } from '@mui/material';
import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import ColorButton from '../../common/ColorButton';
import { documentActions } from '../../../actions';

// ----------------------------------------------------------------------

const FileInputStyle = styled(Input)(() => ({
  display: 'none'
}));

InstitutionProgramEvaluationFinancing.propTypes = {
  program: PropTypes.object
};

export default function InstitutionProgramEvaluationFinancing({ program }) {
  const intl = useIntl();
  const [anexaFile, setAnexaFile] = useState(null);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (anexaFile) {
      const formData = new FormData();
      formData.append('contractFinantare', anexaFile);
      dispatch(documentActions.uploadAnexa2(formData, program.programGuid));
    }
  };

  const handleDownload = () => {
    dispatch(documentActions.downloadAnexa2(program.programGuid));
  };

  return (
    <Grid container>
      <Grid item xs={4} sm={4} mb={2}>
        <Button variant="outlined" startIcon={<DownloadIcon />} onClick={handleDownload}>
          {intl.formatMessage({ id: 'global.anexa.2' })}
        </Button>
      </Grid>
      <Grid item xs={9}>
        <TextField
          id="program-anexa2-file"
          label={intl.formatMessage({ id: 'global.anexa.2.upload' })}
          value={
            // eslint-disable-next-line no-nested-ternary
            anexaFile
              ? anexaFile.name
              : program && program.contractFinantareFile
              ? program && program.contractFinantareFile.numeFisierOriginal
              : ''
          }
          variant="outlined"
          disabled
          fullWidth
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={2}>
        <FileInputStyle
          type="file"
          name="anexa2"
          id="anexa2"
          onChange={(event) => {
            setAnexaFile(event.target.files[0]);
          }}
        />
        <label htmlFor="anexa2">
          <Box height="100%" display="flex" justifyContent="center" flexDirection="column">
            {program && program.contractFinantareFile && (
              <ColorButton
                variant="outlined"
                borderColor="#005db7"
                labelColor="#005db7"
                component="span"
                onClick={(event) => {
                  event.preventDefault();
                  dispatch(
                    documentActions.downloadContractFinantareSemnat(
                      program.contractFinantareFile.fisierUrl,
                      program.programGuid
                    )
                  );
                }}
              >
                {intl.formatMessage({ id: 'global.download' })}
              </ColorButton>
            )}
            {program && !program.contractFinantareFile && (
              <ColorButton
                variant="outlined"
                borderColor="#005db7"
                labelColor="#005db7"
                component="span"
              >
                {intl.formatMessage({ id: 'global.upload' })}
              </ColorButton>
            )}
          </Box>
        </label>
      </Grid>
      <Grid item xs={2} sm={2}>
        <Button variant="contained" sx={{ marginTop: 2 }} onClick={() => handleSubmit()}>
          {intl.formatMessage({ id: 'global.save' })}
        </Button>
      </Grid>
    </Grid>
  );
}
