import * as Yup from 'yup';
import adminEligibilityAssignFormModel from './adminEligibilityAssignFormModel';

const {
  adminEligibilityAssignField: { writer, reviwer }
} = adminEligibilityAssignFormModel;

export const adminEligibilityAssignFormValidationSchema = () =>
  Yup.object().shape({
    [writer.name]: Yup.object().nullable().required(`${writer.requiredErrorMsg}`),
    [reviwer.name]: Yup.object().nullable().required(`${reviwer.requiredErrorMsg}`)
  });
