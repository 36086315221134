import competitionProjectEvaluationFormModel from './competitionProjectEvaluationFormModel';

const {
  competitionProjectEvaluationField: { criteria }
} = competitionProjectEvaluationFormModel;

export const getInitialCompetitionProjectEvaluationFormValues = (competitionProjectEvaluation) => ({
  [criteria.name]: competitionProjectEvaluation.criterii
    ? competitionProjectEvaluation.criterii.map((criteriu) => ({
        [criteria.title.name]: criteriu.denumire,
        [criteria.score.name]: criteriu.scorMaxim
      }))
    : []
});
