import { useIntl } from 'react-intl';
import { Backdrop, CircularProgress, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import Page from '../components/Page';
import CardList from '../components/lists/CardList';
import { reportActions } from '../actions';
import InstitutionProgramReportProjectCyclePhaseCard from '../components/cards/InstitutionProgramReportProjectCyclePhaseCard';
// ----------------------------------------------------------------------

export default function InstitutionProgramReportingProjectCyclePhases() {
  const { idProiect, idCiclu } = useParams();
  const intl = useIntl();

  const [sort, setSort] = useState('ascending');
  const [searchText, setSearchText] = useState('');
  const [cyclePhases, setCyclePhases] = useState([]);
  const reporting = useSelector((state) => state.report);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reportActions.getReportingProjectCyclePhases(idProiect, idCiclu));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      reporting &&
      reporting.phases &&
      reporting.phases.faze &&
      reporting.phases.faze.length > 0
    ) {
      const phases = reporting.phases.faze.sort((f1, f2) => f1.titlu.localeCompare(f2.titlu));

      setCyclePhases(phases);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reporting]);

  useEffect(() => {
    let filteredPhases = [];
    if (
      reporting &&
      reporting.phases &&
      reporting.phases.faze &&
      reporting.phases.faze.length > 0
    ) {
      if (searchText && searchText.length > 0) {
        filteredPhases = reporting.phases.faze.filter((phase) =>
          phase.titlu.toLowerCase().includes(searchText.toLowerCase())
        );
      } else {
        filteredPhases = reporting.phases.faze;
      }
    }

    filteredPhases = filteredPhases.sort((f1, f2) =>
      sort === 'ascending'
        ? f1.titlu.toLowerCase().localeCompare(f2.titlu.toLowerCase())
        : f2.titlu.toLowerCase().localeCompare(f1.titlu.toLowerCase())
    );

    setCyclePhases([...filteredPhases]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, searchText]);

  if (reporting?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Page title={intl.formatMessage({ id: 'program.cycles.phases.title' })} my={2}>
      <Container>
        {cyclePhases && (
          <CardList
            entityId="fazaGuid"
            sort={sort}
            setSort={setSort}
            searchText={searchText}
            setSearchText={setSearchText}
            sortFieldTitle={intl.formatMessage({ id: 'global.name' })}
            items={cyclePhases}
            Card={InstitutionProgramReportProjectCyclePhaseCard}
          />
        )}
      </Container>
    </Page>
  );
}
