import adminProgramEvaluationAssignFormModel from './adminProgramEvaluationAssignFormModel';

const {
  adminProgramEvaluationAssignField: { responsibles }
} = adminProgramEvaluationAssignFormModel;

export const parseAdminProgramEvaluation = (adminProgramEvaluation, idProgram) => ({
  [responsibles.responsible1.name]: adminProgramEvaluation[responsibles.responsible1.name].id,
  [responsibles.responsible2.name]: adminProgramEvaluation[responsibles.responsible2.name].id,
  [responsibles.moderator.name]: adminProgramEvaluation[responsibles.moderator.name].id,
  ProgramGuid: +idProgram
});
