import { programConstants } from '../constants';

export function programs(
  state = {
    programs: [],
    selectedProgram: null,
    selectedProject: null,
    eligibilityResponsibles: null,
    projectsEvaluationResponsibles: null,
    programEvaluationResponsibles: null,
    monitoringProjects: []
  },
  action
) {
  switch (action.type) {
    case programConstants.PROGRAM_UPDATE_REQUEST:
    case programConstants.UPDATE_PROGRAM_ELIGIBILITY_RESPONSIBLES_REQUEST:
    case programConstants.UPDATE_PROGRAM_PROJECT_EVALUATION_RESPONSIBLES_REQUEST:
    case programConstants.UPDATE_PROGRAM_EVALUATION_RESPONSIBLES_REQUEST:
    case programConstants.GET_PROJECT_REQUEST:
    case programConstants.UPDATE_PROJECT_REQUEST:
    case programConstants.CREATE_PROGRAM_REQUEST:
    case programConstants.PROGRAM_SUBMIT_FOR_ELIGIBILITY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case programConstants.GET_PROGRAMS_REQUEST:
    case programConstants.GET_PROGRAMS_APPLICANT_REQUEST:
      return {
        ...state,
        loading: true,
        programs: []
      };
    case programConstants.GET_PROGRAM_REQUEST:
    case programConstants.GET_ADMIN_PROGRAM_EVALUATION_REQUEST:
    case programConstants.UPDATE_PROGRAM_PROJECTS_OBJECTIVES_REQUEST:
      return {
        ...state,
        loading: true,
        selectedProgram: null
      };
    case programConstants.GET_PROGRAM_ELIGIBILITY_RESPONSIBLES_REQUEST:
      return {
        ...state,
        loading: true,
        eligibilityResponsibles: null
      };
    case programConstants.GET_PROGRAM_PROJECTS_EVALUATION_RESPONSIBLES_REQUEST:
      return {
        ...state,
        loading: true,
        projectsEvaluationResponsibles: null
      };
    case programConstants.GET_PROGRAM_EVALUATION_RESPONSIBLES_REQUEST:
      return {
        ...state,
        loading: true,
        programEvaluationResponsibles: null
      };
    case programConstants.GET_MONITORING_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
        monitoringProjects: []
      };
    case programConstants.CREATE_PROGRAM_SUCCESS:
    case programConstants.UPDATE_PROGRAM_PROJECTS_OBJECTIVES_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedProgram: action.program
      };
    case programConstants.PROGRAM_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        programs: [...state.program, action.program]
      };
    case programConstants.GET_PROGRAM_SUCCESS:
    case programConstants.GET_ADMIN_PROGRAM_EVALUATION_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedProgram: action.program
      };
    case programConstants.GET_PROGRAMS_SUCCESS:
    case programConstants.GET_PROGRAMS_APPLICANT_SUCCESS:
      return {
        ...state,
        programs: action.programs,
        loading: false
      };
    case programConstants.GET_PROGRAM_ELIGIBILITY_RESPONSIBLES_SUCCESS:
      return {
        ...state,
        loading: false,
        eligibilityResponsibles: action.eligibilityResponsibles
      };
    case programConstants.GET_PROGRAM_PROJECTS_EVALUATION_RESPONSIBLES_SUCCESS:
      return {
        ...state,
        loading: false,
        projectsEvaluationResponsibles: action.projectsEvaluationResponsibles
      };
    case programConstants.GET_PROGRAM_EVALUATION_RESPONSIBLES_SUCCESS:
      return {
        ...state,
        loading: false,
        programEvaluationResponsibles: action.programEvaluationResponsibles
      };
    case programConstants.GET_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedProject: action.project
      };
    case programConstants.GET_MONITORING_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        monitoringProjects: action.projects
      };
    case programConstants.UPDATE_PROJECT_SUCCESS:
    case programConstants.UPDATE_PROGRAM_ELIGIBILITY_RESPONSIBLES_SUCCESS:
    case programConstants.UPDATE_PROGRAM_PROJECT_EVALUATION_RESPONSIBLES_SUCCESS:
    case programConstants.UPDATE_PROGRAM_EVALUATION_RESPONSIBLES_SUCCESS:
    case programConstants.PROGRAM_SUBMIT_FOR_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case programConstants.PROGRAM_UPDATE_FAILURE:
    case programConstants.GET_PROGRAM_FAILURE:
    case programConstants.GET_ADMIN_PROGRAM_EVALUATION_FAILURE:
    case programConstants.GET_PROGRAM_ELIGIBILITY_RESPONSIBLES_FAILURE:
    case programConstants.GET_PROGRAM_PROJECTS_EVALUATION_RESPONSIBLES_FAILURE:
    case programConstants.GET_PROGRAM_EVALUATION_RESPONSIBLES_FAILURE:
    case programConstants.UPDATE_PROGRAM_ELIGIBILITY_RESPONSIBLES_FAILURE:
    case programConstants.UPDATE_PROGRAM_PROJECT_EVALUATION_RESPONSIBLES_FAILURE:
    case programConstants.UPDATE_PROGRAM_EVALUATION_RESPONSIBLES_FAILURE:
    case programConstants.GET_PROGRAMS_FAILURE:
    case programConstants.PROGRAM_SUBMIT_FOR_ELIGIBILITY_FAILURE:
    case programConstants.GET_PROGRAMS_APPLICANT_FAILURE:
    case programConstants.GET_PROJECT_FAILURE:
    case programConstants.UPDATE_PROJECT_FAILURE:
    case programConstants.CREATE_PROGRAM_FAILURE:
    case programConstants.GET_MONITORING_PROJECTS_FAILURE:
    case programConstants.UPDATE_PROGRAM_PROJECTS_OBJECTIVES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
