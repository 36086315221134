// material
import { Box, Container } from '@mui/material';
//
import sidebarConfig from './SidebarConfig';
import NavSection from '../../components/NavSection';

// ----------------------------------------------------------------------

DashboardMenu.propTypes = {};

export default function DashboardMenu() {
  return (
    <Container maxWidth="false">
      <Box>
        <NavSection navConfig={sidebarConfig} />
      </Box>
    </Container>
  );
}
