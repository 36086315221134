import { parseInformatiiGenerale } from './informatiiGenerale/formOutputParser';
import { parseDeclaratii } from './declaratii/formOutputParser';
import { parseInformatiiStiintifice } from './informatiiStiintifice/formOutputParser';

import schemaRealizareForm from './schemaRealizare/schemaRealizareFormModel';
import costuriTotaleForm from './costuriTotale/costuriTotaleFormModel';
import resurseUmaneForm from './resurseUmane/resurseUmaneFormModel';
import alteInformatiiForm from './alteInformatii/alteInformatiiFormModel';

import { removeValue } from '../../utils/formatInput';

const {
  alteInformatiiFormFields: { alteInformatii }
} = alteInformatiiForm;

export const parseProiect = (proiect) => {
  const proiectObject = {
    ...parseInformatiiGenerale(proiect),
    ...parseDeclaratii(proiect),
    ...parseInformatiiStiintifice(proiect),
    [schemaRealizareForm.name]: [...proiect[schemaRealizareForm.name]],
    [costuriTotaleForm.name]: { ...proiect[costuriTotaleForm.name] },
    [resurseUmaneForm.name]: { ...proiect[resurseUmaneForm.name] },
    [alteInformatii.name]: proiect[alteInformatii.name]
  };

  removeValue(proiectObject, null);
  removeValue(proiectObject, '');

  return proiectObject;
};
