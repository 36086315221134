import * as Yup from 'yup';
import competitionEligibilityFormModel from './competitionEligibilityFormModel';

const {
  competitionEligibilityField: { criteria }
} = competitionEligibilityFormModel;

export const competitionEligibilityFormValidationSchema = () =>
  Yup.object().shape({
    [criteria.name]: Yup.array()
      .min(1, `${criteria.requiredErrorMsg}`)
      .of(Yup.string().nullable().required(`${criteria.requiredErrorMsg}`))
  });
