import { useIntl } from 'react-intl';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Input,
  Tab,
  TextField
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import Page from '../components/Page';
import { documentActions, reportActions } from '../actions';
import ColorButton from '../components/common/ColorButton';
import CardList from '../components/lists/CardList';
import ReporterProgramReportProjectCyclePhasePublicationCard from '../components/cards/ReporterProgramReportProjectCyclePhasePublicationCard';
import ReporterProgramReportProjectCyclePhasePatentCard from '../components/cards/ReporterProgramReportProjectCyclePhasePatentCard';
import { REPORTING_STATUS } from '../utils/enums';

// ----------------------------------------------------------------------

const FileInputStyle = styled(Input)(() => ({
  display: 'none'
}));

export default function ReporterProgramProjectCycleReport() {
  const { idProiect, idCiclu } = useParams();
  const intl = useIntl();
  const [value, setValue] = useState('1');
  const [showReportForm, setShowReportForm] = useState(false);
  const [anexa16File, setAnexa16File] = useState(null);
  const [reportComments, setReportComments] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const reporting = useSelector((state) => state.report);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reportActions.getEvaluatorFinalPhaseData(idProiect, idCiclu));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reporting.reportInfo) {
      setIsSubmitted(reporting.reportInfo.statusFisaRaportare === REPORTING_STATUS.SUBMITTED);
      setReportComments(reporting.reportInfo.comentarii);
      setAnexa16File({
        name: reporting.reportInfo.anexa16.numeFisierOriginal,
        url: reporting.reportInfo.anexa16.fisierUrl
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reporting.reportInfo]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = () => {
    if (anexa16File) {
      const formData = new FormData();
      formData.append('anexa16', anexa16File);
      formData.append('comentarii', reportComments);
      dispatch(
        reportActions.saveEvaluatorReport(formData, idProiect, idCiclu, () => {
          dispatch(reportActions.getEvaluatorFinalPhaseData(idProiect, idCiclu));
          setReportComments('');
          setAnexa16File(null);
          setShowReportForm(false);
        })
      );
    }
  };

  if (reporting?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Page title={intl.formatMessage({ id: 'programReport.project.cycle.phase.reporting' })} my={2}>
      <Container>
        {showReportForm && (
          <>
            {!isSubmitted && (
              <ColorButton
                variant="outlined"
                borderColor="#269620"
                labelColor="#269620"
                component="span"
                onClick={handleSubmit}
                sx={{ mr: 2 }}
              >
                {intl.formatMessage({ id: 'global.save' })}
              </ColorButton>
            )}
            <ColorButton
              variant="outlined"
              borderColor="#c23127"
              labelColor="#c23127"
              component="span"
              onClick={() => setShowReportForm(false)}
            >
              {isSubmitted
                ? intl.formatMessage({ id: 'form.previous_step' })
                : intl.formatMessage({ id: 'global.cancel' })}
            </ColorButton>
          </>
        )}
        {!showReportForm && (
          <Grid item xs={2} sm={2}>
            <Button
              variant="contained"
              sx={{ marginTop: 2, mb: 2 }}
              onClick={() => setShowReportForm(true)}
            >
              {isSubmitted
                ? intl.formatMessage({ id: 'reporting.view.report' })
                : intl.formatMessage({ id: 'reporting.add.report' })}
            </Button>
          </Grid>
        )}
        {showReportForm && (
          <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <TextField
                id="reporting-comments"
                label={intl.formatMessage({ id: 'global.comments' })}
                multiline
                rows={4}
                value={reportComments}
                onChange={(e) => setReportComments(e.target.value)}
                variant="outlined"
                fullWidth
                disabled={isSubmitted}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                id="anexa16-file"
                label={intl.formatMessage({ id: 'Anexa16' })}
                value={anexa16File ? anexa16File.name : ''}
                variant="outlined"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <FileInputStyle
                type="file"
                name="anexa16"
                id="anexa16"
                onChange={(event) => {
                  setAnexa16File(event.target.files[0]);
                }}
                disabled={isSubmitted}
              />
              <label htmlFor="anexa16">
                <Box height="100%" display="flex" justifyContent="center" flexDirection="column">
                  <ColorButton
                    variant="outlined"
                    borderColor="#005db7"
                    labelColor="#005db7"
                    component="span"
                    onClick={(e) => {
                      if (isSubmitted) {
                        e.preventDefault();
                        dispatch(documentActions.downloadAnexaRaportRaportor(anexa16File.url));
                      }
                    }}
                  >
                    {isSubmitted
                      ? intl.formatMessage({ id: 'global.download' })
                      : intl.formatMessage({ id: 'global.upload' })}
                  </ColorButton>
                </Box>
              </label>
            </Grid>
          </Grid>
        )}
        {!showReportForm && (
          <TabContext value={value}>
            <TabList onChange={handleChange} aria-label="tabs">
              <Tab label={intl.formatMessage({ id: 'phase.reporting.tab1' })} value="1" />
              <Tab label={intl.formatMessage({ id: 'phase.reporting.tab2' })} value="2" />
              <Tab label={intl.formatMessage({ id: 'phase.reporting.tab3' })} value="3" />
            </TabList>
            <TabPanel value="1" index={0} key={0} sx={{ pl: 0 }}>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12}>
                  {reporting.publications && reporting.publications.length > 0 && (
                    <CardList
                      entityId="id"
                      items={reporting.publications}
                      Card={ReporterProgramReportProjectCyclePhasePublicationCard}
                    />
                  )}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2" index={1} key={1} sx={{ pl: 0 }}>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12}>
                  {reporting.patents && reporting.patents.length > 0 && (
                    <CardList
                      entityId="id"
                      items={reporting.patents}
                      Card={ReporterProgramReportProjectCyclePhasePatentCard}
                    />
                  )}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="3" index={2} key={2}>
              <Grid container>
                {reporting.documents &&
                  reporting.documents.map((document) => (
                    <Grid item xs={12} sm={12} sx={{ mb: 1 }} key={document.fisierUrl}>
                      <ColorButton
                        variant="outlined"
                        borderColor="#005db7"
                        labelColor="#005db7"
                        component="span"
                        startIcon={<DownloadIcon />}
                        onClick={(event) => {
                          event.preventDefault();
                          dispatch(documentActions.downloadAnexaRaportRaportor(document.fisierUrl));
                        }}
                      >
                        {document.numeFisierOriginal}
                      </ColorButton>
                    </Grid>
                  ))}
              </Grid>
            </TabPanel>
          </TabContext>
        )}
      </Container>
    </Page>
  );
}
