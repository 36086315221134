import patentsFormModel from './patentsFormModel';

const {
  patentsField: { patent, cycle, phase }
} = patentsFormModel;

export const getInitialPatentFormValues = (patentData) => ({
  [patent.name]:
    patent && patentData[patent.name]
      ? {
          [patent.id.name]: patentData[patent.name][patent.id.name]
            ? patentData[patent.name][patent.id.name]
            : '',
          [patent.status.name]: patentData[patent.name][patent.status.name]
            ? patentData[patent.name][patent.status.name]
            : '',
          [patent.number.name]: patentData[patent.name][patent.number.name]
            ? patentData[patent.name][patent.number.name]
            : '',
          [patent.roName.name]: patentData[patent.name][patent.roName.name]
            ? patentData[patent.name][patent.roName.name]
            : '',
          [patent.enName.name]: patentData[patent.name][patent.enName.name]
            ? patentData[patent.name][patent.enName.name]
            : '',
          [patent.roDescription.name]: patentData[patent.name][patent.roDescription.name]
            ? patentData[patent.name][patent.roDescription.name]
            : '',
          [patent.enDescription.name]: patentData[patent.name][patent.enDescription.name]
            ? patentData[patent.name][patent.enDescription.name]
            : '',
          [patent.proposalYear.name]: patentData[patent.name][patent.proposalYear.name]
            ? patentData[patent.name][patent.proposalYear.name]
            : '',
          [patent.acceptanceYear.name]: patentData[patent.name][patent.acceptanceYear.name]
            ? patentData[patent.name][patent.acceptanceYear.name]
            : '',
          [patent.type.name]: patentData[patent.name][patent.type.name]
            ? patentData[patent.name][patent.type.name]
            : '',
          [patent.authors.name]: patentData[patent.name][patent.authors.name]
            ? patentData[patent.name][patent.authors.name]
            : ''
        }
      : {},
  [cycle.name]: patent && patentData[cycle.name] ? patentData[cycle.name] : '',
  [phase.name]: patent && patentData[phase.name] ? patentData[phase.name] : ''
});
