import reportAssignedEvaluatorsFormModel from './reportAssignedEvaluatorsFormModel';

const {
  reportAssignedEvaluatorsField: { assignedEvaluators }
} = reportAssignedEvaluatorsFormModel;

export const getInitialReportAssignedEvaluatorsFormValues = (programAssignedEvaluators) => ({
  [assignedEvaluators.name]: programAssignedEvaluators
    ? programAssignedEvaluators.map((assignedEvaluator) => ({
        [assignedEvaluators.project.name]: { id: assignedEvaluator.proiectGuid },
        [assignedEvaluators.mainEvaluator.name]: { id: assignedEvaluator.raportorPrincipalGuid },
        [assignedEvaluators.secondEvaluator.name]: { id: assignedEvaluator.raportorSecundarGuid }
      }))
    : []
});
