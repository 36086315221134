// eslint-disable-next-line camelcase
import { camelizeKeys } from 'humps';
import { authHeader } from '../utils/auth-header';
import { env } from '../utils/config';
import { userService } from './index';

export const evaluationService = {
  getMyTechnicalEvaluationPrograms,
  getMyProgramEvaluationPrograms,
  getInstitutionProgramEvaluation,
  saveDraftProjectEvaluation,
  submitProjectEvaluation,
  saveDraftProgramEvaluation,
  submitProgramEvaluation,
  contestProgramEvaluation,
  sendProjectToMonitoring
};

function getMyTechnicalEvaluationPrograms() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_EVALUARE_BASE_PATH}/VizualizeazaObiectiveSiProiecteEvaluareTehnica`,
    requestOptions
  ).then(handleResponse);
}

function getMyProgramEvaluationPrograms() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_EVALUARE_BASE_PATH}/VizualizeazaProgrameEvaluareTehnica`,
    requestOptions
  ).then(handleResponse);
}

function getInstitutionProgramEvaluation(programId, type) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/VizualizeazaEvaluareProgram/${programId}`,
    requestOptions
  ).then(handleResponse);
}

function saveDraftProjectEvaluation(type, projectEvaluationSheet) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(projectEvaluationSheet)
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_EVALUARE_BASE_PATH}/CompleteazaEvaluareTehinicaProiect/${type}`,
    requestOptions
  ).then(handleResponse);
}

function submitProjectEvaluation(projectId, type, projectEvaluationSheet) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(projectEvaluationSheet)
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_EVALUARE_BASE_PATH}/SubmiteEvaluareTehinicaProiect/${projectId}/${type}`,
    requestOptions
  ).then(handleResponse);
}

function saveDraftProgramEvaluation(sheetType, type, programEvaluationSheet) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(programEvaluationSheet)
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_EVALUARE_BASE_PATH}/CompleteazaEvaluareTehinicaProgram/${type}/${sheetType}`,
    requestOptions
  ).then(handleResponse);
}

function submitProgramEvaluation(programId, sheetType, type, programEvaluationSheet) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(programEvaluationSheet)
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_EVALUARE_BASE_PATH}/SubmiteEvaluareTehinicaProgram/${programId}/${type}/${sheetType}`,
    requestOptions
  ).then(handleResponse);
}

function contestProgramEvaluation(formData, idProgram) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: formData
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/ContestaEvaluarea/${idProgram}`,
    requestOptions
  ).then(handleResponse);
}

function sendProjectToMonitoring(projectId, programId) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ProiecteGuid: [projectId]
    })
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${programId}/MarcheazaEligibilMonitorizare`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        window.location.reload(true);
      }
      return Promise.reject(JSON.parse(text));
    }

    const data = text && JSON.parse(text);

    if (data) {
      return camelizeKeys(data);
    }

    return text;
  });
}
