import * as Yup from 'yup';
import patentsFormModel from './patentsFormModel';

const {
  patentsField: { patent }
} = patentsFormModel;

export const patentFormValidationSchema = () =>
  Yup.object().shape({
    [patent.name]: Yup.object().shape({
      [patent.status.name]: Yup.string().required(`${patent.status.requiredErrorMsg}`),
      [patent.number.name]: Yup.string().required(`${patent.number.requiredErrorMsg}`),
      [patent.roName.name]: Yup.string().required(`${patent.roName.requiredErrorMsg}`),
      [patent.enName.name]: Yup.string().required(`${patent.enName.requiredErrorMsg}`)
    })
  });
