import * as _ from 'lodash';
import { useIntl } from 'react-intl';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

export default function ProjectsObjectivesTableHeader({ program, noOfYears, disabled }) {
  const intl = useIntl();

  return (
    <TableHead>
      <TableRow>
        <TableCell rowSpan={2}>{intl.formatMessage({ id: 'table.head.number' })}</TableCell>
        <TableCell rowSpan={2}>
          {intl.formatMessage({ id: 'program.totalValue.table.head.project' })}
        </TableCell>
        <TableCell rowSpan={2}>
          {intl.formatMessage({ id: 'program.totalValue.table.head.activity' })}
        </TableCell>
        <TableCell rowSpan={2}>
          {intl.formatMessage({ id: 'program.totalValue.table.head.length' })}
        </TableCell>
        <TableCell colSpan={noOfYears + 1}>
          {intl.formatMessage({ id: 'program.totalValue.table.head.value' })}
        </TableCell>
        <TableCell rowSpan={2}>
          {intl.formatMessage({ id: 'program.totalValue.table.head.results' })}
        </TableCell>
        <TableCell rowSpan={2}>
          {intl.formatMessage({ id: 'program.totalValue.table.head.responsible' })}
        </TableCell>
        {!disabled && (
          <TableCell rowSpan={2}>{intl.formatMessage({ id: 'table.head.actions' })}</TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell>{intl.formatMessage({ id: 'table.head.total' })}</TableCell>
        {_.range(program.start, program.end + 1).map((year) => (
          <TableCell key={year}>{year}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
