import * as Yup from 'yup';
import programInformationFormModel from './programInformationFormModel';

const {
  programInformationFormField: {
    coreProgram,
    acronym,
    programAdmin,
    programAdminCV,
    description,
    purpose,
    goals,
    domains
  }
} = programInformationFormModel;

export const programInformationFormValidationSchema = () =>
  Yup.object().shape({
    [coreProgram.name]: Yup.string().required(`${coreProgram.requiredErrorMsg}`),
    [acronym.name]: Yup.string().required(`${acronym.requiredErrorMsg}`),
    [programAdmin.name]: Yup.object().nullable().required(`${programAdmin.requiredErrorMsg}`),
    [programAdminCV.name]: Yup.string().nullable().required(`${programAdminCV.requiredErrorMsg}`),
    [description.name]: Yup.string().required(`${description.requiredErrorMsg}`),
    [purpose.name]: Yup.string().required(`${purpose.requiredErrorMsg}`),
    [goals.name]: Yup.string().required(`${goals.requiredErrorMsg}`),
    [domains.name]: Yup.string().required(`${domains.requiredErrorMsg}`)
  });
