import programInformationFormModel from './programInformationFormModel';

const {
  programInformationFormField: {
    contractor,
    coreProgram,
    acronym,
    programAdmin,
    programAdminCV,
    description,
    purpose,
    goals,
    domains
  }
} = programInformationFormModel;

export const getInitialProgramInformationFormValues = (program) => ({
  [contractor.name]: program?.detaliiProgram?.[contractor.name] ?? '',
  [coreProgram.name]: program?.detaliiProgram?.[coreProgram.name] ?? '',
  [acronym.name]: program?.detaliiProgram?.[acronym.name] ?? '',
  [programAdmin.name]:
    program && program.directorulProgramului
      ? { id: program.directorulProgramului.userGuid, label: program.directorulProgramului.nume }
      : '',
  [programAdminCV.name]: program?.detaliiProgram?.cvDirector?.numeFisierOriginal ?? '',
  [description.name]: program?.detaliiProgram?.[description.name] ?? '',
  [purpose.name]: program?.detaliiProgram?.[purpose.name] ?? '',
  [goals.name]: program?.detaliiProgram?.[goals.name] ?? '',
  [domains.name]: program?.detaliiProgram?.[domains.name] ?? ''
});
