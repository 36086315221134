const schemaRealizareForm = {
  formId: 'schemaRealizare',
  name: 'schemaRealizare',
  cycles: {
    name: 'cicluri',
    id: {
      name: 'cicluId'
    },
    cycleName: {
      name: 'denumire'
    },
    startDate: {
      name: 'startDate'
    },
    endDate: {
      name: 'endDate'
    }
  },
  schemaRealizareFields: {
    activitatiFaza: {
      name: 'activitatiFaza'
    },
    rezultateFaza: {
      name: 'rezultateFaza'
    },
    faza: {
      name: 'faza'
    }
  },
  schemaRealizareTableHead: {
    dataInceput: {
      name: 'dataInceput',
      parent: 'faza',
      label: 'form.project.schema.header.dataInceput'
    },
    dataFinal: {
      name: 'dataFinal',
      parent: 'faza',
      label: 'form.project.schema.header.dataFinal'
    },
    termenPredare: {
      name: 'termenPredare',
      parent: 'faza',
      label: 'form.project.schema.header.termenPredare'
    },
    buget: {
      name: 'buget',
      parent: 'faza',
      label: 'form.project.schema.header.buget'
    },
    titlu: {
      name: 'titlu',
      parent: 'faza',
      label: 'form.project.schema.header.titlu'
    }
  },
  activitatiFaza: {
    informatiiAditionale: {
      name: 'informatiiAditionale',
      parent: 'activitatiFaza',
      label: 'form.project.schema.activitati.activitatiFaza'
    },
    descriere: {
      name: 'descriere',
      parent: 'activitatiFaza',
      label: 'form.project.schema.activitati.descriere'
    },
    nume: {
      name: 'nume',
      parent: 'activitatiFaza',
      label: 'form.project.schema.activitati.nume'
    }
  },
  rezultateFazaFields: [
    {
      name: 'descriere',
      parent: 'rezultateFaza',
      label: 'form.project.schema.rezultate.descriere',
      rows: 3
    }
  ]
};

export default schemaRealizareForm;
