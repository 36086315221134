import * as Yup from 'yup';
import moment from 'moment';
import monitoringCycleFormModel from './monitoringCycleFormModel';

const {
  monitoringCycleField: { cycle }
} = monitoringCycleFormModel;

export const monitoringCycleFormValidationSchema = () =>
  Yup.object().shape({
    [cycle.name]: Yup.array()
      .min(1, `${cycle.requiredErrorMsg}`)
      .of(
        Yup.object().shape({
          [cycle.title.name]: Yup.string().nullable().required(`${cycle.title.requiredErrorMsg}`),
          [cycle.startDate.name]: Yup.date()
            .nullable()
            .required(`${cycle.startDate.requiredErrorMsg}`)
            .test({
              name: 'check order start date',
              message: cycle.startDate.wrongOrderErrorMsg,
              /* eslint-disable */
              test: function (value) {
                return moment(value).diff(moment(this.parent[cycle.endDate.name])) <= 0;
              }
            }),
          [cycle.endDate.name]: Yup.date().nullable().required(`${cycle.endDate.requiredErrorMsg}`)
            .test({
              name: 'check order end date',
              message: cycle.endDate.wrongOrderErrorMsg,
              /* eslint-disable */
              test: function (value) {
                return moment(value).diff(moment(this.parent[cycle.startDate.name])) >= 0 && moment(value).year() === moment(this.parent[cycle.startDate.name]).year();
              }
            }),
          [cycle.projects.name]: Yup.array()
            .min(1, `${cycle.projects.requiredErrorMsg}`)
            .of(
              Yup.object().shape({
                [cycle.projects.project.name]: Yup.object().required(
                  `${cycle.projects.project.requiredErrorMsg}`
                ),
                [cycle.projects.budget.name]: Yup.number()
                  .nullable()
                  .required(`${cycle.projects.budget.requiredErrorMsg}`),
                [cycle.projects.monitor.name]: Yup.object().required(
                  `${cycle.projects.monitor.requiredErrorMsg}`
                )
              })
            )
        })
      )
  });
