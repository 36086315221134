import { useIntl } from 'react-intl';
import { Backdrop, CircularProgress, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import Page from '../components/Page';
import CardList from '../components/lists/CardList';
import { eligibilityActions } from '../actions';
import EligibilityProgramCard from '../components/cards/EligibilityProgramCard';
// ----------------------------------------------------------------------

export default function EligibilityPrograms() {
  const intl = useIntl();

  const [sort, setSort] = useState('ascending'); // set initial State to ascending
  const [searchText, setSearchText] = useState(''); // set initial State to ''
  const [programItems, setProgramItems] = useState([]); // set initial State to empty Array
  const eligibility = useSelector((state) => state.eligibility);
  const dispatch = useDispatch();

  // getAssignedPrograms
  useEffect(() => {
    dispatch(eligibilityActions.getAssignedPrograms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // sort/order programs by numeProgram
  useEffect(() => {
    if (eligibility && eligibility.programs) {
      setProgramItems(
        [...eligibility.programs].sort((p1, p2) => p1.numeProgram.localeCompare(p2.numeProgram))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eligibility]);

  // filter Programs with numeProgram by searchText
  useEffect(() => {
    let filteredPrograms = [];
    if (eligibility && eligibility.programs) {
      if (searchText && searchText.length > 0) {
        filteredPrograms = eligibility.programs.filter((program) =>
          program.numeProgram.toLowerCase().includes(searchText.toLowerCase())
        );
      } else {
        filteredPrograms = eligibility.programs;
      }
    }

    // sort/order ascending filtered programs by numeProgram
    filteredPrograms = [...filteredPrograms].sort((p1, p2) =>
      sort === 'ascending'
        ? p1.numeProgram.toLowerCase().localeCompare(p2.numeProgram.toLowerCase())
        : p2.numeProgram.toLowerCase().localeCompare(p1.numeProgram.toLowerCase())
    );

    setProgramItems([...filteredPrograms]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, searchText]);

  // display CircularProgress on eligibility programs loading
  if (eligibility?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  // display  eligibility programs
  return (
    <Page title={intl.formatMessage({ id: 'applicant.programmes' })} my={2}>
      <Container>
        {programItems && (
          <CardList
            entityId="programGuid"
            sort={sort}
            setSort={setSort}
            searchText={searchText}
            setSearchText={setSearchText}
            sortFieldTitle={intl.formatMessage({ id: 'global.name' })}
            items={programItems}
            Card={EligibilityProgramCard}
          />
        )}
      </Container>
    </Page>
  );
}
