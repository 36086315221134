const competitionEligibilityForm = {
  formId: 'competitionEligibilityForm',
  competitionEligibilityField: {
    criteria: {
      name: 'Criterii',
      label: 'competition.criteria',
      requiredErrorMsg: 'form.error.required'
    }
  }
};

export default competitionEligibilityForm;
