import { Button, FormHelperText, Grid } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';

import { useEffect } from 'react';
import * as React from 'react';
import EditableFormTextField from '../../../forms/shared/EditableFormTextField';
import { FORM_IDS } from '../../../utils/enums';
import { competitionActions } from '../../../actions';
import competitionEligibilityFormModel from '../../../forms/competitions/competitionEligibility/competitionEligibilityFormModel';
import { getInitialCompetitionEligibilityFormValues } from '../../../forms/competitions/competitionEligibility/formInitialValues';
import { competitionEligibilityFormValidationSchema } from '../../../forms/competitions/competitionEligibility/validationSchema';

// ----------------------------------------------------------------------------

const {
  competitionEligibilityField: { criteria }
} = competitionEligibilityFormModel;

export default function CompetitionEligibility({ id, disabled, completeStep }) {
  const intl = useIntl();
  const competitions = useSelector((state) => state.competitions);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id !== '0') {
      dispatch(competitionActions.getCompetition(id, true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const formik = useFormik({
    initialValues: getInitialCompetitionEligibilityFormValues(
      competitions.selectedCompetitionEligibility || {}
    ),
    validationSchema: competitionEligibilityFormValidationSchema(),
    onSubmit: (values) => {
      const valuesCopy = JSON.parse(JSON.stringify(values));
      dispatch(
        competitionActions.addCompetitionEligibility(
          valuesCopy,
          competitions.selectedCompetition.competitionGuid
        )
      );
      completeStep();
    }
  });

  return (
    <form id={FORM_IDS.ADMIN_COMPETITION_ELIGIBILITY} onSubmit={formik.handleSubmit} noValidate>
      <Grid container spacing={5}>
        {formik.values[criteria.name].map((criterion, index) => (
          <Grid item xs={12} sm={12} key={index}>
            <EditableFormTextField
              parentName={criteria.name}
              index={index}
              formik={formik}
              label={intl.formatMessage({ id: criteria.label })}
              variant="outlined"
              fullWidth
              disabled={disabled}
              required
            />
          </Grid>
        ))}
        <Grid item xs={3} sm={3}>
          <Button
            variant="contained"
            onClick={() =>
              formik.setFieldValue(criteria.name, [...formik.values[criteria.name], null])
            }
          >
            {intl.formatMessage({ id: 'competitions.eligibility.add_criterion' })}
          </Button>
          {formik.errors[criteria.name] && !Array.isArray(formik.errors[criteria.name]) && (
            <FormHelperText id="component-helper-text" error>
              {intl.formatMessage({ id: formik.errors[criteria.name] })}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </form>
  );
}
