import * as Yup from 'yup';
import projectTechnicalSheetForm from './projectTechnicalSheetFormModel';

const {
  projectTechnicalSheetField: { projectTechnicalSheet }
} = projectTechnicalSheetForm;

export const expectedResultsFormValidationSchema = (isSaveDraft) =>
  isSaveDraft
    ? Yup.object()
    : Yup.object().shape({
        [projectTechnicalSheet.name]: Yup.object().shape({
          [projectTechnicalSheet.criteria.name]: Yup.array().of(
            Yup.object().shape({
              [projectTechnicalSheet.criteria.value.name]: Yup.number()
                .required(projectTechnicalSheet.criteria.value.requiredError)
                .test({
                  name: 'test',
                  message: projectTechnicalSheet.criteria.value.maxValueError,
                  /* eslint-disable */
                  test: function (value) {
                    return this.parent && value <= this.parent[projectTechnicalSheet.criteria.maxValue.name]
                  }
              }),
              [projectTechnicalSheet.criteria.comments.name]: Yup.string().required(projectTechnicalSheet.criteria.comments.requiredError).min(100, projectTechnicalSheet.criteria.comments.requiredLengthError)
            })
          )
        })
  });
