import { useIntl } from 'react-intl';
import { Backdrop, CircularProgress, Grid, Stack } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import PropTypes from 'prop-types';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import InstitutionProgramEvaluationProjectSheet from './InstitutionProgramEvaluationProjectSheet';
import { evaluationActions } from '../../../actions';
// ----------------------------------------------------------------------

InstitutionProgramEvaluationResults.propTypes = {
  objectives: PropTypes.array,
  programId: PropTypes.number
};

export default function InstitutionProgramEvaluationResults({ objectives, programId }) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [selectedObjective, setSelectedObjective] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const evaluation = useSelector((state) => state.evaluation);

  if (evaluation?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Stack spacing={2}>
      {!selectedProject &&
        objectives.map((objective) => [
          ...objective.proiecte.map((project) => (
            <Card key={project.proiectGuid}>
              <Grid container alignItems="center">
                <Grid item xs={9} sm={9}>
                  <CardHeader
                    title={
                      <div>
                        <p>{project.titluProiect}</p>
                      </div>
                    }
                  />
                  <CardContent>
                    <p>
                      {intl.formatMessage({ id: 'global.result' })}:{' '}
                      {project.fisaFinala.criterii.reduce((prev, curr) => prev + curr.scor, 0)} /{' '}
                      {project.fisaFinala.criterii.reduce((prev, curr) => prev + curr.scorMaxim, 0)}
                    </p>
                  </CardContent>
                </Grid>
                <Grid item xs={3} sm={3}>
                  <Stack spacing={2} sx={{ mr: 2 }}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setSelectedProject(project.proiectGuid);
                        setSelectedObjective(objective.obiectivGuid);
                      }}
                    >
                      {intl.formatMessage({ id: 'program.card.view.sheet' })}
                    </Button>
                    {!project.admisPentruMonitorziareDupaEvaluareTehnicaInFunctieDeScor && (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          dispatch(
                            evaluationActions.sendProjectToMonitoring(
                              project.proiectGuid,
                              programId
                            )
                          );
                        }}
                      >
                        {intl.formatMessage({ id: 'global.send.monitoring' })}
                      </Button>
                    )}
                    {project.admisPentruMonitorziareDupaEvaluareTehnicaInFunctieDeScor && (
                      <Button variant="outlined" disabled>
                        {intl.formatMessage({ id: 'global.project.monitored' })}
                      </Button>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          ))
        ])}
      {selectedProject && (
        <>
          <Button
            sx={{ width: 1 / 10 }}
            variant="outlined"
            onClick={() => {
              setSelectedProject(null);
              setSelectedObjective(null);
            }}
          >
            {intl.formatMessage({ id: 'form.previous_step' })}
          </Button>
          <InstitutionProgramEvaluationProjectSheet
            sheet={objectives
              .find((objective) => objective.obiectivGuid === selectedObjective)
              .proiecte.find((project) => project.proiectGuid === selectedProject)}
          />
        </>
      )}
    </Stack>
  );
}
