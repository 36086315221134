import { evaluationConstants } from '../constants';

export function evaluation(
  state = {
    loading: false,
    saving: false,
    programs: [],
    programEvaluation: null
  },
  action
) {
  switch (action.type) {
    case evaluationConstants.GET_OBJECTIVES_REQUEST:
    case evaluationConstants.GET_EVALUATION_PROGRAMS_REQUEST:
    case evaluationConstants.GET_PROGRAM_EVALUATION_REQUEST:
    case evaluationConstants.CONTEST_PROGRAM_EVALUATION_REQUEST:
    case evaluationConstants.SEND_PROJECT_TO_MONITORING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case evaluationConstants.GET_OBJECTIVES_SUCCESS:
    case evaluationConstants.GET_EVALUATION_PROGRAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        programs: action.programs.programe
      };
    case evaluationConstants.GET_PROGRAM_EVALUATION_SUCCESS:
      return {
        ...state,
        loading: false,
        programEvaluation: action.programEvaluation.program
      };
    case evaluationConstants.SEND_PROJECT_TO_MONITORING_SUCCESS:
    case evaluationConstants.CONTEST_PROGRAM_EVALUATION_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case evaluationConstants.GET_OBJECTIVES_FAILURE:
    case evaluationConstants.GET_EVALUATION_PROGRAMS_FAILURE:
    case evaluationConstants.GET_PROGRAM_EVALUATION_FAILURE:
    case evaluationConstants.CONTEST_PROGRAM_EVALUATION_FAILURE:
    case evaluationConstants.SEND_PROJECT_TO_MONITORING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
