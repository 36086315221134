import * as Yup from 'yup';
import 'yup-phone-lite';
import institutionInfoFormModel from './institutionInfoFormModel';

const {
  institutionInfoFormField: {
    name,
    shortName,
    fiscalCode,
    region,
    county,
    city,
    address,
    directorPhone,
    directorEmail,
    economicDirectorPhone,
    economicDirectorEmail
  }
} = institutionInfoFormModel;

export const institutionInfoFormValidationSchema = () =>
  Yup.object().shape({
    [name.name]: Yup.string().required(`${name.requiredErrorMsg}`),
    [shortName.name]: Yup.string().required(`${shortName.requiredErrorMsg}`),
    [fiscalCode.name]: Yup.string().required(`${fiscalCode.requiredErrorMsg}`),
    [region.name]: Yup.string().required(`${region.requiredErrorMsg}`),
    [county.name]: Yup.string().required(`${county.requiredErrorMsg}`),
    [city.name]: Yup.string().required(`${city.requiredErrorMsg}`),
    [address.name]: Yup.string().required(`${address.requiredErrorMsg}`),
    [directorEmail.name]: Yup.string().email(`${directorEmail.emailErrorMsg}`),
    [economicDirectorEmail.name]: Yup.string().email(`${economicDirectorEmail.emailErrorMsg}`),
    [directorPhone.name]: Yup.string().phone('RO', `${directorPhone.phoneErrorMsg}`),
    [economicDirectorPhone.name]: Yup.string().phone('RO', `${economicDirectorPhone.phoneErrorMsg}`)
  });
