import { useIntl } from 'react-intl';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Typography,
  Link
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import Page from '../components/Page';
import CardList from '../components/lists/CardList';
import { documentActions, monitoringActions } from '../actions';
import MonitorProgramProjectCyclePhaseCard from '../components/cards/MonitorProgramProjectCyclePhaseCard';
import { MONITORING_STATUS } from '../utils/enums';
// ----------------------------------------------------------------------

export default function MonitorProgramProjectCyclePhases() {
  const { idProgram, idProiect, idCiclu } = useParams();
  const intl = useIntl();

  const [open, setOpen] = React.useState(false);
  const [sort, setSort] = useState('ascending');
  const [searchText, setSearchText] = useState('');
  const [monitorComments, setMonitorComments] = useState('');
  const [phaseItems, setPhaseItems] = useState([]);
  const monitoring = useSelector((state) => state.monitoring);
  const [selectedProjectCycle, setSelectedProjectCycle] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(monitoringActions.getMyMonitoringProjects(idProgram));
    dispatch(monitoringActions.getCyclePhases(idCiclu, idProiect));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (monitoring && monitoring.phases && monitoring.phases.length > 0) {
      setPhaseItems(monitoring.phases.sort((c1, c2) => c1.titlu.localeCompare(c2.titlu)));
    }

    if (monitoring && monitoring.projects && monitoring.projects.length > 0) {
      setSelectedProjectCycle(
        monitoring.projects
          .find((project) => project.proiectGuid === +idProiect)
          .cicluri.find((cycle) => cycle.cicluId === +idCiclu)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitoring]);

  const handleSubmit = (status) => {
    dispatch(
      monitoringActions.submitBudgetCheck(
        monitoring.notaJustificativa.monitorizareProiectId,
        status,
        monitorComments
      )
    );
    setMonitorComments('');
    setOpen(false);
  };

  if (monitoring?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Page title={intl.formatMessage({ id: 'program.projects.title' })} my={2}>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {intl.formatMessage({ id: 'global.confirm' })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {intl.formatMessage({ id: 'monitoring.reject.comment' })}
          </DialogContentText>
          <TextField
            autoFocus
            id="monitor-reject-comment"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            onChange={(event) => setMonitorComments(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>
            {intl.formatMessage({ id: 'global.cancel' })}
          </Button>
          <Button onClick={() => handleSubmit('Respins')} autoFocus>
            {intl.formatMessage({ id: 'global.ok' })}
          </Button>
        </DialogActions>
      </Dialog>
      {phaseItems && phaseItems.length > 0 && (
        <Container>
          <Typography gutterBottom align="left" variant="h5" sx={{ mb: '24px' }}>
            {intl.formatMessage({ id: 'global.status' })}:{' '}
            {selectedProjectCycle ? selectedProjectCycle.monitorizare.status : '2'}
          </Typography>
          <Typography gutterBottom align="left" variant="h5" sx={{ mb: '24px' }}>
            {intl.formatMessage({ id: 'global.nota_justificativa' })}:{' '}
            <Link
              component="button"
              variant="h6"
              onClick={() =>
                dispatch(
                  documentActions.downloadNotaJustificativa(
                    monitoring.notaJustificativa.nota.fisierUrl
                  )
                )
              }
            >
              {monitoring.notaJustificativa
                ? monitoring.notaJustificativa.nota.numeFisierOriginal
                : ''}
            </Link>
          </Typography>
          {phaseItems && (
            <CardList
              entityId="fazaGuid"
              sort={sort}
              setSort={setSort}
              searchText={searchText}
              setSearchText={setSearchText}
              sortFieldTitle={intl.formatMessage({ id: 'global.name' })}
              items={phaseItems}
              Card={MonitorProgramProjectCyclePhaseCard}
            />
          )}
          {selectedProjectCycle &&
            selectedProjectCycle.monitorizare.status === MONITORING_STATUS.EVALUATING && (
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={2} sm={2}>
                  <Button variant="outlined" onClick={() => handleSubmit('Admis')}>
                    {intl.formatMessage({ id: 'monitoring.approve' })}
                  </Button>
                </Grid>
                <Grid item xs={8} sm={8} />
                <Grid item xs={2} sm={2} alignItems="flex-end">
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: 'red' }}
                      onClick={() => setOpen(true)}
                    >
                      {intl.formatMessage({ id: 'monitoring.reject' })}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            )}
        </Container>
      )}
      {!phaseItems ||
        (phaseItems.length === 0 && (
          <Container>
            <Typography gutterBottom align="left" variant="h5" sx={{ mb: '24px' }}>
              {intl.formatMessage({ id: 'monitoring.cycle.no_phases' })}
            </Typography>
          </Container>
        ))}
    </Page>
  );
}
