// eslint-disable-next-line camelcase
import { camelizeKeys } from 'humps';
import { authHeader } from '../utils/auth-header';
import { env } from '../utils/config';
import { userService } from './index';

export const monitoringService = {
  getProgramCycles,
  getInstitutionProgramCycles,
  getProgramAssignedMonitors,
  saveCycles,
  saveAssignedMonitors,
  getMyMonitoringPrograms,
  getMyMonitoringProjects,
  getProgramCycleProjects,
  getCyclePhases,
  getProgramCycleProjectPhases,
  getPhase,
  submitBudgetCheck,
  submitMonitoringPhases
};

function getProgramCycles(idProgram) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/VizualizeazaCicluriFinantare/${idProgram}`,
    requestOptions
  ).then(handleResponse);
}

function getInstitutionProgramCycles(idProgram) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/CicluriProgram/${idProgram}`,
    requestOptions
  ).then(handleResponse);
}

function getProgramAssignedMonitors(idProgram) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/MonitoriAsignati/${idProgram}`,
    requestOptions
  ).then(handleResponse);
}

function getMyMonitoringPrograms() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_MONITOR_BASE_PATH}/ProgrameCuProiecteAsignate`,
    requestOptions
  ).then(handleResponse);
}

function getMyMonitoringProjects(programId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_MONITOR_BASE_PATH}/ProiecteAsignate/${programId}`,
    requestOptions
  ).then(handleResponse);
}

function getProgramCycleProjects(idProgram, idCiclu) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${idProgram}/ProiecteAsociateCiclu/${idCiclu}`,
    requestOptions
  ).then(handleResponse);
}

function getCyclePhases(idCiclu, idProiect) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_MONITOR_BASE_PATH}/FazeProiectPerCiclu/${idCiclu}/${idProiect}`,
    requestOptions
  ).then(handleResponse);
}

function getProgramCycleProjectPhases(idCiclu, idProiect) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/FazeProiectAsociabileCiclu/${idCiclu}/${idProiect}`,
    requestOptions
  ).then(handleResponse);
}

function getPhase(idProiect, idCiclu, idFaza) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_MONITOR_BASE_PATH}/DetaliiFaza/${idFaza}`,
    requestOptions
  ).then(handleResponse);
}

function saveCycles(data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/SeteazaCicluriFinantare`,
    requestOptions
  ).then(handleResponse);
}

function saveAssignedMonitors(data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_PLATFORMA_BASE_PATH}/AsigneazaMonitori`,
    requestOptions
  ).then(handleResponse);
}

function submitBudgetCheck(monitorizareProiectId, status, monitorComments) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Comentarii: monitorComments,
      Status: status
    })
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_MONITOR_BASE_PATH}/SubmiteVerificare/${monitorizareProiectId}`,
    requestOptions
  ).then(handleResponse);
}

function submitMonitoringPhases(idProgram, data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: data
  };

  return fetch(
    `${env.REACT_APP_SERVER_BUSINESS_BASE_PATH}${env.REACT_APP_SERVER_ADMINISTRATOR_INSTITUTIE_BASE_PATH}/Program/${idProgram}/SubmiteFazeMonitorizare`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        window.location.reload(true);
      }
      return Promise.reject(JSON.parse(text));
    }

    const data = text && JSON.parse(text);

    if (data) {
      return camelizeKeys(data);
    }

    return text;
  });
}
