import { useIntl } from 'react-intl';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Input,
  Tab,
  TextField
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { FieldArray, Formik } from 'formik';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { styled } from '@mui/material/styles';
import { pascalizeKeys } from 'humps';
import moment from 'moment';
import Page from '../components/Page';
import { documentActions, reportActions } from '../actions';
import {
  PATENT_STATUSES,
  PATENT_TYPES,
  PUBLICATION_CATEGORIES,
  PUBLICATION_TYPES,
  REPORT_DOCUMENTS_FIRST_PHASE,
  REPORT_DOCUMENTS_GENERAL,
  REPORT_DOCUMENTS_LAST_PHASE,
  REPORT_DOCUMENTS_PROJECT_END,
  REPORT_DOCUMENTS_YEAR_END
} from '../utils/enums';
import ColorButton from '../components/common/ColorButton';
import CardList from '../components/lists/CardList';
import InstitutionProgramReportProjectCyclePhasePublicationCard from '../components/cards/InstitutionProgramReportProjectCyclePhasePublicationCard';
import InstitutionProgramReportProjectCyclePhasePatentCard from '../components/cards/InstitutionProgramReportProjectCyclePhasePatentCard';
import publicationsFormModel from '../forms/reporting/publications/publicationsFormModel';
import EditableFormTextField from '../forms/shared/EditableFormTextField';
import { getInitialPublicationFormValues } from '../forms/reporting/publications/formInitialValues';
import { publicationFormValidationSchema } from '../forms/reporting/publications/validationSchema';
import EditableFormSelect from '../forms/shared/EditableFormSelect';
import EditableFormDatePicker from '../forms/shared/EditableFormDatePicker';
import { defaultConfig } from '../utils/config';
import KeywordField from '../components/KeywordField';
import { getInitialPatentFormValues } from '../forms/reporting/patents/formInitialValues';
import { patentFormValidationSchema } from '../forms/reporting/patents/validationSchema';
import patentsFormModel from '../forms/reporting/patents/patentsFormModel';
// ----------------------------------------------------------------------

const FileInputStyle = styled(Input)(() => ({
  display: 'none'
}));

const {
  publicationsField: { publication, cycle, phase }
} = publicationsFormModel;

const {
  patentsField: { patent, cycle: patentCycle, phase: patentPhase }
} = patentsFormModel;

export default function InstitutionProgramReportProjectCyclePhaseReporting() {
  const { idProgram, idProiect, idCiclu, idFaza } = useParams();
  const intl = useIntl();
  const [value, setValue] = useState('1');
  const [showPublicationForm, setShowPublicationForm] = useState(false);
  const [showPatentForm, setShowPatentForm] = useState(false);
  const [reportDocuments, setReportDocuments] = useState({ ...REPORT_DOCUMENTS_GENERAL });

  const [anexaFiles, setAnexaFiles] = useState({
    ...Object.keys(reportDocuments).reduce((a, v) => ({ ...a, [reportDocuments[v]]: null }), {})
  });

  const reporting = useSelector((state) => state.report);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reportActions.getReportingProjectCyclePhases(idProiect, idCiclu));
    dispatch(reportActions.getReportingProjectCycles(user.info.institutieParinte, idProiect));
    dispatch(reportActions.getPhasePublications(idProgram, idFaza));
    dispatch(reportActions.getPhasePatents(idProgram, idFaza));
    dispatch(reportActions.getPhaseDocuments(idProgram, idFaza));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAnexaFiles({
      ...Object.keys(reportDocuments).reduce((a, v) => ({ ...a, [reportDocuments[v]]: null }), {})
    });
  }, [reportDocuments]);

  useEffect(() => {
    if (reporting.cycles && reporting.cycles.cicluri) {
      const currentCycle = reporting.cycles.cicluri.find((cycle) => cycle.idCiclu === +idCiclu);
      if (reporting.phases && reporting.phases.faze) {
        let currentReportDocuments = { ...REPORT_DOCUMENTS_GENERAL };
        reporting.phases.faze.forEach((phase, index) => {
          if (phase.fazaGuid === +idFaza && index === 0) {
            // first phase
            currentReportDocuments = { ...currentReportDocuments, ...REPORT_DOCUMENTS_FIRST_PHASE };
          }

          if (phase.fazaGuid === +idFaza && index === reporting.phases.faze.length - 1) {
            // last phase
            currentReportDocuments = { ...currentReportDocuments, ...REPORT_DOCUMENTS_LAST_PHASE };

            if (moment(currentCycle.dataFinalizare).month() === 11 || currentCycle.cicluFinal) {
              // end of year
              currentReportDocuments = { ...currentReportDocuments, ...REPORT_DOCUMENTS_YEAR_END };
            }

            if (currentCycle.cicluFinal) {
              // end of project
              currentReportDocuments = {
                ...currentReportDocuments,
                ...REPORT_DOCUMENTS_PROJECT_END
              };
            }
          }
        });
        setReportDocuments({ ...currentReportDocuments });
      }
    }
  }, [reporting.phases, reporting.cycles, idCiclu, idFaza]);

  useEffect(() => {
    if (reporting.publication) {
      setShowPublicationForm(true);
    } else {
      setShowPublicationForm(false);
    }
  }, [reporting.publication]);

  useEffect(() => {
    if (reporting.patent) {
      setShowPatentForm(true);
    } else {
      setShowPatentForm(false);
    }
  }, [reporting.patent]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmitAnexe = () => {
    const formData = new FormData();
    const anexaTypes = [];
    Object.keys(anexaFiles).forEach((key) => {
      if (anexaFiles[key] !== null) {
        anexaTypes.push(key);
        formData.append('files', anexaFiles[key]);
      }
    });
    formData.append('anexaTypes', anexaTypes);
    formData.append('fazaId', idFaza);
    formData.append('cicluId', idCiclu);
    dispatch(
      reportActions.savePhaseDocuments(idProgram, formData, () => {
        setAnexaFiles({
          ...Object.keys(reportDocuments).reduce(
            (a, v) => ({ ...a, [reportDocuments[v]]: null }),
            {}
          )
        });
        dispatch(reportActions.getPhaseDocuments(idProgram, idFaza));
      })
    );
  };

  const publicationFormik = {
    initialValues: getInitialPublicationFormValues(reporting.publication || {}),
    enableReinitialize: true,
    validationSchema: publicationFormValidationSchema(),
    onSubmit: (values) => {
      const valuesCopy = JSON.parse(JSON.stringify(values));
      valuesCopy[cycle.name] = idCiclu;
      valuesCopy[phase.name] = idFaza;
      dispatch(
        reportActions.savePhasePublication(idProgram, valuesCopy, () => {
          if (reporting.publication) {
            dispatch(reportActions.setEditPublication(null));
          }
          setShowPublicationForm(false);
          dispatch(reportActions.getPhasePublications(idProgram, idFaza));
        })
      );
    }
  };

  const patentFormik = {
    initialValues: getInitialPatentFormValues(reporting.patent || {}),
    enableReinitialize: true,
    validationSchema: patentFormValidationSchema(),
    onSubmit: (values) => {
      const valuesCopy = JSON.parse(JSON.stringify(values));
      valuesCopy[patentCycle.name] = idCiclu;
      valuesCopy[patentPhase.name] = idFaza;
      dispatch(
        reportActions.savePhasePatent(idProgram, valuesCopy, () => {
          if (reporting.patent) {
            dispatch(reportActions.setEditPatent(null));
          }
          setShowPatentForm(false);
          dispatch(reportActions.getPhasePatents(idProgram, idFaza));
        })
      );
    }
  };

  if (reporting?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Page title={intl.formatMessage({ id: 'programReport.project.cycle.phase.reporting' })} my={2}>
      <Container>
        <TabContext value={value}>
          <TabList onChange={handleChange} aria-label="tabs">
            <Tab label={intl.formatMessage({ id: 'phase.reporting.tab1' })} value="1" />
            <Tab label={intl.formatMessage({ id: 'phase.reporting.tab2' })} value="2" />
            <Tab label={intl.formatMessage({ id: 'phase.reporting.tab3' })} value="3" />
          </TabList>
          <TabPanel value="1" index={0} key={0} sx={{ pl: 0 }}>
            <Grid container spacing={0}>
              {!showPublicationForm && (
                <>
                  <Grid item xs={3} sm={3} sx={{ mb: 2 }}>
                    <Button variant="outlined" onClick={() => setShowPublicationForm(true)}>
                      {intl.formatMessage({ id: 'publication.add' })}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {reporting.publications &&
                      reporting.publications.publicatii &&
                      reporting.publications.publicatii.length > 0 && (
                        <CardList
                          entityId="id"
                          items={reporting.publications.publicatii}
                          Card={InstitutionProgramReportProjectCyclePhasePublicationCard}
                        />
                      )}
                  </Grid>
                </>
              )}
              {showPublicationForm && (
                <Formik
                  initialValues={publicationFormik.initialValues}
                  validationSchema={publicationFormik.validationSchema}
                  onSubmit={publicationFormik.onSubmit}
                  enableReinitialize
                >
                  {({
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    setFieldValue
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <>
                        <ColorButton
                          variant="outlined"
                          borderColor="#269620"
                          labelColor="#269620"
                          component="span"
                          onClick={handleSubmit}
                          sx={{ mr: 2 }}
                        >
                          {intl.formatMessage({ id: 'global.save' })}
                        </ColorButton>
                        <ColorButton
                          variant="outlined"
                          borderColor="#c23127"
                          labelColor="#c23127"
                          component="span"
                          onClick={() => {
                            setShowPublicationForm(false);
                            if (reporting.publication) {
                              dispatch(reportActions.setEditPublication(null));
                            }
                          }}
                        >
                          {intl.formatMessage({ id: 'global.cancel' })}
                        </ColorButton>
                      </>
                      <Grid container spacing={3} sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12}>
                          <EditableFormSelect
                            parentName={publication.name}
                            childrenNames={[publication.type.name]}
                            formik={{
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              values
                            }}
                            inputLabel={intl.formatMessage({ id: publication.type.label })}
                            options={Object.keys(PUBLICATION_TYPES).map((key) => ({
                              id: key,
                              label: PUBLICATION_TYPES[key]
                            }))}
                            label={intl.formatMessage({ id: publication.type.label })}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <EditableFormTextField
                            parentName={publication.name}
                            childrenNames={[publication.title.name]}
                            formik={{
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              values
                            }}
                            label={intl.formatMessage({ id: publication.title.label })}
                            variant="outlined"
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <EditableFormTextField
                            parentName={publication.name}
                            childrenNames={[publication.description.name]}
                            formik={{
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              values
                            }}
                            label={intl.formatMessage({ id: publication.description.label })}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <EditableFormTextField
                            parentName={publication.name}
                            childrenNames={[publication.pagesArticle.name]}
                            formik={{
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              values
                            }}
                            label={intl.formatMessage({ id: publication.pagesArticle.label })}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <EditableFormTextField
                            parentName={publication.name}
                            childrenNames={[publication.publishingHouse.name]}
                            formik={{
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              values
                            }}
                            label={intl.formatMessage({
                              id: publication.publishingHouse.label
                            })}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <EditableFormTextField
                            parentName={publication.name}
                            childrenNames={[publication.doi.name]}
                            formik={{
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              values
                            }}
                            label={intl.formatMessage({ id: publication.doi.label })}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <EditableFormTextField
                            parentName={publication.name}
                            childrenNames={[publication.wos.name]}
                            formik={{
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              values
                            }}
                            label={intl.formatMessage({ id: publication.wos.label })}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <EditableFormDatePicker
                            parentName={publication.name}
                            childrenNames={[publication.publicationDate.name]}
                            formik={{
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              setFieldValue,
                              values
                            }}
                            label={intl.formatMessage({
                              id: publication.publicationDate.label
                            })}
                            variant="outlined"
                            format={defaultConfig.dateTimeFormats.short}
                            value={values[publication.publicationDate.name]}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <EditableFormTextField
                            parentName={publication.name}
                            childrenNames={[publication.link.name]}
                            formik={{
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              values
                            }}
                            label={intl.formatMessage({ id: publication.link.label })}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <EditableFormSelect
                            parentName={publication.name}
                            childrenNames={[publication.category.name]}
                            formik={{
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              values
                            }}
                            inputLabel={intl.formatMessage({ id: publication.category.label })}
                            options={Object.keys(PUBLICATION_CATEGORIES).map((key) => ({
                              id: key,
                              label: PUBLICATION_CATEGORIES[key]
                            }))}
                            label={intl.formatMessage({ id: publication.category.label })}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FieldArray
                            name={`${publication.name}.${publication.keywords.name}`}
                            render={(arrayHelpers) => (
                              <KeywordField
                                parentName={publication.name}
                                childrenNames={[publication.keywords.name]}
                                disabled={false}
                                values={values}
                                keywordField={publication.keywords}
                                arrayHelpers={arrayHelpers}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FieldArray
                            name={`${publication.name}.${publication.authors.name}`}
                            render={(arrayHelpers) => (
                              <KeywordField
                                parentName={publication.name}
                                childrenNames={[publication.authors.name]}
                                disabled={false}
                                values={values}
                                keywordField={publication.authors}
                                name={`${publication.name}[${publication.authors.name}]`}
                                arrayHelpers={arrayHelpers}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </LocalizationProvider>
                  )}
                </Formik>
              )}
            </Grid>
          </TabPanel>
          <TabPanel value="2" index={1} key={1} sx={{ pl: 0 }}>
            <Grid container spacing={0}>
              {!showPatentForm && (
                <>
                  <Grid item xs={3} sm={3} sx={{ mb: 2 }}>
                    <Button variant="outlined" onClick={() => setShowPatentForm(true)}>
                      {intl.formatMessage({ id: 'patent.add' })}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {reporting.patents &&
                      reporting.patents.brevete &&
                      reporting.patents.brevete.length > 0 && (
                        <CardList
                          entityId="id"
                          items={reporting.patents.brevete}
                          Card={InstitutionProgramReportProjectCyclePhasePatentCard}
                        />
                      )}
                  </Grid>
                </>
              )}
              {showPatentForm && (
                <Formik
                  initialValues={patentFormik.initialValues}
                  validationSchema={patentFormik.validationSchema}
                  onSubmit={patentFormik.onSubmit}
                  enableReinitialize
                >
                  {({ handleChange, handleBlur, values, errors, touched, handleSubmit }) => (
                    <>
                      <ColorButton
                        variant="outlined"
                        borderColor="#269620"
                        labelColor="#269620"
                        component="span"
                        onClick={handleSubmit}
                        sx={{ mr: 2 }}
                      >
                        {intl.formatMessage({ id: 'global.save' })}
                      </ColorButton>
                      <ColorButton
                        variant="outlined"
                        borderColor="#c23127"
                        labelColor="#c23127"
                        component="span"
                        onClick={() => {
                          setShowPatentForm(false);
                          if (reporting.patent) {
                            dispatch(reportActions.setEditPatent(null));
                          }
                        }}
                      >
                        {intl.formatMessage({ id: 'global.cancel' })}
                      </ColorButton>
                      <Grid container spacing={3} sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <EditableFormSelect
                            parentName={patent.name}
                            childrenNames={[patent.status.name]}
                            formik={{
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              values
                            }}
                            inputLabel={intl.formatMessage({ id: patent.status.label })}
                            options={Object.keys(PATENT_STATUSES).map((key) => ({
                              id: key,
                              label: PATENT_STATUSES[key]
                            }))}
                            label={intl.formatMessage({ id: patent.status.label })}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <EditableFormTextField
                            parentName={patent.name}
                            childrenNames={[patent.number.name]}
                            formik={{
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              values
                            }}
                            label={intl.formatMessage({ id: patent.number.label })}
                            variant="outlined"
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <EditableFormTextField
                            parentName={patent.name}
                            childrenNames={[patent.roName.name]}
                            formik={{
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              values
                            }}
                            label={intl.formatMessage({ id: patent.roName.label })}
                            variant="outlined"
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <EditableFormTextField
                            parentName={patent.name}
                            childrenNames={[patent.enName.name]}
                            formik={{
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              values
                            }}
                            label={intl.formatMessage({ id: patent.enName.label })}
                            variant="outlined"
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <EditableFormTextField
                            parentName={patent.name}
                            childrenNames={[patent.roDescription.name]}
                            formik={{
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              values
                            }}
                            label={intl.formatMessage({ id: patent.roDescription.label })}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <EditableFormTextField
                            parentName={patent.name}
                            childrenNames={[patent.enDescription.name]}
                            formik={{
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              values
                            }}
                            label={intl.formatMessage({ id: patent.enDescription.label })}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <EditableFormTextField
                            parentName={patent.name}
                            childrenNames={[patent.proposalYear.name]}
                            formik={{
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              values
                            }}
                            label={intl.formatMessage({ id: patent.proposalYear.label })}
                            variant="outlined"
                            fullWidth
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <EditableFormTextField
                            parentName={patent.name}
                            childrenNames={[patent.acceptanceYear.name]}
                            formik={{
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              values
                            }}
                            label={intl.formatMessage({ id: patent.acceptanceYear.label })}
                            variant="outlined"
                            fullWidth
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <EditableFormSelect
                            parentName={patent.name}
                            childrenNames={[patent.type.name]}
                            formik={{
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              values
                            }}
                            inputLabel={intl.formatMessage({ id: patent.type.label })}
                            options={Object.keys(PATENT_TYPES).map((key) => ({
                              id: key,
                              label: PATENT_TYPES[key]
                            }))}
                            label={intl.formatMessage({ id: patent.type.label })}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FieldArray
                            name={`${patent.name}.${patent.authors.name}`}
                            render={(arrayHelpers) => (
                              <KeywordField
                                parentName={patent.name}
                                childrenNames={[patent.authors.name]}
                                disabled={false}
                                values={values}
                                keywordField={patent.authors}
                                name={`${patent.name}[${patent.authors.name}]`}
                                arrayHelpers={arrayHelpers}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Formik>
              )}
            </Grid>
          </TabPanel>
          <TabPanel value="3" index={2} key={2}>
            <Grid container>
              {Object.keys(reportDocuments).map((key) => (
                <Fragment key={key}>
                  <Grid item xs={9} sx={{ mb: 2 }}>
                    <TextField
                      id="key"
                      label={intl.formatMessage({ id: reportDocuments[key] })}
                      value={
                        // eslint-disable-next-line no-nested-ternary
                        anexaFiles[reportDocuments[key]]
                          ? anexaFiles[reportDocuments[key]].name
                          : reporting.documents &&
                            reporting.documents.anexe &&
                            reporting.documents.anexe.find(
                              (anexa) => anexa.anexaType === pascalizeKeys(reportDocuments[key])
                            )
                          ? reporting.documents.anexe.find(
                              (anexa) => anexa.anexaType === pascalizeKeys(reportDocuments[key])
                            ).numeFisierOriginal
                          : ''
                      }
                      variant="outlined"
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={2} sx={{ mb: 2 }}>
                    <FileInputStyle
                      type="file"
                      name={key}
                      id={key}
                      onChange={(event) => {
                        setAnexaFiles({
                          ...anexaFiles,
                          [reportDocuments[key]]: event.target.files[0]
                        });
                      }}
                    />
                    <label htmlFor={key}>
                      <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                      >
                        {reporting.documents &&
                          reporting.documents.anexe &&
                          reporting.documents.anexe.find(
                            (anexa) => anexa.anexaType === pascalizeKeys(reportDocuments[key])
                          ) && (
                            <ColorButton
                              variant="outlined"
                              borderColor="#005db7"
                              labelColor="#005db7"
                              component="span"
                              onClick={(event) => {
                                event.preventDefault();
                                dispatch(
                                  documentActions.downloadAnexaRaport(
                                    idProgram,
                                    idFaza,
                                    idCiclu,
                                    reportDocuments[key]
                                  )
                                );
                              }}
                            >
                              {intl.formatMessage({ id: 'global.download' })}
                            </ColorButton>
                          )}
                        {reporting.documents &&
                          reporting.documents.anexe &&
                          !reporting.documents.anexe.find(
                            (anexa) => anexa.anexaType === pascalizeKeys(reportDocuments[key])
                          ) && (
                            <ColorButton
                              variant="outlined"
                              borderColor="#005db7"
                              labelColor="#005db7"
                              component="span"
                            >
                              {intl.formatMessage({ id: 'global.upload' })}
                            </ColorButton>
                          )}
                      </Box>
                    </label>
                  </Grid>
                </Fragment>
              ))}
              <Grid item xs={2} sm={2}>
                <Button
                  variant="contained"
                  sx={{ marginTop: 2, mb: 2 }}
                  onClick={() => handleSubmitAnexe()}
                >
                  {intl.formatMessage({ id: 'global.save' })}
                </Button>
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Container>
    </Page>
  );
}
