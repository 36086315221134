import resurseUmaneForm from './resurseUmaneFormModel';

const { resurseUmaneFormFields, resurseUmaneFormSubfields } = resurseUmaneForm;

export const getInitialResurseUmaneFormValues = (proiect) => {
  const initialValues = {};
  resurseUmaneFormFields.forEach((field) => {
    initialValues[field.name] = {};
    resurseUmaneFormSubfields.forEach((subfield) => {
      initialValues[field.name][subfield.name] =
        proiect?.[resurseUmaneForm.name]?.[field.name]?.[subfield.name] ?? '';
    });
  });
  return { [resurseUmaneForm.name]: initialValues };
};
