import { useIntl } from 'react-intl';
import { Backdrop, CircularProgress, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import Page from '../components/Page';
import CardList from '../components/lists/CardList';
import { reportActions } from '../actions';
import ReporterProgramProjectCycleCard from '../components/cards/ReporterProgramProjectCycleCard';
// ----------------------------------------------------------------------

export default function ReporterProgramProjectCycles() {
  const { idProiect } = useParams();
  const intl = useIntl();

  const [sort, setSort] = useState('ascending');
  const [searchText, setSearchText] = useState('');
  const [cycleItems, setCycleItems] = useState([]);
  const reporting = useSelector((state) => state.report);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reportActions.getEvaluatorProjectFinalCycles(idProiect));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reporting && reporting.finalCycles) {
      setCycleItems(
        reporting.finalCycles.sort((c1, c2) => c1.numeCiclu.localeCompare(c2.numeCiclu))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reporting]);

  useEffect(() => {
    let filteredCycles = [];
    if (reporting && reporting.finalCycles && reporting.finalCycles.length > 0) {
      if (searchText && searchText.length > 0) {
        filteredCycles =
          reporting.finalCycles &&
          reporting.finalCycles.filter((cycle) =>
            cycle.numeCiclu.toLowerCase().includes(searchText.toLowerCase())
          );
      } else {
        filteredCycles = reporting.finalCycles;
      }
    }

    filteredCycles = filteredCycles.sort((c1, c2) =>
      sort === 'ascending'
        ? c1.numeCiclu.toLowerCase().localeCompare(c2.numeCiclu.toLowerCase())
        : c2.numeCiclu.toLowerCase().localeCompare(c1.numeCiclu.toLowerCase())
    );

    setCycleItems([...filteredCycles]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, searchText]);

  if (reporting?.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Page title={intl.formatMessage({ id: 'program.cycles.title' })} my={2}>
      <Container>
        {cycleItems && (
          <CardList
            entityId="cicluId"
            sort={sort}
            setSort={setSort}
            searchText={searchText}
            setSearchText={setSearchText}
            sortFieldTitle={intl.formatMessage({ id: 'global.name' })}
            items={cycleItems}
            Card={ReporterProgramProjectCycleCard}
          />
        )}
      </Container>
    </Page>
  );
}
