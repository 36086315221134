import publicationsFormModel from './publicationsFormModel';

const {
  publicationsField: { publication, cycle, phase }
} = publicationsFormModel;

export const getInitialPublicationFormValues = (publicationData) => ({
  [publication.name]:
    publication && publicationData[publication.name]
      ? {
          [publication.id.name]: publicationData[publication.name][publication.id.name]
            ? publicationData[publication.name][publication.id.name]
            : '',
          [publication.type.name]: publicationData[publication.name][publication.type.name]
            ? publicationData[publication.name][publication.type.name]
            : '',
          [publication.title.name]: publicationData[publication.name][publication.title.name]
            ? publicationData[publication.name][publication.title.name]
            : '',
          [publication.description.name]: publicationData[publication.name][
            publication.description.name
          ]
            ? publicationData[publication.name][publication.description.name]
            : '',
          [publication.pagesArticle.name]: publicationData[publication.name][
            publication.pagesArticle.name
          ]
            ? publicationData[publication.name][publication.pagesArticle.name]
            : '',
          [publication.publishingHouse.name]: publicationData[publication.name][
            publication.publishingHouse.name
          ]
            ? publicationData[publication.name][publication.publishingHouse.name]
            : '',
          [publication.doi.name]: publicationData[publication.name][publication.doi.name]
            ? publicationData[publication.name][publication.doi.name]
            : '',
          [publication.wos.name]: publicationData[publication.name][publication.wos.name]
            ? publicationData[publication.name][publication.wos.name]
            : '',
          [publication.publicationDate.name]: publicationData[publication.name][
            publication.publicationDate.name
          ]
            ? publicationData[publication.name][publication.publicationDate.name]
            : '',
          [publication.link.name]: publicationData[publication.name][publication.link.name]
            ? publicationData[publication.name][publication.link.name]
            : '',
          [publication.category.name]: publicationData[publication.name][publication.category.name]
            ? publicationData[publication.name][publication.category.name]
            : '',
          [publication.keywords.name]: publicationData[publication.name][publication.keywords.name]
            ? publicationData[publication.name][publication.keywords.name]
            : [],
          [publication.authors.name]: publicationData[publication.name][publication.authors.name]
            ? publicationData[publication.name][publication.authors.name]
            : []
        }
      : {
          [publication.id.name]: 0,
          [publication.type.name]: '',
          [publication.title.name]: '',
          [publication.description.name]: '',
          [publication.pagesArticle.name]: '',
          [publication.publishingHouse.name]: '',
          [publication.doi.name]: '',
          [publication.wos.name]: '',
          [publication.publicationDate.name]: '',
          [publication.link.name]: '',
          [publication.category.name]: '',
          [publication.keywords.name]: [],
          [publication.authors.name]: []
        },
  [cycle.name]: publication && publicationData[cycle.name] ? publicationData[cycle.name] : '',
  [phase.name]: publication && publicationData[phase.name] ? publicationData[phase.name] : ''
});
