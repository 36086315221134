const competitionProgramEvaluationForm = {
  formId: 'competitionProgramEvaluationForm',
  competitionProgramEvaluationField: {
    criteria: {
      name: 'Criterii',
      requiredErrorMsg: 'form.error.required',
      title: {
        name: 'Denumire',
        label: 'competition.criteria',
        requiredErrorMsg: 'form.error.required'
      },
      score: {
        name: 'ScorMaxim',
        label: 'competition.score',
        requiredErrorMsg: 'form.error.required',
        numberErrorMsg: 'form.error.number.positive'
      }
    }
  }
};

export default competitionProgramEvaluationForm;
