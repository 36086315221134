const informatiiGeneraleForm = {
  formId: 'informatiiGenerale',
  informatiiGeneraleFormFields: {
    projectTitle: {
      name: 'titluProiect',
      label: 'form.project.info.projectTitle'
    },
    keywords: {
      name: 'cuvinteCheie',
      label: 'form.project.info.keywords',
      button: 'form.project.info.keywords.button.label'
    },
    responsabilProiect: {
      name: 'responsabilProiect'
    },
    responsabil: {
      name: 'user',
      label: 'form.project.info.responsabil'
    },
    cnpResponsabil: {
      name: 'cNP',
      label: 'form.project.info.cnpResponsabil',
      invalidFormatErrorMsg: 'form.cnp.error'
    },
    titluResponsabil: {
      name: 'titlu',
      label: 'form.project.info.titluResponsabil'
    },
    functieResponsabil: {
      name: 'functie',
      label: 'form.project.info.functieResponsabil'
    },
    telResponsabil: {
      name: 'telefon',
      label: 'form.project.info.telResponsabil',
      invalidFormatErrorMsg: 'form.phone.error'
    },
    faxResponsabil: {
      name: 'fax',
      label: 'form.project.info.faxResponsabil',
      invalidFormatErrorMsg: 'form.phone.error'
    },
    emailResponsabil: {
      name: 'email',
      label: 'form.project.info.emailResponsabil',
      invalidFormatErrorMsg: 'form.email.error'
    },
    cvResponsabil: {
      name: 'cvResponsabil',
      label: 'form.project.info.CVResponsabil'
    },
    domenii: {
      name: 'domeniiPrincipalSecundare',
      label: 'form.project.info.domenii',
      invalidFormatErrorMsg: 'form.email.error'
    },
    prezentareaProiectului: {
      name: 'prezentareaProiectului',
      label: 'form.project.info.prezentare',
      invalidFormatErrorMsg: 'form.email.error'
    },
    descriereaStiintificaFile: {
      name: 'descriereaStiintificaFile',
      label: 'form.project.info.descriereaStiintifica'
    },
    structuraBugetuluiFile: {
      name: 'structuraBugetuluiFile',
      label: 'form.project.info.structuraBugetului'
    },
    managementulRisculuiFile: {
      name: 'managementulRisculuiFile',
      label: 'form.project.info.managementulRiscului'
    },
    impactFile: {
      name: 'impactFile',
      label: 'form.project.info.impact'
    },
    planFile: {
      name: 'planFile',
      label: 'form.project.info.plan'
    },
    alteInformatiiFile: {
      name: 'alteInformatiiFile',
      label: 'form.project.info.altele'
    }
  }
};

export default informatiiGeneraleForm;
