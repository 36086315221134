import { reportConstants } from '../constants';

export function report(
  state = {
    loading: false,
    saving: false,
    availableProjects: [],
    evaluators: [],
    documents: [],
    patents: [],
    publications: [],
    results: [],
    phases: [],
    cycles: [],
    programs: [],
    finalCycles: [],
    publication: null,
    patent: null,
    reportInfo: null
  },
  action
) {
  switch (action.type) {
    case reportConstants.GET_AVAILABLE_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
        availableProjects: []
      };
    case reportConstants.GET_ASSIGNED_REPORT_EVALUATORS_REQUEST:
      return {
        ...state,
        loading: true,
        evaluators: []
      };
    case reportConstants.GET_PHASE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        documents: [],
        patents: [],
        publications: [],
        reportInfo: null
      };
    case reportConstants.GET_PHASE_DOCUMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        documents: []
      };
    case reportConstants.GET_PHASE_PATENTS_REQUEST:
      return {
        ...state,
        loading: true,
        patents: []
      };
    case reportConstants.GET_PHASE_PUBLICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        publications: []
      };
    case reportConstants.GET_CYCLE_RESULTS_REQUEST:
      return {
        ...state,
        loading: true,
        results: []
      };
    case reportConstants.GET_CYCLE_PHASES_REQUEST:
      return {
        ...state,
        loading: true,
        phases: []
      };
    case reportConstants.GET_PROJECT_CYCLES_REQUEST:
      return {
        ...state,
        loading: true,
        cycles: []
      };
    case reportConstants.GET_EVALUATOR_PROGRAMS_REQUEST:
      return {
        ...state,
        loading: true,
        programs: []
      };
    case reportConstants.GET_FINAL_CYCLES_REQUEST:
      return {
        ...state,
        loading: true,
        finalCycles: []
      };
    case reportConstants.GET_AVAILABLE_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        availableProjects: action.projects
      };
    case reportConstants.GET_ASSIGNED_REPORT_EVALUATORS_SUCCESS:
      return {
        ...state,
        loading: false,
        evaluators: action.evaluators
      };
    case reportConstants.GET_PHASE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: action.data.anexe,
        patents: action.data.brevete,
        publications: action.data.publicatii,
        reportInfo: action.data.evaluareRaportare
      };
    case reportConstants.GET_PHASE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: action.documents
      };
    case reportConstants.GET_PHASE_PATENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        patents: action.patents
      };
    case reportConstants.GET_PHASE_PUBLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        publications: action.publications
      };
    case reportConstants.GET_CYCLE_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        results: action.results
      };
    case reportConstants.GET_CYCLE_PHASES_SUCCESS:
      return {
        ...state,
        loading: false,
        phases: action.phases
      };
    case reportConstants.GET_PROJECT_CYCLES_SUCCESS:
      return {
        ...state,
        loading: false,
        cycles: action.cycles
      };
    case reportConstants.GET_EVALUATOR_PROGRAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        programs: action.programs.programe
      };
    case reportConstants.GET_FINAL_CYCLES_SUCCESS:
      return {
        ...state,
        loading: false,
        finalCycles: action.cycles.cicluriFinale
      };
    case reportConstants.SET_EDIT_PUBLICATION:
      return {
        ...state,
        loading: false,
        publication: action.publication
      };
    case reportConstants.SET_EDIT_PATENT:
      return {
        ...state,
        loading: false,
        patent: action.patent
      };
    case reportConstants.GET_AVAILABLE_PROJECTS_FAILURE:
    case reportConstants.GET_ASSIGNED_REPORT_EVALUATORS_FAILURE:
    case reportConstants.GET_PHASE_DATA_FAILURE:
    case reportConstants.GET_PHASE_DOCUMENTS_FAILURE:
    case reportConstants.GET_PHASE_PATENTS_FAILURE:
    case reportConstants.GET_PHASE_PUBLICATIONS_FAILURE:
    case reportConstants.GET_CYCLE_RESULTS_FAILURE:
    case reportConstants.GET_CYCLE_PHASES_FAILURE:
    case reportConstants.GET_PROJECT_CYCLES_FAILURE:
    case reportConstants.GET_EVALUATOR_PROGRAMS_FAILURE:
    case reportConstants.GET_FINAL_CYCLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
